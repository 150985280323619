import React from 'react';
import '../cards.scss';

import { Card } from 'react-bootstrap';
import ReadOnlyRatings from '../../ratings/ReadOnlyRatings';
import SendGift from '../../gift/SendGift';
import MainCard from '../mainCard';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { calendarIcon } from '../SVGs';
// import swal from 'sweetalert';
import { protectAxios } from '../../../apis/coursesAPI';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStart } from '../../../features/courses/cartList';
// import CartService from '../../../services/CartSrevices';
import { useQueryClient } from '@tanstack/react-query';

import {
  useAddToCartList,
  useEnroll,
} from '../../dropdownCart/hooks/useCartList';
import { message } from 'antd';
import { toNumber } from 'lodash';
import {
  singleCourse,
  fetchStart as fetchStartSingleCourse,
} from '../../../features/singleCourse/singleCourse';
import { Highlight } from 'react-instantsearch-dom';

dayjs.extend(duration);

function CourseCard({
  course,
  levels,
  sliderToggle,
  isWishlist,
  hits,
  algolia,
}) {
  const accesstoken = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { is_enrolled } = course.isEnrolled;
  const duration = course?.total_duration;
  const hours = duration?.slice(0, duration?.indexOf(':'));
  let oneDigitHour = duration?.slice(1, duration?.indexOf(':'));
  const { currentUser } = useSelector((state) => state?.user);

  let courseImg = course?.thumbnailurl;

  // const getCartList = () => {
  //   return CartService.getCartList();
  // };

  const onSuccsses = (data) => {
    message.success(data.data.message, 5);

    dispatch(fetchStart());
    queryClient.invalidateQueries([`cart-info`]);
  };
  const onenrollSuccsses = (data) => {
    message.success(data.data.message, 5);
    queryClient.invalidateQueries([`cart-info`]);
    navigate('/student-dashboard/my-courses');
    dispatch(fetchStart());
  };
  const onFail = (data) => {
    message.error(data.response.data.message, 5);
  };
  const { mutate: addToCart } = useAddToCartList(onSuccsses, onFail);
  const { mutate: enroll } = useEnroll(onenrollSuccsses);

  if (course) {
    const trimText = (text, txtNo) => {
      // let text = course?.objective;
      if (text?.length > txtNo) {
        return text?.slice(0, txtNo) + '...';
      } else {
        return text;
      }
    };

    const addToCard = (id) => {
      addToCart({
        item_id: id,
        item_type: 'course',
      });
    };
    const enrollNow = (id) => {
      if (currentUser?.isVerified) {
        enroll({ course_id: id });
      } else {
        message.error('Your email address has not been verified', 5);
      }
    };

    const goToCourse = () => {
      navigate('/course-view/' + course?.slug || course?.id);
    };
    const fetchCourseDetails = async (slug) => {
      dispatch(fetchStartSingleCourse());
      const response = await protectAxios.get(
        `lecture/course/${slug}?includes=author,reviews,author.reviews`,
      );
      dispatch(singleCourse(response.data.data));
    };
    const courseType = () => {
      if (is_enrolled) {
        return 'View Course';
      } else if (course?.price_plan !== 'free' && !is_enrolled) {
        return 'Add to cart';
      } else {
        return ' Enroll Now';
      }
    };
    const courseTypePtn = () => {
      if (is_enrolled && !!accesstoken) {
        return () => goToCourse();
      } else if (
        course.price_plan !== 'free' &&
        !is_enrolled &&
        !!accesstoken
      ) {
        return () => addToCard(course.id);
      } else if (!course.price && !is_enrolled && !!accesstoken) {
        return () => enrollNow(course.id);
      } else {
        return () => navigate('/sign-in');
      }
    };

    const currentLevel = (id) => {
      let levelArr;
      let level;
      if (levels) {
        levelArr = levels.filter((i) => i.id === id);
        level = levelArr[0]?.title;
      }
      return level;
    };

    if (!course) return;

    return (
      <>
        <MainCard
          favorite={is_enrolled ? null : course.id}
          isWishlist={isWishlist}
          slider={sliderToggle}
          courseImg={
            courseImg
              ? courseImg
              : 'https://img-c.udemycdn.com/course/240x135/4427730_5388.jpg'
          }
          imgAlt={course.title}
          title={
            <Link
              to={'/course-view/' + course?.slug || course?.id}
              onClick={() => {
                // alert("i'm clicked");
                fetchCourseDetails(course.slug);
              }}
            >
              <Card.Title
                className="card-course-title"
                title={
                  algolia ? (
                    <Highlight attribute="title" hit={hits} />
                  ) : (
                    course.title
                  )
                }
              >
                {algolia ? (
                  <Highlight attribute="title" hit={hits} />
                ) : (
                  trimText(course?.title, 40)
                )}
              </Card.Title>
            </Link>
          }
          instructorName={
            course.author?.name ? (
              algolia ? (
                <Highlight attribute="author.name" hit={hits} />
              ) : (
                course.author?.name
              )
            ) : (
              'Unknown'
            )
          }
          coursePath={'/course-view/' + course?.slug || course?.id}
          coursePrice={
            course.sale_price ? (
              <div>
                <span className="afterSale">{course.sale_price}EGP </span>
                <span className="beforeSale">
                  <small className="text-muted">{course.price}EGP</small>
                </span>
              </div>
            ) : course.price ? (
              <span className="afterSale">{course.price}EGP </span>
            ) : (
              'Free'
            )
          }
          instructorPage={'/instructors/' + course?.user_id}
          addSmlButton={true}
          btnTxt={courseType()}
          addToCard={courseTypePtn()}
          gift={
            <div className="gift-btn">
              <SendGift />
            </div>
          }
        >
          <p>{trimText(course?.short_description, 55)}</p>
          <div className="course-level-container d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {course?.level === 1 && (
                <img
                  src="/assets/images/icons/Mask Group 583.svg"
                  alt={currentLevel(1)}
                />
              )}
              {course?.level === undefined && (
                <img
                  src="/assets/images/icons/Mask Group 583.svg"
                  alt={currentLevel(1)}
                />
              )}
              {course?.level === 2 && (
                <img
                  src="/assets/images/icons/Mask Group 582.svg"
                  alt={currentLevel(2)}
                />
              )}
              {course?.level === 3 && (
                <img
                  src="/assets/images/icons/Icon awesome-signal.svg"
                  alt={currentLevel(3)}
                />
              )}
              {course?.level === 4 && (
                <img
                  src="/assets/images/icons/Icon awesome-signal.svg"
                  alt={currentLevel(4)}
                />
              )}

              <span className={'course-level ' + course.level}>
                {course.level ? currentLevel(course.level) : 'beginner'}
              </span>
            </div>

            <div className="d-flex align-items-center">
              {calendarIcon}
              <span className={'course-level ' + course.level}>
                {/* {dayjs("01-01-0000" + course?.total_duration).format("HH")}{" "}
                Hours */}
                {hours > 10 ? `${hours} hours` : `${oneDigitHour} hours`}
              </span>
            </div>
          </div>
          <span>
            {course.rating_value ? (
              <ReadOnlyRatings
                rating={toNumber(course.rating_value)}
                totalRating={toNumber(course.rating_count)}
              />
            ) : (
              <ReadOnlyRatings rating={toNumber(0)} />
            )}
          </span>
        </MainCard>
      </>
    );
  } else {
    return null;
  }
}

export default CourseCard;
