export const yallaOnlineSubscribe = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="329"
    height="283"
    viewBox="0 0 329 283"
  >
    <defs>
      <clipPath id="clip-path-yallasubscribe">
        <rect
          id="Rectangle_1249"
          data-name="Rectangle 1249"
          width="329"
          height="283"
          transform="translate(886 835.999)"
          fill="#dbdbdb"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_374"
      data-name="Mask Group 374"
      transform="translate(-886 -835.999)"
      clipPath="url(#clip-path-yallasubscribe)"
    >
      <g id="Subscriber-rafiki" transform="translate(863.143 792.764)">
        <g
          id="freepik--background-complete--inject-37"
          transform="translate(0 36.19)"
        >
          <rect
            id="Rectangle_2948"
            data-name="Rectangle 2948"
            width="389.26"
            height="0.195"
            transform="translate(0 254.887)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2949"
            data-name="Rectangle 2949"
            width="25.785"
            height="0.195"
            transform="translate(102.352 266.892)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2950"
            data-name="Rectangle 2950"
            width="6.765"
            height="0.195"
            transform="translate(194.747 264.782)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2951"
            data-name="Rectangle 2951"
            width="14.94"
            height="0.195"
            transform="translate(40.841 261.497)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2952"
            data-name="Rectangle 2952"
            width="20.304"
            height="0.195"
            transform="translate(303.389 260.189)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2953"
            data-name="Rectangle 2953"
            width="18.248"
            height="0.195"
            transform="translate(278.204 260.189)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2954"
            data-name="Rectangle 2954"
            width="72.932"
            height="0.195"
            transform="translate(277.332 269.531)"
            fill="#ebebeb"
          />
          <path
            id="Path_8507"
            data-name="Path 8507"
            d="M179.9,256.355H29.58a4.445,4.445,0,0,1-4.438-4.445V40.6A4.445,4.445,0,0,1,29.58,36.19H179.9a4.445,4.445,0,0,1,4.445,4.445V251.91A4.445,4.445,0,0,1,179.9,256.355ZM29.58,36.346A4.251,4.251,0,0,0,25.337,40.6V251.91a4.251,4.251,0,0,0,4.243,4.251H179.9a4.259,4.259,0,0,0,4.251-4.251V40.6a4.259,4.259,0,0,0-4.251-4.251Z"
            transform="translate(4.605 -36.19)"
            fill="#ebebeb"
          />
          <path
            id="Path_8508"
            data-name="Path 8508"
            d="M322.238,256.355H171.906a4.453,4.453,0,0,1-4.445-4.445V40.6a4.453,4.453,0,0,1,4.445-4.406H322.238a4.445,4.445,0,0,1,4.43,4.406V251.91A4.445,4.445,0,0,1,322.238,256.355ZM171.906,36.346a4.258,4.258,0,0,0-4.251,4.251V251.91a4.259,4.259,0,0,0,4.251,4.251H322.238a4.259,4.259,0,0,0,4.251-4.251V40.6a4.259,4.259,0,0,0-4.251-4.251Z"
            transform="translate(30.672 -36.19)"
            fill="#ebebeb"
          />
          <path
            id="Path_8509"
            data-name="Path 8509"
            d="M239.071,119.82h22.951l-2.709-55.2H239.071Z"
            transform="translate(43.788 -30.984)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8510"
            data-name="Path 8510"
            d="M282.478,118.46l4.951,15.43h-4.951l-30.339-15.43Z"
            transform="translate(46.182 -21.121)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8511"
            data-name="Path 8511"
            d="M286.663,118.46v15.43l-30.339-15.43Z"
            transform="translate(46.948 -21.121)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8512"
            data-name="Path 8512"
            d="M170.684,118.46l4.951,15.43h-4.951l-30.339-15.43Z"
            transform="translate(25.706 -21.121)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8513"
            data-name="Path 8513"
            d="M174.869,118.46v15.43L144.53,118.46Z"
            transform="translate(26.472 -21.121)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2955"
            data-name="Rectangle 2955"
            width="19.58"
            height="9.046"
            transform="translate(322.883 88.922)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2956"
            data-name="Rectangle 2956"
            width="164.034"
            height="9.046"
            transform="translate(322.883 97.969) rotate(180)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8514"
            data-name="Path 8514"
            d="M182.009,121.631h83.06l6.088-66.9H188.1Z"
            transform="translate(33.337 -32.794)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8515"
            data-name="Path 8515"
            d="M179.851,121.631h83.068l6.088-66.9H185.939Z"
            transform="translate(32.941 -32.794)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8516"
            data-name="Path 8516"
            d="M258.057,113.081l5.574-52.091H189.36l-5.574,52.091Z"
            transform="translate(33.662 -31.648)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2957"
            data-name="Rectangle 2957"
            width="19.323"
            height="93.967"
            transform="translate(341.443 250.566) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_2958"
            data-name="Rectangle 2958"
            width="133.111"
            height="4.321"
            transform="translate(336.344 254.887) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_2959"
            data-name="Rectangle 2959"
            width="123.987"
            height="93.967"
            transform="translate(198.133 156.599)"
            fill="#e0e0f5"
          />
          <rect
            id="Rectangle_2960"
            data-name="Rectangle 2960"
            width="103.099"
            height="22.359"
            transform="translate(208.573 190.558)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_2961"
            data-name="Rectangle 2961"
            width="103.099"
            height="22.359"
            transform="translate(208.573 219.823)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8517"
            data-name="Path 8517"
            d="M199.3,199.027h49.218a1.557,1.557,0,0,0,1.557-1.557h0a1.557,1.557,0,0,0-1.557-1.557H199.3a1.557,1.557,0,0,0-1.557,1.557h0A1.557,1.557,0,0,0,199.3,199.027Z"
            transform="translate(36.218 -6.935)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8518"
            data-name="Path 8518"
            d="M199.3,223.755h49.218a1.557,1.557,0,0,0,1.557-1.557h0a1.557,1.557,0,0,0-1.557-1.557H199.3a1.557,1.557,0,0,0-1.557,1.557h0A1.557,1.557,0,0,0,199.3,223.755Z"
            transform="translate(36.218 -2.406)"
            fill="#e0e0f5"
          />
          <rect
            id="Rectangle_2962"
            data-name="Rectangle 2962"
            width="103.099"
            height="22.359"
            transform="translate(208.573 161.294)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8519"
            data-name="Path 8519"
            d="M199.3,174.293h49.218a1.557,1.557,0,0,0,1.557-1.557h0a1.557,1.557,0,0,0-1.557-1.557H199.3a1.557,1.557,0,0,0-1.557,1.557h0A1.557,1.557,0,0,0,199.3,174.293Z"
            transform="translate(36.218 -11.465)"
            fill="#e0e0f5"
          />
          <rect
            id="Rectangle_2963"
            data-name="Rectangle 2963"
            width="104.508"
            height="213.33"
            transform="translate(162.282 242.548) rotate(180)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2964"
            data-name="Rectangle 2964"
            width="107.903"
            height="213.33"
            transform="translate(161.722 242.548) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_2965"
            data-name="Rectangle 2965"
            width="104.508"
            height="3.675"
            transform="translate(162.282 246.222) rotate(180)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2966"
            data-name="Rectangle 2966"
            width="107.903"
            height="3.675"
            transform="translate(155.128 246.222) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_2967"
            data-name="Rectangle 2967"
            width="202.251"
            height="96.832"
            transform="translate(156.179 34.761) rotate(90)"
            fill="#fafafa"
          />
          <path
            id="Path_8520"
            data-name="Path 8520"
            d="M84.639,267.822,93.506,65.563h15.337L99.975,267.822Z"
            transform="translate(15.502 -30.81)"
            fill="#fff"
          />
          <path
            id="Path_8521"
            data-name="Path 8521"
            d="M129.9,125.92a.428.428,0,0,0,.428-.42V68.668a.428.428,0,0,0-.428-.42.42.42,0,0,0-.42.42V125.5A.42.42,0,0,0,129.9,125.92Z"
            transform="translate(23.716 -30.318)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8522"
            data-name="Path 8522"
            d="M100.384,267.822l8.867-202.259h5.979l-8.867,202.259Z"
            transform="translate(18.386 -30.81)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2968"
            data-name="Rectangle 2968"
            width="202.251"
            height="0.701"
            transform="translate(60.047 34.761) rotate(90)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8523"
            data-name="Path 8523"
            d="M44.376,72.454h105.8l.506-5.068H44.889Z"
            transform="translate(8.128 -30.476)"
            fill="#e0e0e0"
            opacity="0.5"
          />
          <path
            id="Path_8524"
            data-name="Path 8524"
            d="M44.376,79.509h105.8l.506-5.076H44.889Z"
            transform="translate(8.128 -29.185)"
            fill="#e0e0e0"
            opacity="0.5"
          />
          <path
            id="Path_8525"
            data-name="Path 8525"
            d="M44.376,86.556h105.8l.506-5.076H44.889Z"
            transform="translate(8.128 -27.895)"
            fill="#e0e0e0"
            opacity="0.5"
          />
          <path
            id="Path_8526"
            data-name="Path 8526"
            d="M44.376,93.6h105.8l.506-5.076H44.889Z"
            transform="translate(8.128 -26.604)"
            fill="#e0e0e0"
            opacity="0.5"
          />
          <path
            id="Path_8527"
            data-name="Path 8527"
            d="M44.376,100.649h105.8l.506-5.068H44.889Z"
            transform="translate(8.128 -25.312)"
            fill="#e0e0e0"
            opacity="0.5"
          />
          <path
            id="Path_8528"
            data-name="Path 8528"
            d="M44.376,107.7h105.8l.506-5.076H44.889Z"
            transform="translate(8.128 -24.021)"
            fill="#e0e0e0"
            opacity="0.5"
          />
        </g>
        <g
          id="freepik--Shadow--inject-37"
          transform="translate(43.683 308.609)"
        >
          <ellipse
            id="freepik--path--inject-37"
            cx="150.947"
            cy="8.813"
            rx="150.947"
            ry="8.813"
            fill="#e0e0f5"
          />
        </g>
        <g
          id="freepik--Character--inject-37"
          transform="translate(216.6 65.171)"
        >
          <path
            id="Path_8529"
            data-name="Path 8529"
            d="M242.993,84.845a1.744,1.744,0,0,0,.483-.779,1.682,1.682,0,1,0-3.27-.187,3.946,3.946,0,0,0-.677.1,3.285,3.285,0,0,0-2.265-1.876,2.211,2.211,0,0,0-.389-.047h0a3.624,3.624,0,0,0,.062-.568,3.566,3.566,0,0,0,1.495-5.566,3.527,3.527,0,0,0-1.557-6.75,4.43,4.43,0,0,0-3.41-4.165,3.353,3.353,0,0,0-.553-.07,3.293,3.293,0,0,0-2.452-2.445,3.114,3.114,0,0,0-1.285-.047,4.313,4.313,0,0,0-2.452-1.65,4.266,4.266,0,0,0-3.332.6,4.492,4.492,0,0,0-4.344.3,4.632,4.632,0,0,0-5.45.374h0a3.488,3.488,0,0,0-4.181,2.608,3.332,3.332,0,0,0-.055,1.238,2.336,2.336,0,0,0-2.554,2.99,5.886,5.886,0,0,0-3.223,7.863,2.008,2.008,0,0,0-.428.864,2.094,2.094,0,0,0,.973,2.273,4.36,4.36,0,0,0,1.183,6.143,2.53,2.53,0,0,0,2.118,3.262A3.27,3.27,0,0,0,210.015,93a2.336,2.336,0,0,0,3.114,1.97V95a2.406,2.406,0,0,0,3.293.366,4.336,4.336,0,0,0,6.952-3.893,1.79,1.79,0,0,0,.109.148,3.285,3.285,0,0,0,4.671.4l3.893-2.764a2.421,2.421,0,0,0,2.655-1.183,3.488,3.488,0,0,0,1.121.506,3.324,3.324,0,0,0,1,.062,1.19,1.19,0,0,0,.1.35,2.477,2.477,0,0,0-.529.989,2.625,2.625,0,1,0,5.06,1.4h0a3.8,3.8,0,0,0,1.612-6.524Z"
            transform="translate(-179.404 -60.684)"
            fill="#263238"
          />
          <path
            id="Path_8530"
            data-name="Path 8530"
            d="M228.879,148.005s-4.8,34.621-9.093,57.354c-4.5,23.877-21.339,77.72-22.063,79.339l6.353,2.81s28.649-45.434,29.311-73.523c11.172-17.127,17.493-36.59,19.463-47.677,2.491-14.013-3.114-26.516-3.114-26.516Z"
            transform="translate(-180.385 -46.195)"
            fill="#263238"
          />
          <path
            id="Path_8531"
            data-name="Path 8531"
            d="M239.847,159.8a.633.633,0,0,1-.078.195c-2.421,5.068-3.7,14.107-3.7,22.577a70.614,70.614,0,0,0,.514,8.758,142.785,142.785,0,0,0,7.957-22.811Z"
            transform="translate(-173.361 -42.53)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8532"
            data-name="Path 8532"
            d="M194.393,269.051s1.752-1.261,3.939-6.812a11.076,11.076,0,0,1,6.353,2.81c-1.728,3.737-3.737,7.085-3.737,7.085Z"
            transform="translate(-180.995 -23.768)"
            fill="#ffb573"
          />
          <path
            id="Path_8533"
            data-name="Path 8533"
            d="M204.424,269.406l-7.45-2.982a.732.732,0,0,0-.483,0c-1.074.343-6.228.708-12.386-1.02l-1.035,1.1a71.624,71.624,0,0,0,18.365,8.828,1.759,1.759,0,0,0,2.39-1.285,21.545,21.545,0,0,1,1.012-3.651A.779.779,0,0,0,204.424,269.406Z"
            transform="translate(-183.069 -23.188)"
            fill="#7e59d1"
          />
          <path
            id="Path_8534"
            data-name="Path 8534"
            d="M195.545,122.762l-1.191-1.635a6.8,6.8,0,0,0-5.707-2.818h0a2.452,2.452,0,0,0-2.242,1.635l-.965,2.772a2.437,2.437,0,0,0,1.316,3.044l1.308.584s4.352,2.538,8.065,2.156l.272-1a5.551,5.551,0,0,0-.856-4.741Z"
            transform="translate(-182.66 -50.131)"
            fill="#ffb573"
          />
          <path
            id="Path_8535"
            data-name="Path 8535"
            d="M227.51,114.062l.039.615v3.4c-.047,1.269-.171,2.522-.335,3.768a38.147,38.147,0,0,1-1.759,7.412,30.478,30.478,0,0,1-8.564,13.11l-.374.335-.187.163-.093.086-.234.187a7.108,7.108,0,0,1-2.406,1.269,8.12,8.12,0,0,1-3.97.132,14.791,14.791,0,0,1-4.438-1.845,37.121,37.121,0,0,1-5.87-4.547c-1.72-1.6-3.316-3.254-4.835-4.975a67.24,67.24,0,0,1-4.3-5.348A2.764,2.764,0,0,1,194.08,124l.062.047c3.425,2.577,6.905,5.255,10.37,7.521a35.036,35.036,0,0,0,5.053,2.888,4.531,4.531,0,0,0,1.72.49c.062,0-.078-.117-.7,0a2.95,2.95,0,0,0-.958.49h-.054l.055-.062.109-.117.226-.234A21.191,21.191,0,0,0,213,130.777a26.386,26.386,0,0,0,2.032-4.99,33.282,33.282,0,0,0,1.129-5.45c.125-.934.195-1.884.257-2.818,0-.467.039-.942.047-1.394v-1.65a5.521,5.521,0,1,1,11.032-.467Z"
            transform="translate(-181.858 -51.886)"
            fill="#ffb573"
          />
          <path
            id="Path_8536"
            data-name="Path 8536"
            d="M252.766,138.384s7.871,5.169,10.775,19.1c2.336,11.016.779,27.9-1.386,50.868,12.768,22.951,1.829,78.42,1.829,78.42h-6.937c.07-1.767-6.773-55.633-11.475-79.471C241.1,184.6,229.293,141.4,229.293,141.4Z"
            transform="translate(-174.602 -46.453)"
            fill="#263238"
          />
          <path
            id="Path_8537"
            data-name="Path 8537"
            d="M251.79,271.449s1.16-1.822,1.129-7.785c0,0,4.15-1.051,6.944.249-.218,4.118-.553,8.1-.553,8.1Z"
            transform="translate(-170.482 -23.58)"
            fill="#ffb573"
          />
          <path
            id="Path_8538"
            data-name="Path 8538"
            d="M261.835,268.313h-8.027a.709.709,0,0,0-.459.163,36.708,36.708,0,0,1-12.051,4.975l-.4,1.456h20.343a1.767,1.767,0,0,0,1.744-2.086,22.509,22.509,0,0,1-.42-3.76A.779.779,0,0,0,261.835,268.313Z"
            transform="translate(-172.476 -22.655)"
            fill="#7e59d1"
          />
          <path
            id="Path_8539"
            data-name="Path 8539"
            d="M213.02,119.155c.779,2.982-2.515,10.938,4.188,13.733a133.025,133.025,0,0,0,8.93,22.11l29.669-9.848c-5.06-10.2-5.06-15.57-5.1-35.812a7.9,7.9,0,0,0-8.743-7.847c-1.246.132-2.561.3-3.893.522a98.345,98.345,0,0,0-13.889,2.919c-1.471.452-2.982.965-4.422,1.487A10.72,10.72,0,0,0,213.02,119.155Z"
            transform="translate(-177.644 -53.219)"
            fill="#7e59d1"
          />
          <path
            id="Path_8540"
            data-name="Path 8540"
            d="M222.819,104.845s-6.641,6.555.989,16.466c2.281-8.338,12.9-19.393,12.9-19.393Z"
            transform="translate(-176.279 -53.132)"
            fill="#fff"
            opacity="0.3"
          />
          <path
            id="Path_8541"
            data-name="Path 8541"
            d="M231.838,88.942c.07,4.671.919,13.025,4.671,15.352,0,0-8.642,6.267-13.492,14.317-3.893-5.551-.4-11.39-.4-11.39,4.733-2.242,3.823-5.917,2.227-9.2Z"
            transform="translate(-176.089 -55.509)"
            fill="#ffb573"
          />
          <path
            id="Path_8542"
            data-name="Path 8542"
            d="M239.334,108.487h0l6.508,3.628h0l.086,8.984h0c-2.943-3.215-6.158-9.109-6.586-12.542v-.086Z"
            transform="translate(-172.763 -51.932)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8543"
            data-name="Path 8543"
            d="M228.421,92.074l-4.142,5.364a14.523,14.523,0,0,1,.95,2.374c1.845-.67,4.025-3.316,3.807-5.348A6.687,6.687,0,0,0,228.421,92.074Z"
            transform="translate(-175.521 -54.935)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8544"
            data-name="Path 8544"
            d="M236.015,82.857c-.21,7.637-.14,10.9-3.791,14.909-5.489,6.065-14.636,4.118-16.668-3.433-1.822-6.8-1.168-18.139,6.049-21.409S236.272,75.227,236.015,82.857Z"
            transform="translate(-177.276 -58.618)"
            fill="#ffb573"
          />
          <path
            id="Path_8545"
            data-name="Path 8545"
            d="M230.685,74.868a48.338,48.338,0,0,1-18,3.55S228.341,61.485,230.685,74.868Z"
            transform="translate(-177.644 -59.03)"
            fill="#263238"
          />
          <path
            id="Path_8546"
            data-name="Path 8546"
            d="M228.77,71.172c-3.893,3.651-5.123,14.091,4.827,16.2C238.2,84.983,244.706,68.167,228.77,71.172Z"
            transform="translate(-175.223 -58.829)"
            fill="#263238"
          />
          <path
            id="Path_8547"
            data-name="Path 8547"
            d="M223.849,83.328c0,.623-.288,1.137-.693,1.144s-.779-.475-.779-1.1.288-1.137.693-1.144S223.834,82.705,223.849,83.328Z"
            transform="translate(-175.869 -56.738)"
            fill="#263238"
          />
          <path
            id="Path_8548"
            data-name="Path 8548"
            d="M217.408,83.632c0,.615-.3,1.129-.7,1.144s-.779-.483-.779-1.1.3-1.137.7-1.152S217.362,82.978,217.408,83.632Z"
            transform="translate(-177.05 -56.684)"
            fill="#263238"
          />
          <path
            id="Path_8549"
            data-name="Path 8549"
            d="M216.912,82.612l-1.5-.381S216.211,83.367,216.912,82.612Z"
            transform="translate(-177.145 -56.738)"
            fill="#263238"
          />
          <path
            id="Path_8550"
            data-name="Path 8550"
            d="M219.325,84a16.434,16.434,0,0,1-2.086,4.033,2.663,2.663,0,0,0,2.227.343Z"
            transform="translate(-176.81 -56.414)"
            fill="#ffb573"
          />
          <path
            id="Path_8551"
            data-name="Path 8551"
            d="M222.389,90.165a5.309,5.309,0,0,1-1.035.125.171.171,0,0,1-.187-.171.187.187,0,0,1,.171-.195,4.889,4.889,0,0,0,3.947-2.048.182.182,0,0,1,.311.187A5.076,5.076,0,0,1,222.389,90.165Z"
            transform="translate(-176.091 -55.72)"
            fill="#263238"
          />
          <path
            id="Path_8552"
            data-name="Path 8552"
            d="M234.962,85.583a4.928,4.928,0,0,1-2.39,3.246c-1.557.779-2.772-.841-2.686-2.787.07-1.752.973-4.274,2.709-4.266S235.382,83.785,234.962,85.583Z"
            transform="translate(-174.495 -56.821)"
            fill="#ffb573"
          />
          <path
            id="Path_8553"
            data-name="Path 8553"
            d="M225.152,79.808a.35.35,0,0,1-.257-.125,2.912,2.912,0,0,0-2.413-1.051.374.374,0,0,1-.413-.311.366.366,0,0,1,.311-.413,3.62,3.62,0,0,1,3.067,1.3.358.358,0,0,1-.039.514.343.343,0,0,1-.257.086Z"
            transform="translate(-175.926 -57.532)"
            fill="#263238"
          />
          <path
            id="Path_8554"
            data-name="Path 8554"
            d="M215.018,80.951a.343.343,0,0,1-.272-.047.358.358,0,0,1-.117-.5,3.612,3.612,0,0,1,2.81-1.775.362.362,0,1,1,.023.724,2.881,2.881,0,0,0-2.211,1.432.358.358,0,0,1-.234.163Z"
            transform="translate(-177.299 -57.397)"
            fill="#263238"
          />
          <path
            id="Path_8555"
            data-name="Path 8555"
            d="M223.362,82.309l-1.51-.381S222.653,83.088,223.362,82.309Z"
            transform="translate(-175.965 -56.794)"
            fill="#263238"
          />
          <path
            id="Path_8556"
            data-name="Path 8556"
            d="M252.871,137.324l-5.535.958,5.45,7.334s4.188-2.787,5.489-6.29l-.779-.685a5.574,5.574,0,0,0-4.624-1.316Z"
            transform="translate(-171.298 -46.662)"
            fill="#ffb573"
          />
          <path
            id="Path_8557"
            data-name="Path 8557"
            d="M246.616,144.123l2.063,2.094a2.437,2.437,0,0,0,3.316.156l1.1-.919-5.45-7.334-1.518,3.278a2.444,2.444,0,0,0,.49,2.725Z"
            transform="translate(-171.559 -46.501)"
            fill="#ffb573"
          />
          <path
            id="Path_8558"
            data-name="Path 8558"
            d="M243.416,102.484c.716.234,1.238.42,1.837.646s1.144.444,1.713.67c1.129.452,2.242.95,3.34,1.464,2.2,1.02,4.344,2.172,6.454,3.41a57.791,57.791,0,0,1,11.818,9.109c.452.459.887.934,1.316,1.417l.638.732.156.187.288.343a7.431,7.431,0,0,1,.716,1.082,8.026,8.026,0,0,1,.779,2.157,9.157,9.157,0,0,1,.171,1.814,11.86,11.86,0,0,1-.389,2.764,21.145,21.145,0,0,1-1.557,4.048c-.592,1.183-1.238,2.266-1.9,3.316a57.855,57.855,0,0,1-4.29,5.839,63.586,63.586,0,0,1-4.827,5.232,2.764,2.764,0,0,1-4.368-3.285l.047-.086c2.079-3.893,4.251-7.871,5.886-11.732.413-.958.778-1.915,1.067-2.826a11.2,11.2,0,0,0,.584-2.437,1.463,1.463,0,0,0,0-.623s-.039,0,0,.117a1.487,1.487,0,0,0,.179.561,2.065,2.065,0,0,0,.234.389l.07.086-.07-.07-.132-.125-.522-.514c-.366-.335-.724-.677-1.105-1a57.392,57.392,0,0,0-10.214-6.929c-1.845-1.02-3.76-1.954-5.691-2.849-.965-.444-1.939-.872-2.912-1.285l-1.456-.607-1.362-.537-.218-.086a5.527,5.527,0,0,1,3.76-10.385Z"
            transform="translate(-173.326 -53.076)"
            fill="#ffb573"
          />
          <path
            id="Path_8559"
            data-name="Path 8559"
            d="M213.459,75.1c-.849.335-.132,3.893,1.3,4.009,1.7-.677,17.859-4.313,26.47-2.538,1.2-1.1-.529-4.772-2.655-5.037A54.17,54.17,0,0,0,213.459,75.1Z"
            transform="translate(-177.569 -58.743)"
            fill="#7e59d1"
          />
        </g>
        <g id="freepik--Device--inject-37" transform="translate(93.696 83.329)">
          <path
            id="Path_8560"
            data-name="Path 8560"
            d="M193.342,291.978H93.5a6.656,6.656,0,0,1-6.68-6.968L95.171,82.992a7.341,7.341,0,0,1,7.256-6.96h99.845a6.649,6.649,0,0,1,6.68,6.96L200.6,285.025a7.349,7.349,0,0,1-7.256,6.952Z"
            transform="translate(-77.796 -76.032)"
            fill="#7e59d1"
          />
          <path
            id="Path_8561"
            data-name="Path 8561"
            d="M193.342,291.978H93.5a6.656,6.656,0,0,1-6.68-6.968L95.171,82.992a7.341,7.341,0,0,1,7.256-6.96h99.845a6.649,6.649,0,0,1,6.68,6.96L200.6,285.025a7.349,7.349,0,0,1-7.256,6.952Z"
            transform="translate(-77.796 -76.032)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <rect
            id="Rectangle_2969"
            data-name="Rectangle 2969"
            width="16.279"
            height="0.779"
            transform="translate(0 208.984) rotate(-87.62)"
            fill="#7e59d1"
          />
          <path
            id="Path_8562"
            data-name="Path 8562"
            d="M83.688,260.431a9.342,9.342,0,0,1-3.464-3.441l.677-.389a8.439,8.439,0,0,0,3.169,3.114Z"
            transform="translate(-79.002 -42.959)"
            fill="#7e59d1"
          />
          <path
            id="Path_8563"
            data-name="Path 8563"
            d="M188.019,291.978H88.174a6.649,6.649,0,0,1-6.68-6.968L89.848,82.992a7.334,7.334,0,0,1,7.248-6.96h99.853a6.641,6.641,0,0,1,6.672,6.96l-8.346,202.034a7.341,7.341,0,0,1-7.256,6.952Z"
            transform="translate(-78.771 -76.032)"
            fill="#7e59d1"
          />
          <path
            id="Path_8564"
            data-name="Path 8564"
            d="M87.829,288.526a3.791,3.791,0,0,1-3.815-3.978L92.368,82.53a4.188,4.188,0,0,1,4.142-3.978H196.37a3.784,3.784,0,0,1,3.815,3.978l-8.361,202.033a4.188,4.188,0,0,1-4.142,3.978Z"
            transform="translate(-78.309 -75.57)"
            fill="#fff"
          />
          <path
            id="Path_8565"
            data-name="Path 8565"
            d="M184.514,265.755H91.092a2.522,2.522,0,0,1-2.538-2.655L95.693,90.386a2.787,2.787,0,0,1,2.764-2.655h93.422a2.522,2.522,0,0,1,2.546,2.655L187.286,263.1a2.8,2.8,0,0,1-2.771,2.655Z"
            transform="translate(-77.477 -73.889)"
            fill="#7e59d1"
            opacity="0.2"
          />
          <path
            id="Path_8566"
            data-name="Path 8566"
            d="M130.694,255.454a7.186,7.186,0,0,1-7.232-7.536,7.894,7.894,0,0,1,7.785-7.5,7.186,7.186,0,0,1,7.232,7.544A7.894,7.894,0,0,1,130.694,255.454Zm.576-14.013a6.89,6.89,0,0,0-6.812,6.547,6.181,6.181,0,0,0,6.228,6.5,6.89,6.89,0,0,0,6.812-6.54h0a6.181,6.181,0,0,0-6.228-6.508Z"
            transform="translate(-71.084 -45.924)"
            fill="#7e59d1"
            opacity="0.2"
          />
          <ellipse
            id="Ellipse_1808"
            data-name="Ellipse 1808"
            cx="37.556"
            cy="36.038"
            rx="37.556"
            ry="36.038"
            transform="matrix(0.714, -0.7, 0.7, 0.714, 10.861, 134.584)"
            fill="#b7a3e2"
          />
          <path
            id="Path_8567"
            data-name="Path 8567"
            d="M152.735,201.538l.42-10.284a16.8,16.8,0,0,0-16.022-17.8,18.272,18.272,0,0,0-18.739,17.377l-.444,10.7a1.977,1.977,0,0,1-.615,1.347l-2.748,2.631a2.009,2.009,0,0,0-.615,1.355h0a1.814,1.814,0,0,0,1.83,1.907h38.49a2.009,2.009,0,0,0,1.985-1.907h0a1.837,1.837,0,0,0-.506-1.355l-2.522-2.631a1.806,1.806,0,0,1-.514-1.347Z"
            transform="translate(-72.821 -58.189)"
            fill="#fff"
          />
          <path
            id="Path_8568"
            data-name="Path 8568"
            d="M136.64,168.992a3.114,3.114,0,0,1-3.114,2.966,2.818,2.818,0,0,1-2.834-2.966,3.114,3.114,0,0,1,3.114-2.958,2.818,2.818,0,0,1,2.834,2.958Z"
            transform="translate(-69.759 -59.547)"
            fill="#fff"
          />
          <path
            id="Path_8569"
            data-name="Path 8569"
            d="M128.628,205.625a1.6,1.6,0,0,0-1.557,2.071,5.45,5.45,0,0,0,5.208,3.682,6.065,6.065,0,0,0,5.52-3.682,1.479,1.479,0,0,0-1.362-2.071Z"
            transform="translate(-70.435 -52.296)"
            fill="#fff"
          />
        </g>
        <g
          id="freepik--speech-bubble--inject-37"
          transform="translate(46.391 113.349)"
        >
          <path
            id="Path_8570"
            data-name="Path 8570"
            d="M168.736,134.18H53.119a7.692,7.692,0,0,1-7.715-8.065l.685-16.645a8.509,8.509,0,0,1,8.4-8.065h115.6a7.7,7.7,0,0,1,7.731,8.065l-.685,16.645a8.517,8.517,0,0,1-8.4,8.065Z"
            transform="translate(-38.077 -101.404)"
            fill="#7e59d1"
          />
          <path
            id="Path_8571"
            data-name="Path 8571"
            d="M168.736,134.18H53.119a7.692,7.692,0,0,1-7.715-8.065l.685-16.645a8.509,8.509,0,0,1,8.4-8.065h115.6a7.7,7.7,0,0,1,7.731,8.065l-.685,16.645a8.517,8.517,0,0,1-8.4,8.065Z"
            transform="translate(-38.077 -101.404)"
            fill="#f2b636"
            opacity="0.79"
          />
          <path
            id="Path_8572"
            data-name="Path 8572"
            d="M162.536,134.18H46.957a7.707,7.707,0,0,1-7.738-8.065l.693-16.645a8.5,8.5,0,0,1,8.4-8.065h115.61a7.707,7.707,0,0,1,7.738,8.065l-.693,16.645a8.5,8.5,0,0,1-8.431,8.065Z"
            transform="translate(-39.21 -101.404)"
            fill="#f2b636"
          />
          <path
            id="Path_8573"
            data-name="Path 8573"
            d="M116.519,125.375l20.989,21.892.9-21.892Z"
            transform="translate(-25.05 -97.014)"
            fill="#f2b636"
          />
          <path
            id="Path_8574"
            data-name="Path 8574"
            d="M68.724,105.642H60.269a1.168,1.168,0,0,1,0-2.336h8.455a1.168,1.168,0,0,1,0,2.336Z"
            transform="translate(-35.566 -101.056)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_8575"
            data-name="Path 8575"
            d="M42.614,118.588H42.56a1.152,1.152,0,0,1-1.105-1.2l.343-8.322a6.018,6.018,0,0,1,5.948-5.714h9.482a1.168,1.168,0,0,1,0,2.336H47.752a3.682,3.682,0,0,0-3.643,3.5l-.319,8.291A1.144,1.144,0,0,1,42.614,118.588Z"
            transform="translate(-38.799 -101.048)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_8576"
            data-name="Path 8576"
            d="M48.133,118.519l3.643-.226a2.725,2.725,0,0,0,.428,1.355,2.222,2.222,0,0,0,2.935.4,1.222,1.222,0,0,0,.483-.9,1.09,1.09,0,0,0-.389-.856,4.671,4.671,0,0,0-1.931-.716,8,8,0,0,1-3.519-1.5,2.9,2.9,0,0,1-.989-2.421,3.441,3.441,0,0,1,.631-1.806,3.893,3.893,0,0,1,1.7-1.331,7.98,7.98,0,0,1,3.06-.483,6.088,6.088,0,0,1,3.581.88,3.644,3.644,0,0,1,1.378,2.842l-3.612.21a1.822,1.822,0,0,0-.553-1.214,1.853,1.853,0,0,0-1.253-.381,1.6,1.6,0,0,0-1.02.28.95.95,0,0,0-.366.685.67.67,0,0,0,.257.537,3.114,3.114,0,0,0,1.269.452,15.416,15.416,0,0,1,3.542,1.1,3.581,3.581,0,0,1,1.557,1.37,3.363,3.363,0,0,1,.413,1.83,4.041,4.041,0,0,1-.779,2.2,4.4,4.4,0,0,1-1.9,1.557,7.684,7.684,0,0,1-3.005.514q-3.114,0-4.321-1.214A4.57,4.57,0,0,1,48.133,118.519Z"
            transform="translate(-37.575 -99.826)"
            fill="#fff"
          />
          <path
            id="Path_8577"
            data-name="Path 8577"
            d="M67.632,110.208h3.815l-.3,7.373a6.337,6.337,0,0,1-.428,2.071,4.842,4.842,0,0,1-1.144,1.7,4.492,4.492,0,0,1-1.557,1.02,7.785,7.785,0,0,1-2.694.413,16.346,16.346,0,0,1-1.962-.125,5.053,5.053,0,0,1-1.767-.5,4.4,4.4,0,0,1-1.269-1.067,3.815,3.815,0,0,1-.779-1.432,7.318,7.318,0,0,1-.257-2.055l.3-7.373H63.4l-.311,7.544a2.055,2.055,0,0,0,.5,1.557,1.993,1.993,0,0,0,1.557.576,2.133,2.133,0,0,0,1.557-.568,2.25,2.25,0,0,0,.631-1.557Z"
            transform="translate(-35.533 -99.792)"
            fill="#fff"
          />
          <path
            id="Path_8578"
            data-name="Path 8578"
            d="M71.8,110.21h7.162a3.76,3.76,0,0,1,2.709.888,2.74,2.74,0,0,1,.864,2.2,2.842,2.842,0,0,1-.779,1.876A3.153,3.153,0,0,1,80.4,116a3.254,3.254,0,0,1,1.915,1.1,2.943,2.943,0,0,1,.545,1.962,3.433,3.433,0,0,1-.514,1.728A3.472,3.472,0,0,1,81.067,122a4,4,0,0,1-1.471.4,15.785,15.785,0,0,1-1.721.171H71.281Zm3.659,4.85h1.666a1.985,1.985,0,0,0,1.253-.3,1.191,1.191,0,0,0,.389-.9,1.012,1.012,0,0,0-.319-.841,1.814,1.814,0,0,0-1.207-.3h-1.7Zm-.2,4.866h1.954a2.2,2.2,0,0,0,1.4-.35,1.238,1.238,0,0,0,.444-.942,1.035,1.035,0,0,0-.358-.88,2.172,2.172,0,0,0-1.394-.335H75.361Z"
            transform="translate(-33.336 -99.793)"
            fill="#fff"
          />
          <path
            id="Path_8579"
            data-name="Path 8579"
            d="M82.25,118.524l3.643-.226a2.655,2.655,0,0,0,.428,1.355,2.258,2.258,0,0,0,2.935.335,1.214,1.214,0,0,0,.475-.9,1.082,1.082,0,0,0-.381-.856,4.671,4.671,0,0,0-1.931-.716,8.081,8.081,0,0,1-3.527-1.5,2.919,2.919,0,0,1-.981-2.39,3.379,3.379,0,0,1,.631-1.806,3.947,3.947,0,0,1,1.713-1.331,8.027,8.027,0,0,1,3.067-.483,6.088,6.088,0,0,1,3.581.88,3.667,3.667,0,0,1,1.323,2.873l-3.612.21a1.822,1.822,0,0,0-.553-1.214,1.853,1.853,0,0,0-1.253-.381,1.6,1.6,0,0,0-1.02.28.919.919,0,0,0-.366.685.669.669,0,0,0,.257.537,3.028,3.028,0,0,0,1.269.452,15.569,15.569,0,0,1,3.542,1.1,3.511,3.511,0,0,1,1.518,1.37,3.3,3.3,0,0,1,.42,1.83,4.04,4.04,0,0,1-.779,2.2,4.375,4.375,0,0,1-1.907,1.557,7.645,7.645,0,0,1-3,.514q-3.114,0-4.321-1.215a4.57,4.57,0,0,1-1.176-3.153Z"
            transform="translate(-31.327 -99.831)"
            fill="#fff"
          />
          <path
            id="Path_8580"
            data-name="Path 8580"
            d="M101.664,117.555l3.309,1.012a6.516,6.516,0,0,1-1.16,2.336,4.951,4.951,0,0,1-1.861,1.425,6.789,6.789,0,0,1-2.764.483,7.832,7.832,0,0,1-3.27-.584,4.928,4.928,0,0,1-2.118-2.063,7.886,7.886,0,0,1,1.051-8.494,6.446,6.446,0,0,1,4.671-1.65,6.1,6.1,0,0,1,3.636.942,5.216,5.216,0,0,1,1.861,2.9l-3.4.779a2.935,2.935,0,0,0-.335-.833,2.094,2.094,0,0,0-.779-.67,2.133,2.133,0,0,0-1.028-.241,2.336,2.336,0,0,0-2.032,1.051,6.329,6.329,0,0,0-.039,5.255,2.063,2.063,0,0,0,1.721.779,2.258,2.258,0,0,0,1.689-.615,4.064,4.064,0,0,0,.849-1.814Z"
            transform="translate(-29.355 -99.828)"
            fill="#fff"
          />
          <path
            id="Path_8581"
            data-name="Path 8581"
            d="M104.622,122.58l.514-12.371h6.376a9.132,9.132,0,0,1,2.694.3,2.7,2.7,0,0,1,1.464,1.129,3.324,3.324,0,0,1,.49,2,3.6,3.6,0,0,1-.514,1.775,3.675,3.675,0,0,1-1.253,1.214,5.044,5.044,0,0,1-1.362.49,3.705,3.705,0,0,1,.934.49,3.894,3.894,0,0,1,.584.654,4.755,4.755,0,0,1,.506.779l1.7,3.581h-4.352l-1.892-3.784a2.632,2.632,0,0,0-.654-.95,1.557,1.557,0,0,0-.919-.288H108.6l-.2,5.021Zm4.15-7.357h1.612a5.6,5.6,0,0,0,1.02-.171,1.028,1.028,0,0,0,.631-.389,1.136,1.136,0,0,0,.272-.716,1.074,1.074,0,0,0-.343-.919,2.25,2.25,0,0,0-1.409-.319h-1.682Z"
            transform="translate(-27.229 -99.793)"
            fill="#fff"
          />
          <path
            id="Path_8582"
            data-name="Path 8582"
            d="M116.546,110.208h3.83l-.514,12.371h-3.83Z"
            transform="translate(-25.139 -99.792)"
            fill="#fff"
          />
          <path
            id="Path_8583"
            data-name="Path 8583"
            d="M122.115,110.21h7.162a3.76,3.76,0,0,1,2.709.888,2.74,2.74,0,0,1,.864,2.2,2.873,2.873,0,0,1-.779,1.876,3.231,3.231,0,0,1-1.37.833,3.254,3.254,0,0,1,1.915,1.1,2.9,2.9,0,0,1,.545,1.962,3.433,3.433,0,0,1-.514,1.728A3.472,3.472,0,0,1,131.371,122a4,4,0,0,1-1.464.4,16.141,16.141,0,0,1-1.721.171h-6.6Zm3.659,4.85h1.666a1.985,1.985,0,0,0,1.253-.3,1.191,1.191,0,0,0,.389-.9,1.028,1.028,0,0,0-.311-.841,1.845,1.845,0,0,0-1.214-.3h-1.682Zm-.2,4.889h1.954a2.188,2.188,0,0,0,1.4-.35,1.269,1.269,0,0,0,.452-.942,1.059,1.059,0,0,0-.366-.88,2.157,2.157,0,0,0-1.394-.335h-1.97Z"
            transform="translate(-24.122 -99.793)"
            fill="#fff"
          />
          <path
            id="Path_8584"
            data-name="Path 8584"
            d="M133.465,110.208H143.71l-.109,2.639h-6.415l-.078,1.97h5.925l-.1,2.522h-5.948l-.1,2.437h6.594l-.109,2.8H132.936Z"
            transform="translate(-22.043 -99.792)"
            fill="#fff"
          />
          <path
            id="Path_8585"
            data-name="Path 8585"
            d="M141.46,146.584h-7.2l.724-17.478h7.2Z"
            transform="translate(-21.801 -96.33)"
            fill="#7e59d1"
          />
          <path
            id="Path_8586"
            data-name="Path 8586"
            d="M141.46,146.584h-7.2l.724-17.478h7.2Z"
            transform="translate(-21.801 -96.33)"
            fill="#f2b636"
            opacity="0.79"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const createZoomMeeting = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="329"
    height="283"
    viewBox="0 0 329 283"
  >
    <defs>
      <clipPath id="clip-path-createzoommeeting">
        <rect
          id="Rectangle_2992"
          data-name="Rectangle 2992"
          width="329"
          height="283"
          transform="translate(905 1167.999)"
          fill="#dbdbdb"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_375"
      data-name="Mask Group 375"
      transform="translate(-905 -1167.999)"
      clipPath="url(#clip-path-createzoommeeting)"
    >
      <g
        id="Remote_team-rafiki"
        data-name="Remote team-rafiki"
        transform="translate(886.936 1137.277)"
      >
        <g
          id="freepik--background-complete--inject-11"
          transform="translate(0 36.19)"
        >
          <rect
            id="Rectangle_2993"
            data-name="Rectangle 2993"
            width="372.467"
            height="0.186"
            transform="translate(0 243.891)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2994"
            data-name="Rectangle 2994"
            width="24.672"
            height="0.186"
            transform="translate(310.473 255.877)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2995"
            data-name="Rectangle 2995"
            width="6.473"
            height="0.186"
            transform="translate(240.263 257.903)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2996"
            data-name="Rectangle 2996"
            width="14.295"
            height="0.186"
            transform="translate(295.433 248.964)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2997"
            data-name="Rectangle 2997"
            width="32.174"
            height="0.186"
            transform="translate(39.079 250.216)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2998"
            data-name="Rectangle 2998"
            width="4.715"
            height="0.186"
            transform="translate(77.89 250.216)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_2999"
            data-name="Rectangle 2999"
            width="69.785"
            height="0.186"
            transform="translate(97.936 253.359)"
            fill="#ebebeb"
          />
          <path
            id="Path_8587"
            data-name="Path 8587"
            d="M173.227,246.857H29.388a4.254,4.254,0,0,1-4.246-4.254V40.406a4.254,4.254,0,0,1,4.246-4.216H173.227a4.254,4.254,0,0,1,4.254,4.254V242.6A4.254,4.254,0,0,1,173.227,246.857ZM29.388,36.339a4.067,4.067,0,0,0-4.06,4.067V242.6a4.067,4.067,0,0,0,4.06,4.067H173.227a4.075,4.075,0,0,0,4.067-4.067V40.406a4.075,4.075,0,0,0-4.067-4.067Z"
            transform="translate(3.322 -36.19)"
            fill="#ebebeb"
          />
          <path
            id="Path_8588"
            data-name="Path 8588"
            d="M315.561,246.857H171.715a4.261,4.261,0,0,1-4.254-4.254V40.406a4.261,4.261,0,0,1,4.254-4.216H315.561a4.254,4.254,0,0,1,4.239,4.216V242.6A4.254,4.254,0,0,1,315.561,246.857ZM171.715,36.339a4.075,4.075,0,0,0-4.067,4.067V242.6a4.075,4.075,0,0,0,4.067,4.067H315.561a4.075,4.075,0,0,0,4.067-4.067V40.406a4.075,4.075,0,0,0-4.067-4.067Z"
            transform="translate(22.125 -36.19)"
            fill="#ebebeb"
          />
          <rect
            id="Rectangle_3000"
            data-name="Rectangle 3000"
            width="189.377"
            height="188.826"
            transform="translate(323.152 203.568) rotate(180)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8589"
            data-name="Path 8589"
            d="M108.1,45.961H315.985a1.073,1.073,0,0,1,1.073,1.073h0a1.073,1.073,0,0,1-1.073,1.065H108.1a1.073,1.073,0,0,1-1.073-1.065h0A1.073,1.073,0,0,1,108.1,45.961Z"
            transform="translate(14.14 -34.899)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_3001"
            data-name="Rectangle 3001"
            width="191.805"
            height="188.826"
            transform="translate(321.014 203.568) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3002"
            data-name="Rectangle 3002"
            width="176.028"
            height="179.008"
            transform="translate(314.615 21.141) rotate(90)"
            fill="#fafafa"
          />
          <path
            id="Path_8590"
            data-name="Path 8590"
            d="M264.836,230.892,227.276,54.864H209.547l37.56,176.028Z"
            transform="translate(27.685 -33.723)"
            fill="#fff"
          />
          <path
            id="Path_8591"
            data-name="Path 8591"
            d="M244.551,230.892,206.991,54.864h-6.913l37.56,176.028Z"
            transform="translate(26.434 -33.723)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3003"
            data-name="Rectangle 3003"
            width="176.028"
            height="0.812"
            transform="translate(136.42 21.141) rotate(90)"
            fill="#e6e6e6"
          />
          <g
            id="Group_4821"
            data-name="Group 4821"
            transform="translate(292.52 9.155)"
            opacity="0.4"
          >
            <rect
              id="Rectangle_3004"
              data-name="Rectangle 3004"
              width="11.01"
              height="227.532"
              transform="translate(33.038 227.532) rotate(180)"
              fill="#f0f0f0"
            />
            <rect
              id="Rectangle_3005"
              data-name="Rectangle 3005"
              width="8.582"
              height="227.532"
              transform="translate(26.311 227.532) rotate(180)"
              fill="#e6e6e6"
            />
            <rect
              id="Rectangle_3006"
              data-name="Rectangle 3006"
              width="11.01"
              height="227.532"
              transform="translate(22.028 227.532) rotate(180)"
              fill="#f0f0f0"
            />
            <rect
              id="Rectangle_3007"
              data-name="Rectangle 3007"
              width="8.582"
              height="227.532"
              transform="translate(15.301 227.532) rotate(180)"
              fill="#e6e6e6"
            />
            <rect
              id="Rectangle_3008"
              data-name="Rectangle 3008"
              width="11.01"
              height="227.532"
              transform="translate(11.01 227.532) rotate(180)"
              fill="#f0f0f0"
            />
          </g>
          <g
            id="Group_4822"
            data-name="Group 4822"
            transform="translate(127.019 9.155)"
            opacity="0.4"
          >
            <rect
              id="Rectangle_3009"
              data-name="Rectangle 3009"
              width="6.347"
              height="227.532"
              transform="translate(19.033 227.532) rotate(180)"
              fill="#f0f0f0"
            />
            <rect
              id="Rectangle_3010"
              data-name="Rectangle 3010"
              width="4.946"
              height="227.532"
              transform="translate(15.159 227.532) rotate(180)"
              fill="#e6e6e6"
            />
            <rect
              id="Rectangle_3011"
              data-name="Rectangle 3011"
              width="6.347"
              height="227.532"
              transform="translate(12.694 227.532) rotate(180)"
              fill="#f0f0f0"
            />
            <rect
              id="Rectangle_3012"
              data-name="Rectangle 3012"
              width="4.946"
              height="227.532"
              transform="translate(8.82 227.532) rotate(180)"
              fill="#e6e6e6"
            />
            <rect
              id="Rectangle_3013"
              data-name="Rectangle 3013"
              width="6.347"
              height="227.532"
              transform="translate(6.347 227.532) rotate(180)"
              fill="#f0f0f0"
            />
          </g>
          <rect
            id="Rectangle_3014"
            data-name="Rectangle 3014"
            width="28.367"
            height="70.635"
            transform="translate(199.918 238.893) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3015"
            data-name="Rectangle 3015"
            width="146.789"
            height="4.991"
            transform="translate(194.465 243.891) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3016"
            data-name="Rectangle 3016"
            width="129.313"
            height="70.635"
            transform="translate(42.23 168.266)"
            fill="#e0e0f5"
          />
          <rect
            id="Rectangle_3017"
            data-name="Rectangle 3017"
            width="129.812"
            height="25.849"
            transform="translate(42.23 173.041)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3018"
            data-name="Rectangle 3018"
            width="129.812"
            height="25.849"
            transform="translate(42.23 203.613)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3019"
            data-name="Rectangle 3019"
            width="4.909"
            height="25.849"
            transform="translate(176.46 198.897) rotate(180)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_3020"
            data-name="Rectangle 3020"
            width="4.909"
            height="25.849"
            transform="translate(176.46 229.462) rotate(180)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_3021"
            data-name="Rectangle 3021"
            width="133.09"
            height="4.343"
            transform="translate(171.543 168.266) rotate(180)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3022"
            data-name="Rectangle 3022"
            width="32.338"
            height="4.343"
            transform="translate(171.543 163.915)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8592"
            data-name="Path 8592"
            d="M64.266,171.214c2.168-22.467,3.68-58.924,2.51-71.119a1.49,1.49,0,0,0-.611-1.058h0a1.49,1.49,0,0,0-2.384,1.259c.209,6.041.447,21.141-.842,47.192-.641,12.969-1.564,21.849-.931,23.726Z"
            transform="translate(8.164 -27.926)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8593"
            data-name="Path 8593"
            d="M70.259,121.495c4.954-6.377,15.2-11.412,25.7-12.187,4.119-.3-12.44,7.919-19.257,16.128-5.3,6.377-11.174,16.88-13.193,22.609C64.665,136.215,65.305,127.842,70.259,121.495Z"
            transform="translate(8.391 -26.531)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8594"
            data-name="Path 8594"
            d="M63.886,120.408c-5.133-7.144-16.97-12.664-29.8-13.252-5.021-.246,14.1,8.716,21.238,17.878,5.55,7.129,11.234,18.951,12.8,25.425C68.52,137.065,69.012,127.545,63.886,120.408Z"
            transform="translate(4.394 -26.815)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8595"
            data-name="Path 8595"
            d="M69.649,102.752c3.911-5.319,12.552-9.684,21.7-10.578,3.6-.35-10.362,6.771-15.778,13.632-4.209,5.334-8.686,14.042-10.034,18.757C65.753,114.895,65.738,108.078,69.649,102.752Z"
            transform="translate(8.659 -28.795)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8596"
            data-name="Path 8596"
            d="M65.231,103.794C60.9,97.745,50.869,93.1,40.023,92.568c-4.254-.2,11.919,7.382,17.99,15.159,4.693,6.041,9.505,16.046,10.831,21.536C69.164,117.873,69.574,109.835,65.231,103.794Z"
            transform="translate(5.195 -28.742)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8597"
            data-name="Path 8597"
            d="M67.171,142.224c2.823-4.008,9.17-7.382,15.956-8.194,2.667-.313-7.6,5.215-11.517,10.369a49.973,49.973,0,0,0-7.166,14.087C64.489,151.3,64.34,146.239,67.171,142.224Z"
            transform="translate(8.514 -23.265)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8598"
            data-name="Path 8598"
            d="M64.15,140.224c-2.451-3.978-8.522-7.338-15.293-8.142-2.659-.32,7.151,5.155,10.593,10.288a45.191,45.191,0,0,1,5.84,13.975C65.99,149.223,66.608,144.2,64.15,140.224Z"
            transform="translate(6.395 -23.523)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8599"
            data-name="Path 8599"
            d="M64.461,183.532h0c5.408,0,9.87-1.125,10.183-6.526l.9-15.6H53.384l.9,15.6C54.6,182.437,59.06,183.532,64.461,183.532Z"
            transform="translate(7.053 -19.647)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8600"
            data-name="Path 8600"
            d="M53.737,165.322H75.646a1.758,1.758,0,0,0,1.706-1.564l.328-2.98a1.743,1.743,0,0,0-1.728-1.937H53.4a1.743,1.743,0,0,0-1.728,1.937l.328,2.98a1.758,1.758,0,0,0,1.736,1.564Z"
            transform="translate(6.826 -19.986)"
            fill="#f0f0f0"
          />
        </g>
        <g
          id="freepik--Shadow--inject-11"
          transform="translate(41.798 296.857)"
        >
          <ellipse
            id="freepik--path--inject-11"
            cx="144.435"
            cy="8.433"
            rx="144.435"
            ry="8.433"
            fill="#e0e0f5"
          />
        </g>
        <g id="freepik--Desk--inject-11" transform="translate(119.882 67.194)">
          <rect
            id="Rectangle_3023"
            data-name="Rectangle 3023"
            width="178.374"
            height="6.459"
            transform="translate(181.399 108.611) rotate(180)"
            fill="#7e59d1"
          />
          <path
            id="Path_8601"
            data-name="Path 8601"
            d="M252.811,287.648h-2.078L240.8,159.512h4.887Z"
            transform="translate(-88.069 -50.901)"
            fill="#263238"
          />
          <path
            id="Path_8602"
            data-name="Path 8602"
            d="M270.2,287.648h-2.078l-9.937-128.136h4.887Z"
            transform="translate(-85.772 -50.901)"
            fill="#263238"
          />
          <path
            id="Path_8603"
            data-name="Path 8603"
            d="M123.276,287.648h2.086L135.3,159.512h-4.887Z"
            transform="translate(-103.595 -50.901)"
            fill="#263238"
          />
          <path
            id="Path_8604"
            data-name="Path 8604"
            d="M105.892,287.648h2.078l9.945-128.136h-4.887Z"
            transform="translate(-105.892 -50.901)"
            fill="#263238"
          />
          <path
            id="Path_8605"
            data-name="Path 8605"
            d="M243.137,123.184c-5.215-2.235-12.589-8.79-6.771-15.495C239.748,103.786,243.137,123.184,243.137,123.184Z"
            transform="translate(-88.936 -57.816)"
            fill="#7e59d1"
          />
          <path
            id="Path_8620"
            data-name="Path 8620"
            d="M206.025,120.855H185.51l6.608,37.306h25.656Z"
            transform="translate(-95.373 -56.008)"
            fill="#7e59d1"
          />
          <path
            id="Path_8621"
            data-name="Path 8621"
            d="M206.025,120.855H185.516l3.665,20.731,22.706-2.138Z"
            transform="translate(-95.372 -56.008)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <rect
            id="Rectangle_3024"
            data-name="Rectangle 3024"
            width="13.029"
            height="0.745"
            transform="translate(30.886 23.434) rotate(-87.04)"
            fill="#7e59d1"
          />
          <rect
            id="Rectangle_3025"
            data-name="Rectangle 3025"
            width="5.46"
            height="0.745"
            transform="translate(30.348 33.946) rotate(-87.03)"
            fill="#7e59d1"
          />
          <path
            id="Path_8622"
            data-name="Path 8622"
            d="M250.291,143.329H137.955a4.8,4.8,0,0,1-4.827-5.088l3.62-69.577a5.453,5.453,0,0,1,5.356-5.088H254.44a4.8,4.8,0,0,1,4.827,5.088l-3.628,69.577a5.431,5.431,0,0,1-5.349,5.088Z"
            transform="translate(-102.295 -63.576)"
            fill="#7e59d1"
          />
          <path
            id="Path_8623"
            data-name="Path 8623"
            d="M137.756,140a1.982,1.982,0,0,1-2-2.108l3.62-69.577a2.235,2.235,0,0,1,2.235-2.108H253.966a1.989,1.989,0,0,1,2,2.108l-3.628,69.577A2.235,2.235,0,0,1,250.107,140Z"
            transform="translate(-101.947 -63.228)"
            fill="#fff"
            opacity="0.8"
          />
          <path
            id="Path_8624"
            data-name="Path 8624"
            d="M173.6,66.208l-1.922,36.9h38.848l1.922-36.9Z"
            transform="translate(-97.2 -63.228)"
            fill="#fff"
          />
          <path
            id="Path_8625"
            data-name="Path 8625"
            d="M244.685,66.208h-36.77l-1.922,36.9h38.856l1.81-34.788a1.989,1.989,0,0,0-1.974-2.108Z"
            transform="translate(-92.667 -63.228)"
            fill="#f2b636"
            opacity="0.5"
          />
          <path
            id="Path_8626"
            data-name="Path 8626"
            d="M137.569,98.8l-1.81,34.788a1.982,1.982,0,0,0,2,2.108H174.5l1.922-36.9Z"
            transform="translate(-101.946 -58.922)"
            fill="#f2b636"
            opacity="0.5"
          />
          <path
            id="Path_8627"
            data-name="Path 8627"
            d="M171.9,98.8l-1.922,36.9h38.848l1.922-36.9Z"
            transform="translate(-97.425 -58.922)"
            fill="#fff"
            opacity="0.6"
          />
          <path
            id="Path_8628"
            data-name="Path 8628"
            d="M206.218,98.8,204.3,135.7h36.74a2.235,2.235,0,0,0,2.235-2.108L245.088,98.8Z"
            transform="translate(-92.891 -58.922)"
            fill="none"
          />
          <path
            id="Path_8629"
            data-name="Path 8629"
            d="M149.882,92.026V91.81a3.146,3.146,0,0,0,0-.529,1.209,1.209,0,0,0,0-.164.577.577,0,0,0,0-.112,4.47,4.47,0,0,1-1.974-1.386h0a10.474,10.474,0,0,0-1.43,3.576,24.09,24.09,0,0,1,3.27-.745,3.647,3.647,0,0,0,.134-.425Z"
            transform="translate(-100.53 -60.135)"
            fill="none"
          />
          <path
            id="Path_8630"
            data-name="Path 8630"
            d="M159.206,87.547l-.3.052c-.045.365-.089.745-.119,1.058.156-.291.305-.6.462-.924a.343.343,0,0,1-.045-.186Z"
            transform="translate(-98.903 -60.409)"
            fill="none"
          />
          <path
            id="Path_8631"
            data-name="Path 8631"
            d="M148.128,98.11c-1.929-.745-1.49-2.16-.656-3.278a8.664,8.664,0,0,1,0-1.989,19.681,19.681,0,0,0-10.109,6.63v.1h11.174a1.046,1.046,0,0,0-.082-.276C148.344,98.893,148.233,98.5,148.128,98.11Z"
            transform="translate(-101.734 -59.709)"
            fill="#263238"
          />
          <path
            id="Path_8632"
            data-name="Path 8632"
            d="M147.285,93.879c1-.834,1.885-.261,2.473-1.572v-.142a24.087,24.087,0,0,0-3.27.745,8.067,8.067,0,0,0-.06,1.833,6.662,6.662,0,0,1,.857-.864Z"
            transform="translate(-100.54 -59.799)"
            fill="#263238"
          />
          <path
            id="Path_8633"
            data-name="Path 8633"
            d="M173.6,99.362c-1.341-3.144-4.075-4.842-7.449-6.123a25.707,25.707,0,0,0-6.794-1.408,3.323,3.323,0,0,0,.633.983c.075,1.214-1.013,2.481-2.719,3.538a29.054,29.054,0,0,0,.79,3.039c.037.112.067.216.1.328H173.59Z"
            transform="translate(-99.104 -59.843)"
            fill="#263238"
          />
          <path
            id="Path_8634"
            data-name="Path 8634"
            d="M158.44,95.917a16.962,16.962,0,0,1-9.684,2.183,7.575,7.575,0,0,1-1.706-.32c.1.343.2.7.32,1.073.037.112.06.216.1.328H159.3a1.016,1.016,0,0,0-.074-.276,27.109,27.109,0,0,1-.782-2.987Z"
            transform="translate(-100.454 -59.303)"
            fill="#263238"
          />
          <path
            id="Path_8635"
            data-name="Path 8635"
            d="M149.511,91.616a3.143,3.143,0,0,0,0-.529A3.143,3.143,0,0,1,149.511,91.616Z"
            transform="translate(-100.129 -59.941)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8636"
            data-name="Path 8636"
            d="M157.29,96.735c1.706-1.058,2.793-2.324,2.719-3.538a3.322,3.322,0,0,1-.633-.983,7.338,7.338,0,0,1-.387-3.278c-.142.276-.291.536-.425.79A8.608,8.608,0,0,0,157.29,96.735Z"
            transform="translate(-99.133 -60.225)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8637"
            data-name="Path 8637"
            d="M149.524,90.843h0a.577.577,0,0,1,0,.112.571.571,0,0,0,0-.112Z"
            transform="translate(-100.127 -59.973)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8638"
            data-name="Path 8638"
            d="M160.41,87.6a8.4,8.4,0,0,1-3.97,3.084c-.112.045-.224.075-.335.112-2.235,1.669-4.8,2.347-6.243,1.661a1.237,1.237,0,0,0,0-.164,3.644,3.644,0,0,1-.1.477c0,.045,0,.089,0,.142-.588,1.311-1.49.745-2.473,1.572a6.66,6.66,0,0,0-.827.827,17.679,17.679,0,0,0,.685,3.568,7.576,7.576,0,0,0,1.706.32,16.962,16.962,0,0,0,9.684-2.183c-.7-3.576.1-5.043,1.281-7.2.194-.358.41-.745.618-1.162a9.014,9.014,0,0,1,.119-1.058Z"
            transform="translate(-100.532 -60.403)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8639"
            data-name="Path 8639"
            d="M147.032,97.878a18.215,18.215,0,0,1-.656-3.278C145.535,95.718,145.1,97.156,147.032,97.878Z"
            transform="translate(-100.637 -59.477)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8640"
            data-name="Path 8640"
            d="M147.163,98.629a17.678,17.678,0,0,1-.685-3.568,8.07,8.07,0,0,1,.06-1.833,10.475,10.475,0,0,1,1.43-3.576h0c-.045-.052-.082-.119-.119-.171a.616.616,0,0,0-.052.1,10.429,10.429,0,0,0-1.49,3.725,8.663,8.663,0,0,0,0,1.989,18.216,18.216,0,0,0,.656,3.278c.1.387.216.782.35,1.2a.9.9,0,0,0,.082.276h.194c-.037-.112-.06-.216-.1-.328C147.364,99.329,147.26,98.972,147.163,98.629Z"
            transform="translate(-100.56 -60.153)"
            fill="#fff"
          />
          <path
            id="Path_8641"
            data-name="Path 8641"
            d="M149.53,90.942a1.187,1.187,0,0,1,0,.164,1.184,1.184,0,0,0,0-.164Z"
            transform="translate(-100.126 -59.96)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8642"
            data-name="Path 8642"
            d="M149.484,91.77v-.216A.424.424,0,0,1,149.484,91.77Z"
            transform="translate(-100.133 -59.879)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8643"
            data-name="Path 8643"
            d="M149.53,91.011a1.187,1.187,0,0,1,0,.164,3.139,3.139,0,0,1,0,.529c0,.067,0,.142,0,.216a1.234,1.234,0,0,1,0,.164c1.445.685,3.993,0,6.243-1.661-2.645.924-4.678,1.013-6.176.477a.565.565,0,0,1-.067.112Z"
            transform="translate(-100.126 -60.029)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8644"
            data-name="Path 8644"
            d="M149.53,91.011a1.187,1.187,0,0,1,0,.164,3.139,3.139,0,0,1,0,.529c0,.067,0,.142,0,.216a1.234,1.234,0,0,1,0,.164c1.445.685,3.993,0,6.243-1.661-2.645.924-4.678,1.013-6.176.477a.565.565,0,0,1-.067.112Z"
            transform="translate(-100.126 -60.029)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8645"
            data-name="Path 8645"
            d="M148.479,77.524l-.089.052c-.082.313-.149.633-.2.946.209-.454.439-.894.678-1.326a2.517,2.517,0,0,0-.387.328Z"
            transform="translate(-100.304 -61.776)"
            fill="#263238"
          />
          <path
            id="Path_8646"
            data-name="Path 8646"
            d="M153.787,72.8a1.549,1.549,0,0,1-.462-.521c-2,.44-3.836,2.615-4.723,5.08a2.757,2.757,0,0,1,.693-.328A10.913,10.913,0,0,1,153.787,72.8Z"
            transform="translate(-100.249 -62.426)"
            fill="#263238"
          />
          <path
            id="Path_8647"
            data-name="Path 8647"
            d="M149.911,93.24h0c1.49.536,3.531.447,6.176-.477a.988.988,0,0,0,.335-.112,8.4,8.4,0,0,0,3.97-3.084,2.985,2.985,0,0,1-.365,0c-1.87.052-1.691-1.43-.745-2.98a12.864,12.864,0,0,1,1.08-1.49,4.641,4.641,0,0,1,.216-6.012c2.652-2.741,1.594-5.036-.439-5.557-1.78-.447-4.768.149-6.1-.827a10.913,10.913,0,0,0-4.47,4.231,2.66,2.66,0,0,1,1.073-.1.261.261,0,0,1,.2.313.276.276,0,0,1-.32.216,2.183,2.183,0,0,0-1.438.387c-.238.432-.469.872-.678,1.326a20.954,20.954,0,0,0-1.81,6.622,8.777,8.777,0,0,0,1.222,5.959c.037.052.074.119.119.171A4.47,4.47,0,0,0,149.911,93.24Zm2.063-12.709-1.08,3.836a2.376,2.376,0,0,1-2.51-.589,9.639,9.639,0,0,1,3.591-3.248Zm3.2-.045c.283.082.395.507.253.946s-.492.745-.745.648a.752.752,0,0,1-.253-.946c.142-.439.44-.715.723-.6Zm-.9,4.85a4.088,4.088,0,0,1-.693.454,4.417,4.417,0,0,1-3.062.425.149.149,0,0,1-.1-.179.164.164,0,0,1,.186-.089,4.157,4.157,0,0,0,3.479-.819.156.156,0,0,1,.209,0,.127.127,0,0,1-.045.223Zm2.235-3.382a2,2,0,0,1,1.624.588,3.9,3.9,0,0,1-1.684-.574Zm.037-.462a2.518,2.518,0,0,1,1.914.171,4.827,4.827,0,0,1-1.967-.156Zm1.4-3.084a.283.283,0,0,1-.2.335.208.208,0,0,1-.134,0,.238.238,0,0,1-.186-.186,2,2,0,0,0-1.214-1.49.261.261,0,0,1-.164-.335.283.283,0,0,1,.343-.179,2.51,2.51,0,0,1,1.5,1.87Zm-8.529,1.281c.149-.44.492-.745.745-.648a.752.752,0,0,1,.253.946c-.142.44-.492.745-.745.648a.742.742,0,0,1-.305-.931Z"
            transform="translate(-100.522 -62.37)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8648"
            data-name="Path 8648"
            d="M153.249,73.1c1.333.976,4.321.38,6.1.827,2.034.521,3.091,2.816.439,5.557a4.641,4.641,0,0,0-.216,6.012c.8-.931,1.788-1.639,2.883-1.192a2.078,2.078,0,0,1,1.341,1.721c2.548-1.669,5.1-4.939,2.6-6.213a6.348,6.348,0,0,1-.946-.268,2.518,2.518,0,0,0,1.021,0c2.168-1.944-.745-5.542-3.24-6.943,1.266-.335.551-1.319.551-1.319s.223,1.222-3.091-.514-8.82.074-7.911,1.773h0a1.549,1.549,0,0,0,.469.559Z"
            transform="translate(-99.71 -62.719)"
            fill="#263238"
          />
          <path
            id="Path_8649"
            data-name="Path 8649"
            d="M163.96,78.549a6.345,6.345,0,0,0,.946.268c2.034.38,1.535-1.259,1.535-1.259a2.1,2.1,0,0,1-1.49,1.021,2.518,2.518,0,0,1-.991-.03Z"
            transform="translate(-98.22 -61.729)"
            fill="#263238"
          />
          <path
            id="Path_8650"
            data-name="Path 8650"
            d="M153.611,83.694a4.157,4.157,0,0,1-3.479.819.164.164,0,0,0-.186.089.149.149,0,0,0,.1.179,4.417,4.417,0,0,0,3.062-.425,4.09,4.09,0,0,0,.693-.454.127.127,0,0,0,0-.194.156.156,0,0,0-.186-.015Z"
            transform="translate(-100.072 -60.921)"
            fill="#263238"
          />
          <path
            id="Path_8651"
            data-name="Path 8651"
            d="M155.327,80.493a4.827,4.827,0,0,0,1.914.171,2.518,2.518,0,0,0-1.914-.171Z"
            transform="translate(-99.361 -61.356)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8652"
            data-name="Path 8652"
            d="M155.327,80.493a4.827,4.827,0,0,0,1.914.171,2.518,2.518,0,0,0-1.914-.171Z"
            transform="translate(-99.361 -61.356)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8653"
            data-name="Path 8653"
            d="M155.288,80.889a3.9,3.9,0,0,0,1.624.589A2,2,0,0,0,155.288,80.889Z"
            transform="translate(-99.366 -61.29)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8654"
            data-name="Path 8654"
            d="M155.288,80.889a3.9,3.9,0,0,0,1.624.589A2,2,0,0,0,155.288,80.889Z"
            transform="translate(-99.366 -61.29)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8655"
            data-name="Path 8655"
            d="M161.86,82.654c-1.1-.447-2.086.261-2.883,1.192a12.86,12.86,0,0,0-1.08,1.49c-.961,1.535-1.14,3.017.745,2.98a2.985,2.985,0,0,0,.365,0h.127l.3-.052a2.891,2.891,0,0,1,.358-1.333,1.721,1.721,0,0,1,.134-.261.9.9,0,0,1-.179-1.14.961.961,0,0,1,1.147-.611.879.879,0,0,1,.276,1.222,1.17,1.17,0,0,1-.261.358,4.331,4.331,0,0,1-.29.79,3.881,3.881,0,0,1-.469.79,5.55,5.55,0,0,0,2.235-1.415,2.779,2.779,0,0,0,.8-2.235A2.078,2.078,0,0,0,161.86,82.654Z"
            transform="translate(-99.114 -61.073)"
            fill="#ffc3bd"
          />
          <path
            id="Path_8656"
            data-name="Path 8656"
            d="M160.566,85.386c.149.067.156.372.045.79a1.17,1.17,0,0,0,.261-.358A.879.879,0,0,0,160.6,84.6a.961.961,0,0,0-1.147.611.9.9,0,0,0,.179,1.14C159.97,85.713,160.365,85.3,160.566,85.386Z"
            transform="translate(-98.833 -60.803)"
            fill="#fff"
          />
          <path
            id="Path_8657"
            data-name="Path 8657"
            d="M160.632,86.08c.112-.417.1-.745-.045-.79s-.6.328-.939.961a1.719,1.719,0,0,0-.134.261,2.89,2.89,0,0,0-.358,1.333.343.343,0,0,0,.045.186c.067-.149.134-.3.209-.447a.089.089,0,0,1,.119-.052.1.1,0,0,1,.052.127l-.209.432a1.1,1.1,0,0,0,.5-.432,3.881,3.881,0,0,0,.469-.79,4.331,4.331,0,0,0,.291-.79Z"
            transform="translate(-98.855 -60.707)"
            fill="#fff"
          />
          <path
            id="Path_8658"
            data-name="Path 8658"
            d="M157.312,96.955c-.663-3.456.119-4.909,1.274-7.01.134-.253.283-.514.425-.79.216-.4.439-.842.663-1.326l.209-.432a.1.1,0,0,0-.052-.127.089.089,0,0,0-.119.052c-.074.149-.142.3-.209.447-.156.32-.305.633-.462.924-.209.417-.425.8-.618,1.162-1.177,2.16-1.982,3.628-1.281,7.2a27.118,27.118,0,0,0,.79,2.98.89.89,0,0,0,.074.276h.194a1.1,1.1,0,0,0-.1-.328A29.067,29.067,0,0,1,157.312,96.955Z"
            transform="translate(-99.155 -60.446)"
            fill="#fff"
          />
          <path
            id="Path_8659"
            data-name="Path 8659"
            d="M153.718,81.187c.283.082.626-.209.745-.648s0-.864-.253-.946-.633.209-.745.648S153.435,81.113,153.718,81.187Z"
            transform="translate(-99.613 -61.461)"
            fill="#263238"
          />
          <path
            id="Path_8660"
            data-name="Path 8660"
            d="M149.29,79.911c.283.082.626-.209.745-.648s0-.864-.253-.946-.626.209-.745.648S149.007,79.829,149.29,79.911Z"
            transform="translate(-100.199 -61.63)"
            fill="#263238"
          />
          <path
            id="Path_8661"
            data-name="Path 8661"
            d="M150.657,83.469l1.1-3.851a9.639,9.639,0,0,0-3.591,3.248A2.376,2.376,0,0,0,150.657,83.469Z"
            transform="translate(-100.306 -61.456)"
            fill="#ed847e"
          />
          <path
            id="Path_8662"
            data-name="Path 8662"
            d="M154.888,76.346a.261.261,0,0,0,.164.335,2,2,0,0,1,1.214,1.49.238.238,0,0,0,.186.186.209.209,0,0,0,.134,0,.283.283,0,0,0,.2-.335,2.51,2.51,0,0,0-1.557-1.818A.283.283,0,0,0,154.888,76.346Z"
            transform="translate(-99.421 -61.909)"
            fill="#263238"
          />
          <path
            id="Path_8663"
            data-name="Path 8663"
            d="M148.886,77.305a2.183,2.183,0,0,1,1.438-.387.276.276,0,0,0,.32-.216.261.261,0,0,0-.2-.313,2.659,2.659,0,0,0-1.073.1,2.756,2.756,0,0,0-.693.328,3.2,3.2,0,0,0-.559.44.268.268,0,0,0,0,.38.261.261,0,0,0,.291.045l.089-.052a2.519,2.519,0,0,1,.387-.328Z"
            transform="translate(-100.323 -61.885)"
            fill="#263238"
          />
          <path
            id="Path_8664"
            data-name="Path 8664"
            d="M188.685,90.65a6.185,6.185,0,0,0-.142-.745,1.323,1.323,0,0,0-.082-.328c-.03-.112-.075-.268-.119-.41a6.28,6.28,0,0,1-4.283-1.765c.246,2.54-1.006,4.47-3.017,4.35.745,1.736,2.98,2.8,6.749,2.287A3.8,3.8,0,0,0,188.685,90.65Z"
            transform="translate(-95.963 -60.428)"
            fill="#263238"
          />
          <path
            id="Path_8665"
            data-name="Path 8665"
            d="M182.5,84.8a20.373,20.373,0,0,1,1.944-6.838,1.49,1.49,0,0,1-.223-.432l.335.209a16.778,16.778,0,0,1,1.378-2.287,2.235,2.235,0,0,0-1.08.432.32.32,0,0,1-.2.045.253.253,0,0,1-.089-.462,2.727,2.727,0,0,1,1.773-.536,10.878,10.878,0,0,1,1.7-1.713,1.818,1.818,0,0,0-.886.194c4.06-3.725,9.684-3.1,13.722,1.4l-.276.2c2.5,1.49-.1,17.022-4.224,17.662a4.9,4.9,0,0,1-2.175-.142,7.629,7.629,0,0,0,1.192,3.456,4.984,4.984,0,0,0,2.145-2.54c1.572.924,4.514.991,6.392-2.049-2.235.79-4.954-1.028-2.779-4.47,3.24-5.073,3.33-10.586.663-12.768,0,0,.931-5.386-5.885-5.55a14.981,14.981,0,0,0-9.394,3.233c-4.09-.521-5.617,6.615-5.215,11.5.305,3.807-1.371,3.538-1.847,3.091-.231,2.324,1.616,3.017,4.023,2.451a6.436,6.436,0,0,1-.991-4.09Z"
            transform="translate(-96.173 -62.911)"
            fill="#263238"
          />
          <path
            id="Path_8666"
            data-name="Path 8666"
            d="M197.711,93.278c-.879,0-1.818.045-2.771.1a26.382,26.382,0,0,0-2.525,1.386,54.527,54.527,0,0,0-5.036,4.76h15.644v-.149a5.214,5.214,0,0,0-5.311-6.1Z"
            transform="translate(-95.126 -59.652)"
            fill="#7e59d1"
          />
          <path
            id="Path_8667"
            data-name="Path 8667"
            d="M197.711,93.278c-.879,0-1.818.045-2.771.1a26.382,26.382,0,0,0-2.525,1.386,54.527,54.527,0,0,0-5.036,4.76h15.644v-.149a5.214,5.214,0,0,0-5.311-6.1Z"
            transform="translate(-95.126 -59.652)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_8668"
            data-name="Path 8668"
            d="M186.207,94.357c-1.073.216-2.183.477-3.24.745a8.15,8.15,0,0,0-5.274,4.283h5.55a6.607,6.607,0,0,1,1.549-3.635,4.656,4.656,0,0,1,1.415-1.393Z"
            transform="translate(-96.406 -59.509)"
            fill="#7e59d1"
          />
          <path
            id="Path_8669"
            data-name="Path 8669"
            d="M186.207,94.357c-1.073.216-2.183.477-3.24.745a8.15,8.15,0,0,0-5.274,4.283h5.55a6.607,6.607,0,0,1,1.549-3.635,4.656,4.656,0,0,1,1.415-1.393Z"
            transform="translate(-96.406 -59.509)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_8670"
            data-name="Path 8670"
            d="M184.144,95.588a6.608,6.608,0,0,0-1.549,3.635h.544a6.332,6.332,0,0,1,1.006-3.635Z"
            transform="translate(-95.758 -59.347)"
            fill="#fff"
          />
          <path
            id="Path_8671"
            data-name="Path 8671"
            d="M192.533,94.594a31.741,31.741,0,0,0-6.056,4.76H187.5a54.53,54.53,0,0,1,5.036-4.76Z"
            transform="translate(-95.245 -59.478)"
            fill="#fff"
          />
          <path
            id="Path_8672"
            data-name="Path 8672"
            d="M187.678,89.671a1.321,1.321,0,0,0-.082-.328C187.626,89.455,187.655,89.559,187.678,89.671Z"
            transform="translate(-95.097 -60.172)"
            fill="#ffb573"
          />
          <path
            id="Path_8673"
            data-name="Path 8673"
            d="M195.508,94.017a3.5,3.5,0,0,1-.6-.641,7.629,7.629,0,0,1-1.192-3.457,6.839,6.839,0,0,1-2.555-1.445h-.082a5.215,5.215,0,0,1-2.659,2.049v-.045a3.8,3.8,0,0,1-.894,3.412A5.132,5.132,0,0,1,185.5,95.1a4.656,4.656,0,0,0-1.415,1.393,6.332,6.332,0,0,0-1.006,3.635h3.851a31.743,31.743,0,0,1,6.056-4.76,26.391,26.391,0,0,1,2.518-1.356Z"
            transform="translate(-95.694 -60.286)"
            fill="#ffb573"
          />
          <path
            id="Path_8674"
            data-name="Path 8674"
            d="M187.491,88.981c.045.142.082.276.119.41l-.112-.41Z"
            transform="translate(-95.111 -60.219)"
            fill="#263238"
          />
          <path
            id="Path_8675"
            data-name="Path 8675"
            d="M187.491,88.981c.045.142.082.276.119.41l-.112-.41Z"
            transform="translate(-95.111 -60.219)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8676"
            data-name="Path 8676"
            d="M187.81,90.358a3.53,3.53,0,0,0-.142-.745,6.188,6.188,0,0,1,.142.745Z"
            transform="translate(-95.088 -60.136)"
            fill="#263238"
          />
          <path
            id="Path_8677"
            data-name="Path 8677"
            d="M187.81,90.358a3.53,3.53,0,0,0-.142-.745,6.188,6.188,0,0,1,.142.745Z"
            transform="translate(-95.088 -60.136)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8678"
            data-name="Path 8678"
            d="M187.53,89.045l.112.41a1.759,1.759,0,0,0,.082.328,7.336,7.336,0,0,1,.142.745v.045A5.215,5.215,0,0,0,190.51,88.5,7.553,7.553,0,0,1,187.53,89.045Z"
            transform="translate(-95.106 -60.283)"
            fill="#ffb573"
          />
          <path
            id="Path_8679"
            data-name="Path 8679"
            d="M187.53,89.045l.112.41a1.759,1.759,0,0,0,.082.328,7.336,7.336,0,0,1,.142.745v.045A5.215,5.215,0,0,0,190.51,88.5,7.553,7.553,0,0,1,187.53,89.045Z"
            transform="translate(-95.106 -60.283)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8680"
            data-name="Path 8680"
            d="M188.237,91.13a7.554,7.554,0,0,0,2.98-.544h.082a11.316,11.316,0,0,1-2.235-2.7s2.168,1.1,3.605-1.49c-.968-1.989.566-4.8,2.049-5.445a6.317,6.317,0,0,1,.983-5.1c-3.263-.35-5.959-3.091-7.986-3.129a10.876,10.876,0,0,0-1.7,1.713,2.517,2.517,0,0,1,.559.1.246.246,0,0,1,.134.335.261.261,0,0,1-.335.149,1.959,1.959,0,0,0-.745-.06,16.789,16.789,0,0,0-1.378,2.287l.574.358h0a.745.745,0,0,1,.149.842c-.142.417-.469.7-.745.626s-.38-.477-.246-.9a1.058,1.058,0,0,1,.387-.536.543.543,0,0,1-.216-.171,20.375,20.375,0,0,0-1.944,6.838,6.436,6.436,0,0,0,.983,4.119,5.915,5.915,0,0,0,.782.976,6.28,6.28,0,0,0,4.283,1.765Zm2.622-14.765a2.391,2.391,0,0,1,1.736,1.49.268.268,0,0,1-.149.343.231.231,0,0,1-.186,0,.238.238,0,0,1-.149-.134,1.907,1.907,0,0,0-1.356-1.2.246.246,0,0,1-.2-.291.276.276,0,0,1,.268-.209Zm-1.877,1.967.909.566h0a.745.745,0,0,1,.149.842c-.134.425-.469.7-.745.626s-.387-.477-.246-.9a1.013,1.013,0,0,1,.38-.536,1.073,1.073,0,0,1-.447-.6ZM183.969,81.8a12.791,12.791,0,0,0,2.361-2.294l-.961,2.98A1.8,1.8,0,0,1,184,81.8Zm2.041,3.047a.134.134,0,0,1-.1-.171.156.156,0,0,1,.186-.112,4.075,4.075,0,0,0,3.524-.745.145.145,0,1,1,.194.216,4.336,4.336,0,0,1-2.98.961,4.223,4.223,0,0,1-.79-.149Z"
            transform="translate(-95.813 -62.368)"
            fill="#ffb573"
          />
          <path
            id="Path_8681"
            data-name="Path 8681"
            d="M189.416,82.518a.149.149,0,0,0-.209,0,4.075,4.075,0,0,1-3.524.745.156.156,0,0,0-.186.112.134.134,0,0,0,.1.171,4.221,4.221,0,0,0,.819.127,4.335,4.335,0,0,0,2.98-.961A.149.149,0,0,0,189.416,82.518Z"
            transform="translate(-95.375 -61.079)"
            fill="#263238"
          />
          <path
            id="Path_8682"
            data-name="Path 8682"
            d="M188.273,78.75c-.142.425,0,.827.246.9s.6-.2.745-.626a.745.745,0,0,0-.149-.842.477.477,0,0,1-.454,0A1.013,1.013,0,0,0,188.273,78.75Z"
            transform="translate(-95.015 -61.646)"
            fill="#263238"
          />
          <path
            id="Path_8683"
            data-name="Path 8683"
            d="M184.107,77.036a1.058,1.058,0,0,0-.387.536c-.134.425,0,.827.246.9s.6-.209.745-.626a.745.745,0,0,0-.149-.842.477.477,0,0,1-.454.03Z"
            transform="translate(-95.617 -61.802)"
            fill="#263238"
          />
          <path
            id="Path_8684"
            data-name="Path 8684"
            d="M183.893,76.927a.544.544,0,0,0,.216.171.477.477,0,0,0,.454,0h0l-.574-.358-.335-.209A1.49,1.49,0,0,0,183.893,76.927Z"
            transform="translate(-95.618 -61.864)"
            fill="#263238"
          />
          <path
            id="Path_8685"
            data-name="Path 8685"
            d="M183.786,81.011a1.8,1.8,0,0,0,1.4.685l.961-2.98A12.791,12.791,0,0,1,183.786,81.011Z"
            transform="translate(-95.601 -61.576)"
            fill="#ed893e"
          />
          <path
            id="Path_8686"
            data-name="Path 8686"
            d="M195.215,91.937c4.127-.641,6.7-16.158,4.224-17.662a6.4,6.4,0,0,1-4.574,1.333,6.317,6.317,0,0,0-.983,5.1c-1.49.648-2.98,3.456-2.049,5.445-1.438,2.585-3.605,1.49-3.605,1.49a11.316,11.316,0,0,0,2.235,2.7,6.839,6.839,0,0,0,2.555,1.445,4.9,4.9,0,0,0,2.2.142Z"
            transform="translate(-95.014 -62.162)"
            fill="#263238"
          />
          <path
            id="Path_8687"
            data-name="Path 8687"
            d="M199.7,74.744l.276-.2c-4.038-4.47-9.684-5.125-13.722-1.4a1.818,1.818,0,0,1,.886-.194c2.019,0,4.723,2.779,7.986,3.129a6.4,6.4,0,0,0,4.574-1.333Z"
            transform="translate(-95.275 -62.632)"
            fill="#263238"
          />
          <path
            id="Path_8688"
            data-name="Path 8688"
            d="M189.745,76.446a1.907,1.907,0,0,1,1.356,1.2.238.238,0,0,0,.149.134.231.231,0,0,0,.186,0,.268.268,0,0,0,.149-.343,2.391,2.391,0,0,0-1.736-1.49.276.276,0,0,0-.305.216.246.246,0,0,0,.2.283Z"
            transform="translate(-94.84 -61.942)"
            fill="#263238"
          />
          <path
            id="Path_8689"
            data-name="Path 8689"
            d="M183.983,74.739a.253.253,0,0,0,.089.462.32.32,0,0,0,.2-.045,2.235,2.235,0,0,1,1.08-.432,1.959,1.959,0,0,1,.745.06.261.261,0,0,0,.335-.149.246.246,0,0,0-.134-.335,2.518,2.518,0,0,0-.559-.1,2.727,2.727,0,0,0-1.758.536Z"
            transform="translate(-95.589 -62.172)"
            fill="#263238"
          />
          <path
            id="Path_8690"
            data-name="Path 8690"
            d="M189.11,78.25h0l-.909-.566a1.073,1.073,0,0,0,.439.6A.477.477,0,0,0,189.11,78.25Z"
            transform="translate(-95.017 -61.712)"
            fill="#263238"
          />
          <path
            id="Path_8691"
            data-name="Path 8691"
            d="M216.378,81.8a.246.246,0,0,1-.1-.074.231.231,0,0,1,.052-.32,1.638,1.638,0,0,1,.291-.194,11.463,11.463,0,0,1,1.021-2.183,3.226,3.226,0,0,1-1.162-1.125,3.36,3.36,0,0,0-.544,2.913,13.48,13.48,0,0,1,.045,2.808,15.171,15.171,0,0,1,.447-1.825Z"
            transform="translate(-91.37 -61.683)"
            fill="#263238"
          />
          <path
            id="Path_8692"
            data-name="Path 8692"
            d="M231.755,96.068H228.32a5.684,5.684,0,0,1-1.08,3.077h8.634A5.113,5.113,0,0,0,231.755,96.068Z"
            transform="translate(-89.86 -59.283)"
            fill="#7e59d1"
          />
          <path
            id="Path_8693"
            data-name="Path 8693"
            d="M218.108,97.147a13.468,13.468,0,0,0-4.686,1.87h3.352a5.214,5.214,0,0,1,1.333-1.87Z"
            transform="translate(-91.685 -59.14)"
            fill="#7e59d1"
          />
          <path
            id="Path_8694"
            data-name="Path 8694"
            d="M228.6,96.114c-.939-.589-1.453-.343-1.453-.343h0a1.743,1.743,0,0,1-.358,1.989,15.025,15.025,0,0,1-1.646,1.49h2.391a5.684,5.684,0,0,0,1.065-3.136Z"
            transform="translate(-90.137 -59.329)"
            fill="#fff"
          />
          <path
            id="Path_8695"
            data-name="Path 8695"
            d="M218.938,97.078a5.77,5.77,0,0,0,.745-.425,5.125,5.125,0,0,0-1.959.551h0a5.214,5.214,0,0,0-1.333,1.87h1l.067-.291A2.786,2.786,0,0,1,218.938,97.078Z"
            transform="translate(-91.293 -59.206)"
            fill="#fff"
          />
          <path
            id="Path_8696"
            data-name="Path 8696"
            d="M220.641,92.192a.319.319,0,0,1,0,.082.319.319,0,0,0,0-.082Z"
            transform="translate(-90.732 -59.795)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8697"
            data-name="Path 8697"
            d="M228.174,96.508a1.5,1.5,0,0,0-.075-.156c-.939-1.542-1.222-3.956-1.207-6.317a5.267,5.267,0,0,1-1.073.812,14.946,14.946,0,0,1-2.235,1.05,5.4,5.4,0,0,1-2.369,1.49h0a3.993,3.993,0,0,1-1.676,4.164,5.766,5.766,0,0,1-.745.425,2.786,2.786,0,0,0-1.49,1.713l-.067.291h8.9a15.019,15.019,0,0,0,1.646-1.49,1.743,1.743,0,0,0,.387-1.982Z"
            transform="translate(-91.181 -60.08)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8698"
            data-name="Path 8698"
            d="M220.8,93.069a7.583,7.583,0,0,0-.142-.8,7.587,7.587,0,0,1,.142.8Z"
            transform="translate(-90.73 -59.786)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8699"
            data-name="Path 8699"
            d="M223.166,91.679a8.939,8.939,0,0,1-2.525.581.319.319,0,0,1,0,.082,7.6,7.6,0,0,1,.142.8h0a5.4,5.4,0,0,0,2.384-1.468Z"
            transform="translate(-90.732 -59.863)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8700"
            data-name="Path 8700"
            d="M223.166,91.679a8.939,8.939,0,0,1-2.525.581.319.319,0,0,1,0,.082,7.6,7.6,0,0,1,.142.8h0a5.4,5.4,0,0,0,2.384-1.468Z"
            transform="translate(-90.732 -59.863)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8701"
            data-name="Path 8701"
            d="M223.819,93.378a14.949,14.949,0,0,0,2.235-1.05,5.265,5.265,0,0,0,1.073-.812,5.84,5.84,0,0,0,1.49-2.585,4.228,4.228,0,0,1-.462.156,1.49,1.49,0,0,1-.611,0,14.594,14.594,0,0,1-6.041,1.639.991.991,0,0,1-1.073.462c-.529-.1-.886-.514-.79-.924a.946.946,0,0,1,1.132-.581.909.909,0,0,1,.782.67,14.348,14.348,0,0,0,5.565-1.453c-.514-.395-.566-1.326-.119-2.272a.271.271,0,0,0,0-.067c-1.624-1.281-.291-3.293.648-4.149,1.1-1-.864-1.49.335-3.591-4.425,1.736-4.239-.1-8.418.4a3.032,3.032,0,0,1-1.9-.35,11.464,11.464,0,0,0-1.021,2.183,2.309,2.309,0,0,1,1.751-.32.216.216,0,0,1,.134.283.238.238,0,0,1-.291.156,1.855,1.855,0,0,0-1.617.425.238.238,0,0,1-.171.045A15.17,15.17,0,0,0,216,83.47a17.05,17.05,0,0,0-.194,5.215c.447,3.352,2.361,5.431,5.5,5.215h0A8.939,8.939,0,0,0,223.819,93.378Zm-.909-11.837a2.048,2.048,0,0,1,1.661,1.125.246.246,0,0,1-.1.313.283.283,0,0,1-.112,0,.216.216,0,0,1-.194-.112,1.631,1.631,0,0,0-1.3-.909.216.216,0,0,1-.2-.238A.246.246,0,0,1,222.91,81.541Zm-1.549,2.451c.074-.387.328-.678.581-.656s.395.365.328.745-.328.678-.581.648S221.294,84.379,221.361,83.992Zm-4.693-.6c.067-.387.328-.678.581-.656s.395.365.328.745-.328.678-.581.648-.41-.35-.328-.737Zm-.432,2.838a34.772,34.772,0,0,1,2.98-2.592l-1.14,3.434s-1.438-.022-1.84-.842Zm2.451,2.436a.1.1,0,0,1-.075-.142.119.119,0,0,1,.142-.082,3.24,3.24,0,0,0,2.749-.6.1.1,0,0,1,.156,0,.112.112,0,0,1,0,.164,3.389,3.389,0,0,1-2.466.745,4.223,4.223,0,0,1-.507-.089Z"
            transform="translate(-91.385 -61.562)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8702"
            data-name="Path 8702"
            d="M217.634,80.047a3.032,3.032,0,0,0,1.9.35c4.179-.507,3.993,1.333,8.418-.4-1.2,2.116.745,2.592-.335,3.591-.939.857-2.272,2.868-.648,4.149.611-1.251,2.034-2.89,3.307-2.615a1.356,1.356,0,0,1,1.058,1.058,12.663,12.663,0,0,0,1.088-.685c2.324-1.669,3.881-6.257.454-6.973a4.768,4.768,0,0,1-2.6.6,2.928,2.928,0,0,0,1.81-1.058c-.417-3.516-2.719-1.535-5.073-2.98-1.728-1.028-1.408-2.577-4.283-2.235-1.1.134-1.87-1.274.551-2.235-1.318-1.49-7.293.872-7.449,5.572a4.663,4.663,0,0,0,.663,2.667,3.226,3.226,0,0,0,1.14,1.2Z"
            transform="translate(-91.368 -62.705)"
            fill="#263238"
          />
          <path
            id="Path_8703"
            data-name="Path 8703"
            d="M228.589,78.528a4.767,4.767,0,0,0,2.6-.6c.939-.626,1.117-1.728-.134-3.315a3.248,3.248,0,0,1-.656,2.853,2.928,2.928,0,0,1-1.81,1.058Z"
            transform="translate(-89.681 -62.117)"
            fill="#263238"
          />
          <path
            id="Path_8704"
            data-name="Path 8704"
            d="M227.6,87.007a6.266,6.266,0,0,1-1.49,1.065,1.49,1.49,0,0,0,.611,0,4.226,4.226,0,0,0,.462-.156,5.006,5.006,0,0,0,1.773-1.184A1.162,1.162,0,0,1,227.6,87.007Z"
            transform="translate(-90.009 -60.517)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8705"
            data-name="Path 8705"
            d="M229.011,83.379c-1.274-.276-2.7,1.363-3.307,2.615a.27.27,0,0,1,0,.067c-.447.946-.395,1.877.119,2.272a6.346,6.346,0,0,0,1.646-1.147,1.348,1.348,0,0,1,.186-1.415,1.244,1.244,0,0,1,1.617-.544,1.177,1.177,0,0,1,.06,1.639,3.6,3.6,0,0,0,.305-.365,2.592,2.592,0,0,0,.462-2.063,1.356,1.356,0,0,0-1.088-1.058Z"
            transform="translate(-90.103 -60.964)"
            fill="#ff8b7b"
          />
          <path
            id="Path_8706"
            data-name="Path 8706"
            d="M229.018,85.02a1.244,1.244,0,0,0-1.617.544,1.348,1.348,0,0,0-.186,1.415,4.085,4.085,0,0,0,.3-.343.17.17,0,1,1,.261.216,3.33,3.33,0,0,1-.343.395,1.162,1.162,0,0,0,1.371-.305,1.875,1.875,0,0,0,.223-.253h0a1.177,1.177,0,0,0-.007-1.669Z"
            transform="translate(-89.879 -60.758)"
            fill="#263238"
          />
          <path
            id="Path_8707"
            data-name="Path 8707"
            d="M220.49,89.5a.179.179,0,0,1-.179-.194.194.194,0,0,1,.186-.179h.559a.909.909,0,0,0-.782-.67.946.946,0,0,0-1.132.581c-.1.41.261.827.79.924A.991.991,0,0,0,221,89.5h-.514Z"
            transform="translate(-90.932 -60.294)"
            fill="#263238"
          />
          <path
            id="Path_8708"
            data-name="Path 8708"
            d="M228.378,87.055a3.333,3.333,0,0,0,.343-.395.17.17,0,1,0-.261-.216,4.077,4.077,0,0,1-.3.343,6.347,6.347,0,0,1-1.646,1.147,14.347,14.347,0,0,1-5.565,1.453h-.559a.194.194,0,0,0-.186.179.179.179,0,0,0,.179.194h.514a14.593,14.593,0,0,0,6.041-1.639A6.266,6.266,0,0,0,228.378,87.055Z"
            transform="translate(-90.789 -60.565)"
            fill="#263238"
          />
          <path
            id="Path_8709"
            data-name="Path 8709"
            d="M221.03,84.209a.7.7,0,0,0,.581-.648c.067-.387-.082-.745-.328-.745s-.507.268-.581.656S220.784,84.187,221.03,84.209Z"
            transform="translate(-90.726 -61.034)"
            fill="#263238"
          />
          <path
            id="Path_8710"
            data-name="Path 8710"
            d="M216.884,83.682c.253,0,.507-.268.581-.648s-.082-.745-.328-.745-.514.268-.581.656S216.638,83.66,216.884,83.682Z"
            transform="translate(-91.273 -61.103)"
            fill="#263238"
          />
          <path
            id="Path_8711"
            data-name="Path 8711"
            d="M219.139,83.079a34.769,34.769,0,0,0-2.98,2.592c.4.819,1.825.842,1.825.842Z"
            transform="translate(-91.324 -60.999)"
            fill="#ff5652"
          />
          <path
            id="Path_8712"
            data-name="Path 8712"
            d="M221.306,86.958a.112.112,0,0,0,0-.164.1.1,0,0,0-.156,0,3.241,3.241,0,0,1-2.749.6.119.119,0,0,0-.142.082.1.1,0,0,0,.074.142,4.221,4.221,0,0,0,.492.082,3.389,3.389,0,0,0,2.481-.745Z"
            transform="translate(-91.047 -60.513)"
            fill="#263238"
          />
          <path
            id="Path_8713"
            data-name="Path 8713"
            d="M222.046,81.674a1.631,1.631,0,0,1,1.3.909.216.216,0,0,0,.194.112.283.283,0,0,0,.112,0,.246.246,0,0,0,.1-.313,2.048,2.048,0,0,0-1.661-1.125.246.246,0,0,0-.253.216A.216.216,0,0,0,222.046,81.674Z"
            transform="translate(-90.572 -61.24)"
            fill="#263238"
          />
          <path
            id="Path_8714"
            data-name="Path 8714"
            d="M216.224,81.38a.246.246,0,0,0,.1.075h.045a.238.238,0,0,0,.171-.045,1.855,1.855,0,0,1,1.617-.425.238.238,0,0,0,.291-.156.216.216,0,0,0-.134-.283,2.309,2.309,0,0,0-1.751.32,1.638,1.638,0,0,0-.291.194.231.231,0,0,0-.052.32Z"
            transform="translate(-91.321 -61.341)"
            fill="#263238"
          />
          <path
            id="Path_8715"
            data-name="Path 8715"
            d="M152.764,127.06a6.816,6.816,0,0,0-.074-.745.1.1,0,0,1,0-.045,6.246,6.246,0,0,1-7.2-6.265,19.586,19.586,0,0,1,1.1-6.3v-.037h0a16.54,16.54,0,0,1,1.631-3.389c-.589-.939-.268-2.235,1.445-4.1a4.373,4.373,0,0,1,.79-2.235,8.2,8.2,0,0,0-1.833.425c-5.1,1.877-2.436,9.334-6.19,12.478s-5.252,7.777-3.814,11.457a10.034,10.034,0,0,0,3.948,1.654,18.551,18.551,0,0,1,.283-4.537s.246,3.218,1.356,4.842a34.8,34.8,0,0,0,4.1.335,8.284,8.284,0,0,1,.2-1.222,5.621,5.621,0,0,0,.41,1.237l2.294.045A3.725,3.725,0,0,0,152.764,127.06Z"
            transform="translate(-101.639 -58.242)"
            fill="#263238"
          />
          <path
            id="Path_8716"
            data-name="Path 8716"
            d="M159.7,116.821a1.765,1.765,0,0,1,.4,2.607,4.216,4.216,0,0,1-2.57,1.676,1.8,1.8,0,0,1-.335,0,8.834,8.834,0,0,1-1.3,1.773c-.283,2.585-.261,5.431.745,7.092a28.22,28.22,0,0,0,6.377-1.319c2.928-4.909-1.006-7.546-1.639-10.064-.842-3.293,1.132-4.47,1.564-7.263a3.2,3.2,0,0,0-3.605-3.687,7.405,7.405,0,0,1,.335,1.982C161.389,110.05,161.255,113.6,159.7,116.821Z"
            transform="translate(-99.307 -57.755)"
            fill="#263238"
          />
          <path
            id="Path_8717"
            data-name="Path 8717"
            d="M139.5,131.568h.417a4.917,4.917,0,0,1,1.132-1.527,10.431,10.431,0,0,0-1.549,1.527Z"
            transform="translate(-101.452 -54.795)"
            fill="#7e59d1"
          />
          <path
            id="Path_8718"
            data-name="Path 8718"
            d="M141.136,129.853a1.657,1.657,0,0,0-.179.142,4.562,4.562,0,0,1,.38-.283,1.649,1.649,0,0,0-.2.142Z"
            transform="translate(-101.259 -54.838)"
            fill="#7e59d1"
          />
          <path
            id="Path_8719"
            data-name="Path 8719"
            d="M149.251,128.238c-2.153.261-4.47.67-6.22,1.021a4.284,4.284,0,0,0-1.549.648,4.58,4.58,0,0,0-.38.283l-.1.089a4.917,4.917,0,0,0-1.132,1.527h5.11C145.809,129.169,149.251,128.238,149.251,128.238Z"
            transform="translate(-101.404 -55.033)"
            fill="#7e59d1"
          />
          <path
            id="Path_8720"
            data-name="Path 8720"
            d="M162.118,128.35c-1.49-.164-3.24-.305-5.11-.343,0,0,.827,1.184-.246,3.844h2.168C158.945,129.75,159.742,128.208,162.118,128.35Z"
            transform="translate(-99.171 -55.063)"
            fill="#7e59d1"
          />
          <path
            id="Path_8721"
            data-name="Path 8721"
            d="M148.654,128.238s-3.442.931-4.276,3.568h4.2a5.639,5.639,0,0,1,.075-3.568Z"
            transform="translate(-100.807 -55.033)"
            fill="#fff"
          />
          <path
            id="Path_8722"
            data-name="Path 8722"
            d="M157.559,127.994a26.72,26.72,0,0,1-4.969,3.844h4.723C158.386,129.179,157.559,127.994,157.559,127.994Z"
            transform="translate(-99.722 -55.065)"
            fill="#fff"
          />
          <path
            id="Path_8723"
            data-name="Path 8723"
            d="M150.978,123.7a.1.1,0,0,0,0,.045A.1.1,0,0,1,150.978,123.7Z"
            transform="translate(-99.936 -55.632)"
            fill="#b55b52"
          />
          <path
            id="Path_8724"
            data-name="Path 8724"
            d="M158.181,128.9a3.494,3.494,0,0,1-.529-.685c-1.021-1.661-1.043-4.47-.745-7.092a10.887,10.887,0,0,1-1.788,1.49,9.684,9.684,0,0,1-.946.574,5.155,5.155,0,0,1-2.682,1.669v-.045a3.725,3.725,0,0,1-1.549,3.62,5.557,5.557,0,0,1-1.728.745,5.639,5.639,0,0,0-.074,3.568h5.1a26.721,26.721,0,0,0,4.946-3.844Z"
            transform="translate(-100.344 -55.973)"
            fill="#b55b52"
          />
          <path
            id="Path_8725"
            data-name="Path 8725"
            d="M151.059,124.449a5.256,5.256,0,0,0-.074-.745C151.014,123.987,151.044,124.233,151.059,124.449Z"
            transform="translate(-99.934 -55.632)"
            fill="#263238"
          />
          <path
            id="Path_8726"
            data-name="Path 8726"
            d="M151.059,124.449a5.256,5.256,0,0,0-.074-.745C151.014,123.987,151.044,124.233,151.059,124.449Z"
            transform="translate(-99.934 -55.632)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8727"
            data-name="Path 8727"
            d="M150.986,123.839c0,.246.06.484.075.745v.045a5.155,5.155,0,0,0,2.682-1.669,8.083,8.083,0,0,1-2.771.849A.1.1,0,0,0,150.986,123.839Z"
            transform="translate(-99.936 -55.73)"
            fill="#b55b52"
          />
          <path
            id="Path_8728"
            data-name="Path 8728"
            d="M150.986,123.839c0,.246.06.484.075.745v.045a5.155,5.155,0,0,0,2.682-1.669,8.083,8.083,0,0,1-2.771.849A.1.1,0,0,0,150.986,123.839Z"
            transform="translate(-99.936 -55.73)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8729"
            data-name="Path 8729"
            d="M157.62,118.664a4.774,4.774,0,0,1,1.2-1.639,4.416,4.416,0,0,1-.335-6.027c-4.723.745-9.9.536-11.129-1.415a16.538,16.538,0,0,0-1.631,3.389l.931.469h0a.745.745,0,0,1,.223.819c-.1.425-.41.745-.685.685s-.425-.44-.328-.872a1.073,1.073,0,0,1,.335-.566,1,1,0,0,1-.469-.521,19.585,19.585,0,0,0-1.1,6.3,6.231,6.231,0,0,0,7.2,6.265h0a8.083,8.083,0,0,0,2.771-.849,9.684,9.684,0,0,0,.946-.574,10.886,10.886,0,0,0,1.788-1.49,8.835,8.835,0,0,0,1.3-1.773C157.523,120.884,157.136,119.774,157.62,118.664Zm-4.775-5.959a2.451,2.451,0,0,1,2.056,1.013.268.268,0,0,1-.246.417.253.253,0,0,1-.179-.1,1.937,1.937,0,0,0-1.616-.819.246.246,0,0,1-.261-.231.276.276,0,0,1,.246-.291Zm-.745,1.721h0a.745.745,0,0,1,.224.812c-.1.432-.4.745-.678.685s-.425-.44-.328-.864a1.043,1.043,0,0,1,.335-.566,1.08,1.08,0,0,1-.484-.559Zm-5.706-2.011a.26.26,0,1,1-.209-.477,2.51,2.51,0,0,1,2.339,0,.246.246,0,0,1,.067.35.276.276,0,0,1-.358.089,1.982,1.982,0,0,0-1.833.03Zm-.082,5.088a12.663,12.663,0,0,0,2.153-2.458l-.745,3.024a1.788,1.788,0,0,1-1.4-.574Zm.842,2.041a47.5,47.5,0,0,1,6.243-1.1s.268,1.49-2.071,2.019-3.464-.052-4.164-.924Z"
            transform="translate(-100.774 -57.497)"
            fill="#b55b52"
          />
          <path
            id="Path_8730"
            data-name="Path 8730"
            d="M147.068,110.5c1.229,1.952,6.406,2.16,11.129,1.415a7.367,7.367,0,0,1,1.49-1.49,1.952,1.952,0,0,1,1.155-.1,7.4,7.4,0,0,0-.335-1.982c-1.654-5.311-8.768-7.449-11.174-4.164a4.372,4.372,0,0,0-.79,2.235C146.8,108.232,146.48,109.565,147.068,110.5Z"
            transform="translate(-100.488 -58.418)"
            fill="#263238"
          />
          <path
            id="Path_8731"
            data-name="Path 8731"
            d="M157.321,117.053a2.16,2.16,0,0,1,1.847-.633,1.8,1.8,0,0,1,.462.171c1.594-3.226,1.728-6.771,0-7.174a1.952,1.952,0,0,0-1.155.1,7.368,7.368,0,0,0-1.49,1.49A4.433,4.433,0,0,0,157.321,117.053Z"
            transform="translate(-99.276 -57.525)"
            fill="#263238"
          />
          <path
            id="Path_8732"
            data-name="Path 8732"
            d="M153.108,117.4a47.5,47.5,0,0,0-6.243,1.1c.7.872,1.833,1.453,4.172.916S153.108,117.4,153.108,117.4Z"
            transform="translate(-100.479 -56.465)"
            fill="#fff"
          />
          <path
            id="Path_8733"
            data-name="Path 8733"
            d="M150.863,113.927a1.043,1.043,0,0,0-.335.566c-.1.425.045.819.328.864s.581-.253.678-.685a.745.745,0,0,0-.223-.812.484.484,0,0,1-.447.067Z"
            transform="translate(-99.999 -56.932)"
            fill="#263238"
          />
          <path
            id="Path_8734"
            data-name="Path 8734"
            d="M146.046,113.065a1.072,1.072,0,0,0-.335.566c-.1.432.045.819.328.872s.581-.261.685-.685A.745.745,0,0,0,146.5,113a.477.477,0,0,1-.454.067Z"
            transform="translate(-100.635 -57.046)"
            fill="#263238"
          />
          <path
            id="Path_8735"
            data-name="Path 8735"
            d="M146.059,113.12a.477.477,0,0,0,.454-.067h0l-.931-.469h0v.037a1,1,0,0,0,.477.5Z"
            transform="translate(-100.648 -57.101)"
            fill="#263238"
          />
          <path
            id="Path_8736"
            data-name="Path 8736"
            d="M147.556,117.424l.745-3.024a12.663,12.663,0,0,1-2.153,2.458A1.788,1.788,0,0,0,147.556,117.424Z"
            transform="translate(-100.573 -56.861)"
            fill="#a02724"
          />
          <path
            id="Path_8737"
            data-name="Path 8737"
            d="M157.327,116.228a4.775,4.775,0,0,0-1.2,1.639c-.484,1.11-.1,2.235,1.006,2.235a1.8,1.8,0,0,0,.335,0,4.216,4.216,0,0,0,2.57-1.676,1.765,1.765,0,0,0-.4-2.607,1.8,1.8,0,0,0-.462-.171,2.16,2.16,0,0,0-1.847.581Z"
            transform="translate(-99.282 -56.7)"
            fill="#b55b52"
          />
          <path
            id="Path_8738"
            data-name="Path 8738"
            d="M151.93,112.848a1.937,1.937,0,0,1,1.616.819.253.253,0,0,0,.179.1.268.268,0,0,0,.246-.417,2.451,2.451,0,0,0-2.056-1.013.276.276,0,0,0-.246.283.246.246,0,0,0,.261.231Z"
            transform="translate(-99.844 -57.134)"
            fill="#263238"
          />
          <path
            id="Path_8739"
            data-name="Path 8739"
            d="M148.085,112.149a.276.276,0,0,0,.358-.089.246.246,0,0,0-.067-.35,2.51,2.51,0,0,0-2.339,0,.26.26,0,1,0,.209.477,1.982,1.982,0,0,1,1.84-.037Z"
            transform="translate(-100.61 -57.255)"
            fill="#263238"
          />
          <path
            id="Path_8740"
            data-name="Path 8740"
            d="M150.879,113.984a.484.484,0,0,0,.447-.067h0l-.954-.484A1.08,1.08,0,0,0,150.879,113.984Z"
            transform="translate(-100.015 -56.989)"
            fill="#263238"
          />
          <path
            id="Path_8741"
            data-name="Path 8741"
            d="M161.891,128.311h0c-2.376-.127-3.173,1.415-3.188,3.5h8.775A6.176,6.176,0,0,0,161.891,128.311Z"
            transform="translate(-98.915 -55.024)"
            fill="#7e59d1"
          />
          <path
            id="Path_8742"
            data-name="Path 8742"
            d="M181.01,127.369a25.212,25.212,0,0,0-4.738,1.646,10.809,10.809,0,0,0-3.725,2.905h7.129C177.285,130.416,179.825,128.233,181.01,127.369Z"
            transform="translate(-97.086 -55.148)"
            fill="#263238"
          />
          <path
            id="Path_8743"
            data-name="Path 8743"
            d="M196.926,128.081a23.4,23.4,0,0,0-3.427-.968,6.265,6.265,0,0,1-3.784,4.842h12.172A9.289,9.289,0,0,0,196.926,128.081Z"
            transform="translate(-94.817 -55.182)"
            fill="#263238"
          />
          <path
            id="Path_8744"
            data-name="Path 8744"
            d="M184.124,129.915h.06c3.024-.462,7.449-3.077,7.449-3.077h0a49.739,49.739,0,0,1-.223-7.293,7.889,7.889,0,0,1-2.153,2.153,6.779,6.779,0,0,1-1.639.827,4.917,4.917,0,0,1-3.285,1.378v-.052a3.591,3.591,0,0,1-1.021,3.285h0S181.211,129.468,184.124,129.915Z"
            transform="translate(-95.76 -56.181)"
            fill="#7f3e3b"
          />
          <path
            id="Path_8745"
            data-name="Path 8745"
            d="M184.033,122.714a1.694,1.694,0,0,0-.089-.372C183.974,122.469,184.011,122.588,184.033,122.714Z"
            transform="translate(-95.58 -55.812)"
            fill="#7f3e3b"
          />
          <path
            id="Path_8746"
            data-name="Path 8746"
            d="M184.164,123.436a7.324,7.324,0,0,0-.142-.745,6.926,6.926,0,0,1,.142.745Z"
            transform="translate(-95.569 -55.766)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8747"
            data-name="Path 8747"
            d="M183.931,122.289a.141.141,0,0,0,0,.06Z"
            transform="translate(-95.582 -55.819)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8748"
            data-name="Path 8748"
            d="M187.469,122.177a5.96,5.96,0,0,1-3.538.127v.06a2.257,2.257,0,0,0,.089.372c.06.253.1.514.142.745v.052A4.917,4.917,0,0,0,187.469,122.177Z"
            transform="translate(-95.582 -55.834)"
            fill="#7f3e3b"
          />
          <path
            id="Path_8749"
            data-name="Path 8749"
            d="M187.469,122.177a5.96,5.96,0,0,1-3.538.127v.06a2.257,2.257,0,0,0,.089.372c.06.253.1.514.142.745v.052A4.917,4.917,0,0,0,187.469,122.177Z"
            transform="translate(-95.582 -55.834)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8750"
            data-name="Path 8750"
            d="M183.615,107.4h0a1.222,1.222,0,0,1-1.311-.529,7.271,7.271,0,0,0-1.17,2.935,2.451,2.451,0,0,1,.8-.276A9.9,9.9,0,0,1,183.615,107.4Z"
            transform="translate(-95.951 -57.856)"
            fill="#263238"
          />
          <path
            id="Path_8751"
            data-name="Path 8751"
            d="M181.083,110.026a6.846,6.846,0,0,0,0,.745c.164-.372.335-.745.529-1.1a2.332,2.332,0,0,0-.529.35Z"
            transform="translate(-95.959 -57.485)"
            fill="#263238"
          />
          <path
            id="Path_8752"
            data-name="Path 8752"
            d="M188.021,124.167a6.778,6.778,0,0,0,1.639-.827,7.888,7.888,0,0,0,2.153-2.153,10.107,10.107,0,0,0,.745-1.4c-1.378.127-1.833-1.177-1.229-2.436a5.1,5.1,0,0,1,.8-1.17c-.886-1.527-.425-5.781-.641-7.494a10.835,10.835,0,0,0-7.717-1.319,9.9,9.9,0,0,0-1.676,2.131,2.413,2.413,0,0,1,.35,0,.216.216,0,0,1,.2.246.238.238,0,0,1-.253.216,1.75,1.75,0,0,0-.618.119c-.194.358-.365.745-.529,1.1a17.662,17.662,0,0,0-1.49,6.973,6.406,6.406,0,0,0,4.663,6.176h0a5.96,5.96,0,0,0,3.605-.156Zm-.112-13.915a2.153,2.153,0,0,1,1.356,1.572.238.238,0,0,1-.171.283.194.194,0,0,1-.112,0,.231.231,0,0,1-.164-.164,1.765,1.765,0,0,0-1.058-1.259.216.216,0,0,1-.141-.29.231.231,0,0,1,.29-.142Zm-1.967,2.615c.089-.395.365-.67.618-.626s.387.4.3.79-.365.67-.618.626-.387-.387-.3-.775Zm-4.656-.536c.089-.387.365-.663.618-.618s.38.4.3.79-.365.67-.618.618-.387-.38-.3-.775Zm.238,3.456a15.249,15.249,0,0,0,2.592-2.98l-.827,3.68a2.19,2.19,0,0,1-1.766-.685Zm2.347,1.877a.142.142,0,0,1,.171-.074,3.471,3.471,0,0,0,3.077-.5.119.119,0,0,1,.179,0,.142.142,0,0,1-.045.179,4.906,4.906,0,0,1-.566.32,3.568,3.568,0,0,1-2.756.209.127.127,0,0,1-.06-.1Z"
            transform="translate(-96.134 -57.823)"
            fill="#7f3e3b"
          />
          <path
            id="Path_8753"
            data-name="Path 8753"
            d="M183.538,108.186h0a10.873,10.873,0,0,1,7.718,1.319c2.667-.924,4.47-.924,5.721-.291,2.011-2.369-2.518-5.215-6.757-5.483,1.378-.842,1.028-3.725-3.859-2.5s-5.215,4.991-4.134,6.429a1.222,1.222,0,0,0,1.311.529Z"
            transform="translate(-95.874 -58.641)"
            fill="#263238"
          />
          <path
            id="Path_8754"
            data-name="Path 8754"
            d="M190.162,108.59c.216,1.713-.246,5.959.641,7.494a2.81,2.81,0,0,1,2.577-1.125,1.676,1.676,0,0,1,1.058.8,4.544,4.544,0,0,0,1.333-3.1c.849.209,2.682-1.579,1.49-3.211a3.8,3.8,0,0,0-1.348-1.155C194.632,107.666,192.806,107.666,190.162,108.59Z"
            transform="translate(-94.758 -57.726)"
            fill="#263238"
          />
          <path
            id="Path_8755"
            data-name="Path 8755"
            d="M186.208,116.47a4.91,4.91,0,0,0,.566-.32.142.142,0,0,0,.045-.179.119.119,0,0,0-.179,0,3.471,3.471,0,0,1-3.077.5.142.142,0,0,0-.171.075.127.127,0,0,0,.06.164A3.568,3.568,0,0,0,186.208,116.47Z"
            transform="translate(-95.654 -56.659)"
            fill="#263238"
          />
          <path
            id="Path_8756"
            data-name="Path 8756"
            d="M193.409,114.137a2.761,2.761,0,0,0-2.577,1.125,5.1,5.1,0,0,0-.8,1.17c-.6,1.259-.149,2.563,1.229,2.436a.56.56,0,0,1,.112,0,4.47,4.47,0,0,0,2.838-1.684,2.086,2.086,0,0,0,.253-2.235,1.676,1.676,0,0,0-1.058-.812Z"
            transform="translate(-94.81 -56.903)"
            fill="#7f3e3b"
          />
          <path
            id="Path_8757"
            data-name="Path 8757"
            d="M185.522,113.072c.253.045.529-.238.618-.626s-.045-.745-.3-.79-.529.231-.618.626S185.268,113.02,185.522,113.072Z"
            transform="translate(-95.414 -57.224)"
            fill="#263238"
          />
          <path
            id="Path_8758"
            data-name="Path 8758"
            d="M182.027,111.953c.082-.387-.045-.745-.3-.79s-.529.231-.618.618.045.745.3.79S181.938,112.37,182.027,111.953Z"
            transform="translate(-95.958 -57.289)"
            fill="#263238"
          />
          <path
            id="Path_8759"
            data-name="Path 8759"
            d="M181.318,115.138a2.19,2.19,0,0,0,1.765.685l.827-3.68a15.249,15.249,0,0,1-2.592,2.995Z"
            transform="translate(-95.927 -57.159)"
            fill="#630f0f"
          />
          <path
            id="Path_8760"
            data-name="Path 8760"
            d="M186.844,110.339A1.765,1.765,0,0,1,187.9,111.6a.231.231,0,0,0,.164.164.194.194,0,0,0,.112,0,.238.238,0,0,0,.171-.283,2.153,2.153,0,0,0-1.356-1.572.231.231,0,0,0-.291.149.216.216,0,0,0,.141.283Z"
            transform="translate(-95.217 -57.456)"
            fill="#263238"
          />
          <path
            id="Path_8761"
            data-name="Path 8761"
            d="M182.332,109.658a.238.238,0,0,0,.253-.216.216.216,0,0,0-.2-.246,2.419,2.419,0,0,0-.35,0,2.451,2.451,0,0,0-.8.276,2.592,2.592,0,0,0-.745.663.231.231,0,0,0,0,.328.2.2,0,0,0,.127.045.246.246,0,0,0,.2-.089,2.161,2.161,0,0,1,.9-.67A1.751,1.751,0,0,1,182.332,109.658Z"
            transform="translate(-96.046 -57.549)"
            fill="#263238"
          />
          <path
            id="Path_8762"
            data-name="Path 8762"
            d="M186.378,129.547a18.621,18.621,0,0,0-2.645,2.071h4.47A4.91,4.91,0,0,0,186.378,129.547Z"
            transform="translate(-95.608 -54.86)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8763"
            data-name="Path 8763"
            d="M183.977,128.711Z"
            transform="translate(-95.576 -54.97)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8764"
            data-name="Path 8764"
            d="M182.068,129.238a6.943,6.943,0,0,0-2.98,2.436h3.524l.2-.283a12.4,12.4,0,0,1-.745-2.153Z"
            transform="translate(-96.221 -54.901)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8765"
            data-name="Path 8765"
            d="M183.72,131.389Z"
            transform="translate(-95.609 -54.616)"
            fill="#263238"
          />
          <path
            id="Path_8766"
            data-name="Path 8766"
            d="M182.213,131.422h.343l-.142-.283Z"
            transform="translate(-95.809 -54.65)"
            fill="#263238"
          />
          <path
            id="Path_8767"
            data-name="Path 8767"
            d="M186.644,129.657a6.1,6.1,0,0,0-2.369-.946,6.547,6.547,0,0,0-2.555.6,12.4,12.4,0,0,0,.745,2.153l.142.283h1.363a18.624,18.624,0,0,1,2.674-2.086Z"
            transform="translate(-95.874 -54.97)"
            fill="#263238"
          />
          <path
            id="Path_8768"
            data-name="Path 8768"
            d="M194.274,127.261a.565.565,0,0,0,0-.082,7,7,0,0,0-2.808-1.192h0s-4.47,2.615-7.449,3.077h0a6.1,6.1,0,0,1,2.369.946,4.909,4.909,0,0,1,1.8,2.086h2.354a6.265,6.265,0,0,0,3.74-4.835Z"
            transform="translate(-95.57 -55.33)"
            fill="#fafafa"
          />
          <path
            id="Path_8769"
            data-name="Path 8769"
            d="M182.215,129.632a6.547,6.547,0,0,1,2.555-.6h-.037c-2.913-.469-.782-2.786-.782-2.786h0a18.77,18.77,0,0,0-3.106.894s-.223.142-.544.372c-1.192.864-3.725,3.047-1.333,4.552h.291a6.943,6.943,0,0,1,2.957-2.436Z"
            transform="translate(-96.368 -55.295)"
            fill="#fafafa"
          />
          <path
            id="Path_8770"
            data-name="Path 8770"
            d="M219.369,111.4h0a15.882,15.882,0,0,1,1.393-2.131,2.578,2.578,0,0,0-.745.432.224.224,0,0,1-.186.067.2.2,0,0,1-.156-.067.246.246,0,0,1,.045-.343,2.875,2.875,0,0,1,1.49-.7,9.2,9.2,0,0,1,3.36-2.473,4.38,4.38,0,0,1-.38-1.378,11.465,11.465,0,0,0-5.915,2.942c-2.98,3.024-2.019,6.444-.924,9.066a19.151,19.151,0,0,1,1.952-5.393A.157.157,0,0,1,219.369,111.4Z"
            transform="translate(-91.307 -58.129)"
            fill="#263238"
          />
          <path
            id="Path_8771"
            data-name="Path 8771"
            d="M234,112.01a4.574,4.574,0,0,1-.447,2.63,4.112,4.112,0,0,1,.089,3.017c-.663,2.026-2.458,3.382-4.015,2.98a2.108,2.108,0,0,1-1.125-.7,8.648,8.648,0,0,1-2.235,2,16.864,16.864,0,0,0-.328,3.926c6.444-.477,9.61-4.194,10.429-8.649.842-4.611.387-7.524-1.065-8.41C235.772,110.453,235.347,111.742,234,112.01Z"
            transform="translate(-90.033 -57.6)"
            fill="#263238"
          />
          <path
            id="Path_8772"
            data-name="Path 8772"
            d="M222.467,122.473v-.447a5.527,5.527,0,0,1-4.119-2.421,8.808,8.808,0,0,1-3.866,1.49s2.235,2.332,7.874,3.159a5.792,5.792,0,0,0,.142-1.088C222.482,122.942,222.475,122.711,222.467,122.473Z"
            transform="translate(-91.545 -56.173)"
            fill="#263238"
          />
          <path
            id="Path_8773"
            data-name="Path 8773"
            d="M222.272,132.052h15.5c0-2.756-4.47-5.215-7.3-5.527-.6-.06-1.229-.112-1.87-.149l.067.037C227.241,128.491,223.323,131.314,222.272,132.052Z"
            transform="translate(-90.516 -55.279)"
            fill="#7e59d1"
          />
          <path
            id="Path_8774"
            data-name="Path 8774"
            d="M221.307,128.9c-.432.231-.864.484-1.333.745a6.117,6.117,0,0,1-.574-1.11h0a4.025,4.025,0,0,0-2.533,2.57c-.164-3.077.812-4.216,1.423-4.619l-1.207.149c-2.98.417-6.7,2.6-8.194,5.364h14.824C223.594,131.382,223.005,129.147,221.307,128.9Z"
            transform="translate(-92.284 -55.264)"
            fill="#7e59d1"
          />
          <path
            id="Path_8775"
            data-name="Path 8775"
            d="M221.535,122.174v0Z"
            transform="translate(-90.613 -55.875)"
            fill="#ce7a63"
          />
          <path
            id="Path_8776"
            data-name="Path 8776"
            d="M227.314,120.414c-.246.164-.5.32-.782.477a8.41,8.41,0,0,1-1.974.79,5.214,5.214,0,0,1-2.57,1.393v-.037a5.793,5.793,0,0,1-.142,1.088,3.725,3.725,0,0,1-2.481,2.689h0a2.235,2.235,0,0,0-1.2,2.51,6.114,6.114,0,0,0,.574,1.11c.469-.276.9-.529,1.333-.745h0c1.117-1.05,6.295-3.166,7.263-3.561a6.034,6.034,0,0,1-.358-1.773A16.865,16.865,0,0,1,227.314,120.414Z"
            transform="translate(-91.066 -56.067)"
            fill="#ce7a63"
          />
          <path
            id="Path_8777"
            data-name="Path 8777"
            d="M219.851,129.043h0c1.7.246,2.287,2.481,2.428,3.136h.305c1.05-.745,4.969-3.561,6.4-5.639l-.067-.037a19.443,19.443,0,0,1-1.639-1.088l-.171.067C226.146,125.877,220.968,127.992,219.851,129.043Z"
            transform="translate(-90.836 -55.406)"
            fill="#fff"
          />
          <path
            id="Path_8778"
            data-name="Path 8778"
            d="M215.944,131.2a3.976,3.976,0,0,1,2.533-2.57h0a2.235,2.235,0,0,1,1.2-2.51h0a2.674,2.674,0,0,0-1.818.261,1.55,1.55,0,0,0-.469.223C216.756,126.983,215.81,128.123,215.944,131.2Z"
            transform="translate(-91.354 -55.32)"
            fill="#fff"
          />
          <path
            id="Path_8779"
            data-name="Path 8779"
            d="M221.535,122.138v.7C221.543,122.607,221.543,122.376,221.535,122.138Z"
            transform="translate(-90.613 -55.839)"
            fill="#263238"
          />
          <path
            id="Path_8780"
            data-name="Path 8780"
            d="M221.535,122.138v.7C221.543,122.607,221.543,122.376,221.535,122.138Z"
            transform="translate(-90.613 -55.839)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8781"
            data-name="Path 8781"
            d="M221.509,121.73v.007Z"
            transform="translate(-90.617 -55.893)"
            fill="#263238"
          />
          <path
            id="Path_8782"
            data-name="Path 8782"
            d="M221.509,121.73v.007Z"
            transform="translate(-90.617 -55.893)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8783"
            data-name="Path 8783"
            d="M221.509,121.755v1.177a5.214,5.214,0,0,0,2.57-1.393A7.144,7.144,0,0,1,221.509,121.755Z"
            transform="translate(-90.617 -55.918)"
            fill="#ce7a63"
          />
          <path
            id="Path_8784"
            data-name="Path 8784"
            d="M221.509,121.755v1.177a5.214,5.214,0,0,0,2.57-1.393A7.144,7.144,0,0,1,221.509,121.755Z"
            transform="translate(-90.617 -55.918)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8785"
            data-name="Path 8785"
            d="M222.089,123.8h0a7.144,7.144,0,0,0,2.6-.216,8.41,8.41,0,0,0,1.974-.79c.283-.156.536-.313.782-.477a8.649,8.649,0,0,0,2.235-2,3.725,3.725,0,0,1-.484-3.635,5.438,5.438,0,0,1,.529-1.11,6.392,6.392,0,0,1-.611-4.589,11.174,11.174,0,0,1-4.336-4.47c-.089-.164-.164-.32-.231-.477a9.2,9.2,0,0,0-3.36,2.473,1.981,1.981,0,0,1,.693,0,.209.209,0,0,1,.149.283.276.276,0,0,1-.313.186,1.952,1.952,0,0,0-1.028.127,15.88,15.88,0,0,0-1.393,2.131l.8.507h0a.678.678,0,0,1,.1.79c-.156.395-.484.663-.745.6s-.335-.447-.186-.842a1.073,1.073,0,0,1,.395-.507.864.864,0,0,1-.365-.521,19.152,19.152,0,0,0-1.952,5.393c-.06.328-.119.648-.156.961a5.371,5.371,0,0,0,4.931,6.183Zm3.889-9.431a1.982,1.982,0,0,1,1.594.611,3.851,3.851,0,0,1-1.594-.6Zm1.937-.253a4.812,4.812,0,0,1-1.9-.209,2.5,2.5,0,0,1,1.9.209ZM225,110.491a2.078,2.078,0,0,1,1.8,1.1.253.253,0,0,1-.112.335.261.261,0,0,1-.179.037.194.194,0,0,1-.149-.1,1.706,1.706,0,0,0-1.408-.886.209.209,0,0,1-.216-.238.283.283,0,0,1,.268-.223Zm-.946,2.98a1.051,1.051,0,0,1,.387-.507.916.916,0,0,1-.372-.559l.827.521h0a.678.678,0,0,1,.089.782c-.149.395-.484.663-.745.6s-.343-.4-.186-.8Zm.171,4.008a.119.119,0,1,1,.171.164,3.773,3.773,0,0,1-3.218.633.112.112,0,0,1-.074-.149.142.142,0,0,1,.164-.082,3.613,3.613,0,0,0,2.957-.536Zm-2.98-3.956-1.125,2.831s-.961,0-1.281-.633a13.073,13.073,0,0,0,2.4-2.168Z"
            transform="translate(-91.196 -57.966)"
            fill="#ce7a63"
          />
          <path
            id="Path_8786"
            data-name="Path 8786"
            d="M232.744,114.266a4.574,4.574,0,0,0,.447-2.63,2.427,2.427,0,0,1-.544.06h-.119a14.926,14.926,0,0,1-.484,1.669,2.5,2.5,0,0,1,.7.9Z"
            transform="translate(-89.225 -57.226)"
            fill="#263238"
          />
          <path
            id="Path_8787"
            data-name="Path 8787"
            d="M232.744,114.266a4.574,4.574,0,0,0,.447-2.63,2.427,2.427,0,0,1-.544.06h-.119a14.926,14.926,0,0,1-.484,1.669,2.5,2.5,0,0,1,.7.9Z"
            transform="translate(-89.225 -57.226)"
            fill="#263238"
            opacity="0.7"
          />
          <path
            id="Path_8788"
            data-name="Path 8788"
            d="M230.44,113.211h0c.179-.514.343-1.043.492-1.579a10.891,10.891,0,0,1-3.2-1.2,6.391,6.391,0,0,0,.611,4.589A4.165,4.165,0,0,1,230.44,113.211Z"
            transform="translate(-89.819 -57.385)"
            fill="#ce7a63"
          />
          <path
            id="Path_8789"
            data-name="Path 8789"
            d="M230.44,113.211h0c.179-.514.343-1.043.492-1.579a10.891,10.891,0,0,1-3.2-1.2,6.391,6.391,0,0,0,.611,4.589A4.165,4.165,0,0,1,230.44,113.211Z"
            transform="translate(-89.819 -57.385)"
            fill="#263238"
            opacity="0.7"
          />
          <path
            id="Path_8790"
            data-name="Path 8790"
            d="M224.977,113.017a4.812,4.812,0,0,0,1.9.209,2.5,2.5,0,0,0-1.9-.209Z"
            transform="translate(-90.159 -57.057)"
            fill="#ce7a63"
          />
          <path
            id="Path_8791"
            data-name="Path 8791"
            d="M224.977,113.017a4.812,4.812,0,0,0,1.9.209,2.5,2.5,0,0,0-1.9-.209Z"
            transform="translate(-90.159 -57.057)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8792"
            data-name="Path 8792"
            d="M224.944,113.414a3.851,3.851,0,0,0,1.594.611,1.981,1.981,0,0,0-1.594-.611Z"
            transform="translate(-90.163 -56.992)"
            fill="#ce7a63"
          />
          <path
            id="Path_8793"
            data-name="Path 8793"
            d="M224.944,113.414a3.851,3.851,0,0,0,1.594.611,1.981,1.981,0,0,0-1.594-.611Z"
            transform="translate(-90.163 -56.992)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8794"
            data-name="Path 8794"
            d="M223.434,113.534c.253.067.589-.2.745-.6a.678.678,0,0,0-.089-.782.492.492,0,0,1-.44,0,1.05,1.05,0,0,0-.387.507C223.1,113.095,223.181,113.467,223.434,113.534Z"
            transform="translate(-90.396 -57.158)"
            fill="#263238"
          />
          <path
            id="Path_8795"
            data-name="Path 8795"
            d="M219.392,111.134a1.073,1.073,0,0,0-.395.507c-.149.395-.067.745.186.842s.581-.2.745-.6a.678.678,0,0,0-.1-.79A.462.462,0,0,1,219.392,111.134Z"
            transform="translate(-90.958 -57.298)"
            fill="#263238"
          />
          <path
            id="Path_8796"
            data-name="Path 8796"
            d="M219.016,110.669a.864.864,0,0,0,.365.521.462.462,0,0,0,.432,0h0l-.8-.507h0l.007-.015Z"
            transform="translate(-90.947 -57.354)"
            fill="#263238"
          />
          <path
            id="Path_8797"
            data-name="Path 8797"
            d="M219.922,115.478l1.088-2.808a13.073,13.073,0,0,1-2.369,2.175A1.583,1.583,0,0,0,219.922,115.478Z"
            transform="translate(-90.996 -57.09)"
            fill="#ba4d3c"
          />
          <path
            id="Path_8798"
            data-name="Path 8798"
            d="M220.615,116.823a.112.112,0,0,0,.075.149,3.773,3.773,0,0,0,3.218-.633.119.119,0,1,0-.171-.164,3.613,3.613,0,0,1-2.98.566.142.142,0,0,0-.142.082Z"
            transform="translate(-90.736 -56.632)"
            fill="#263238"
          />
          <path
            id="Path_8799"
            data-name="Path 8799"
            d="M224.061,110.461a1.706,1.706,0,0,1,1.408.886.194.194,0,0,0,.149.1.261.261,0,0,0,.179-.037.253.253,0,0,0,.112-.335,2.078,2.078,0,0,0-1.8-1.1.283.283,0,0,0-.268.238.209.209,0,0,0,.216.238Z"
            transform="translate(-90.308 -57.444)"
            fill="#263238"
          />
          <path
            id="Path_8800"
            data-name="Path 8800"
            d="M219.328,109.273a.2.2,0,0,0,.156.067.223.223,0,0,0,.186-.067,2.578,2.578,0,0,1,.745-.432,1.952,1.952,0,0,1,1.028-.127.276.276,0,0,0,.313-.186.209.209,0,0,0-.149-.283,1.981,1.981,0,0,0-.693,0,2.875,2.875,0,0,0-1.49.7.246.246,0,0,0-.1.328Z"
            transform="translate(-90.909 -57.678)"
            fill="#263238"
          />
          <path
            id="Path_8801"
            data-name="Path 8801"
            d="M224.073,112.217h0l-.827-.521a.916.916,0,0,0,.372.559.492.492,0,0,0,.454-.037Z"
            transform="translate(-90.387 -57.218)"
            fill="#263238"
          />
          <path
            id="Path_8802"
            data-name="Path 8802"
            d="M232.518,112.5h.119a2.428,2.428,0,0,0,.544-.06c1.371-.268,1.773-1.557,1.318-3.166a9.408,9.408,0,0,0-2.384-3.8C232.868,106.818,233.226,109.448,232.518,112.5Z"
            transform="translate(-89.215 -58.04)"
            fill="#263238"
          />
          <path
            id="Path_8803"
            data-name="Path 8803"
            d="M223.949,106.984a11.174,11.174,0,0,0,4.336,4.47,10.891,10.891,0,0,0,3.2,1.2c.849-3.151,1.006-6.7-.745-8.783-3.725-2.235-7.65-1.557-7.449,1.244a4.381,4.381,0,0,0,.38,1.378C223.777,106.663,223.859,106.82,223.949,106.984Z"
            transform="translate(-90.382 -58.429)"
            fill="#263238"
          />
          <path
            id="Path_8804"
            data-name="Path 8804"
            d="M229.414,120.13c1.557.365,3.352-.991,4.015-2.98a4.112,4.112,0,0,0-.089-3.017,2.5,2.5,0,0,0-.7-.909.8.8,0,0,1-.1.283c-1.572.559-2.078-.529-2.1-.588a4.164,4.164,0,0,0-2.108,1.8,5.439,5.439,0,0,0-.529,1.11,3.725,3.725,0,0,0,.484,3.635A2.108,2.108,0,0,0,229.414,120.13Zm3.084-6.146c.909.134,1.184,1.49.611,3.077s-1.765,2.726-2.674,2.585-1.177-1.49-.611-3.077,1.765-2.719,2.674-2.615Z"
            transform="translate(-89.821 -57.057)"
            fill="#7e59d1"
          />
          <path
            id="Path_8805"
            data-name="Path 8805"
            d="M229.591,116.446c-.566,1.564-.291,2.98.611,3.077s2.108-1.021,2.674-2.585.3-2.942-.611-3.077S230.157,114.882,229.591,116.446Z"
            transform="translate(-89.588 -56.934)"
            fill="#7e59d1"
          />
          <path
            id="Path_8806"
            data-name="Path 8806"
            d="M229.591,116.446c-.566,1.564-.291,2.98.611,3.077s2.108-1.021,2.674-2.585.3-2.942-.611-3.077S230.157,114.882,229.591,116.446Z"
            transform="translate(-89.588 -56.934)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_8807"
            data-name="Path 8807"
            d="M230.613,112.509c-.149.536-.313,1.065-.492,1.579h0c0,.06.529,1.147,2.1.588.037-.089.067-.186.1-.283a14.94,14.94,0,0,0,.484-1.669c.708-3.054.35-5.684-.4-7.017h0s-1.535-1.885-2.5-1.974h0C231.619,105.827,231.462,109.358,230.613,112.509Z"
            transform="translate(-89.508 -58.27)"
            fill="#7e59d1"
          />
          <rect
            id="Rectangle_3026"
            data-name="Rectangle 3026"
            width="13.074"
            height="15.74"
            rx="1.375"
            transform="translate(80.587 102.153) rotate(180)"
            fill="#7e59d1"
          />
          <path
            id="Path_8808"
            data-name="Path 8808"
            d="M168.146,153.091a5.587,5.587,0,1,1,5.587-5.587,5.587,5.587,0,0,1-5.587,5.587Zm0-10.429a4.842,4.842,0,1,0,4.842,4.842A4.842,4.842,0,0,0,168.146,142.662Z"
            transform="translate(-98.405 -53.226)"
            fill="#7e59d1"
          />
        </g>
        <g
          id="freepik--Character--inject-11"
          transform="translate(49.477 65.122)"
        >
          <path
            id="Path_8809"
            data-name="Path 8809"
            d="M158.736,269.653l9.535-.909-6.421-23.488-9.543.9Z"
            transform="translate(-29.354 -37.501)"
            fill="#ffb573"
          />
          <path
            id="Path_8810"
            data-name="Path 8810"
            d="M179.663,273.194c-5.8-1.84-5.55-4.4-11.271-9.252a3.873,3.873,0,0,0-2.063-.991l-9.49-.857a.745.745,0,0,0-.745.648l-1.49,6.548c-.514,2.235.849,4.47,2.808,4.6,3.166.186,7.04.38,10.489.693,5.028.454,5.036,2.98,10.958,2.838C182.441,277.321,181.637,273.827,179.663,273.194Z"
            transform="translate(-29.065 -35.276)"
            fill="#263238"
          />
          <path
            id="Path_8811"
            data-name="Path 8811"
            d="M111.236,176.547s53.68-1.617,54.134,17.4c.745,31.451,10.392,76.445,10.392,76.445l-10.571.976s-16.009-35.839-20.262-66.56c-19.122,1.237-36.755.38-50.872.38-15.83,0-17.133-17.379-1.728-37.656C101.761,167.533,111.236,176.547,111.236,176.547Z"
            transform="translate(-38.715 -47.769)"
            fill="#7e59d1"
          />
          <path
            id="Path_8812"
            data-name="Path 8812"
            d="M111.236,176.547s53.68-1.617,54.134,17.4c.745,31.451,10.392,76.445,10.392,76.445l-10.571.976s-16.009-35.839-20.262-66.56c-19.122,1.237-36.755.38-50.872.38-15.83,0-17.133-17.379-1.728-37.656C101.761,167.533,111.236,176.547,111.236,176.547Z"
            transform="translate(-38.715 -47.769)"
            fill="#263238"
          />
          <path
            id="Path_8813"
            data-name="Path 8813"
            d="M167.393,260.125l-12.09,1.4-2.719-6.846,13.781-4.4Z"
            transform="translate(-29.318 -36.837)"
            fill="#7e59d1"
          />
          <path
            id="Path_8814"
            data-name="Path 8814"
            d="M91.884,94.311c.983,5.959,1.81,16.791-2.436,20.6,0,0,10.369,5.431,17.133,14.9,7.271-5.758,1.184-14.355,1.184-14.355-6.533-1.765-6.22-6.645-4.939-11.174Z"
            transform="translate(-37.659 -57.443)"
            fill="#ffb573"
          />
          <path
            id="Path_8815"
            data-name="Path 8815"
            d="M86.751,114.891c-.372-4.052-1.922-5.907-1.922-5.907s16.262-1.378,25.328,2.071c1.818,3.479,3.412,7.077,3.412,7.077Z"
            transform="translate(-38.269 -55.541)"
            fill="#594991"
          />
          <path
            id="Path_8816"
            data-name="Path 8816"
            d="M127.135,128.634c-.633,8.582-2.54,23.041-7.248,47.378l-40.428-1.2c1.058-14.9-.544-26.073-4.194-52.145a8.477,8.477,0,0,1,7.539-9.61c2.6-.268,5.542-.492,8.507-.544a128.519,128.519,0,0,1,18.348.544c1.967.238,4,.551,5.959.886a13.863,13.863,0,0,1,11.517,14.69Z"
            transform="translate(-39.543 -55.048)"
            fill="#f2b636"
          />
          <path
            id="Path_8817"
            data-name="Path 8817"
            d="M110.2,86.52c.1.8.618,1.386,1.132,1.311s.857-.745.745-1.572-.618-1.378-1.132-1.311S110.093,85.723,110.2,86.52Z"
            transform="translate(-34.919 -58.681)"
            fill="#263238"
          />
          <path
            id="Path_8818"
            data-name="Path 8818"
            d="M110.656,85.107l1.84-.8S111.721,85.927,110.656,85.107Z"
            transform="translate(-34.857 -58.765)"
            fill="#263238"
          />
          <path
            id="Path_8819"
            data-name="Path 8819"
            d="M110.458,87.422a21.485,21.485,0,0,0,3.531,4.693,3.472,3.472,0,0,1-2.764.916Z"
            transform="translate(-34.883 -58.353)"
            fill="#ed893e"
          />
          <path
            id="Path_8820"
            data-name="Path 8820"
            d="M110.563,80.249a.477.477,0,0,0,0-.469.469.469,0,0,0-.633-.209,4.663,4.663,0,0,0-2.63,3.4.469.469,0,0,0,.41.521.477.477,0,0,0,.529-.41h0a3.725,3.725,0,0,1,2.108-2.674.447.447,0,0,0,.216-.164Z"
            transform="translate(-35.301 -59.397)"
            fill="#263238"
          />
          <path
            id="Path_8821"
            data-name="Path 8821"
            d="M85.95,88.479c1.937,9.684,2.548,13.826,8.194,18.161,8.455,6.533,19.927,2.034,20.925-8.03.894-9.066-2.421-23.383-12.507-25.961A13.468,13.468,0,0,0,85.95,88.479Z"
            transform="translate(-38.16 -60.364)"
            fill="#ffb573"
          />
          <path
            id="Path_8822"
            data-name="Path 8822"
            d="M108.039,91.779c2.235,8.88-4.283,11.487-4.283,11.487,1.929,3.673-3.047,11.643-12.537,6.943-8.462-4.194-3.412-10.295-3.412-10.295s-6.771-1.49-9.379-11.33,3.106-16.85,13.163-19.271,15.77.06,17.633,4.589c1.922-1.922,6.7,1.184,6.2,5.4-3.345,4.1-8.939,3.68-8.939,3.68S115.615,89.112,108.039,91.779Z"
            transform="translate(-39.198 -60.871)"
            fill="#263238"
          />
          <path
            id="Path_8823"
            data-name="Path 8823"
            d="M87.649,74.314c3.166-5.289-16.731-23.942-28.032-2.488s4.231,21.99,6.213,36.725c15.316-11.174,11.509-31.131,8.4-35.9C82.524,72.347,83.969,80.638,87.649,74.314Z"
            transform="translate(-42.139 -61.746)"
            fill="#263238"
          />
          <path
            id="Path_8824"
            data-name="Path 8824"
            d="M80.3,77.489c-2.853-2.853-5.818-2.54-4.984-4.306s5.6-5.825,4.775-8.4c4.544.551,7.144,4.939,8.015,7.934a3.524,3.524,0,0,1-.641,3.181C85.011,78.971,82.351,80.185,80.3,77.489Z"
            transform="translate(-39.545 -61.345)"
            fill="#7e59d1"
          />
          <path
            id="Path_8825"
            data-name="Path 8825"
            d="M101.741,89.749a7.71,7.71,0,0,0,3.725,4.566c2.406,1.266,4.172-.745,3.978-3.315-.171-2.309-1.639-5.751-4.276-5.959S101.033,87.336,101.741,89.749Z"
            transform="translate(-36.058 -58.669)"
            fill="#ffb573"
          />
          <path
            id="Path_8826"
            data-name="Path 8826"
            d="M180.1,245.278l7.531-4.082-7.01-18.377-9.557,5.729Z"
            transform="translate(-26.876 -40.465)"
            fill="#ffb573"
          />
          <path
            id="Path_8827"
            data-name="Path 8827"
            d="M186.005,236.119l-9.066,5.3a.857.857,0,0,0-.365,1.043l2.376,6.317c.827,2.19,3.307,2.831,5.423,1.84,2.89-1.363,5.013-4.4,8.552-6.5,5.125-3.047,5.863-2.771,9.736-7.732,2.607-3.352-1.281-4.231-2.905-3.367-4.09,2.16-2.764,2.577-11.174,2.5a4.961,4.961,0,0,0-2.577.588Z"
            transform="translate(-26.155 -39.157)"
            fill="#263238"
          />
          <path
            id="Path_8828"
            data-name="Path 8828"
            d="M105.564,168.3s65.405-9.684,72.177,7.092c11.785,29.164,19.249,65.644,19.249,65.644l-8.656,5.818s-18.728-28.62-28.866-57.9c-12.12,3.926-50.655,16.538-64.809,16.538-15.085,0-21.238-12.858-15.793-34.207a4.276,4.276,0,0,1,4.179-3.211C92.557,168.09,105.564,168.3,105.564,168.3Z"
            transform="translate(-39.315 -48.035)"
            fill="#7e59d1"
          />
          <path
            id="Path_8829"
            data-name="Path 8829"
            d="M105.564,168.3s65.405-9.684,72.177,7.092c11.785,29.164,19.249,65.644,19.249,65.644l-8.656,5.818s-18.728-28.62-28.866-57.9c-12.12,3.926-50.655,16.538-64.809,16.538-15.085,0-21.238-12.858-15.793-34.207a4.276,4.276,0,0,1,4.179-3.211C92.557,168.09,105.564,168.3,105.564,168.3Z"
            transform="translate(-39.315 -48.035)"
            fill="#263238"
            opacity="0.94"
          />
          <path
            id="Path_8830"
            data-name="Path 8830"
            d="M185.63,233.4l-10.548,6.816-4.015-6.243,13.021-10.824Z"
            transform="translate(-26.876 -40.421)"
            fill="#7e59d1"
          />
          <path
            id="Path_8831"
            data-name="Path 8831"
            d="M78.643,165.456l-1.14,3.591c-.149.276.171.566.641.581l41.582,1.237c.365,0,.67-.156.7-.387l.469-3.605c.037-.246-.276-.469-.67-.484l-40.912-1.229A.745.745,0,0,0,78.643,165.456Z"
            transform="translate(-39.242 -48.083)"
            fill="#fff"
          />
          <path
            id="Path_8832"
            data-name="Path 8832"
            d="M121.587,128.991l2.577,6.7q1.307,3.352,2.674,6.7a129.308,129.308,0,0,0,5.959,12.664l.2.372a.672.672,0,0,1,.037.067,2.914,2.914,0,0,0-.223-.305,4.558,4.558,0,0,0-.529-.544,4.944,4.944,0,0,0-.581-.41,4.119,4.119,0,0,0-.529-.253l-.246-.082-.179-.052-.186-.037h-.127a2.015,2.015,0,0,0-.358,0h.179a11.955,11.955,0,0,0,2.577-.745,66.37,66.37,0,0,0,6.265-2.808c4.261-2.1,8.574-4.47,12.872-6.757h.06a2.98,2.98,0,0,1,3.456,4.775,106.147,106.147,0,0,1-11.74,9.736,59.173,59.173,0,0,1-6.771,4.224,22.416,22.416,0,0,1-4.373,1.788,10.954,10.954,0,0,1-1.579.313,9.882,9.882,0,0,1-1,.067,8.006,8.006,0,0,1-1.274-.082l-.358-.06-.41-.089-.4-.112c-.149-.045-.313-.112-.462-.171a5.773,5.773,0,0,1-.946-.462,6.607,6.607,0,0,1-1.8-1.557,6,6,0,0,1-.365-.492l-.082-.119-.283-.425a70.765,70.765,0,0,1-4.119-6.786c-1.266-2.235-2.384-4.574-3.471-6.891s-2.078-4.641-3.032-6.995-1.833-4.686-2.659-7.181a5.959,5.959,0,0,1,11.211-4.03Z"
            transform="translate(-34.934 -53.364)"
            fill="#ffb573"
          />
          <path
            id="Path_8833"
            data-name="Path 8833"
            d="M117.116,115.823c9.289,6.809,10.019,22.05,10.019,22.05l-18.214,10.958s-4.872-12.485-2.235-21.774C108.624,120.27,113.191,112.97,117.116,115.823Z"
            transform="translate(-35.485 -54.685)"
            fill="#f2b636"
          />
          <path
            id="Path_8834"
            data-name="Path 8834"
            d="M162.26,145.7l-3.784,2.078a4.872,4.872,0,0,1-3.1.544l-4.09-.6a11.114,11.114,0,0,1-6.7-3.658h0c.685-3.829,7.211-3.963,7.211-3.963l4.8-2.041a2.086,2.086,0,0,1,2.294.447l3.889,3.9a2.086,2.086,0,0,1-.514,3.3Z"
            transform="translate(-30.375 -51.685)"
            fill="#ffb573"
          />
          <path
            id="Path_8835"
            data-name="Path 8835"
            d="M144.181,216.49a14.529,14.529,0,0,0-13.9-12.195H118.086l-10.064-71.826a12.664,12.664,0,0,0-12.105-10.608H52.934a9.005,9.005,0,0,0-9.125,10.608l9.267,66.158c1.43,10.176,10.824,18.437,21,18.437h70.18Z"
            transform="translate(-43.703 -53.803)"
            fill="#7e59d1"
          />
          <path
            id="Path_8836"
            data-name="Path 8836"
            d="M130.312,205.841a2.235,2.235,0,0,1-2.138-1.564L123.3,188.745a9.394,9.394,0,0,0-9.006-6.615H102.251a2.235,2.235,0,1,1,0-4.47h12.038a13.863,13.863,0,0,1,13.29,9.744l4.879,15.532a2.235,2.235,0,0,1-1.49,2.808,2.354,2.354,0,0,1-.656.1Z"
            transform="translate(-36.263 -46.431)"
            fill="#7e59d1"
          />
          <path
            id="Path_8837"
            data-name="Path 8837"
            d="M93.065,284.078H84.007l.06-78.144h8.939Z"
            transform="translate(-38.378 -42.696)"
            fill="#7e59d1"
          />
          <path
            id="Path_8838"
            data-name="Path 8838"
            d="M93.065,284.078H84.007l.06-78.144h8.939Z"
            transform="translate(-38.378 -42.696)"
            fill="#fff"
            opacity="0.6"
          />
          <path
            id="Path_8839"
            data-name="Path 8839"
            d="M93,209.949l-8.939,2.183v-6.2H93Z"
            transform="translate(-38.371 -42.696)"
            fill="#7e59d1"
            opacity="0.3"
          />
          <path
            id="Path_8840"
            data-name="Path 8840"
            d="M89.925,273.267c-9.133,0-16.545,1.624-16.545,2.682h33.09C106.47,274.891,99.065,273.267,89.925,273.267Z"
            transform="translate(-39.782 -33.8)"
            fill="#263238"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const getRewardPoints = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="329"
    height="283"
    viewBox="0 0 329 283"
  >
    <defs>
      <clipPath id="clip-path-getrewadpoints">
        <rect
          id="Rectangle_3061"
          data-name="Rectangle 3061"
          width="329"
          height="283"
          transform="translate(193 1167.999)"
          fill="#dbdbdb"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_376"
      data-name="Mask Group 376"
      transform="translate(-193 -1167.999)"
      clipPath="url(#clip-path-getrewadpoints)"
    >
      <g
        id="Pleasant_surprise-amico"
        data-name="Pleasant surprise-amico"
        transform="translate(192.187 1132.764)"
      >
        <g id="freepik--Floor--inject-34" transform="translate(15.173 142.832)">
          <path
            id="Path_8841"
            data-name="Path 8841"
            d="M273.308,160.9c-59.328-34.224-155.2-34.224-214.147,0s-58.6,89.794.752,124.045,155.2,34.257,214.147,0S332.663,195.153,273.308,160.9Z"
            transform="translate(-15.173 -135.228)"
            fill="#e0e0f5"
          />
        </g>
        <g
          id="freepik--Shadows--inject-34"
          transform="translate(67.065 156.07)"
        >
          <path
            id="Path_8842"
            data-name="Path 8842"
            d="M271.607,235.262c-4.979-2.879-13.025-2.879-17.971,0s-4.926,7.536.054,10.408,13.032,2.879,17.978,0S276.586,238.14,271.607,235.262Z"
            transform="translate(-49.212 -141.023)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8843"
            data-name="Path 8843"
            d="M189.955,148.069l92.606,53.47a1.342,1.342,0,0,1,0,2.322l-35.848,20.615a4.026,4.026,0,0,1-4.026,0l-92.606-53.47a1.342,1.342,0,0,1,0-2.322l35.848-20.615a4.026,4.026,0,0,1,4.026,0Z"
            transform="translate(-56.856 -147.53)"
            fill="#e6e6e6"
          />
          <path
            id="Path_8844"
            data-name="Path 8844"
            d="M78.014,203.667c19.394-11.2,50.739-11.2,70.011,0,17.031,9.9,18.917,25.218,5.751,36.378,10.623-.671,21.635,1.342,29.748,6.086,14.24,8.274,14.166,21.689-.168,29.963s-37.5,8.281-51.738,0c-11.327-6.583-13.589-16.421-6.818-24.332-16.333,2.362-34.3-.148-47.014-7.536C58.513,233.025,58.614,214.867,78.014,203.667Z"
            transform="translate(-63.397 -143.9)"
            fill="#e6e6e6"
          />
        </g>
        <g
          id="freepik--Window--inject-34"
          transform="translate(204.565 35.235)"
        >
          <path
            id="Path_8845"
            data-name="Path 8845"
            d="M227.88,63.3l-.154,45.39,3.02,1.738.148-45.39Z"
            transform="translate(-188.401 -11.58)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8846"
            data-name="Path 8846"
            d="M230.686,64.914h-.007l-.148,45.39,2.617-1.523.148-42.357Z"
            transform="translate(-188.188 -11.457)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3062"
            data-name="Rectangle 3062"
            height="3.483"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 39.482, 51.72)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8847"
            data-name="Path 8847"
            d="M230.875,67.473l.007-2.436L227.869,63.3l-.007,2.429,3.013,1.745Z"
            transform="translate(-188.391 -11.58)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8848"
            data-name="Path 8848"
            d="M230.662,67.35l2.121-1.221-2.107-1.215h-.007Z"
            transform="translate(-188.178 -11.457)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <rect
            id="Rectangle_3063"
            data-name="Rectangle 3063"
            height="3.483"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 39.482, 51.713)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8849"
            data-name="Path 8849"
            d="M227.88,40.966l-.154,20.454,3.02,1.738.148-20.454Z"
            transform="translate(-188.401 -13.279)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8850"
            data-name="Path 8850"
            d="M230.686,42.582h-.007l-.148,20.454,2.617-1.523.148-17.421Z"
            transform="translate(-188.188 -13.156)"
            fill="#f0f0f0"
          />
          <rect
            id="Rectangle_3064"
            data-name="Rectangle 3064"
            height="3.483"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 39.481, 27.686)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8851"
            data-name="Path 8851"
            d="M230.875,45.14l.007-2.436-3.013-1.738-.007,2.429,3.013,1.745Z"
            transform="translate(-188.391 -13.279)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8852"
            data-name="Path 8852"
            d="M230.662,45.017l2.121-1.215-2.107-1.221h-.007Z"
            transform="translate(-188.178 -13.156)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <rect
            id="Rectangle_3065"
            data-name="Rectangle 3065"
            height="3.483"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 39.481, 27.686)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8853"
            data-name="Path 8853"
            d="M194.147,43.93l2.624-1.523-.154,45.39-2.624,1.523Z"
            transform="translate(-190.967 -13.169)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8854"
            data-name="Path 8854"
            d="M193.993,86.113l2.624-1.523,75.615,43.652-2.624,1.523Z"
            transform="translate(-190.967 -9.961)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8855"
            data-name="Path 8855"
            d="M191.355,38.983,273,86.118l-.174,52.342L191.18,91.305Zm78.467,94.277.154-45.39L194.361,44.251l-.154,45.39,75.615,43.619"
            transform="translate(-191.18 -13.429)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8856"
            data-name="Path 8856"
            d="M191.343,39.091l2.624-1.523L275.607,84.7l-2.617,1.523Z"
            transform="translate(-191.168 -13.537)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8857"
            data-name="Path 8857"
            d="M267.232,82.895l2.617-1.523-.168,52.322-2.624,1.523Z"
            transform="translate(-185.41 -10.206)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8858"
            data-name="Path 8858"
            d="M194.147,21.6l2.624-1.523-.154,20.454-2.624,1.523Z"
            transform="translate(-190.967 -14.867)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8859"
            data-name="Path 8859"
            d="M193.993,40.606l2.624-1.523,75.615,43.652-2.624,1.523Z"
            transform="translate(-190.967 -13.422)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8860"
            data-name="Path 8860"
            d="M191.355,16.651,273,63.786l-.174,27.386L191.18,44.037Zm78.467,69.32.168-20.46L194.361,21.858l-.154,20.454,75.615,43.659"
            transform="translate(-191.18 -15.128)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8861"
            data-name="Path 8861"
            d="M191.343,16.759l2.624-1.523L275.607,62.37l-2.617,1.523Z"
            transform="translate(-191.168 -15.235)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8862"
            data-name="Path 8862"
            d="M267.232,60.562l2.617-1.523-.168,27.386-2.624,1.523Z"
            transform="translate(-185.41 -11.904)"
            fill="#f0f0f0"
          />
        </g>
        <g
          id="freepik--Pictures--inject-34"
          transform="translate(45.377 48.828)"
        >
          <path
            id="Path_8863"
            data-name="Path 8863"
            d="M68.747,63.436,47.334,75.8l.047,16.367L68.794,79.8Z"
            transform="translate(-42.931 -32.695)"
            fill="none"
          />
          <path
            id="Path_8864"
            data-name="Path 8864"
            d="M67.234,63.516l.047,16.367,1.986,1.148-.054-18.655Z"
            transform="translate(-41.418 -32.775)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8865"
            data-name="Path 8865"
            d="M47.377,91.007l.007,2.3L70.777,79.794l-1.986-1.148Z"
            transform="translate(-42.928 -31.538)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8866"
            data-name="Path 8866"
            d="M73.285,58.534,45.1,74.8l.067,24.158L73.352,82.685ZM47.57,94.865v-2.3L47.524,76.2,68.937,63.842,70.95,62.7,71,81.357Z"
            transform="translate(-43.101 -33.067)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8867"
            data-name="Path 8867"
            d="M73.426,58.616l-1.986-1.154L43.255,73.734l1.986,1.147Z"
            transform="translate(-43.242 -33.149)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8868"
            data-name="Path 8868"
            d="M43.255,72.584l.074,24.165,1.98,1.148-.067-24.165Z"
            transform="translate(-43.242 -31.999)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8869"
            data-name="Path 8869"
            d="M68.735,97.567,47.321,109.928l.047,16.367,21.413-12.361Z"
            transform="translate(-42.932 -30.099)"
            fill="none"
          />
          <path
            id="Path_8870"
            data-name="Path 8870"
            d="M67.221,97.648l.047,16.367,1.98,1.154L69.2,96.507Z"
            transform="translate(-41.419 -30.18)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8871"
            data-name="Path 8871"
            d="M47.365,125.138l.007,2.3,23.386-13.5-1.98-1.154Z"
            transform="translate(-42.929 -28.943)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8872"
            data-name="Path 8872"
            d="M73.273,92.665,45.089,108.938l.067,24.158L73.34,116.823Zm-25.7,36.3v-2.3L47.524,110.3,68.938,97.94l1.98-1.141.047,18.662Z"
            transform="translate(-43.102 -30.472)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8873"
            data-name="Path 8873"
            d="M73.413,92.747l-1.986-1.154L43.243,107.866l1.986,1.154Z"
            transform="translate(-43.243 -30.554)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8874"
            data-name="Path 8874"
            d="M43.243,106.716l.067,24.165,1.986,1.154-.067-24.165Z"
            transform="translate(-43.243 -29.403)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8875"
            data-name="Path 8875"
            d="M97.979,72.842h0c.255-.127.51-.248.765-.382h0Q98.371,72.661,97.979,72.842Z"
            transform="translate(-39.08 -32.008)"
            fill="none"
          />
          <path
            id="Path_8876"
            data-name="Path 8876"
            d="M97.723,72.974h0L98,72.84h0Z"
            transform="translate(-39.1 -31.98)"
            fill="none"
          />
          <path
            id="Path_8877"
            data-name="Path 8877"
            d="M106.933,43.18l-5.852,3.382L95.129,50l5.952-3.436Z"
            transform="translate(-39.297 -34.235)"
            fill="none"
          />
          <path
            id="Path_8878"
            data-name="Path 8878"
            d="M106.15,61.85l-.013-5.429-.013-4.966.013,4.966Z"
            transform="translate(-38.461 -33.606)"
            fill="none"
          />
          <path
            id="Path_8879"
            data-name="Path 8879"
            d="M98.7,72.465l.161-.074Z"
            transform="translate(-39.026 -32.014)"
            fill="none"
          />
          <path
            id="Path_8880"
            data-name="Path 8880"
            d="M106.212,83.85l-.013-4.543-.007-3.973.007,3.973Z"
            transform="translate(-38.456 -31.79)"
            fill="none"
          />
          <path
            id="Path_8881"
            data-name="Path 8881"
            d="M95.609,73.873c.752-.268,1.51-.584,2.275-.933h0A20.067,20.067,0,0,1,95.609,73.873Z"
            transform="translate(-39.26 -31.972)"
            fill="none"
          />
          <path
            id="Path_8882"
            data-name="Path 8882"
            d="M89.94,58.759l.047,17.454.027,10.113-.027-10.113Z"
            transform="translate(-39.691 -33.05)"
            fill="none"
          />
          <path
            id="Path_8883"
            data-name="Path 8883"
            d="M107.427,79.307V75.335l-.195.134-.336.195a41.638,41.638,0,0,0-8.623,7.57q-.671.745-1.342,1.51c-2.308,2.651-4.577,5.449-6.9,8.053v6.422L92.839,97.6a61.67,61.67,0,0,1,14.589-18.293Z"
            transform="translate(-39.684 -31.79)"
            fill="#ebebeb"
          />
          <path
            id="Path_8884"
            data-name="Path 8884"
            d="M106.65,88.563V83.249a33.614,33.614,0,0,0-5.952,8.764Z"
            transform="translate(-38.873 -31.188)"
            fill="#ebebeb"
          />
          <path
            id="Path_8885"
            data-name="Path 8885"
            d="M101.289,92.334a33.613,33.613,0,0,1,5.952-8.764V79.027A61.669,61.669,0,0,0,92.653,97.333l8.636-4.986Z"
            transform="translate(-39.485 -31.509)"
            fill="#fafafa"
          />
          <path
            id="Path_8886"
            data-name="Path 8886"
            d="M97.723,72.965h0"
            transform="translate(-39.1 -31.97)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8887"
            data-name="Path 8887"
            d="M97.979,72.815h0"
            transform="translate(-39.08 -31.981)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8888"
            data-name="Path 8888"
            d="M95.636,75.094l.4-.141c.752-.268,1.51-.584,2.275-.933l.275-.134c.262-.121.517-.248.772-.382.443-.221.886-.456,1.342-.711-.45.255-.893.49-1.342.711l.161-.074c.389-.2.778-.416,1.174-.671l.248-.141.2-.121a42.276,42.276,0,0,0,6-4.295l.255-.221V61.27c-4.261,2.181-8.455,2.859-11.837,1.805a10.348,10.348,0,0,1-5.6-4.342l.047,17.447a20.615,20.615,0,0,0,5.63-1.087Z"
            transform="translate(-39.69 -33.052)"
            fill="#fafafa"
          />
          <path
            id="Path_8889"
            data-name="Path 8889"
            d="M98.282,83.478a41.638,41.638,0,0,1,8.623-7.57l.336-.195.181-.107V71.761a83.533,83.533,0,0,0-15.7,11.945c-.57.544-1.134,1.1-1.7,1.631v7.731c2.322-2.631,4.59-5.429,6.9-8.053Q97.6,84.223,98.282,83.478Z"
            transform="translate(-39.685 -32.062)"
            fill="#fafafa"
          />
          <path
            id="Path_8890"
            data-name="Path 8890"
            d="M107.41,72.1V67.358l-.255.221a42.276,42.276,0,0,1-6,4.295l-.2.121-.248.141c-.4.221-.785.436-1.174.671l-.161.074-.107.06h0l.107-.06c-.255.134-.51.255-.765.382l-.282.134c-.765.349-1.523.671-2.275.933l.429-.154-.429.154-.4.141A20.615,20.615,0,0,1,90,75.592V85.705c.57-.53,1.134-1.087,1.7-1.631A83.534,83.534,0,0,1,107.41,72.1Z"
            transform="translate(-39.687 -32.396)"
            fill="#ebebeb"
          />
          <path
            id="Path_8891"
            data-name="Path 8891"
            d="M106.972,56.812V51.846a8.347,8.347,0,0,1-5.878-5.523l-5.952,3.436a12.287,12.287,0,0,0,11.831,7.053Z"
            transform="translate(-39.296 -33.996)"
            fill="#fafafa"
          />
          <path
            id="Path_8892"
            data-name="Path 8892"
            d="M95.545,63.8c3.355,1.054,7.576.376,11.837-1.805V56.569a12.287,12.287,0,0,1-11.858-7.053l-5.6,3.234v6.711A10.348,10.348,0,0,0,95.545,63.8Z"
            transform="translate(-39.693 -33.753)"
            fill="#ebebeb"
          />
          <path
            id="Path_8893"
            data-name="Path 8893"
            d="M108.378,41.609,89,52.8l.141,50.678,19.373-11.186ZM90.125,101.782V77.517l-.047-17.447V53.359l5.6-3.234,5.952-3.436,5.852-3.355V91.757l-5.972,3.449h0l-8.583,4.973Z"
            transform="translate(-39.763 -34.355)"
            fill="#fff"
          />
          <path
            id="Path_8894"
            data-name="Path 8894"
            d="M98.584,72.522h0"
            transform="translate(-39.034 -32.004)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8895"
            data-name="Path 8895"
            d="M98.6,72.52l.107-.06"
            transform="translate(-39.033 -32.008)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8896"
            data-name="Path 8896"
            d="M100.032,71.8c-.45.255-.893.49-1.342.711"
            transform="translate(-39.026 -32.059)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <line
            id="Line_411"
            data-name="Line 411"
            transform="translate(59.67 40.451)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8897"
            data-name="Path 8897"
            d="M95.609,73.818l.429-.154"
            transform="translate(-39.26 -31.917)"
            fill="none"
            stroke="gray"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Path_8898"
            data-name="Path 8898"
            d="M106.537,52.085v-8.9l-5.852,3.355A8.193,8.193,0,0,0,106.537,52.085Z"
            transform="translate(-38.874 -34.235)"
            fill="#ebebeb"
          />
          <path
            id="Path_8899"
            data-name="Path 8899"
            d="M108.509,88.705,89.136,99.892l.007,1.342,20.534-11.851Z"
            transform="translate(-39.753 -30.773)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8900"
            data-name="Path 8900"
            d="M107.009,41.656l.141,50.678,1.168.678-.148-52.027Z"
            transform="translate(-38.393 -34.402)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8901"
            data-name="Path 8901"
            d="M112.9,35.971,86.092,51.439l.168,59.234,26.782-15.434Zm-23.487,69.28v-1.342l-.141-50.678,19.373-11.187,1.161-.671.148,52.027Z"
            transform="translate(-39.984 -34.783)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8902"
            data-name="Path 8902"
            d="M113.031,36.055l-2.208-1.188L84.034,50.335l2.214,1.188Z"
            transform="translate(-40.141 -34.867)"
            fill="#e0e0f5"
          />
          <path
            id="Path_8903"
            data-name="Path 8903"
            d="M84.034,49.242l.168,59.207,2.214,1.181-.168-59.2Z"
            transform="translate(-40.141 -33.774)"
            fill="#f0f0f0"
          />
          <path
            id="Path_8904"
            data-name="Path 8904"
            d="M61.87,69.211l5.845,4.6.007,4.489L48.3,89.51l-.013-4.489,3.7-7.355,3.322,2.584L54.139,83.9l1.7-3.241Z"
            transform="translate(-42.859 -32.256)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8905"
            data-name="Path 8905"
            d="M55.1,71.328c.913-.53,1.658-.1,1.658.946a3.63,3.63,0,0,1-1.651,2.845c-.913.53-1.658.1-1.658-.946A3.617,3.617,0,0,1,55.1,71.328Z"
            transform="translate(-42.466 -32.112)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8906"
            data-name="Path 8906"
            d="M61.857,103.342l5.838,4.6.013,4.489-19.42,11.22-.013-4.5,3.7-7.348,3.322,2.577-1.174,3.651,1.7-3.241Z"
            transform="translate(-42.86 -29.66)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8907"
            data-name="Path 8907"
            d="M55.083,105.459c.919-.523,1.658-.1,1.664.946a3.637,3.637,0,0,1-1.651,2.859c-.913.53-1.658.107-1.658-.946a3.637,3.637,0,0,1,1.644-2.859Z"
            transform="translate(-42.467 -29.516)"
            fill="#e0e0e0"
          />
        </g>
        <g
          id="freepik--Furniture--inject-34"
          transform="translate(162.805 100.401)"
        >
          <path
            id="Path_8908"
            data-name="Path 8908"
            d="M153.563,119.787v53.242l90.559,52.007V171.794Z"
            transform="translate(-152.281 -93.972)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8909"
            data-name="Path 8909"
            d="M273.442,149.01v53.248l-35.72,20.554V169.572Z"
            transform="translate(-145.881 -91.749)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8910"
            data-name="Path 8910"
            d="M153.563,119.787v53.242l90.559,52.007V171.794Z"
            transform="translate(-152.281 -93.972)"
            fill="#2a2a2a"
            opacity="0.1"
          />
          <path
            id="Path_8911"
            data-name="Path 8911"
            d="M273.442,149.01v53.248l-35.72,20.554V169.572Z"
            transform="translate(-145.881 -91.749)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8912"
            data-name="Path 8912"
            d="M194.355,175.013l42.391,24.346v-12.5l-42.391-24.339Z"
            transform="translate(-149.179 -90.722)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8913"
            data-name="Path 8913"
            d="M236.745,184.991V172.14L194.355,147.8v12.844Z"
            transform="translate(-149.179 -91.841)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8914"
            data-name="Path 8914"
            d="M192.88,146.953l-35.338-20.3V139.5L192.88,159.8Z"
            transform="translate(-151.979 -93.449)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8915"
            data-name="Path 8915"
            d="M194.355,188.964,236.745,213.3V201.251l-42.391-24.346Z"
            transform="translate(-149.179 -89.628)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8916"
            data-name="Path 8916"
            d="M192.88,161.67l-35.338-20.3v12.5l35.338,20.293Z"
            transform="translate(-151.979 -92.33)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8917"
            data-name="Path 8917"
            d="M157.542,155.764v12.052l35.338,20.3V176.057Z"
            transform="translate(-151.979 -91.236)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8918"
            data-name="Path 8918"
            d="M152.372,116.652l92.666,53.221,36.177-20.823L188.495,95.8Z"
            transform="translate(-152.372 -95.796)"
            fill="#ebebeb"
          />
          <path
            id="Path_8919"
            data-name="Path 8919"
            d="M152.372,115.178v5.436l92.666,53.228V168.4Z"
            transform="translate(-152.372 -94.322)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8920"
            data-name="Path 8920"
            d="M274.666,145.287v5.442l-36.177,20.823V166.11Z"
            transform="translate(-145.823 -92.032)"
            fill="#e0e0e0"
          />
          <path
            id="Path_8921"
            data-name="Path 8921"
            d="M274.666,145.287v5.442l-36.177,20.823V166.11Z"
            transform="translate(-145.823 -92.032)"
            fill="#2a2a2a"
            opacity="0.1"
          />
        </g>
        <g
          id="freepik--speech-bubble--inject-34"
          transform="translate(174.907 86.512)"
        >
          <path
            id="Path_8937"
            data-name="Path 8937"
            d="M176.339,100.106,204.8,83.779c1.926-1.107,6.489.389,6.489,3.483a1.436,1.436,0,0,0-2.147-1.241L180.68,102.368a4.248,4.248,0,0,0-1.678,1.8l-4.261-2.469a4.248,4.248,0,0,1,1.6-1.59Z"
            transform="translate(-153.979 -65.848)"
            fill="#7e59d1"
          />
          <path
            id="Path_8938"
            data-name="Path 8938"
            d="M179.042,102.858c-.3.591-.483,8.294-.483,8.972v23.151a1.429,1.429,0,0,0,2.141,1.241,4.234,4.234,0,0,1-6.476-3.49v-30.2a4.308,4.308,0,0,1,.557-2.1Z"
            transform="translate(-154.018 -64.554)"
            fill="#7e59d1"
          />
          <path
            id="Path_8939"
            data-name="Path 8939"
            d="M176.339,100.106,204.8,83.779c1.926-1.107,6.489.389,6.489,3.483a1.436,1.436,0,0,0-2.147-1.241L180.68,102.368a4.248,4.248,0,0,0-1.678,1.8l-4.261-2.469a4.248,4.248,0,0,1,1.6-1.59Z"
            transform="translate(-153.979 -65.848)"
            fill="#f2b636"
            opacity="0.86"
          />
          <path
            id="Path_8940"
            data-name="Path 8940"
            d="M179.042,102.858c-.3.591-.483,8.294-.483,8.972v23.151a1.429,1.429,0,0,0,2.141,1.241,4.234,4.234,0,0,1-6.476-3.49v-30.2a4.308,4.308,0,0,1,.557-2.1Z"
            transform="translate(-154.018 -64.554)"
            fill="#f2b636"
            opacity="0.86"
          />
          <path
            id="Path_8941"
            data-name="Path 8941"
            d="M208.873,85.85,180.413,102.2a4.288,4.288,0,0,0-2.161,3.718v30.2a1.429,1.429,0,0,0,2.141,1.241l28.459-16.28a4.281,4.281,0,0,0,2.168-3.724V87.091a1.436,1.436,0,0,0-2.147-1.241Z"
            transform="translate(-153.712 -65.677)"
            fill="#f2b636"
          />
          <path
            id="Path_8942"
            data-name="Path 8942"
            d="M167.049,138.854l-.423-.094a.443.443,0,0,1-.235-.121c-.389-.336-.289-1.275.3-2.013l13.294-16.6-4.335-2.476-13.3,16.6c-.584.731-.671,1.671-.3,2.013l4.127,2.342L155.446,151.96l4.342,2.476,11.69-14.642Z"
            transform="translate(-155.446 -63.252)"
            fill="#7e59d1"
          />
          <path
            id="Path_8943"
            data-name="Path 8943"
            d="M179.681,125.935v-6.08l-13.307,16.6c-.591.731-.671,1.671-.3,2.013a.443.443,0,0,0,.235.121l.423.094,4.422.946-11.69,14.642,15.951-16.36c.758-.772.926-2.013.322-2.275l-3.543-1.55.45-.49Z"
            transform="translate(-155.141 -63.077)"
            fill="#f2b636"
          />
          <path
            id="Path_8944"
            data-name="Path 8944"
            d="M174.848,134.661l-3.094-2.04-.45.49Z"
            transform="translate(-154.24 -62.106)"
            fill="#7e59d1"
          />
          <path
            id="Path_8945"
            data-name="Path 8945"
            d="M167.049,138.854l-.423-.094a.443.443,0,0,1-.235-.121c-.389-.336-.289-1.275.3-2.013l13.294-16.6-4.335-2.476-13.3,16.6c-.584.731-.671,1.671-.3,2.013l4.127,2.342L155.446,151.96l4.342,2.476,11.69-14.642Z"
            transform="translate(-155.446 -63.252)"
            fill="#f2b636"
            opacity="0.86"
          />
          <path
            id="Path_8946"
            data-name="Path 8946"
            d="M174.848,134.661l-3.094-2.04-.45.49Z"
            transform="translate(-154.24 -62.106)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_8947"
            data-name="Path 8947"
            d="M198.954,102.648l4.382-2.523v5.6l-.544.315.034,10.415-16.172,9.341V115.371l-.544.315v-5.6l6.261-3.617-1.181-.174a3.476,3.476,0,0,1-1.537-.51,2.168,2.168,0,0,1-.778-2.013,4.563,4.563,0,0,1,1.758-3.114.922.922,0,0,1,.134-.087,1.49,1.49,0,0,1,1.51-.107,2.483,2.483,0,0,1,.812.893c.456.731.892,1.523,1.3,2.315a24.071,24.071,0,0,1,2.684-5.509,10.407,10.407,0,0,1,1.711-2.053,4.584,4.584,0,0,1,.7-.5,1.852,1.852,0,0,1,1.5-.3c.966.322,1.027,1.852.584,3.194a10.9,10.9,0,0,1-2.013,3.422C199.37,102.138,199.169,102.4,198.954,102.648Zm-5.925,18.367v-9.321l-5.415,3.12v9.328l5.415-3.127m8.851-5.107V106.58l-5.409,3.127v9.321l5.415-3.12m-3.919-17a20.8,20.8,0,0,0-2.5,5.368,19.764,19.764,0,0,0,3.355-3.355,7.838,7.838,0,0,0,1.423-2.362c.2-.6.188-1.342-.175-1.449a.778.778,0,0,0-.611.134,2.3,2.3,0,0,0-.349.255,7.173,7.173,0,0,0-1.174,1.429m-4.959,11.69V107.2l-5.966,3.443v3.409l5.959-3.436m9.395-5.429v-3.409l-5.959,3.443v3.409l5.959-3.443m-10.435-.671.886.134c-.322-.611-.671-1.208-1.02-1.772a1.919,1.919,0,0,0-.55-.671.5.5,0,0,0-.5,0h0a1.4,1.4,0,0,0-.456.906,1.074,1.074,0,0,0,.423.98,3,3,0,0,0,1.241.383"
            transform="translate(-153.114 -64.947)"
            fill="#fff"
          />
          <path
            id="Path_8948"
            data-name="Path 8948"
            d="M191.327,71.268c.671-.376,1.194-.074,1.194.671v6.295a2.637,2.637,0,0,1-1.188,2.053c-.671.382-1.194.074-1.194-.671V73.328a2.631,2.631,0,0,1,1.188-2.06Z"
            transform="translate(-152.808 -66.784)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8949"
            data-name="Path 8949"
            d="M176.441,84.458c.409-.242.812-.221,1.027.087l2.758,3.865a2.009,2.009,0,0,1-.429,2.121,2.12,2.12,0,0,1-.6.523.738.738,0,0,1-1.033-.087L175.414,87.1a2.339,2.339,0,0,1,1.027-2.644Z"
            transform="translate(-153.94 -65.781)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8950"
            data-name="Path 8950"
            d="M165.564,103.285a1.107,1.107,0,0,1,.6-.161l4.744.409c.57.047.765.772.443,1.617a2.61,2.61,0,0,1-1.033,1.282,1,1,0,0,1-.591.154l-4.751-.4c-.57-.054-.772-.772-.443-1.617a2.61,2.61,0,0,1,1.027-1.282Z"
            transform="translate(-154.767 -64.349)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8951"
            data-name="Path 8951"
            d="M167.094,119.749c.671-.383,1.194-.074,1.2.671a2.631,2.631,0,0,1-1.188,2.053l-5.5,3.2c-.671.376-1.188.067-1.194-.671a2.617,2.617,0,0,1,1.188-2.053Z"
            transform="translate(-155.069 -63.097)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8952"
            data-name="Path 8952"
            d="M179.334,137.21a1.013,1.013,0,0,1,.591-.161c.57.054.765.778.443,1.624l-2.724,7.026a2.59,2.59,0,0,1-1.027,1.275,1.087,1.087,0,0,1-.6.161c-.57-.047-.765-.772-.443-1.617l2.724-7.026A2.583,2.583,0,0,1,179.334,137.21Z"
            transform="translate(-153.927 -61.769)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8953"
            data-name="Path 8953"
            d="M191.5,133.905c.671-.383,1.194-.081,1.194.671v6.288a2.644,2.644,0,0,1-1.188,2.06c-.671.376-1.194.067-1.194-.671v-6.288a2.617,2.617,0,0,1,1.188-2.06Z"
            transform="translate(-152.795 -62.021)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8954"
            data-name="Path 8954"
            d="M203.843,122.967c.416-.242.819-.228,1.04.087l2.751,3.865a2.009,2.009,0,0,1-.43,2.121,2.121,2.121,0,0,1-.6.523.738.738,0,0,1-1.033-.087l-2.751-3.865a2,2,0,0,1,.429-2.121,2.208,2.208,0,0,1,.591-.523Z"
            transform="translate(-151.856 -62.853)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8955"
            data-name="Path 8955"
            d="M212.86,107.351a1.033,1.033,0,0,1,.6-.161l4.751.4c.57.054.765.778.436,1.624a2.523,2.523,0,0,1-1.027,1.275,1.02,1.02,0,0,1-.6.161l-4.744-.409c-.57-.047-.772-.772-.443-1.617A2.59,2.59,0,0,1,212.86,107.351Z"
            transform="translate(-151.17 -64.04)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8956"
            data-name="Path 8956"
            d="M221.611,88.3c.671-.376,1.188-.067,1.188.671a2.61,2.61,0,0,1-1.181,2.053l-5.476,3.161c-.671.383-1.194.074-1.194-.671a2.624,2.624,0,0,1,1.188-2.06Z"
            transform="translate(-150.922 -65.489)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8957"
            data-name="Path 8957"
            d="M217.515,73.488c.409-.235.805-.221,1.027.087a2,2,0,0,1-.429,2.121l-4.7,5.878a2.135,2.135,0,0,1-.59.53c-.409.242-.819.228-1.033-.087a1.983,1.983,0,0,1,.43-2.121l4.731-5.878A2.154,2.154,0,0,1,217.515,73.488Z"
            transform="translate(-151.175 -66.615)"
            fill="#f2b636"
            opacity="0.63"
          />
          <path
            id="Path_8958"
            data-name="Path 8958"
            d="M206.43,67.251a1.033,1.033,0,0,1,.6-.161c.564.054.765.772.436,1.617l-2.718,7.026a2.637,2.637,0,0,1-1.027,1.282,1.087,1.087,0,0,1-.6.161c-.57-.054-.765-.778-.443-1.617l2.684-7.026A2.59,2.59,0,0,1,206.43,67.251Z"
            transform="translate(-151.866 -67.089)"
            fill="#f2b636"
            opacity="0.63"
          />
        </g>
        <g
          id="freepik--Character--inject-34"
          transform="translate(43.696 72.828)"
        >
          <path
            id="Path_8970"
            data-name="Path 8970"
            d="M156.117,173.639c7.962,5.537,16.974,9.36,23.594,16.78,12.423,13.854,12.714,36.146,2.958,52s-27.918,25.372-46.4,27.546c-19.4,2.287-39.743-3.071-54.963-15.309s-24.9-31.369-24.9-50.922,10.015-38.975,26.366-49.637c14.929-9.7,36.373-12.512,51.342-1.374,7.566,5.658,12.617,13.935,20.207,19.585C154.889,172.782,155.5,173.219,156.117,173.639Z"
            transform="translate(-56.407 -67.306)"
            fill="#7e59d1"
          />
          <path
            id="Path_8971"
            data-name="Path 8971"
            d="M156.117,173.639c7.962,5.537,16.974,9.36,23.594,16.78,12.423,13.854,12.714,36.146,2.958,52s-27.918,25.372-46.4,27.546c-19.4,2.287-39.743-3.071-54.963-15.309s-24.9-31.369-24.9-50.922,10.015-38.975,26.366-49.637c14.929-9.7,36.373-12.512,51.342-1.374,7.566,5.658,12.617,13.935,20.207,19.585C154.889,172.782,155.5,173.219,156.117,173.639Z"
            transform="translate(-56.407 -67.306)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8972"
            data-name="Path 8972"
            d="M156.117,173.639c7.962,5.537,16.974,9.36,23.594,16.78,12.423,13.854,12.714,36.146,2.958,52s-27.918,25.372-46.4,27.546c-19.4,2.287-39.743-3.071-54.963-15.309s-24.9-31.369-24.9-50.922,10.015-38.975,26.366-49.637c14.929-9.7,36.373-12.512,51.342-1.374,7.566,5.658,12.617,13.935,20.207,19.585C154.889,172.782,155.5,173.219,156.117,173.639Z"
            transform="translate(-56.407 -67.306)"
            fill="#e0e0f5"
            opacity="0.3"
          />
          <path
            id="Path_8973"
            data-name="Path 8973"
            d="M146.621,250.525a.963.963,0,0,0-.057-.4,21.015,21.015,0,0,0-16.974-17.475,66.28,66.28,0,0,1-21.767-8.333c-4.761-2.877-9.255-6.41-14.59-7.962a23.828,23.828,0,0,0-15.729.905c4.486-4.7,12.173-6.773,18.324-4.648-4.252-6.507-7.994-13.523-9.465-21.161s-.42-16.012,4.179-22.284a22.414,22.414,0,0,1,34.611-1.843c4.567,4.987,7.137,12,10.128,18.065,2.15,4.349,4.381,8.8,7.921,12.124,5.351,5,13.046,6.806,18.591,11.591,6.3,5.432,9,14.113,9.037,22.414A66.027,66.027,0,0,1,168.4,248.1a62.95,62.95,0,0,1-22.753,13.911A24.653,24.653,0,0,0,146.621,250.525Z"
            transform="translate(-50.161 -63.008)"
            fill="#2a2a2a"
            opacity="0.1"
          />
          <path
            id="Path_8974"
            data-name="Path 8974"
            d="M64.448,201.028a56.976,56.976,0,0,1,48.626-55.335,39.6,39.6,0,0,1,5.5.921,55.578,55.578,0,0,0-52.619,54.438c-.356,18.178,8.535,36.7,23.788,49.556,11.946,10.071,27.821,16.756,45.264,19.156l-1.115.145c-1.568.186-3.144.315-4.72.4-15.5-3.007-29.535-9.4-40.414-18.59C73.178,238.613,64.085,219.651,64.448,201.028Z"
            transform="translate(-54.03 -67.254)"
            fill="#fff"
          />
          <path
            id="Path_8975"
            data-name="Path 8975"
            d="M137.488,242.18l-2.021,3.961c-2.829-2.611-3.338-4.72-3.338-4.72.913-3.233,1.98-6.515,2.506-8.083.275,2.223,1.794,4.138,2.425,6.3a10.64,10.64,0,0,1,.428,2.538Z"
            transform="translate(-33.987 -41.303)"
            fill="#7e59d1"
          />
          <path
            id="Path_8976"
            data-name="Path 8976"
            d="M136.628,244.218l-1.924-.1,2-3.961C136.782,241.5,136.668,242.86,136.628,244.218Z"
            transform="translate(-33.224 -39.282)"
            fill="#7e59d1"
          />
          <path
            id="Path_8977"
            data-name="Path 8977"
            d="M137.488,242.18l-2.021,3.961c-2.829-2.611-3.338-4.72-3.338-4.72.913-3.233,1.98-6.515,2.506-8.083.275,2.223,1.794,4.138,2.425,6.3a10.64,10.64,0,0,1,.428,2.538Z"
            transform="translate(-33.987 -41.303)"
            fill="#2a2a2a"
            opacity="0.1"
          />
          <path
            id="Path_8978"
            data-name="Path 8978"
            d="M136.628,244.218l-1.924-.1,2-3.961C136.782,241.5,136.668,242.86,136.628,244.218Z"
            transform="translate(-33.224 -39.282)"
            fill="#2a2a2a"
            opacity="0.4"
          />
          <path
            id="Path_8979"
            data-name="Path 8979"
            d="M135.388,230.338a17.372,17.372,0,0,1,2.36-2.756,9.635,9.635,0,0,0,0,5.286c.6,2.3,1.673,4.446,2.207,6.757.655,2.8.509,5.828,1.7,8.455A9.077,9.077,0,0,0,148,253.14a4.041,4.041,0,0,0,3.007-.364,2.15,2.15,0,0,0,.808-1.124,20.287,20.287,0,0,0,2.166,3.532c.938,1.22,2.11,2.554,1.851,4.041a3.233,3.233,0,0,1-2.651,2.425,8.527,8.527,0,0,1-3.767-.307,23.569,23.569,0,0,1-7.549-3.08,11.316,11.316,0,0,1-4.793-6.426,14.443,14.443,0,0,1-.291-3.92c.04-1.358.154-2.716.1-4.041a10.635,10.635,0,0,0-.4-2.562c-.639-2.166-2.158-4.082-2.425-6.3,0-.121-.04-.242-.048-.364A7.194,7.194,0,0,1,135.388,230.338Z"
            transform="translate(-33.431 -43.007)"
            fill="#455a64"
          />
          <path
            id="Path_8980"
            data-name="Path 8980"
            d="M135.388,230.338a17.372,17.372,0,0,1,2.36-2.756,9.635,9.635,0,0,0,0,5.286c.6,2.3,1.673,4.446,2.207,6.757.655,2.8.509,5.828,1.7,8.455A9.077,9.077,0,0,0,148,253.14a4.041,4.041,0,0,0,3.007-.364,2.15,2.15,0,0,0,.808-1.124,20.287,20.287,0,0,0,2.166,3.532c.938,1.22,2.11,2.554,1.851,4.041a3.233,3.233,0,0,1-2.651,2.425,8.527,8.527,0,0,1-3.767-.307,23.569,23.569,0,0,1-7.549-3.08,11.316,11.316,0,0,1-4.793-6.426,14.443,14.443,0,0,1-.291-3.92c.04-1.358.154-2.716.1-4.041a10.635,10.635,0,0,0-.4-2.562c-.639-2.166-2.158-4.082-2.425-6.3,0-.121-.04-.242-.048-.364A7.194,7.194,0,0,1,135.388,230.338Z"
            transform="translate(-33.431 -43.007)"
            fill="#7e59d1"
          />
          <path
            id="Path_8981"
            data-name="Path 8981"
            d="M147.252,253.295a9.077,9.077,0,0,1-6.345-5.06c-1.188-2.627-1.043-5.658-1.7-8.455-.533-2.312-1.617-4.462-2.207-6.757a9.635,9.635,0,0,1,0-5.286l.687-.679c3.4,1.77,7.275,3.233,10.378,5.48a72.984,72.984,0,0,0,.752,11.882,28.031,28.031,0,0,0,2.3,7.388,2.15,2.15,0,0,1-.808,1.124,4.041,4.041,0,0,1-3.063.364Z"
            transform="translate(-32.653 -43.162)"
            fill="#ffb573"
          />
          <path
            id="Path_8982"
            data-name="Path 8982"
            d="M156.587,239.212a80.069,80.069,0,0,0,3.08,9.982s-11.8.477-12.06-.065l-3.468-5.359Z"
            transform="translate(-30.431 -39.563)"
            fill="#ffb573"
          />
          <path
            id="Path_8983"
            data-name="Path 8983"
            d="M135.962,178.87l30.092,16.772a14.9,14.9,0,0,1,7.4,15.745l-5.836,31.483,4.785,15.22c-4.13,5.545-13.62,5.828-13.62,5.828-5.286-10.621-9.174-15.131-12.181-24.022s-5.011-15.026-5.011-15.026L116.6,218.031A29.615,29.615,0,0,1,94.95,192.837c-.428-3.783-.445-6.361-.445-6.361Z"
            transform="translate(-45.127 -57.43)"
            fill="#37474f"
          />
          <path
            id="Path_8984"
            data-name="Path 8984"
            d="M127.087,185.393a14.218,14.218,0,0,1-3.371,9.174l21.242,13.3a15.261,15.261,0,0,1,6.466,8.471l7.549,24.6-6.781-27.368A12.4,12.4,0,0,0,146.671,206l-19.609-12.124C129.067,188.844,127.087,185.393,127.087,185.393Z"
            transform="translate(-36.478 -55.499)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_8985"
            data-name="Path 8985"
            d="M152.571,260.292l-.275,4.421c-3.621-1.293-4.922-3.023-4.922-3.023-.428-3.33-.744-6.773-.889-8.447,1.124,1.94,3.274,3.1,4.72,4.85A10.564,10.564,0,0,1,152.571,260.292Z"
            transform="translate(-29.736 -35.408)"
            fill="#7e59d1"
          />
          <path
            id="Path_8986"
            data-name="Path 8986"
            d="M152.755,262.449l-1.786.655.275-4.421C151.825,259.887,152.262,261.18,152.755,262.449Z"
            transform="translate(-28.409 -33.798)"
            fill="#7e59d1"
          />
          <path
            id="Path_8987"
            data-name="Path 8987"
            d="M152.755,262.449l-1.786.655.275-4.421C151.825,259.887,152.262,261.18,152.755,262.449Z"
            transform="translate(-28.409 -33.798)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_8988"
            data-name="Path 8988"
            d="M146.016,250.332a17.422,17.422,0,0,1,1.083-3.468,9.626,9.626,0,0,0,2.11,4.85c1.455,1.883,3.29,3.435,4.688,5.343,1.705,2.328,2.756,5.165,4.85,7.113a9.061,9.061,0,0,0,7.824,2.158,3.985,3.985,0,0,0,2.659-1.56,2.223,2.223,0,0,0,.323-1.358,20.3,20.3,0,0,0,3.379,2.425c1.342.744,2.942,1.511,3.306,3.007a3.274,3.274,0,0,1-1.487,3.274,8.551,8.551,0,0,1-3.589,1.2,23.44,23.44,0,0,1-8.156.137,11.267,11.267,0,0,1-6.927-4.041,14.275,14.275,0,0,1-1.811-3.484c-.493-1.269-.93-2.562-1.512-3.767a10.562,10.562,0,0,0-1.366-2.207c-1.447-1.738-3.6-2.9-4.72-4.85-.057-.105-.129-.2-.186-.307a7.275,7.275,0,0,1-.469-4.462Z"
            transform="translate(-29.922 -37.297)"
            fill="#7e59d1"
          />
          <path
            id="Path_8989"
            data-name="Path 8989"
            d="M146.016,250.332a17.422,17.422,0,0,1,1.083-3.468,9.626,9.626,0,0,0,2.11,4.85c1.455,1.883,3.29,3.435,4.688,5.343,1.705,2.328,2.756,5.165,4.85,7.113a9.061,9.061,0,0,0,7.824,2.158,3.985,3.985,0,0,0,2.659-1.56,2.223,2.223,0,0,0,.323-1.358,20.3,20.3,0,0,0,3.379,2.425c1.342.744,2.942,1.511,3.306,3.007a3.274,3.274,0,0,1-1.487,3.274,8.551,8.551,0,0,1-3.589,1.2,23.44,23.44,0,0,1-8.156.137,11.267,11.267,0,0,1-6.927-4.041,14.275,14.275,0,0,1-1.811-3.484c-.493-1.269-.93-2.562-1.512-3.767a10.562,10.562,0,0,0-1.366-2.207c-1.447-1.738-3.6-2.9-4.72-4.85-.057-.105-.129-.2-.186-.307a7.275,7.275,0,0,1-.469-4.462Z"
            transform="translate(-29.922 -37.297)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_8990"
            data-name="Path 8990"
            d="M166.312,266.525a9.061,9.061,0,0,1-7.824-2.158c-2.126-1.948-3.177-4.785-4.85-7.113-1.4-1.908-3.233-3.459-4.688-5.343a9.627,9.627,0,0,1-2.11-4.85l.364-.889c3.823.283,7.962.1,11.7.954a73.472,73.472,0,0,0,5.351,10.629,27.65,27.65,0,0,0,5.028,5.892,2.223,2.223,0,0,1-.323,1.358A3.985,3.985,0,0,1,166.312,266.525Z"
            transform="translate(-29.631 -37.502)"
            fill="#ffb573"
          />
          <path
            id="Path_8991"
            data-name="Path 8991"
            d="M152.768,91.8a5.892,5.892,0,0,1-4.292,7.194,31.9,31.9,0,0,1-3.993-7.8,18.241,18.241,0,0,0,.857-3.233C148.3,87.754,152.016,88.926,152.768,91.8Z"
            transform="translate(-30.329 -84.356)"
            fill="#eba567"
          />
          <path
            id="Path_8992"
            data-name="Path 8992"
            d="M144.255,91.109V88.434a4.429,4.429,0,0,1,.356-2.1,2.223,2.223,0,0,1,3.621-.4,1.7,1.7,0,0,1,1.794-.242,1.617,1.617,0,0,1,.913,1.511,1.689,1.689,0,0,1,1.681-.283,1.463,1.463,0,0,1,.954,1.253,1.617,1.617,0,0,1,1.277.9,2.425,2.425,0,0,1,.162,1.423,4.631,4.631,0,0,1-1.3,2.6,2.126,2.126,0,0,1-.808.542.994.994,0,0,1-.938-.154.671.671,0,0,1-.113-.889,1.528,1.528,0,0,1-1.059.3.978.978,0,0,1-.622-.647,2.765,2.765,0,0,1-.105-.93,1.3,1.3,0,0,1-1.511.663,1.261,1.261,0,0,1-.76-1.431,4.768,4.768,0,0,1-.525.727.905.905,0,0,1-1.423-.2,2.247,2.247,0,0,1-.315-.849C145.16,90.535,144.635,90.794,144.255,91.109Z"
            transform="translate(-30.4 -85.173)"
            fill="#ffb573"
          />
          <path
            id="Path_8993"
            data-name="Path 8993"
            d="M141.951,101.927l1.924-5.415V91.339a1.948,1.948,0,0,1,1.018-1.705l.76-.4a1.932,1.932,0,0,1,1.883,0l3.621,2.085h0a2.425,2.425,0,0,1-3.088,1.075l-.962-.4c4.138,3.694,2.578,6.321,2.578,6.321l-2.49,6.135Z"
            transform="translate(-31.079 -84.045)"
            fill="#ffb573"
          />
          <path
            id="Path_8994"
            data-name="Path 8994"
            d="M123.173,149.853,146.2,97.371c6.086-1.018,8.455,2.554,8.964,4.85L131.725,165.6Z"
            transform="translate(-36.639 -81.614)"
            fill="#7e59d1"
          />
          <path
            id="Path_8995"
            data-name="Path 8995"
            d="M123.173,149.853,146.2,97.371c6.086-1.018,8.455,2.554,8.964,4.85L131.725,165.6Z"
            transform="translate(-36.639 -81.614)"
            fill="#7e59d1"
          />
          <path
            id="Path_8996"
            data-name="Path 8996"
            d="M103.384,177.033c-4.211-2.19-8.39-4.631-11.47-8.083s-4.931-8.18-3.807-12.577c1.027-4.041,4.3-7.178,7.614-9.918s6.9-5.391,8.94-9.069c2.425-4.405,2.287-9.61,2.611-14.549s1.471-10.346,5.561-13.49c3.185-2.425,7.638-3.039,11.7-2.425s7.824,2.344,11.486,4.106c2.554,1.229,6.838,3.306,8.891,5.181s3.6,4.429,3.548,7.129a6.264,6.264,0,0,1-4.85,5.965,16.071,16.071,0,0,1-7.113,15.357c-4.421,3.055-2.328,9.271-5.416,13.741s-6.208,8.956-10.411,12.512S108.339,179.6,103.384,177.033Z"
            transform="translate(-47.119 -78.799)"
            fill="#263238"
          />
          <path
            id="Path_8997"
            data-name="Path 8997"
            d="M132.309,135.888c5.432.865,8.2,18.421,9.958,23.634,1.827,5.383-.655,9.287-3.411,12.545h0a5.561,5.561,0,0,0-1.269,4.308l5.706,21.209c-15.584,11.567-41.707,5.957-48.788,2.182A50.114,50.114,0,0,1,99.081,178.3a35.184,35.184,0,0,0,3.451-13.894l.267-7.784L101.99,133.9l12.456,6.07,11.922-.533S130.539,135.613,132.309,135.888Z"
            transform="translate(-45.128 -70.745)"
            fill="#7e59d1"
          />
          <path
            id="Path_8998"
            data-name="Path 8998"
            d="M132.309,135.888c5.432.865,8.2,18.421,9.958,23.634,1.827,5.383-.655,9.287-3.411,12.545h0a5.561,5.561,0,0,0-1.269,4.308l5.706,21.209c-15.584,11.567-41.707,5.957-48.788,2.182A50.114,50.114,0,0,1,99.081,178.3a35.184,35.184,0,0,0,3.451-13.894l.267-7.784L101.99,133.9l12.456,6.07,11.922-.533S130.539,135.613,132.309,135.888Z"
            transform="translate(-45.128 -70.745)"
            fill="#7e59d1"
          />
          <path
            id="Path_8999"
            data-name="Path 8999"
            d="M121.843,135.347v10.661l1.374,5.5a1.431,1.431,0,0,1-2.425,1.326l-10.9-11.664V129.8Z"
            transform="translate(-40.57 -71.958)"
            fill="#ffb573"
          />
          <path
            id="Path_9000"
            data-name="Path 9000"
            d="M110.258,129.965c1.01,7.533,8.026,12.8,11.453,13.62V135.3Z"
            transform="translate(-40.463 -71.91)"
            fill="#eba567"
          />
          <path
            id="Path_9001"
            data-name="Path 9001"
            d="M115.555,125.517a11.672,11.672,0,0,1-.687,2.78,3.379,3.379,0,0,1-1.455,1.568,2.118,2.118,0,0,1-2.013,0,3.74,3.74,0,0,0-6.466-3.759,4.7,4.7,0,0,0-.356,3.952,8.892,8.892,0,0,0,2.247,3.379,3.169,3.169,0,0,0,4.041-.348c2.166,5.456,7.275,10.572,13.094,11.785a8.527,8.527,0,0,0,4.785-.243c2.651-1.026,3.556-3.492,4.567-5.9a36.7,36.7,0,0,0,2.732-9.481,19.475,19.475,0,0,0-2.6-13.175C128.713,124.9,119.063,125.8,115.555,125.517Z"
            transform="translate(-42.227 -76.023)"
            fill="#ffb573"
          />
          <path
            id="Path_9002"
            data-name="Path 9002"
            d="M122.51,127.215l2.473,5.965-3.993.17Z"
            transform="translate(-37.285 -72.725)"
            fill="#eba567"
          />
          <path
            id="Path_9003"
            data-name="Path 9003"
            d="M123.445,134.741l-5.019-1.908a3.7,3.7,0,0,0-.469,2.425,2.554,2.554,0,0,0,1.471,1.908,2.772,2.772,0,0,0,2.465-.291A5.335,5.335,0,0,0,123.445,134.741Z"
            transform="translate(-38.195 -71.061)"
            fill="#ad5755"
          />
          <path
            id="Path_9004"
            data-name="Path 9004"
            d="M123.445,134.741l-5.019-1.908a3.7,3.7,0,0,0-.469,2.425,2.554,2.554,0,0,0,1.471,1.908,2.772,2.772,0,0,0,2.465-.291A5.335,5.335,0,0,0,123.445,134.741Z"
            transform="translate(-38.195 -71.061)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_9005"
            data-name="Path 9005"
            d="M117.8,126.952a1.132,1.132,0,1,0,1.374-.808,1.132,1.132,0,0,0-1.374.808Z"
            transform="translate(-38.241 -73.052)"
            fill="#263238"
          />
          <path
            id="Path_9006"
            data-name="Path 9006"
            d="M124.961,128.427a1.132,1.132,0,1,0,1.346-.743A1.132,1.132,0,0,0,124.961,128.427Z"
            transform="translate(-36.114 -72.596)"
            fill="#263238"
          />
          <path
            id="Path_9007"
            data-name="Path 9007"
            d="M126.048,124.33l2.692,1.414a1.617,1.617,0,0,0-.7-1.705A1.907,1.907,0,0,0,126.048,124.33Z"
            transform="translate(-35.787 -73.712)"
            fill="#263238"
          />
          <path
            id="Path_9008"
            data-name="Path 9008"
            d="M120.486,123.2l-3.007.461a1.617,1.617,0,0,1,1.212-1.382A1.916,1.916,0,0,1,120.486,123.2Z"
            transform="translate(-38.324 -74.187)"
            fill="#263238"
          />
          <path
            id="Path_9009"
            data-name="Path 9009"
            d="M117.92,134.18a3.232,3.232,0,0,0,0,.679,2.376,2.376,0,0,0,3.233,1.9,3.27,3.27,0,0,0-3.233-2.578Z"
            transform="translate(-38.199 -70.662)"
            fill="#ad5755"
          />
          <path
            id="Path_9010"
            data-name="Path 9010"
            d="M110.109,196.921a1.107,1.107,0,0,0,.04.4h0a1.406,1.406,0,0,0,.162.307h0a1.823,1.823,0,0,0,.267.3h0a2.256,2.256,0,0,0,.4.291L133,211.018a3.232,3.232,0,0,0,.639.275l.242.073.315.081.4.065h1.326a2.237,2.237,0,0,0,.517-.089h.089a4.047,4.047,0,0,0,.445-.129h.081a2.966,2.966,0,0,0,.485-.226l36.373-21.015a2.671,2.671,0,0,0,.412-.234.656.656,0,0,0,.1-.113,1.171,1.171,0,0,0,.194-.21.738.738,0,0,0,.073-.137,1.2,1.2,0,0,0,.105-.2,1.007,1.007,0,0,0,0-.154.881.881,0,0,0,0-.2v1.285a.949.949,0,0,1,0,.21.908.908,0,0,1,0,.145,1.276,1.276,0,0,1-.105.21.177.177,0,0,1-.073.137,1.528,1.528,0,0,1-.194.21l-.1.113a3.37,3.37,0,0,1-.412.291l-36.373,20.975a3.859,3.859,0,0,1-.485.234h-.081l-.445.137h-.089a3.635,3.635,0,0,1-.517.089h-.081a3.745,3.745,0,0,1-.525,0h-.089a4.029,4.029,0,0,1-.542,0h-.089l-.4-.065-.315-.081-.242-.073a3.816,3.816,0,0,1-.639-.283l-22.018-12.8a2.257,2.257,0,0,1-.4-.291h0a1.858,1.858,0,0,1-.267-.307,1.246,1.246,0,0,1-.162-.307h0a.9.9,0,0,1,0-.291h0v-1.4Z"
            transform="translate(-40.508 -54.49)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9011"
            data-name="Path 9011"
            d="M110.109,196.921a1.107,1.107,0,0,0,.04.4h0a1.406,1.406,0,0,0,.162.307h0a1.823,1.823,0,0,0,.267.3h0a2.256,2.256,0,0,0,.4.291L133,211.018a3.232,3.232,0,0,0,.639.275l.242.073.315.081.4.065h1.326a2.237,2.237,0,0,0,.517-.089h.089a4.047,4.047,0,0,0,.445-.129h.081a2.966,2.966,0,0,0,.485-.226l36.373-21.015a2.671,2.671,0,0,0,.412-.234.656.656,0,0,0,.1-.113,1.171,1.171,0,0,0,.194-.21.738.738,0,0,0,.073-.137,1.2,1.2,0,0,0,.105-.2,1.007,1.007,0,0,0,0-.154.881.881,0,0,0,0-.2v1.285a.949.949,0,0,1,0,.21.908.908,0,0,1,0,.145,1.276,1.276,0,0,1-.105.21.177.177,0,0,1-.073.137,1.528,1.528,0,0,1-.194.21l-.1.113a3.37,3.37,0,0,1-.412.291l-36.373,20.975a3.859,3.859,0,0,1-.485.234h-.081l-.445.137h-.089a3.635,3.635,0,0,1-.517.089h-.081a3.745,3.745,0,0,1-.525,0h-.089a4.029,4.029,0,0,1-.542,0h-.089l-.4-.065-.315-.081-.242-.073a3.816,3.816,0,0,1-.639-.283l-22.018-12.8a2.257,2.257,0,0,1-.4-.291h0a1.858,1.858,0,0,1-.267-.307,1.246,1.246,0,0,1-.162-.307h0a.9.9,0,0,1,0-.291h0v-1.4Z"
            transform="translate(-40.508 -54.49)"
            fill="#2a2a2a"
            opacity="0.2"
          />
          <path
            id="Path_9012"
            data-name="Path 9012"
            d="M174.794,192.492a1.2,1.2,0,0,1-.105.2.736.736,0,0,1-.073.137,1.174,1.174,0,0,1-.194.21.654.654,0,0,1-.1.113,2.664,2.664,0,0,1-.412.291L137.54,214.461a2.961,2.961,0,0,1-.485.226h-.081a4.036,4.036,0,0,1-.445.129h-.089c-.17.04-.339.065-.517.089H134.6l-.4-.065-.315-.081-.242-.073a3.234,3.234,0,0,1-.639-.275l-22.018-12.8a2.256,2.256,0,0,1-.4-.291h0a1.816,1.816,0,0,1-.267-.3h0a1.406,1.406,0,0,1-.162-.307h0a1.107,1.107,0,0,1-.04-.4,1.617,1.617,0,0,1,.93-1.245L147.418,178.1a4.947,4.947,0,0,1,4.518,0l22.026,12.8a1.617,1.617,0,0,1,.93,1.309.882.882,0,0,1,0,.2,1.008,1.008,0,0,1-.1.089Z"
            transform="translate(-40.506 -57.821)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9013"
            data-name="Path 9013"
            d="M168.868,190.466l-35.306,20.409-.137.073h-.073l-.275.081-.194.04h-.137a3.33,3.33,0,0,1-.348,0h-.364l-.234-.04-.283-.081a2.161,2.161,0,0,1-.243-.1l-11.356-6.6,36.462-21.056Z"
            transform="translate(-37.602 -56.15)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9014"
            data-name="Path 9014"
            d="M129.522,204.719Z"
            transform="translate(-34.759 -49.776)"
            fill="#263238"
          />
          <path
            id="Path_9015"
            data-name="Path 9015"
            d="M134.373,184.511l6.644,3.912a.671.671,0,0,1,0,1.156l-9.934,5.731a1.091,1.091,0,0,1-1.107,0l-6.644-3.9a.671.671,0,0,1,0-1.164l9.926-5.731A1.1,1.1,0,0,1,134.373,184.511Z"
            transform="translate(-36.692 -55.805)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9016"
            data-name="Path 9016"
            d="M168.868,190.466l-35.306,20.409-.137.073h-.073l-.275.081-.194.04h-.137a3.33,3.33,0,0,1-.348,0h-.364l-.234-.04-.283-.081a2.161,2.161,0,0,1-.243-.1l-11.356-6.6,36.462-21.056Z"
            transform="translate(-37.602 -56.15)"
            fill="#2a2a2a"
            opacity="0.4"
          />
          <path
            id="Path_9017"
            data-name="Path 9017"
            d="M134.373,184.511l6.644,3.912a.671.671,0,0,1,0,1.156l-9.934,5.731a1.091,1.091,0,0,1-1.107,0l-6.644-3.9a.671.671,0,0,1,0-1.164l9.926-5.731A1.1,1.1,0,0,1,134.373,184.511Z"
            transform="translate(-36.692 -55.805)"
            fill="#2a2a2a"
            opacity="0.4"
          />
          <path
            id="Path_9018"
            data-name="Path 9018"
            d="M129.719,216.074l3.758-23.925a4.421,4.421,0,0,1,2.142-3.128l36.591-21.274a1.091,1.091,0,0,1,.97-.065h0l.873.428-.291.113a1.083,1.083,0,0,1,.089.647L170.133,192.8a4.413,4.413,0,0,1-2.142,3.128L131.336,217.2a1.2,1.2,0,0,1-.234.1v.348l-.808-.412h0a1.091,1.091,0,0,1-.574-1.156Z"
            transform="translate(-34.705 -60.767)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9019"
            data-name="Path 9019"
            d="M129.719,216.074l3.758-23.925a4.421,4.421,0,0,1,2.142-3.128l36.591-21.274a1.091,1.091,0,0,1,.97-.065h0l.873.428-.291.113a1.083,1.083,0,0,1,.089.647L170.133,192.8a4.413,4.413,0,0,1-2.142,3.128L131.336,217.2a1.2,1.2,0,0,1-.234.1v.348l-.808-.412h0a1.091,1.091,0,0,1-.574-1.156Z"
            transform="translate(-34.705 -60.767)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_9020"
            data-name="Path 9020"
            d="M136.177,189.3l36.591-21.266a1.1,1.1,0,0,1,1.617,1.124l-3.758,23.917a4.413,4.413,0,0,1-2.142,3.136l-36.526,21.266a1.1,1.1,0,0,1-1.617-1.124l3.759-23.917a4.437,4.437,0,0,1,2.077-3.136Z"
            transform="translate(-34.52 -60.68)"
            fill="#e0e0e0"
          />
          <path
            id="Path_9021"
            data-name="Path 9021"
            d="M149.36,185a8.083,8.083,0,0,0-3.629,5.23c-.242,1.867.994,2.562,2.764,1.536a8.083,8.083,0,0,0,3.629-5.23C152.367,184.664,151.122,183.977,149.36,185Z"
            transform="translate(-29.969 -55.756)"
            fill="#fff"
          />
          <path
            id="Path_9022"
            data-name="Path 9022"
            d="M85,101.087c-6.119-3-11.55,3.411-9.7,6.725S89.009,107.4,85,101.087Z"
            transform="translate(-50.922 -80.691)"
            fill="#7e59d1"
          />
          <path
            id="Path_9023"
            data-name="Path 9023"
            d="M85,101.087c-6.119-3-11.55,3.411-9.7,6.725S89.009,107.4,85,101.087Z"
            transform="translate(-50.922 -80.691)"
            fill="#2a2a2a"
            opacity="0.3"
          />
          <path
            id="Path_9024"
            data-name="Path 9024"
            d="M70.963,94.776a15.361,15.361,0,0,0,6.24,9.659l6.159-2.861L81.083,90.509,79.531,89.03Z"
            transform="translate(-52.098 -84.031)"
            fill="#eba567"
          />
          <path
            id="Path_9025"
            data-name="Path 9025"
            d="M79.041,86.539a1.9,1.9,0,0,0-2.95.978,1.5,1.5,0,0,0-1.8-.057,1.617,1.617,0,0,0-.59,1.794,1.043,1.043,0,0,0-1.156-.21,1.689,1.689,0,0,0-.808.9,5.31,5.31,0,0,0-.364,1.22,1.253,1.253,0,0,0-1.326.275,1.269,1.269,0,0,0-.267,1.326A13.28,13.28,0,0,0,71.152,95.3a2.368,2.368,0,0,0,1.414,1.245,1.237,1.237,0,0,0,1.212-.5,1.253,1.253,0,0,0,.057-1.318,1.9,1.9,0,0,0,2.207.509,1.617,1.617,0,0,0,.6-1.98,1.277,1.277,0,0,0,1.269.7,1.471,1.471,0,0,0,1.132-.905,1.867,1.867,0,0,0-.065-1.471,1.334,1.334,0,0,0,1,.865,1.229,1.229,0,0,0,1.2-.428,2.263,2.263,0,0,0,.073-2.158,6.054,6.054,0,0,0-2.215-3.314Z"
            transform="translate(-52.473 -84.879)"
            fill="#ffb573"
          />
          <path
            id="Path_9026"
            data-name="Path 9026"
            d="M85.517,107.56l-3.152-6.313L83.6,96.915a5.957,5.957,0,0,0-.63-4.728l-.744-1.245a1.617,1.617,0,0,0-2.1-.647L74.25,92.986v.081a3.233,3.233,0,0,0,4.324,1.827h0c-3.9,3.047-4.041,5.609-2.425,9.214s4.13,9.311,4.13,9.311Z"
            transform="translate(-51.125 -83.704)"
            fill="#ffb573"
          />
          <path
            id="Path_9027"
            data-name="Path 9027"
            d="M114.555,147l-29.64-46.072c-1.309,7.331-9.7,6.725-9.7,6.725l33.269,58.754C112.923,173.8,127.989,166.529,114.555,147Z"
            transform="translate(-50.838 -80.508)"
            fill="#7e59d1"
          />
          <path
            id="Path_9028"
            data-name="Path 9028"
            d="M114.555,147l-29.64-46.072c-1.309,7.331-9.7,6.725-9.7,6.725l33.269,58.754C112.923,173.8,127.989,166.529,114.555,147Z"
            transform="translate(-50.838 -80.508)"
            fill="#7e59d1"
          />
          <path
            id="Path_9029"
            data-name="Path 9029"
            d="M123.5,162.1a9.336,9.336,0,0,1-12.633-2.554c.154,3.007,2,5.9,4.947,6.531S122.137,164.771,123.5,162.1Z"
            transform="translate(-40.282 -63.151)"
            fill="#2a2a2a"
            opacity="0.2"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const startYallaOnlineTop = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="176.314"
    height="182.687"
    viewBox="0 0 176.314 182.687"
  >
    <defs>
      <clipPath id="clip-path-startyallaonlinetop">
        <path
          id="Path_10985"
          data-name="Path 10985"
          d="M91.787,316.05l18.882,4.73v.649l1.589.043v-.746l13.963-2.206s.611-.141.092.541-9.509,8.53-9.509,8.53l-9.979,1.027L91.69,325.294Z"
          transform="translate(-91.69 -316.05)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_10993"
          data-name="Path 10993"
          d="M107.645,407.469l8.411-5.492-.114-.438.773-.227.124.5,7.179-.216s.319,0-.049-.389-6.054-4.589-6.054-4.589l-5.044.541-6.822,4.081Z"
          transform="translate(-106.05 -396.62)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          id="Path_11000"
          data-name="Path 11000"
          d="M342.593,305.55l12.541,16.217L323.5,330.952l3.676-19.493Z"
          transform="translate(-323.5 -305.55)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          id="Path_11007"
          data-name="Path 11007"
          d="M332.423,299.71l-9.179,19.136-21.174,7.19,10.866-17.179Z"
          transform="translate(-302.07 -299.71)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          id="Path_11013"
          data-name="Path 11013"
          d="M212.172,378.519l12.941-19.358L193.82,357.42l3.141,18.8Z"
          transform="translate(-193.82 -357.42)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          id="Path_11020"
          data-name="Path 11020"
          d="M201.9,386.042l-8.644-17.125L172.34,366.7l10.374,14.752Z"
          transform="translate(-172.34 -366.7)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-7">
        <path
          id="Path_11051"
          data-name="Path 11051"
          d="M143.8,190.8l78.891,19.774v2.789l6.773.2v-3.211l59.371-9.363s2.589-.6.4,2.389-40.446,36.262-40.446,36.262l-42.435,4.384L142,229.9l.384-38.008A1.135,1.135,0,0,1,143.8,190.8Z"
          transform="translate(-142 -190.765)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g id="Bibliophile-bro" transform="translate(-86.899 -82.509)">
      <g id="freepik--Books--inject-218" transform="translate(87.399 200.151)">
        <path
          id="Path_10980"
          data-name="Path 10980"
          d="M112.406,318.152a17.07,17.07,0,0,0-5.433-3.141c-2.762-.8-5.946-.816-8.7-1.568s-4.395-1.384-4.395-1.384l-.141,2.487,18.552,5.011,12.693-3.514,1.265-2.579a20.542,20.542,0,0,1-4.325,1.454c-2.762.654-5.854.654-7.568,1.314A2.894,2.894,0,0,0,112.406,318.152Z"
          transform="translate(-92.632 -305.384)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10981"
          data-name="Path 10981"
          d="M93.021,313.1a64.442,64.442,0,0,0,11.109,2.352,16.963,16.963,0,0,1,7.817,3.179,12.573,12.573,0,0,1,6.233-1.968A31.894,31.894,0,0,0,126,315.068l-.795,1.622-13.633,2.67L92.74,314.441Z"
          transform="translate(-92.172 -305.862)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10982"
          data-name="Path 10982"
          d="M92.227,314.14a33.748,33.748,0,0,0,8.974,2.092,34.6,34.6,0,0,1,10.325,2.876,15.822,15.822,0,0,1,6.606-1.687,30.742,30.742,0,0,0,7.638-1.033,1.833,1.833,0,0,0,.232,1.546l-14.525,3.092L92.13,315.4Z"
          transform="translate(-91.892 -306.34)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10983"
          data-name="Path 10983"
          d="M91.787,316.05l18.882,4.73v.649l1.589.043v-.746l13.963-2.206s.611-.141.092.541-9.509,8.53-9.509,8.53l-9.979,1.027L91.69,325.294Z"
          transform="translate(-91.69 -307.217)"
          fill="#7e59d1"
        />
        <g
          id="Group_10863"
          data-name="Group 10863"
          transform="translate(0 8.833)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_10984"
            data-name="Path 10984"
            d="M110.674,321.44v-.66l-18.882-4.73-.092,9.276,15.131,3.325.27-.027,3.995-7.173Z"
            transform="translate(-91.695 -316.05)"
            opacity="0.3"
          />
        </g>
        <path
          id="Path_10986"
          data-name="Path 10986"
          d="M91.787,316.05l18.882,4.73v.649l1.589.043v-.746l13.963-2.206s.611-.141.092.541-9.509,8.53-9.509,8.53l-9.979,1.027L91.69,325.294Z"
          transform="translate(-91.69 -307.217)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10987"
          data-name="Path 10987"
          d="M123.253,326.02l-4.173,7.211.8.049,4.962-7.217Z"
          transform="translate(-104.274 -311.798)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10988"
          data-name="Path 10988"
          d="M118.347,406.555a8.579,8.579,0,0,1-2.119,2.773c-1.211.87-2.757,1.281-3.979,2.119a19.616,19.616,0,0,0-1.9,1.465l-.487-1.654,8.184-5.638,6.784.822,1.049,1.578a8.855,8.855,0,0,0-2.346-.454c-1.454-.1-2.962.276-3.919.043A1.924,1.924,0,0,1,118.347,406.555Z"
          transform="translate(-100.038 -348.368)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10989"
          data-name="Path 10989"
          d="M109.9,412.987a25.152,25.152,0,0,1,5.022-2.935,7.649,7.649,0,0,0,3.276-3.092,5.406,5.406,0,0,0,3.373.541,12.363,12.363,0,0,1,4.081.119l-.665-1.011L117.9,406.5l-8.357,5.606Z"
          transform="translate(-99.891 -348.772)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10990"
          data-name="Path 10990"
          d="M109.349,411.984a13.406,13.406,0,0,1,4.027-2.5,14.106,14.106,0,0,0,4.552-3.195,6.4,6.4,0,0,0,3.5.33,11.511,11.511,0,0,1,3.9-.232,1.735,1.735,0,0,1-.146-1.081l-7.6-.3-8.492,6.146Z"
          transform="translate(-99.684 -348.083)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10991"
          data-name="Path 10991"
          d="M107.645,407.469l8.411-5.492-.114-.438.773-.227.124.5,7.179-.216s.319,0-.049-.389-6.054-4.589-6.054-4.589l-5.044.541-6.822,4.081Z"
          transform="translate(-98.287 -344.233)"
          fill="#7e59d1"
        />
        <g
          id="Group_10864"
          data-name="Group 10864"
          transform="translate(7.763 52.387)"
          clipPath="url(#clip-path-2)"
        >
          <path
            id="Path_10992"
            data-name="Path 10992"
            d="M115.969,401.971l.114.438-8.411,5.492-1.611-6.244,6.822-4.081.135-.016,3.157,4.352Z"
            transform="translate(-106.055 -397.052)"
            opacity="0.3"
          />
        </g>
        <path
          id="Path_10994"
          data-name="Path 10994"
          d="M107.645,407.469l8.411-5.492-.114-.438.773-.227.124.5,7.179-.216s.319,0-.049-.389-6.054-4.589-6.054-4.589l-5.044.541-6.822,4.081Z"
          transform="translate(-98.287 -344.233)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10995"
          data-name="Path 10995"
          d="M121.633,401.907l-3.243-4.357.378-.13,3.638,4.26Z"
          transform="translate(-103.957 -344.601)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10996"
          data-name="Path 10996"
          d="M341.658,299.71l15.266,15.46-32.6,9.184-2.157-15.5Z"
          transform="translate(-197.579 -299.71)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10997"
          data-name="Path 10997"
          d="M354.556,319.268l-33.716,9.909,2.719-17.817,17.012-7.59Z"
          transform="translate(-196.968 -301.575)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10998"
          data-name="Path 10998"
          d="M342.593,305.55l12.541,16.217L323.5,330.952l3.676-19.493Z"
          transform="translate(-198.19 -302.393)"
          fill="#fff"
        />
        <g
          id="Group_10865"
          data-name="Group 10865"
          transform="translate(125.31 3.157)"
          clipPath="url(#clip-path-3)"
        >
          <path
            id="Path_10999"
            data-name="Path 10999"
            d="M342.593,305.55l12.541,16.217L323.5,330.952l3.676-19.493Z"
            transform="translate(-323.5 -305.55)"
            opacity="0.17"
          />
        </g>
        <path
          id="Path_11001"
          data-name="Path 11001"
          d="M342.593,305.55l12.541,16.217L323.5,330.952l3.676-19.493Z"
          transform="translate(-198.19 -302.393)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11002"
          data-name="Path 11002"
          d="M334.852,305.55,329.1,325.519l-22.45,6.8,8.628-18.379Z"
          transform="translate(-190.448 -302.393)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11003"
          data-name="Path 11003"
          d="M326.283,337.027l-21.65,7.271-.643-3.038,20.055-7.19Z"
          transform="translate(-189.226 -315.496)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11004"
          data-name="Path 11004"
          d="M350.118,300.52,364.5,315.461l-.638,2.314-13.185-14.536-7.346,18.374-2.238-2.957Z"
          transform="translate(-206.271 -300.082)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11005"
          data-name="Path 11005"
          d="M332.423,299.71l-9.179,19.136-21.174,7.19,10.866-17.179Z"
          transform="translate(-188.344 -299.71)"
          fill="#7e59d1"
        />
        <g
          id="Group_10866"
          data-name="Group 10866"
          transform="translate(113.726 0)"
          clipPath="url(#clip-path-4)"
        >
          <path
            id="Path_11006"
            data-name="Path 11006"
            d="M332.423,299.71l-9.179,19.136-21.174,7.19,10.866-17.179Z"
            transform="translate(-302.07 -299.71)"
            opacity="0.3"
          />
        </g>
        <path
          id="Path_11008"
          data-name="Path 11008"
          d="M332.423,299.71l-9.179,19.136-21.174,7.19,10.866-17.179Z"
          transform="translate(-188.344 -299.71)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_503"
          data-name="Line 503"
          y1="7.773"
          x2="17.033"
          transform="translate(123.715 3.135)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11009"
          data-name="Path 11009"
          d="M210.715,384.847l15.633-19.234L194.1,364.1l-2.568,16.158Z"
          transform="translate(-137.559 -329.293)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11010"
          data-name="Path 11010"
          d="M224.468,361.823,191.12,359.85l2.227,17.336L210.11,380.8Z"
          transform="translate(-137.371 -327.34)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11011"
          data-name="Path 11011"
          d="M212.172,378.519l12.941-19.358L193.82,357.42l3.141,18.8Z"
          transform="translate(-138.611 -326.224)"
          fill="#fff"
        />
        <g
          id="Group_10867"
          data-name="Group 10867"
          transform="translate(55.209 31.196)"
          clipPath="url(#clip-path-5)"
        >
          <path
            id="Path_11012"
            data-name="Path 11012"
            d="M212.172,378.519l12.941-19.358L193.82,357.42l3.141,18.8Z"
            transform="translate(-193.82 -357.42)"
            opacity="0.17"
          />
        </g>
        <path
          id="Path_11014"
          data-name="Path 11014"
          d="M212.172,378.519l12.941-19.358L193.82,357.42l3.141,18.8Z"
          transform="translate(-138.611 -326.224)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11015"
          data-name="Path 11015"
          d="M204.486,379.2l-5.195-18.79-22.2-1.508,8.109,16.493Z"
          transform="translate(-130.925 -326.904)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11016"
          data-name="Path 11016"
          d="M196.339,363.524l-21.39-2.184-.719,3.216,19.8,2.481Z"
          transform="translate(-129.611 -328.025)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11017"
          data-name="Path 11017"
          d="M219.369,384.188l14.741-18.5-.573-2.184L220,381.312l-6.833-16.8-2.308,3.514Z"
          transform="translate(-146.44 -329.017)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11018"
          data-name="Path 11018"
          d="M201.9,386.042l-8.644-17.125L172.34,366.7l10.374,14.752Z"
          transform="translate(-128.743 -330.487)"
          fill="#7e59d1"
        />
        <g
          id="Group_10868"
          data-name="Group 10868"
          transform="translate(43.597 36.213)"
          clipPath="url(#clip-path-6)"
        >
          <path
            id="Path_11019"
            data-name="Path 11019"
            d="M201.9,386.042l-8.644-17.125L172.34,366.7l10.374,14.752Z"
            transform="translate(-172.34 -366.7)"
            opacity="0.3"
          />
        </g>
        <path
          id="Path_11021"
          data-name="Path 11021"
          d="M201.9,386.042l-8.644-17.125L172.34,366.7l10.374,14.752Z"
          transform="translate(-128.743 -330.487)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_504"
          data-name="Line 504"
          x2="17.033"
          y2="3.584"
          transform="translate(52.376 48.835)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </g>
      <g
        id="freepik--Character--inject-218"
        transform="translate(114.595 83.008)"
      >
        <path
          id="Path_11022"
          data-name="Path 11022"
          d="M240.64,162.808s-7.082,3.422-9.914,7.568-2.006,11.8,2.357,14.277,19.59,11.568,30.332,10.271,9.087-12.276,3.66-16.644a95.259,95.259,0,0,0-12.747-8.26,13.71,13.71,0,0,0-.827-3.184C253.025,166.008,247.6,160.683,240.64,162.808Z"
          transform="translate(-182.005 -119.442)"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11023"
          data-name="Path 11023"
          d="M278.166,195.389a29.171,29.171,0,0,0-29.986,7.26c6.649,3.454,16.444,7.7,24.012,6.784C281.177,208.346,281.485,200.481,278.166,195.389Z"
          transform="translate(-190.782 -133.968)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11024"
          data-name="Path 11024"
          d="M283.43,194.23s9.444,7.568,6.135,15.1"
          transform="translate(-206.977 -134.107)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11025"
          data-name="Path 11025"
          d="M222.874,157.063l-3.784,17.65h3.043l7.5-13.909A5.974,5.974,0,0,0,222.874,157.063Z"
          transform="translate(-177.417 -116.995)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11026"
          data-name="Path 11026"
          d="M273.65,134.581c-3.1-3.784-7.19-7.292-14.687-7.892,0,0-6.39-.2-15.082,3.5-6.795,2.887-14.055,9.19-17.79,12.974a13.255,13.255,0,0,1,6.768,3.741c1.481-1.411,2.773-2.638,3.725-3.411a45.211,45.211,0,0,1,7.9-4.795s-5.1,6.99-6.1,8.79-.3,4.7,4.995,5.5,6.6-.2,8.692-1.4,4.7-2.3,5.7-2.3a8.428,8.428,0,0,0,5.595-1.622c2.595-1.8,3.7-6.892,4.595-7.292.616-.276,6.071,6.838,10.32,12.179l.368-.189a11.714,11.714,0,0,0,4.281-4.6C279.488,142.738,275.655,137.035,273.65,134.581Z"
          transform="translate(-180.633 -103.075)"
          fill="#f2b636"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11027"
          data-name="Path 11027"
          d="M280.49,132.9s-2.119,5.1,2.124,7.027"
          transform="translate(-205.383 -105.93)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_505"
          data-name="Line 505"
          y1="1.362"
          x2="4.606"
          transform="translate(63.847 34.241)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11028"
          data-name="Path 11028"
          d="M260,165.035s2.9,2.562,9.7-.335"
          transform="translate(-196.212 -120.54)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11029"
          data-name="Path 11029"
          d="M306.519,148.41a5.344,5.344,0,0,0-2.979,1.946,33.622,33.622,0,0,1,5.876-.719"
          transform="translate(-216.216 -113.056)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11030"
          data-name="Path 11030"
          d="M260.39,149.553s3.119,0,6.13-.114"
          transform="translate(-196.392 -113.529)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11031"
          data-name="Path 11031"
          d="M263.48,162.84s.335,2.011,4.681,1.784"
          transform="translate(-197.811 -119.685)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11032"
          data-name="Path 11032"
          d="M284.548,128.991s-.7,3.595-.2,5.492,2.3,4.1,3.6,1.8a14.9,14.9,0,0,0,1.995-6.092C289.943,128.964,285.445,126.694,284.548,128.991Z"
          transform="translate(-207.316 -103.681)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11033"
          data-name="Path 11033"
          d="M272.72,103.821a9.955,9.955,0,0,0,5.195-3.7c2.4-3.1-.7-3.492-1.7-6.487s2.519-2-2.573-7.2-13.39-3.395-17.3-1.7-3.784,7.4-6.59,7.8-4-2.3-7.4-2.3-7,3.595-5.595,6.59,4.1,1.7,4.3,1.2-1.4.1-1.5-1.4,3.595-.7,4.7,1.3c.892,1.622-.746,3.925-.692,5.627.113,3.416,4.3,5.6,6.984,6.071,3.627.632,8.038.411,11.325-1.389,4.038-2.216,2.162-6.811,5.135-8.011s4.5.6,4.5.6Z"
          transform="translate(-185.396 -83.008)"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11034"
          data-name="Path 11034"
          d="M244.372,99.605a3.054,3.054,0,0,0-3.152.438"
          transform="translate(-187.584 -90.517)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11035"
          data-name="Path 11035"
          d="M275.223,98.769s0-5.946-4.173-7.157-8.941,4.173-11.352,7.568-5.946,2.584-8.546-.995a8.11,8.11,0,0,0-.811-.973"
          transform="translate(-191.774 -86.881)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11036"
          data-name="Path 11036"
          d="M252.62,116.106c2.162,1.389,3.784,1.789,6.957.795s2.784-6.163,5.562-8.941,7.752-1.789,7.752-1.789-5.562-2.984-9.936.195-3.979,8.546-7.952,9.541"
          transform="translate(-192.822 -93.066)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11037"
          data-name="Path 11037"
          d="M301.52,102.423s3.784-.995,3.579-4.373"
          transform="translate(-215.288 -89.919)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11038"
          data-name="Path 11038"
          d="M243.92,177s10.271,1.3,15.46,4.865"
          transform="translate(-188.825 -126.191)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11039"
          data-name="Path 11039"
          d="M287.955,107.865a3.47,3.47,0,0,0-3.595,1.081c-1.7,1.795-1.622,2.7-3.038,3.822a2.552,2.552,0,0,1-3.1.2c-.6-.3-1.3-.1-1.5,1.3s1.7,2.5,2,2.5a7.57,7.57,0,0,1,.9.1,5.863,5.863,0,0,0,1.7,3.995c1.7,1.5,5.1,3.9,7.4,3.4s3.195-5.795,3.195-8.492a24.644,24.644,0,0,0-1.595-8C289.615,106.195,287.955,107.865,287.955,107.865Z"
          transform="translate(-203.891 -94.074)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11040"
          data-name="Path 11040"
          d="M293.242,118.9c0,.541-.195,1.005-.443,1.005s-.449-.449-.449-1.005.2-1,.449-1S293.242,118.349,293.242,118.9Z"
          transform="translate(-211.075 -99.039)"
          fill="#263238"
        />
        <path
          id="Path_11041"
          data-name="Path 11041"
          d="M299.832,118.28c0,.541-.2,1.005-.443,1.005s-.449-.449-.449-1.005.2-1,.449-1S299.832,117.729,299.832,118.28Z"
          transform="translate(-214.103 -98.754)"
          fill="#263238"
        />
        <path
          id="Path_11042"
          data-name="Path 11042"
          d="M290.08,115.438a2.038,2.038,0,0,1,2.341-.541"
          transform="translate(-210.032 -97.604)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11043"
          data-name="Path 11043"
          d="M297.91,114.536a1.349,1.349,0,0,1,2.119,0"
          transform="translate(-213.629 -97.243)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11044"
          data-name="Path 11044"
          d="M297.71,119.13s.108,2.233.778,2.233.443,1-.449,1.335"
          transform="translate(-213.537 -99.604)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11045"
          data-name="Path 11045"
          d="M294.3,128.909a2.019,2.019,0,0,1-2.784-1.119"
          transform="translate(-210.694 -103.582)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11046"
          data-name="Path 11046"
          d="M230.147,199.133s-11.352-9.957-23.115-13.347-25.3-1.795-37.056-4.979-18.525-7.568-18.525-7.568l-.6,10.557,78.924,21.32,53.992-14.941,5.379-10.957s-6.573,3.384-18.331,6.173-24.931,2.784-32.31,5.557S230.147,199.133,230.147,199.133Z"
          transform="translate(-146.066 -124.463)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11047"
          data-name="Path 11047"
          d="M147.625,177.65s13.168,7.973,42.835,10.763c19.542,1.833,37.656,12.747,37.656,12.747a53.452,53.452,0,0,1,26.488-8.368c15.542-.595,33.272-6.773,33.272-6.773l-3.379,6.957-57.976,11.352-80.091-20.92Z"
          transform="translate(-144.035 -126.489)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11048"
          data-name="Path 11048"
          d="M144.25,182.08a107.1,107.1,0,0,0,35.462,9.147c21.315,1.595,46.619,11.952,46.619,11.952s13.347-7.168,28.11-7.168a130.716,130.716,0,0,0,32.472-4.384,7.79,7.79,0,0,0,.995,6.573l-61.777,13.163-82.281-23.92Z"
          transform="translate(-142.85 -128.525)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11049"
          data-name="Path 11049"
          d="M143.8,190.8l78.891,19.774v2.789l6.773.2v-3.211l59.371-9.363s2.589-.6.4,2.389-40.446,36.262-40.446,36.262l-42.435,4.384L142,229.9l.384-38.008A1.135,1.135,0,0,1,143.8,190.8Z"
          transform="translate(-142 -132.515)"
          fill="#7e59d1"
        />
        <g
          id="Group_10869"
          data-name="Group 10869"
          transform="translate(0 58.25)"
          clipPath="url(#clip-path-7)"
        >
          <path
            id="Path_11050"
            data-name="Path 11050"
            d="M222.7,213.1V210.31L142.4,190.19l-.395,39.446,64.35,14.147,1.151-.119,16.985-30.51Z"
            transform="translate(-142.005 -190.501)"
            opacity="0.3"
          />
        </g>
        <path
          id="Path_11052"
          data-name="Path 11052"
          d="M143.8,190.8l78.891,19.774v2.789l6.773.2v-3.211l59.371-9.363s2.589-.6.4,2.389-40.446,36.262-40.446,36.262l-42.435,4.384L142,229.9l.384-38.008A1.135,1.135,0,0,1,143.8,190.8Z"
          transform="translate(-142 -132.515)"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11053"
          data-name="Path 11053"
          d="M276.2,232.57,258.47,263.253l3.389.195,21.115-30.677Z"
          transform="translate(-195.509 -151.721)"
          fill="#7e59d1"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11054"
          data-name="Path 11054"
          d="M400.74,211.087s5.292.989,4.189,4.519l3.179-3.373s1.454-1.811.789-2.162S400.74,211.087,400.74,211.087Z"
          transform="translate(-260.872 -141.358)"
          fill="#263238"
        />
        <path
          id="Path_11055"
          data-name="Path 11055"
          d="M151.454,192.825s-7.487,2.07-8.649,7.665l-.065-8.579s.227-1.292,1.411-1.081S151.454,192.825,151.454,192.825Z"
          transform="translate(-142.34 -132.534)"
          fill="#263238"
        />
        <path
          id="Path_11056"
          data-name="Path 11056"
          d="M142.08,258.3l3.762,3.584-3.833-.957Z"
          transform="translate(-142.005 -163.542)"
          fill="#263238"
        />
        <path
          id="Path_11057"
          data-name="Path 11057"
          d="M334.54,279.518l5.1-2.508-2.687,2.33Z"
          transform="translate(-230.458 -172.138)"
          fill="#263238"
        />
        <path
          id="Path_11058"
          data-name="Path 11058"
          d="M327.279,165.68l14.358,17.212-3.616.573-15.39-12.974S326.614,167.88,327.279,165.68Z"
          transform="translate(-224.986 -120.99)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11059"
          data-name="Path 11059"
          d="M211.815,186.507s-.719-.87-.9-.092-1.811,3.481-.541,3.67,1.989-2.9,1.989-2.9Z"
          transform="translate(-173.191 -130.368)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11060"
          data-name="Path 11060"
          d="M215,186.315s-.724-.87-.9-.1-1.811,3.481-.541,3.676,1.995-2.9,1.995-2.9Z"
          transform="translate(-174.652 -130.279)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11061"
          data-name="Path 11061"
          d="M217.865,186.156s-.859-.968-1.081-.108-2.162,3.865-.643,4.081,2.362-3.243,2.362-3.243Z"
          transform="translate(-175.798 -130.184)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11062"
          data-name="Path 11062"
          d="M222.145,187.15l2.9,2.789H221.61Z"
          transform="translate(-178.575 -130.854)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11063"
          data-name="Path 11063"
          d="M220.645,186.756s-.859-.968-1.081-.108-2.162,3.865-.643,4.076,2.362-3.243,2.362-3.243Z"
          transform="translate(-177.075 -130.46)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11064"
          data-name="Path 11064"
          d="M364.637,191.344s.578-.973.881-.238,2.33,3.157,1.114,3.546-2.422-2.551-2.422-2.551Z"
          transform="translate(-244.089 -132.545)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11065"
          data-name="Path 11065"
          d="M361.5,191.644s.578-.973.881-.238,2.335,3.157,1.114,3.546-2.422-2.552-2.422-2.552Z"
          transform="translate(-242.647 -132.683)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11066"
          data-name="Path 11066"
          d="M358.259,191.982s.7-1.081,1.043-.276,2.7,3.481,1.276,3.93-2.838-2.811-2.838-2.811Z"
          transform="translate(-241.117 -132.81)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11067"
          data-name="Path 11067"
          d="M353.987,193.5l-2.427,3.211,3.395-.541Z"
          transform="translate(-238.278 -133.771)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_11068"
          data-name="Path 11068"
          d="M355.609,193.012s.692-1.081,1.043-.276,2.7,3.476,1.276,3.925-2.838-2.806-2.838-2.806Z"
          transform="translate(-239.899 -133.284)"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1"
        />
      </g>
      <g
        id="freepik--Sparkles--inject-218"
        transform="translate(96.935 158.922)"
      >
        <path
          id="Path_11069"
          data-name="Path 11069"
          d="M167.953,242.017l3.46-.541-3.46-.541a1.443,1.443,0,0,1-1.211-1.211l-.541-3.465-.541,3.465a1.443,1.443,0,0,1-1.205,1.211l-3.465.541,3.465.541a1.46,1.46,0,0,1,1.205,1.211l.541,3.465.541-3.465A1.46,1.46,0,0,1,167.953,242.017Z"
          transform="translate(-133.064 -229.33)"
          fill="#fff"
        />
        <path
          id="Path_11070"
          data-name="Path 11070"
          d="M120.138,285.437l3.465-.541-3.465-.541a1.465,1.465,0,0,1-1.211-1.211l-.541-3.465-.541,3.465a1.449,1.449,0,0,1-1.211,1.211l-3.465.541,3.465.541a1.449,1.449,0,0,1,1.211,1.211l.541,3.465.541-3.465A1.465,1.465,0,0,1,120.138,285.437Z"
          transform="translate(-111.094 -249.278)"
          fill="#7e59d1"
        />
        <path
          id="Path_11071"
          data-name="Path 11071"
          d="M120.138,285.437l3.465-.541-3.465-.541a1.465,1.465,0,0,1-1.211-1.211l-.541-3.465-.541,3.465a1.449,1.449,0,0,1-1.211,1.211l-3.465.541,3.465.541a1.449,1.449,0,0,1,1.211,1.211l.541,3.465.541-3.465A1.465,1.465,0,0,1,120.138,285.437Z"
          transform="translate(-111.094 -249.278)"
          fill="#fff"
          opacity="0.5"
        />
        <path
          id="Path_11072"
          data-name="Path 11072"
          d="M279.738,312.172l3.465-.541-3.465-.541a1.449,1.449,0,0,1-1.211-1.206l-.541-3.465-.541,3.465a1.449,1.449,0,0,1-1.211,1.205l-3.465.541,3.465.541a1.449,1.449,0,0,1,1.211,1.205l.541,3.465.541-3.465A1.449,1.449,0,0,1,279.738,312.172Z"
          transform="translate(-184.419 -261.563)"
          fill="#7e59d1"
        />
        <path
          id="Path_11073"
          data-name="Path 11073"
          d="M279.738,312.172l3.465-.541-3.465-.541a1.449,1.449,0,0,1-1.211-1.206l-.541-3.465-.541,3.465a1.449,1.449,0,0,1-1.211,1.205l-3.465.541,3.465.541a1.449,1.449,0,0,1,1.211,1.205l.541,3.465.541-3.465A1.449,1.449,0,0,1,279.738,312.172Z"
          transform="translate(-184.419 -261.563)"
          fill="#fff"
          opacity="0.5"
        />
        <path
          id="Path_11074"
          data-name="Path 11074"
          d="M113.941,252.991l2.292-.362-2.292-.362a.957.957,0,0,1-.8-.8l-.357-2.287-.362,2.287a.957.957,0,0,1-.8.8l-2.292.362,2.292.362a.957.957,0,0,1,.8.8l.362,2.287.357-2.287a.957.957,0,0,1,.8-.8Z"
          transform="translate(-109.33 -235.266)"
          fill="#7e59d1"
        />
        <path
          id="Path_11075"
          data-name="Path 11075"
          d="M113.941,252.991l2.292-.362-2.292-.362a.957.957,0,0,1-.8-.8l-.357-2.287-.362,2.287a.957.957,0,0,1-.8.8l-2.292.362,2.292.362a.957.957,0,0,1,.8.8l.362,2.287.357-2.287a.957.957,0,0,1,.8-.8Z"
          transform="translate(-109.33 -235.266)"
          fill="#fff"
          opacity="0.5"
        />
        <path
          id="Path_11076"
          data-name="Path 11076"
          d="M389,262.351l2.292-.357L389,261.632a.962.962,0,0,1-.8-.8l-.362-2.292-.357,2.292a.962.962,0,0,1-.8.8l-2.292.362,2.292.357a.962.962,0,0,1,.8.8l.357,2.292.362-2.292A.962.962,0,0,1,389,262.351Z"
          transform="translate(-235.7 -239.566)"
          fill="#7e59d1"
        />
        <path
          id="Path_11077"
          data-name="Path 11077"
          d="M389,262.351l2.292-.357L389,261.632a.962.962,0,0,1-.8-.8l-.362-2.292-.357,2.292a.962.962,0,0,1-.8.8l-2.292.362,2.292.357a.962.962,0,0,1,.8.8l.357,2.292.362-2.292A.962.962,0,0,1,389,262.351Z"
          transform="translate(-235.7 -239.566)"
          fill="#fff"
          opacity="0.5"
        />
        <path
          id="Path_11078"
          data-name="Path 11078"
          d="M195.3,227.175l2.243-.351-2.243-.351a.941.941,0,0,1-.784-.784l-.357-2.249-.351,2.249a.946.946,0,0,1-.784.784l-2.249.351,2.249.351a.946.946,0,0,1,.784.784l.33,2.27.357-2.249A.941.941,0,0,1,195.3,227.175Z"
          transform="translate(-146.75 -223.44)"
          fill="#fff"
        />
        <path
          id="Path_11079"
          data-name="Path 11079"
          d="M332.8,268.231l2.249-.357-2.249-.351a.946.946,0,0,1-.784-.784l-.351-2.249-.351,2.249a.946.946,0,0,1-.784.784l-2.249.351,2.249.357a.941.941,0,0,1,.784.784l.351,2.243.351-2.243A.941.941,0,0,1,332.8,268.231Z"
          transform="translate(-209.922 -242.299)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const startYallaOnlineBottom = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="205.654"
    height="181.785"
    viewBox="0 0 205.654 181.785"
  >
    <defs>
      <clipPath id="clip-path-startyallaonlinebottom">
        <path
          id="Intersection_1"
          data-name="Intersection 1"
          d="M.689,10.449A20.141,20.141,0,0,1,.689,0H22.51c-2.295,3.821,0,10.449,0,10.449Zm.195-.256H22.157c-.425-1.373-1.813-6.5-.082-9.938H.858A20.36,20.36,0,0,0,.884,10.193Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Intersection_2"
          data-name="Intersection 2"
          d="M.687,10.45A20.2,20.2,0,0,1,.687,0H22.17c-2.295,3.821,0,10.45,0,10.45Zm.2-.261h20.93C21.392,8.81,20,3.688,21.735.251H.882A20.36,20.36,0,0,0,.887,10.188Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Book_lover-bro"
      data-name="Book lover-bro"
      transform="translate(-53.858 -62.963)"
    >
      <g id="freepik--Books--inject-211" transform="translate(65.37 62.963)">
        <path
          id="Path_10736"
          data-name="Path 10736"
          d="M370.152,178.275h0c-.21,0-20.956-.374-21.514-.374s-2.331,0-3.15-.814a35.857,35.857,0,0,1-2.623-3.371.277.277,0,0,1-.041-.21l5.378-22.436a.256.256,0,0,1,.307-.19.251.251,0,0,1,.19.307l-5.343,22.323c.338.471,1.859,2.561,2.5,3.212s2.259.666,2.787.666,18.907.328,21.288.369l4.1-27.22a.259.259,0,1,1,.512.072l-4.123,27.446a.256.256,0,0,1-.266.22Z"
          transform="translate(-206.316 -108.785)"
          fill="#263238"
        />
        <path
          id="Path_10737"
          data-name="Path 10737"
          d="M360.722,143.623h0l-1.706-.031a.261.261,0,0,1-.256-.261.277.277,0,0,1,.261-.251l1.711.031a.256.256,0,0,1,0,.512Z"
          transform="translate(-214.091 -105.256)"
          fill="#263238"
        />
        <path
          id="Path_10738"
          data-name="Path 10738"
          d="M345.256,170.727a.256.256,0,0,1,0-.512l22.41-.072,4.728-26.124-16.294-.3a.256.256,0,0,1-.251-.256.287.287,0,0,1,.261-.256l16.591.3a.256.256,0,0,1,.195.1.266.266,0,0,1,.056.2l-4.82,26.636a.251.251,0,0,1-.251.21l-22.625.077Z"
          transform="translate(-207.38 -105.319)"
          fill="#263238"
        />
        <path
          id="Path_10739"
          data-name="Path 10739"
          d="M371.332,176.975h0l-21.693-.553a.287.287,0,0,1-.184-.087l-1.854-2.049a.261.261,0,0,1,0-.364.251.251,0,0,1,.359,0l1.783,1.957,21.36.548,3.872-23.465-1.593-2.238a.251.251,0,0,1,.056-.359.256.256,0,0,1,.359.061l1.7,2.351a.261.261,0,0,1,.041.195l-3.924,23.788a.256.256,0,0,1-.282.215Z"
          transform="translate(-208.612 -108.786)"
          fill="#263238"
        />
        <path
          id="Path_10740"
          data-name="Path 10740"
          d="M353.445,199.09h3.14s-.512,2.489-.784,4.841-.394,5.368-.394,5.368l-1.829-3.406-2.228,2.884Z"
          transform="translate(-210.477 -132.576)"
          fill="#263238"
        />
        <path
          id="Path_10741"
          data-name="Path 10741"
          d="M87.673,188.912a.251.251,0,0,1-.236-.159.261.261,0,0,1,.138-.338l13.262-5.343a23.377,23.377,0,0,0,.712-2.377,2.5,2.5,0,0,0-.651-1.588c-.19-.266-6.464-9.43-7.479-10.905l-15.265,7.735a.251.251,0,0,1-.343-.108.256.256,0,0,1,.113-.348l15.464-7.837a.261.261,0,0,1,.328.082c.077.108,7.4,10.808,7.6,11.085a2.977,2.977,0,0,1,.743,1.967,25.659,25.659,0,0,1-.768,2.561.266.266,0,0,1-.149.159L87.77,188.882A.236.236,0,0,1,87.673,188.912Z"
          transform="translate(-77.04 -117.225)"
          fill="#263238"
        />
        <path
          id="Path_10742"
          data-name="Path 10742"
          d="M90.061,208.109a.256.256,0,0,1-.21-.113l-.6-.881a.258.258,0,0,1,.425-.292l.6.886a.261.261,0,0,1-.067.353.266.266,0,0,1-.149.046Z"
          transform="translate(-82.61 -136.293)"
          fill="#263238"
        />
        <path
          id="Path_10743"
          data-name="Path 10743"
          d="M82.443,190.143a.256.256,0,0,1-.21-.113l-5.855-8.559a.256.256,0,0,1-.036-.21.261.261,0,0,1,.138-.164l15.3-7.171a.251.251,0,0,1,.318.082L100.33,185.5a.261.261,0,0,1-.056.359.256.256,0,0,1-.359-.061l-8.109-11.331-14.855,6.966,5.686,8.314a.256.256,0,0,1-.072.353A.241.241,0,0,1,82.443,190.143Z"
          transform="translate(-76.333 -120.289)"
          fill="#263238"
        />
        <path
          id="Path_10744"
          data-name="Path 10744"
          d="M101.492,183.08h-.051a.261.261,0,0,1-.2-.3l.348-1.578-7.371-10.977L81,176.692l-.548,1.619a.261.261,0,0,1-.328.159.256.256,0,0,1-.159-.323l.584-1.721a.261.261,0,0,1,.128-.149l13.533-6.613a.256.256,0,0,1,.328.087l7.571,11.269a.241.241,0,0,1,.041.195l-.369,1.685A.256.256,0,0,1,101.492,183.08Z"
          transform="translate(-78.099 -118.21)"
          fill="#263238"
        />
        <path
          id="Path_10745"
          data-name="Path 10745"
          d="M119.447,186.307l-1.137-1.6s1.455-.635,2.751-1.357,2.874-1.742,2.874-1.742l-1.071,2.167,2.269.087Z"
          transform="translate(-96.808 -124.05)"
          fill="#263238"
        />
        <path
          id="Path_10746"
          data-name="Path 10746"
          d="M103.521,249.666h-.031a.271.271,0,0,1-.195-.138L93.922,231.6a.271.271,0,0,1,0-.2.261.261,0,0,1,.128-.149l1.06-.512a.256.256,0,0,1,.23.456l-.825.415,9.082,17.38,15.454-16.463a.236.236,0,0,1,.108-.067l18.835-5.814-9.318-14.855-.686.051a.251.251,0,0,1-.277-.236.256.256,0,0,1,.236-.271l.845-.067a.246.246,0,0,1,.236.118l9.574,15.28a.256.256,0,0,1,0,.225.266.266,0,0,1-.164.159l-19.091,5.886-15.639,16.658A.266.266,0,0,1,103.521,249.666Z"
          transform="translate(-84.903 -138.516)"
          fill="#263238"
        />
        <path
          id="Path_10747"
          data-name="Path 10747"
          d="M104.306,251.162h-.041a.251.251,0,0,1-.179-.133l-8.964-17.114a.251.251,0,0,1,.067-.318,24.178,24.178,0,0,0,6.239-8.257c1.834-4.405,4.989-6.592,8.442-5.855a.251.251,0,0,1,.169.128l8.355,15.075a.256.256,0,0,1-.282.374c-.138-.031-3.432-.712-4.175,2.459-.8,3.391-4.3,7.755-5.978,9.85l-.184.236a30.658,30.658,0,0,1-3.294,3.493.261.261,0,0,1-.174.061Zm-8.641-17.308,8.708,16.637a30.45,30.45,0,0,0,2.991-3.212l.184-.236c1.66-2.049,5.122-6.367,5.875-9.645a3.749,3.749,0,0,1,4.262-2.909l-8.042-14.532c-3.145-.61-6.034,1.465-7.75,5.578A24.553,24.553,0,0,1,95.664,233.854Z"
          transform="translate(-85.483 -142.455)"
          fill="#263238"
        />
        <path
          id="Path_10748"
          data-name="Path 10748"
          d="M131.947,229.706a.256.256,0,0,1-.225-.128l-8.355-15.08a.292.292,0,0,1,0-.174c.6-2.817,3.7-4.1,10.03-4.1,5.527,0,7.765-1.757,7.786-1.772a.266.266,0,0,1,.2-.056.261.261,0,0,1,.174.118l9.374,15.28a.261.261,0,0,1-.077.348,28.229,28.229,0,0,1-4.549,1.66,23.682,23.682,0,0,1-8.016.64,25.209,25.209,0,0,0-2.838,0c-2.244.184-3.232,3.073-3.242,3.073a.256.256,0,0,1-.22.174Zm-8.083-15.367,8.047,14.527c.42-.922,1.506-2.761,3.478-2.925a26.312,26.312,0,0,1,2.9,0,23.357,23.357,0,0,0,7.842-.615,40.258,40.258,0,0,0,4.2-1.485l-9.082-14.814c-.717.466-3.1,1.721-7.873,1.721-5.973.005-8.913,1.132-9.507,3.591Z"
          transform="translate(-99.268 -137.114)"
          fill="#263238"
        />
        <path
          id="Path_10749"
          data-name="Path 10749"
          d="M125.567,261.081a.256.256,0,0,1-.159-.051.261.261,0,0,1-.041-.364c.989-1.24,1.772-2.336,2.464-3.3a18.65,18.65,0,0,1,2.664-3.248,12.524,12.524,0,0,1,2.92-1.875.251.251,0,0,1,.333.133.261.261,0,0,1-.133.338,12.151,12.151,0,0,0-2.787,1.793,18.006,18.006,0,0,0-2.561,3.161c-.7.973-1.485,2.049-2.484,3.324A.256.256,0,0,1,125.567,261.081Z"
          transform="translate(-100.223 -158.49)"
          fill="#263238"
        />
        <path
          id="Path_10750"
          data-name="Path 10750"
          d="M144.259,245.878a.23.23,0,0,1-.154-.056.256.256,0,0,1-.046-.359,6.029,6.029,0,0,1,3.847-2.136l.917-.072a35.615,35.615,0,0,0,11.443-2.561.258.258,0,1,1,.184.481,36.368,36.368,0,0,1-11.587,2.592l-.912.072a5.537,5.537,0,0,0-3.488,1.941A.271.271,0,0,1,144.259,245.878Z"
          transform="translate(-109.342 -152.86)"
          fill="#263238"
        />
        <path
          id="Path_10751"
          data-name="Path 10751"
          d="M101.3,240.484a.256.256,0,0,1-.19-.43,69.212,69.212,0,0,0,4.866-6.29c2.894-4.129,5.02-5.814,7.34-5.814a.256.256,0,1,1,0,.512c-2.131,0-4.139,1.624-6.92,5.6a71.324,71.324,0,0,1-4.907,6.341A.256.256,0,0,1,101.3,240.484Z"
          transform="translate(-88.388 -146.653)"
          fill="#263238"
        />
        <path
          id="Path_10752"
          data-name="Path 10752"
          d="M104.492,245.654a.256.256,0,0,1-.174-.067.251.251,0,0,1,0-.359,71.33,71.33,0,0,0,4.871-6.3c2.889-4.129,5.015-5.814,7.34-5.814a.261.261,0,0,1,.256.256.256.256,0,0,1-.256.256c-2.136,0-4.139,1.624-6.925,5.6a71.326,71.326,0,0,1-4.907,6.341.256.256,0,0,1-.2.082Z"
          transform="translate(-89.947 -149.174)"
          fill="#263238"
        />
        <path
          id="Path_10753"
          data-name="Path 10753"
          d="M107.669,250.824a.256.256,0,0,1-.19-.425,71.65,71.65,0,0,0,4.871-6.29c2.889-4.134,5.015-5.819,7.34-5.819a.261.261,0,0,1,.256.256.256.256,0,0,1-.256.256c-2.136,0-4.139,1.624-6.92,5.6a71.857,71.857,0,0,1-4.912,6.341A.251.251,0,0,1,107.669,250.824Z"
          transform="translate(-91.494 -151.696)"
          fill="#263238"
        />
        <path
          id="Path_10754"
          data-name="Path 10754"
          d="M110.857,256a.236.236,0,0,1-.169-.067.251.251,0,0,1,0-.359,70.449,70.449,0,0,0,4.871-6.29c2.889-4.134,5.015-5.814,7.34-5.814a.256.256,0,1,1,0,.512c-2.136,0-4.139,1.619-6.92,5.594a69.726,69.726,0,0,1-4.912,6.341.251.251,0,0,1-.21.082Z"
          transform="translate(-93.054 -154.223)"
          fill="#263238"
        />
        <path
          id="Path_10755"
          data-name="Path 10755"
          d="M130.337,221.426h-.087a.261.261,0,0,1-.154-.328,4.188,4.188,0,0,1,4.1-2.413c5.968,0,9.84-.922,10.352-2.474a.269.269,0,0,1,.512.164c-.814,2.459-7.141,2.822-10.839,2.822a3.665,3.665,0,0,0-3.632,2.075A.256.256,0,0,1,130.337,221.426Z"
          transform="translate(-102.55 -140.836)"
          fill="#263238"
        />
        <path
          id="Path_10756"
          data-name="Path 10756"
          d="M133.917,227.388a.266.266,0,0,1-.159-.051.256.256,0,0,1-.041-.359c.061-.082,1.537-1.936,5.5-1.936,4.257,0,7.417-1,9.4-2.981a.255.255,0,0,1,.359.364c-2.075,2.08-5.358,3.155-9.758,3.155-3.678,0-5.081,1.721-5.092,1.736A.256.256,0,0,1,133.917,227.388Z"
          transform="translate(-104.296 -143.744)"
          fill="#263238"
        />
        <path
          id="Path_10757"
          data-name="Path 10757"
          d="M136.307,233.162a.277.277,0,0,1-.108-.026.251.251,0,0,1-.123-.338,5.322,5.322,0,0,1,4.917-2.8c4.282,0,8.032-.9,10.035-2.392a.251.251,0,0,1,.359.051.261.261,0,0,1-.051.359c-3.007,2.249-8.237,2.495-10.342,2.495a4.825,4.825,0,0,0-4.456,2.5A.251.251,0,0,1,136.307,233.162Z"
          transform="translate(-105.462 -146.46)"
          fill="#263238"
        />
        <path
          id="Path_10758"
          data-name="Path 10758"
          d="M139.087,237.921a.184.184,0,0,1-.072,0,.261.261,0,0,1-.174-.318c.031-.092.707-2.213,4.308-2.423a33.92,33.92,0,0,0,10.916-2.423.256.256,0,1,1,.2.466,34.186,34.186,0,0,1-11.09,2.469c-3.222.19-3.821,1.982-3.847,2.049A.256.256,0,0,1,139.087,237.921Z"
          transform="translate(-106.818 -148.981)"
          fill="#263238"
        />
        <path
          id="Path_10759"
          data-name="Path 10759"
          d="M312.693,98.355h0a.261.261,0,0,1-.19-.118l-7.212-11.664L291.5,79.059a.246.246,0,0,1-.133-.195.256.256,0,0,1,.082-.22l10.306-9.338a.256.256,0,0,1,.19-.067.251.251,0,0,1,.174.087l.538.615a.256.256,0,0,1-.384.333l-.364-.415-9.85,8.923,13.518,7.371a.338.338,0,0,1,.1.092l7.074,11.438,8.17-8.667-.138-.4a.258.258,0,1,1,.487-.169l.19.548a.256.256,0,0,1-.056.261l-8.508,9.02A.261.261,0,0,1,312.693,98.355Z"
          transform="translate(-181.219 -69.239)"
          fill="#263238"
        />
        <path
          id="Path_10760"
          data-name="Path 10760"
          d="M307.974,85.362a.3.3,0,0,1-.092,0,.261.261,0,0,1-.164-.23c0-.092-.118-2.341-2.274-2.274-2.428.051-5.937-1.537-7.627-2.279l-.184-.082a21.46,21.46,0,0,1-2.909-1.588.255.255,0,0,1-.026-.4l9.845-8.949a.261.261,0,0,1,.328,0,16.535,16.535,0,0,0,6.536,2.7c3.289.466,5.317,2.218,5.425,4.682a.256.256,0,0,1-.077.195l-8.585,8.2a.266.266,0,0,1-.195.031Zm-2.454-3.038a2.607,2.607,0,0,1,2.633,2.254l8.17-7.75c-.154-2.167-1.962-3.647-4.979-4.1a16.576,16.576,0,0,1-6.6-2.679l-9.461,8.59a20.759,20.759,0,0,0,2.561,1.368l.184.082c1.66.743,5.122,2.295,7.407,2.233Z"
          transform="translate(-182.801 -69.365)"
          fill="#263238"
        />
        <path
          id="Path_10761"
          data-name="Path 10761"
          d="M327.353,103.764a.277.277,0,0,1-.174-.061,18.124,18.124,0,0,1-1.906-2.756,16.61,16.61,0,0,1-1.824-5.25,18.313,18.313,0,0,0-.476-1.88c-.512-1.414-2.525-1.567-2.561-1.573a.256.256,0,0,1-.225-.164.266.266,0,0,1,.067-.277l8.585-8.17a.246.246,0,0,1,.164-.067c2.013-.1,3.422,1.767,4.528,6.029.953,3.662,2.484,4.815,2.5,4.825a.266.266,0,0,1,.108.184.261.261,0,0,1-.072.2l-8.529,8.857A.261.261,0,0,1,327.353,103.764ZM321,91.814a3.162,3.162,0,0,1,2.454,1.834,16.711,16.711,0,0,1,.512,1.936,16.051,16.051,0,0,0,1.762,5.092,29.268,29.268,0,0,0,1.655,2.454l8.16-8.488a10.364,10.364,0,0,1-2.474-4.917c-1-3.847-2.244-5.635-3.908-5.635h0Z"
          transform="translate(-195.27 -76.226)"
          fill="#263238"
        />
        <path
          id="Path_10762"
          data-name="Path 10762"
          d="M311.945,97.656a.261.261,0,0,1-.2-.1,8.2,8.2,0,0,0-1.66-1.537,12.7,12.7,0,0,0-2.53-1.163c-.768-.292-1.639-.625-2.648-1.076a.256.256,0,0,1-.128-.338.251.251,0,0,1,.338-.128c.994.441,1.859.773,2.617,1.065a12.805,12.805,0,0,1,2.643,1.219,9,9,0,0,1,1.772,1.629.261.261,0,0,1-.046.364A.256.256,0,0,1,311.945,97.656Z"
          transform="translate(-187.75 -80.972)"
          fill="#263238"
        />
        <path
          id="Path_10763"
          data-name="Path 10763"
          d="M326.568,112.915a.261.261,0,0,1-.21-.108,25.043,25.043,0,0,1-3.739-7.279c-.072-.215-.143-.415-.2-.594a3.78,3.78,0,0,0-1.854-1.941.256.256,0,1,1,.2-.466,4.2,4.2,0,0,1,2.131,2.233c.067.184.133.384.2.6a24.666,24.666,0,0,0,3.678,7.171.251.251,0,0,1-.061.353A.23.23,0,0,1,326.568,112.915Z"
          transform="translate(-195.371 -85.459)"
          fill="#263238"
        />
        <path
          id="Path_10764"
          data-name="Path 10764"
          d="M322.255,80.626a.256.256,0,0,1-.246-.195c-.364-1.388-1.772-2.428-4.871-3.586a48.667,48.667,0,0,1-5.081-2.172.256.256,0,1,1,.241-.451,49.686,49.686,0,0,0,5.015,2.146c3.278,1.214,4.784,2.356,5.194,3.929a.256.256,0,0,1-.184.312Z"
          transform="translate(-191.237 -71.651)"
          fill="#263238"
        />
        <path
          id="Path_10765"
          data-name="Path 10765"
          d="M319.365,83.636a.256.256,0,0,1-.246-.19c-.364-1.393-1.772-2.428-4.871-3.586a49.236,49.236,0,0,1-5.081-2.177.256.256,0,1,1,.241-.451,48.089,48.089,0,0,0,5.02,2.146c3.273,1.219,4.779,2.356,5.189,3.929a.251.251,0,0,1-.184.312.154.154,0,0,1-.067.015Z"
          transform="translate(-189.827 -73.119)"
          fill="#263238"
        />
        <path
          id="Path_10766"
          data-name="Path 10766"
          d="M316.488,86.654a.256.256,0,0,1-.246-.19c-.364-1.393-1.772-2.428-4.871-3.586a48.658,48.658,0,0,1-5.081-2.172.258.258,0,1,1,.241-.456,48.1,48.1,0,0,0,5.02,2.146c3.273,1.219,4.779,2.356,5.189,3.934a.256.256,0,0,1-.184.312Z"
          transform="translate(-188.431 -74.596)"
          fill="#263238"
        />
        <path
          id="Path_10767"
          data-name="Path 10767"
          d="M313.6,89.672a.256.256,0,0,1-.246-.195c-.364-1.388-1.772-2.428-4.871-3.586A48.669,48.669,0,0,1,303.4,83.72a.261.261,0,0,1-.108-.348.266.266,0,0,1,.348-.108,46.77,46.77,0,0,0,5.02,2.146c3.273,1.219,4.779,2.361,5.189,3.934a.256.256,0,0,1-.184.312Z"
          transform="translate(-187.022 -76.067)"
          fill="#263238"
        />
        <path
          id="Path_10768"
          data-name="Path 10768"
          d="M340.2,98.341h0c-1.737-.118-3.043-3.755-3.832-6.782a2.433,2.433,0,0,0-1.941-2,.256.256,0,0,1-.225-.282.261.261,0,0,1,.277-.231,2.909,2.909,0,0,1,2.387,2.377c1.024,3.939,2.285,6.331,3.365,6.4a.256.256,0,0,1,.241.271.261.261,0,0,1-.271.241Z"
          transform="translate(-202.11 -78.902)"
          fill="#263238"
        />
        <path
          id="Path_10769"
          data-name="Path 10769"
          d="M336.925,102.037a.246.246,0,0,1-.133-.036c-1.762-1.024-3.043-3.073-3.811-6-.625-2.4-1.977-3.032-1.993-3.038a.256.256,0,0,1,.2-.466c.067,0,1.593.707,2.285,3.376.732,2.812,1.936,4.723,3.586,5.686a.256.256,0,0,1,.092.348A.261.261,0,0,1,336.925,102.037Z"
          transform="translate(-200.483 -80.578)"
          fill="#263238"
        />
        <path
          id="Path_10770"
          data-name="Path 10770"
          d="M333.479,104.609a.231.231,0,0,1-.159-.056c-2.049-1.624-3.109-5.076-3.478-6.49a3.258,3.258,0,0,0-2.372-2.469.256.256,0,0,1-.2-.3.261.261,0,0,1,.3-.21,3.765,3.765,0,0,1,2.776,2.848c.738,2.833,1.972,5.158,3.3,6.219a.256.256,0,0,1,.041.364A.246.246,0,0,1,333.479,104.609Z"
          transform="translate(-198.728 -81.845)"
          fill="#263238"
        />
        <path
          id="Path_10771"
          data-name="Path 10771"
          d="M330.8,107.431a.266.266,0,0,1-.2-.1,23.707,23.707,0,0,1-3.586-6.977c-.666-2.049-1.911-2.146-1.962-2.146a.261.261,0,0,1-.246-.271.236.236,0,0,1,.266-.241c.067,0,1.649.087,2.428,2.5A23.629,23.629,0,0,0,331,107.016a.256.256,0,0,1-.046.359A.261.261,0,0,1,330.8,107.431Z"
          transform="translate(-197.531 -83.119)"
          fill="#263238"
        />
        <path
          id="Path_10772"
          data-name="Path 10772"
          d="M152.452,123.772h-.061l-1.854-.461a.256.256,0,0,1-.154-.384l8.559-13.421a.258.258,0,1,1,.435.277l-8.37,13.118,1.332.333,8.457-12.575a.261.261,0,0,1,.343-.082l13.477,7.771,1.465-1.465L161.355,109.2a.257.257,0,0,1,.236-.456l15.039,7.868a.254.254,0,0,1,.061.4l-1.849,1.854a.256.256,0,0,1-.307.041l-13.446-7.75-8.426,12.529a.251.251,0,0,1-.21.082Z"
          transform="translate(-112.433 -88.493)"
          fill="#263238"
        />
        <path
          id="Path_10773"
          data-name="Path 10773"
          d="M185.957,141.465a.256.256,0,0,1-.123-.031l-11.336-6.48a.261.261,0,0,1-.128-.179l-3.7-22.21a.261.261,0,0,1,.215-.3.256.256,0,0,1,.292.215l3.683,22.088,10.834,6.178-1.583-19.183a.266.266,0,0,1,.236-.277.256.256,0,0,1,.277.236l1.583,19.665a.246.246,0,0,1-.118.236.22.22,0,0,1-.128.041Z"
          transform="translate(-122.345 -90.225)"
          fill="#263238"
        />
        <path
          id="Path_10774"
          data-name="Path 10774"
          d="M200.278,147.2a.256.256,0,0,1-.225-.374l1.818-3.406-1.383-19.127a.257.257,0,0,1,.512-.036l1.388,19.219a.307.307,0,0,1-.031.138l-1.834,3.447a.256.256,0,0,1-.246.138Z"
          transform="translate(-136.665 -95.956)"
          fill="#263238"
        />
        <path
          id="Path_10775"
          data-name="Path 10775"
          d="M159.5,159.333h-.051a.251.251,0,0,1-.2-.195l-3.934-18.046a.261.261,0,0,1,.2-.307.256.256,0,0,1,.3.2l3.811,17.488,7.3-9.983a.257.257,0,0,1,.415.3L159.71,159.2a.246.246,0,0,1-.21.128Z"
          transform="translate(-114.855 -104.134)"
          fill="#263238"
        />
        <path
          id="Path_10776"
          data-name="Path 10776"
          d="M157.08,157.121h-.031l-2.08-.23a.256.256,0,0,1-.22-.2l-4.395-20.131a.262.262,0,0,1,.512-.108l4.359,19.946,1.9.21a.256.256,0,1,1,0,.512Z"
          transform="translate(-112.436 -101.922)"
          fill="#263238"
        />
        <path
          id="Path_10777"
          data-name="Path 10777"
          d="M160.085,136.011a.256.256,0,0,1-.21-.4l6.019-8.329a.246.246,0,0,1,.353-.056.256.256,0,0,1,.061.359l-6.019,8.329a.251.251,0,0,1-.2.1Z"
          transform="translate(-117.059 -97.496)"
          fill="#263238"
        />
        <path
          id="Path_10778"
          data-name="Path 10778"
          d="M160.99,143.691a.266.266,0,0,1-.149-.046.261.261,0,0,1-.056-.359l6.014-8.329a.251.251,0,0,1,.359-.056.256.256,0,0,1,.056.353l-6.014,8.329A.251.251,0,0,1,160.99,143.691Z"
          transform="translate(-117.503 -101.241)"
          fill="#263238"
        />
        <path
          id="Path_10779"
          data-name="Path 10779"
          d="M161.89,151.37a.236.236,0,0,1-.149-.051.256.256,0,0,1-.056-.353l6.014-8.329a.256.256,0,0,1,.359-.061.261.261,0,0,1,.056.359l-6.014,8.329A.251.251,0,0,1,161.89,151.37Z"
          transform="translate(-117.942 -104.986)"
          fill="#263238"
        />
        <path
          id="Path_10780"
          data-name="Path 10780"
          d="M162.755,159.05a.231.231,0,0,1-.149-.051.256.256,0,0,1-.061-.359l6.019-8.329a.257.257,0,0,1,.415.3l-6.019,8.329A.256.256,0,0,1,162.755,159.05Z"
          transform="translate(-118.361 -108.733)"
          fill="#263238"
        />
        <path
          id="Path_10781"
          data-name="Path 10781"
          d="M184.894,132.779a.231.231,0,0,1-.128-.036l-8.1-4.61a.257.257,0,0,1,.256-.446l8.1,4.61a.261.261,0,0,1,.092.353A.256.256,0,0,1,184.894,132.779Z"
          transform="translate(-125.21 -97.731)"
          fill="#263238"
        />
        <path
          id="Path_10782"
          data-name="Path 10782"
          d="M184.873,139.995a.277.277,0,0,1-.128-.031l-8.1-4.61a.256.256,0,1,1,.256-.441l8.1,4.61a.252.252,0,0,1-.128.471Z"
          transform="translate(-125.189 -101.249)"
          fill="#263238"
        />
        <path
          id="Path_10783"
          data-name="Path 10783"
          d="M187.147,147.685a.271.271,0,0,1-.123-.031l-8.1-4.61a.256.256,0,0,1-.1-.348.261.261,0,0,1,.353-.1l8.093,4.61a.251.251,0,0,1,.1.348A.256.256,0,0,1,187.147,147.685Z"
          transform="translate(-126.311 -105.005)"
          fill="#263238"
        />
        <path
          id="Path_10784"
          data-name="Path 10784"
          d="M187.6,155.359a.231.231,0,0,1-.128-.036l-8.1-4.61a.257.257,0,0,1,.256-.446l8.1,4.61a.256.256,0,0,1-.128.476Z"
          transform="translate(-126.532 -108.745)"
          fill="#263238"
        />
        <path
          id="Path_10785"
          data-name="Path 10785"
          d="M376.755,287.834a.2.2,0,0,1-.092,0l-15.756-6.311a.251.251,0,0,1-.154-.174.261.261,0,0,1,.051-.225l1.655-2.049a.261.261,0,0,1,.3-.072l14.158,6.357,7.12-13.318a.272.272,0,0,1,.261-.133l1.89.277a.246.246,0,0,1,.19.133.256.256,0,0,1,0,.236l-7.171,14.209a.258.258,0,0,1-.461-.23l7.007-13.892-1.357-.2-7.13,13.344a.261.261,0,0,1-.333.113l-14.194-6.372-1.311,1.649,15.434,6.147a.268.268,0,0,1-.108.512Z"
          transform="translate(-215.06 -168.094)"
          fill="#263238"
        />
        <path
          id="Path_10786"
          data-name="Path 10786"
          d="M375.322,273.068a.256.256,0,0,1-.251-.184l-5.886-21.611-11.377-5.056,3.5,18.927a.255.255,0,1,1-.5.092l-3.586-19.4a.241.241,0,0,1,.092-.246.256.256,0,0,1,.261-.036l11.93,5.307a.256.256,0,0,1,.143.164l5.9,21.724a.266.266,0,0,1-.179.318Z"
          transform="translate(-213.34 -155.227)"
          fill="#263238"
        />
        <path
          id="Path_10787"
          data-name="Path 10787"
          d="M357.863,268.678a.251.251,0,0,1-.251-.215l-3.319-18.953a.312.312,0,0,1,0-.138l1.5-3.642a.256.256,0,0,1,.471.195l-1.465,3.586,3.3,18.891a.256.256,0,0,1-.21.3Z"
          transform="translate(-211.908 -155.259)"
          fill="#263238"
        />
        <path
          id="Path_10788"
          data-name="Path 10788"
          d="M393.064,252.234a.261.261,0,0,1-.246-.179l-5.553-17.016-6.259,10.639a.256.256,0,1,1-.441-.261l6.546-11.126a.251.251,0,0,1,.251-.123.256.256,0,0,1,.215.174l5.727,17.554a.251.251,0,0,1-.164.323Z"
          transform="translate(-224.71 -149.684)"
          fill="#263238"
        />
        <path
          id="Path_10789"
          data-name="Path 10789"
          d="M402.034,254.295a.256.256,0,0,1-.246-.174l-6.341-19.409h-1.911a.256.256,0,0,1-.256-.256.266.266,0,0,1,.261-.256h2.1a.256.256,0,0,1,.241.179l6.4,19.583a.251.251,0,0,1-.164.323Z"
          transform="translate(-230.929 -149.701)"
          fill="#263238"
        />
        <path
          id="Path_10790"
          data-name="Path 10790"
          d="M389.916,275.6a.23.23,0,0,1-.128-.036.261.261,0,0,1-.1-.348l5.122-8.892a.261.261,0,0,1,.348-.1.266.266,0,0,1,.1.353l-5.148,8.892A.256.256,0,0,1,389.916,275.6Z"
          transform="translate(-229.163 -165.309)"
          fill="#263238"
        />
        <path
          id="Path_10791"
          data-name="Path 10791"
          d="M388.244,268.028a.221.221,0,0,1-.128-.036.256.256,0,0,1-.1-.348l5.148-8.887a.256.256,0,1,1,.441.256l-5.122,8.892A.256.256,0,0,1,388.244,268.028Z"
          transform="translate(-228.347 -161.606)"
          fill="#263238"
        />
        <path
          id="Path_10792"
          data-name="Path 10792"
          d="M386.566,260.526a.241.241,0,0,1-.128-.036.251.251,0,0,1-.092-.348l5.148-8.892a.256.256,0,0,1,.441.256l-5.122,8.892a.261.261,0,0,1-.246.128Z"
          transform="translate(-227.529 -157.966)"
          fill="#263238"
        />
        <path
          id="Path_10793"
          data-name="Path 10793"
          d="M384.9,252.956a.241.241,0,0,1-.128-.036.251.251,0,0,1-.092-.348l5.122-8.892a.261.261,0,0,1,.353-.092.251.251,0,0,1,.092.348l-5.148,8.892A.256.256,0,0,1,384.9,252.956Z"
          transform="translate(-226.715 -154.263)"
          fill="#263238"
        />
        <path
          id="Path_10794"
          data-name="Path 10794"
          d="M376.811,280.512a.235.235,0,0,1-.1-.026l-8.524-3.785a.251.251,0,0,1-.128-.338.256.256,0,0,1,.338-.133l8.518,3.791a.261.261,0,1,1-.1.512Z"
          transform="translate(-218.614 -170.191)"
          fill="#263238"
        />
        <path
          id="Path_10795"
          data-name="Path 10795"
          d="M376.068,273.329a.334.334,0,0,1-.1,0l-8.524-3.8a.256.256,0,1,1,.21-.466l8.518,3.785a.261.261,0,1,1-.1.512Z"
          transform="translate(-218.245 -166.691)"
          fill="#263238"
        />
        <path
          id="Path_10796"
          data-name="Path 10796"
          d="M373.061,265.9a.236.236,0,0,1-.1-.026l-8.524-3.785a.251.251,0,0,1-.128-.338.256.256,0,0,1,.338-.133l8.518,3.791a.256.256,0,0,1,.133.338A.261.261,0,0,1,373.061,265.9Z"
          transform="translate(-216.785 -163.065)"
          fill="#263238"
        />
        <path
          id="Path_10797"
          data-name="Path 10797"
          d="M371.815,258.319a.23.23,0,0,1-.1,0l-8.524-3.8a.256.256,0,1,1,.2-.466l8.524,3.791a.256.256,0,0,1,.133.338A.266.266,0,0,1,371.815,258.319Z"
          transform="translate(-216.164 -159.37)"
          fill="#263238"
        />
      </g>
      <g id="freepik--Plants--inject-211" transform="translate(53.858 150.451)">
        <path
          id="Path_10798"
          data-name="Path 10798"
          d="M305.04,278.482c-1.434,3.55-2.909.7-2.674-14.174.108-6.971.6-21.212,8.308-18.077,7.4,3.012-2.356,24.075-2.356,24.075Zm10.588,2.11s16.069-16.745,10.045-22c-6.275-5.466-11.361,7.842-13.733,14.4-5.051,13.989-4.579,17.165-2.049,14.281Zm3.391,15.029s14.711-10.475,10.865-15.367c-4.006-5.086-9.825,4.349-12.586,9.02-5.886,9.963-5.988,12.463-3.652,10.619ZM323,310.6s14.711-10.475,10.865-15.367c-4-5.092-9.82,4.349-12.58,9.015-5.891,9.963-5.993,12.463-3.652,10.619ZM297.3,280.484s-20.587-10.716-23.932-3.458c-3.488,7.561,10.716,8.708,17.672,9.133,14.855.912,17.764-.43,14.286-2.049Zm2.9,11.781s-22.3-6.413-24.142,1.368c-1.916,8.109,12.222,6.408,19.137,5.445,14.732-2.049,17.324-3.949,13.6-4.825Zm5.122,12.857s-19.014-4.9-20.387,1.721c-1.429,6.89,10.5,5.122,16.31,4.149,12.411-2.075,14.558-3.739,11.387-4.395Zm2.976,10.619s-18.384-6.895-20.454-.456c-2.157,6.705,9.891,6.213,15.777,5.86,12.56-.748,14.855-2.172,11.781-3.16Zm-12.294-53.785s-8.39-26.083-17.928-21.345c-9.917,4.933,1.537,16.694,7.269,22.323,12.227,12.017,16.012,12.857,14.4,8.662Z"
          transform="translate(-160.665 -240.037)"
          fill="#7e59d1"
        />
        <path
          id="Path_10799"
          data-name="Path 10799"
          d="M305.04,278.482c-1.434,3.55-2.909.7-2.674-14.174.108-6.971.6-21.212,8.308-18.077,7.4,3.012-2.356,24.075-2.356,24.075Zm10.588,2.11s16.069-16.745,10.045-22c-6.275-5.466-11.361,7.842-13.733,14.4-5.051,13.989-4.579,17.165-2.049,14.281Zm3.391,15.029s14.711-10.475,10.865-15.367c-4.006-5.086-9.825,4.349-12.586,9.02-5.886,9.963-5.988,12.463-3.652,10.619ZM323,310.6s14.711-10.475,10.865-15.367c-4-5.092-9.82,4.349-12.58,9.015-5.891,9.963-5.993,12.463-3.652,10.619ZM297.3,280.484s-20.587-10.716-23.932-3.458c-3.488,7.561,10.716,8.708,17.672,9.133,14.855.912,17.764-.43,14.286-2.049Zm2.9,11.781s-22.3-6.413-24.142,1.368c-1.916,8.109,12.222,6.408,19.137,5.445,14.732-2.049,17.324-3.949,13.6-4.825Zm5.122,12.857s-19.014-4.9-20.387,1.721c-1.429,6.89,10.5,5.122,16.31,4.149,12.411-2.075,14.558-3.739,11.387-4.395Zm2.976,10.619s-18.384-6.895-20.454-.456c-2.157,6.705,9.891,6.213,15.777,5.86,12.56-.748,14.855-2.172,11.781-3.16Zm-12.294-53.785s-8.39-26.083-17.928-21.345c-9.917,4.933,1.537,16.694,7.269,22.323,12.227,12.017,16.012,12.857,14.4,8.662Z"
          transform="translate(-160.665 -240.037)"
          fill="#fff"
          opacity="0.65"
        />
        <path
          id="Path_10800"
          data-name="Path 10800"
          d="M327.153,343.181a.323.323,0,0,1-.318-.3c-.773-13.631-4.61-30.089-10.5-45.153-4.969-12.683-25.858-38.249-26.073-38.5a.323.323,0,0,1,.046-.451.318.318,0,0,1,.451.046c.21.256,21.171,25.9,26.175,38.674,5.942,15.147,9.768,31.656,10.552,45.369a.323.323,0,0,1-.3.338Z"
          transform="translate(-169.133 -249.139)"
          fill="#ccc"
        />
        <path
          id="Path_10801"
          data-name="Path 10801"
          d="M333.315,294.926h-.036a.323.323,0,0,1-.282-.359l2.93-24.526a.32.32,0,1,1,.635.077l-2.93,24.526A.323.323,0,0,1,333.315,294.926Z"
          transform="translate(-190.011 -254.535)"
          fill="#ccc"
        />
        <path
          id="Path_10802"
          data-name="Path 10802"
          d="M341.507,315.018a.368.368,0,0,1-.143-.036.323.323,0,0,1-.138-.435l10.624-20.612a.323.323,0,1,1,.569.3l-10.624,20.638A.328.328,0,0,1,341.507,315.018Z"
          transform="translate(-194.008 -266.226)"
          fill="#ccc"
        />
        <path
          id="Path_10803"
          data-name="Path 10803"
          d="M317.309,324.4h-.061l-22.211-4.523a.323.323,0,1,1,.128-.63l22.21,4.523a.323.323,0,0,1,.246.379A.318.318,0,0,1,317.309,324.4Z"
          transform="translate(-171.357 -278.666)"
          fill="#ccc"
        />
        <path
          id="Path_10804"
          data-name="Path 10804"
          d="M349.712,343.324a.323.323,0,0,1-.241-.512L362.518,327.9a.319.319,0,0,1,.482.42L349.942,343.2a.328.328,0,0,1-.23.128Z"
          transform="translate(-198.017 -282.838)"
          fill="#ccc"
        />
        <path
          id="Path_10805"
          data-name="Path 10805"
          d="M330.133,347.8h0l-16.765-.143a.323.323,0,0,1-.318-.323.328.328,0,0,1,.323-.318l16.765.143a.323.323,0,0,1,.318.323.318.318,0,0,1-.323.318Z"
          transform="translate(-180.283 -292.22)"
          fill="#ccc"
        />
        <path
          id="Path_10806"
          data-name="Path 10806"
          d="M327.293,372.207a.323.323,0,0,1-.323-.312.328.328,0,0,1,.307-.333l12.857-.512a.323.323,0,0,1,.333.307.318.318,0,0,1-.307.333L327.3,372.2Z"
          transform="translate(-187.072 -303.94)"
          fill="#ccc"
        />
        <path
          id="Path_10807"
          data-name="Path 10807"
          d="M357.546,378.128a.3.3,0,0,1-.19-.067.323.323,0,0,1-.067-.451l6.884-9.174a.323.323,0,0,1,.451-.067.318.318,0,0,1,.061.451L357.8,378a.323.323,0,0,1-.256.128Z"
          transform="translate(-201.83 -302.602)"
          fill="#ccc"
        />
        <path
          id="Path_10808"
          data-name="Path 10808"
          d="M344.231,392.818h0L329.2,392.07a.321.321,0,1,1,.031-.64l15.039.748a.318.318,0,0,1,.3.333.323.323,0,0,1-.338.307Z"
          transform="translate(-188.009 -313.881)"
          fill="#ccc"
        />
        <path
          id="Path_10809"
          data-name="Path 10809"
          d="M386.58,322.967c-2.6.978-1.8-1.209,6.224-8.4,3.76-3.365,11.576-10.106,13.7-4.467,2.049,5.419-13.958,10.578-13.958,10.578Zm4.1,6.659s16.8.318,16.637-5.471c-.174-6.024-9.758-2.187-14.409-.225-9.922,4.19-11.382,6-8.611,5.916Zm-6.347,9.195s12.806,2.679,13.513-1.772c.743-4.61-7.141-3.073-10.982-2.259-8.2,1.767-9.574,2.94-7.443,3.278Zm-6.014,9.487s12.806,2.674,13.513-1.772c.743-4.61-7.141-3.073-10.982-2.264-8.2,1.767-9.574,2.94-7.443,3.278Zm3.4-28.475s-4.421-16.2-9.917-14.43c-5.732,1.859.64,9.978,3.831,13.887,6.813,8.349,8.959,9.22,8.1,6.6Zm-4.851,7.361s-7.555-15.019-12.6-12.171c-5.245,2.951,2.612,9.61,6.505,12.831,8.334,6.828,10.619,7.274,9.251,4.861Zm-4.328,9.036s-6.736-12.524-10.936-10c-4.369,2.628,2.433,8.1,5.809,10.716,7.207,5.578,9.143,5.906,7.935,3.9Zm-4.185,6.8s-5.368-13.17-9.814-11.105c-4.61,2.151,1.562,8.314,4.641,11.269,6.572,6.311,8.467,6.843,7.474,4.718Zm22.538-32.983s9.732-17.288,2.561-20.023c-7.5-2.853-8.124,9.02-8.3,14.855-.384,12.406,1.024,14.855,2.469,11.915Z"
          transform="translate(-201.663 -264.207)"
          fill="#7e59d1"
        />
        <path
          id="Path_10810"
          data-name="Path 10810"
          d="M386.58,322.967c-2.6.978-1.8-1.209,6.224-8.4,3.76-3.365,11.576-10.106,13.7-4.467,2.049,5.419-13.958,10.578-13.958,10.578Zm4.1,6.659s16.8.318,16.637-5.471c-.174-6.024-9.758-2.187-14.409-.225-9.922,4.19-11.382,6-8.611,5.916Zm-6.347,9.195s12.806,2.679,13.513-1.772c.743-4.61-7.141-3.073-10.982-2.259-8.2,1.767-9.574,2.94-7.443,3.278Zm-6.014,9.487s12.806,2.674,13.513-1.772c.743-4.61-7.141-3.073-10.982-2.264-8.2,1.767-9.574,2.94-7.443,3.278Zm3.4-28.475s-4.421-16.2-9.917-14.43c-5.732,1.859.64,9.978,3.831,13.887,6.813,8.349,8.959,9.22,8.1,6.6Zm-4.851,7.361s-7.555-15.019-12.6-12.171c-5.245,2.951,2.612,9.61,6.505,12.831,8.334,6.828,10.619,7.274,9.251,4.861Zm-4.328,9.036s-6.736-12.524-10.936-10c-4.369,2.628,2.433,8.1,5.809,10.716,7.207,5.578,9.143,5.906,7.935,3.9Zm-4.185,6.8s-5.368-13.17-9.814-11.105c-4.61,2.151,1.562,8.314,4.641,11.269,6.572,6.311,8.467,6.843,7.474,4.718Zm22.538-32.983s9.732-17.288,2.561-20.023c-7.5-2.853-8.124,9.02-8.3,14.855-.384,12.406,1.024,14.855,2.469,11.915Z"
          transform="translate(-201.663 -264.207)"
          fill="#e5e5e5"
          opacity="0.65"
        />
        <path
          id="Path_10811"
          data-name="Path 10811"
          d="M368.98,364.876a.307.307,0,0,1-.22-.087.323.323,0,0,1,0-.456,117.56,117.56,0,0,0,19.491-28.485c4.3-8.862,7.627-32.527,7.658-32.783a.328.328,0,0,1,.364-.277.323.323,0,0,1,.271.364c-.031.241-3.376,24-7.714,32.957a118.532,118.532,0,0,1-19.608,28.649A.307.307,0,0,1,368.98,364.876Z"
          transform="translate(-207.41 -270.643)"
          fill="#ccc"
        />
        <path
          id="Path_10812"
          data-name="Path 10812"
          d="M411.835,345.182a.323.323,0,0,1-.261-.133.328.328,0,0,1,.072-.451l14.486-10.491a.323.323,0,1,1,.379.512l-14.486,10.5a.323.323,0,0,1-.19.061Z"
          transform="translate(-228.31 -285.874)"
          fill="#ccc"
        />
        <path
          id="Path_10813"
          data-name="Path 10813"
          d="M407.153,363.346a.323.323,0,0,1-.087-.63l16.176-4.487a.319.319,0,1,1,.169.615l-16.176,4.492A.225.225,0,0,1,407.153,363.346Z"
          transform="translate(-226.031 -297.681)"
          fill="#ccc"
        />
        <path
          id="Path_10814"
          data-name="Path 10814"
          d="M400.729,349.721a.307.307,0,0,1-.271-.154l-8.5-14.03a.318.318,0,0,1,.108-.441.323.323,0,0,1,.441.108l8.508,14.035a.323.323,0,0,1-.108.44A.292.292,0,0,1,400.729,349.721Z"
          transform="translate(-218.746 -286.381)"
          fill="#ccc"
        />
        <path
          id="Path_10815"
          data-name="Path 10815"
          d="M399.031,382.829a.323.323,0,0,1,0-.64l14.343-.369a.333.333,0,0,1,.333.312.323.323,0,0,1-.312.328l-14.343.369Z"
          transform="translate(-222.084 -309.194)"
          fill="#ccc"
        />
        <path
          id="Path_10816"
          data-name="Path 10816"
          d="M394.553,368.02a.323.323,0,0,1-.236-.1l-8.165-8.99a.328.328,0,0,1,0-.456.323.323,0,0,1,.456.026l8.165,8.99a.323.323,0,0,1-.241.512Z"
          transform="translate(-215.895 -297.765)"
          fill="#ccc"
        />
        <path
          id="Path_10817"
          data-name="Path 10817"
          d="M386.528,385.345a.317.317,0,0,1-.225-.092l-6.592-6.587a.323.323,0,0,1,.456-.456l6.587,6.587a.323.323,0,0,1-.225.548Z"
          transform="translate(-212.756 -307.392)"
          fill="#ccc"
        />
        <path
          id="Path_10818"
          data-name="Path 10818"
          d="M387.28,401a.323.323,0,0,1-.036-.64l8.267-.85a.323.323,0,0,1,.067.64l-8.267.85Z"
          transform="translate(-216.341 -317.822)"
          fill="#ccc"
        />
        <path
          id="Path_10819"
          data-name="Path 10819"
          d="M377.529,397.647a.317.317,0,0,1-.261-.113l-7-8.365a.323.323,0,0,1,.041-.451.318.318,0,0,1,.451.041l7.018,8.36a.323.323,0,0,1-.041.451A.318.318,0,0,1,377.529,397.647Z"
          transform="translate(-208.157 -312.522)"
          fill="#ccc"
        />
        <path
          id="Path_10820"
          data-name="Path 10820"
          d="M145.433,278.482c1.434,3.55,2.909.7,2.679-14.174-.113-6.971-.6-21.212-8.314-18.077-7.4,3.012,2.356,24.075,2.356,24.075Zm-10.588,2.11s-16.069-16.745-10.045-22c6.275-5.466,11.366,7.842,13.733,14.4,5.051,13.989,4.579,17.165,2.049,14.281Zm-3.391,15.029s-14.711-10.475-10.864-15.367c4.006-5.086,9.825,4.349,12.586,9.02,5.886,9.963,5.988,12.463,3.652,10.619Zm-3.98,14.983s-14.711-10.475-10.864-15.367c4-5.092,9.82,4.349,12.58,9.015,5.891,9.963,5.993,12.463,3.652,10.619Zm25.694-30.119s20.587-10.716,23.932-3.458c3.488,7.561-10.716,8.708-17.672,9.133-14.855.912-17.764-.43-14.286-2.049Zm-2.9,11.781s22.308-6.413,24.142,1.368c1.916,8.1-12.232,6.4-19.137,5.435-14.732-2.049-17.324-3.949-13.595-4.825Zm-5.122,12.857s19.014-4.9,20.387,1.721c1.429,6.89-10.5,5.122-16.31,4.149-12.411-2.075-14.558-3.739-11.387-4.395Zm-2.976,10.619s18.379-6.884,20.469-.425c2.157,6.705-9.891,6.213-15.777,5.86-12.56-.748-14.855-2.172-11.781-3.161Zm12.294-53.785s8.4-26.083,17.928-21.345c9.917,4.933-1.537,16.694-7.269,22.323-12.227,12.017-16.012,12.857-14.4,8.662Z"
          transform="translate(-84.153 -240.037)"
          fill="#7e59d1"
        />
        <path
          id="Path_10821"
          data-name="Path 10821"
          d="M145.433,278.482c1.434,3.55,2.909.7,2.679-14.174-.113-6.971-.6-21.212-8.314-18.077-7.4,3.012,2.356,24.075,2.356,24.075Zm-10.588,2.11s-16.069-16.745-10.045-22c6.275-5.466,11.366,7.842,13.733,14.4,5.051,13.989,4.579,17.165,2.049,14.281Zm-3.391,15.029s-14.711-10.475-10.864-15.367c4.006-5.086,9.825,4.349,12.586,9.02,5.886,9.963,5.988,12.463,3.652,10.619Zm-3.98,14.983s-14.711-10.475-10.864-15.367c4-5.092,9.82,4.349,12.58,9.015,5.891,9.963,5.993,12.463,3.652,10.619Zm25.694-30.119s20.587-10.716,23.932-3.458c3.488,7.561-10.716,8.708-17.672,9.133-14.855.912-17.764-.43-14.286-2.049Zm-2.9,11.781s22.308-6.413,24.142,1.368c1.916,8.1-12.232,6.4-19.137,5.435-14.732-2.049-17.324-3.949-13.595-4.825Zm-5.122,12.857s19.014-4.9,20.387,1.721c1.429,6.89-10.5,5.122-16.31,4.149-12.411-2.075-14.558-3.739-11.387-4.395Zm-2.976,10.619s18.379-6.884,20.469-.425c2.157,6.705-9.891,6.213-15.777,5.86-12.56-.748-14.855-2.172-11.781-3.161Zm12.294-53.785s8.4-26.083,17.928-21.345c9.917,4.933-1.537,16.694-7.269,22.323-12.227,12.017-16.012,12.857-14.4,8.662Z"
          transform="translate(-84.153 -240.037)"
          fill="#fff"
          opacity="0.65"
        />
        <path
          id="Path_10822"
          data-name="Path 10822"
          d="M146.562,343.181h0a.323.323,0,0,1-.3-.338c.773-13.7,4.61-30.222,10.547-45.353,5-12.77,25.96-38.418,26.17-38.674a.318.318,0,0,1,.451-.046.323.323,0,0,1,.046.451c-.21.256-21.1,25.827-26.073,38.5-5.9,15.065-9.732,31.523-10.5,45.153A.323.323,0,0,1,146.562,343.181Z"
          transform="translate(-98.928 -249.139)"
          fill="#ccc"
        />
        <path
          id="Path_10823"
          data-name="Path 10823"
          d="M172.485,294.926a.323.323,0,0,1-.318-.282l-2.93-24.526a.32.32,0,0,1,.635-.077l2.93,24.526a.323.323,0,0,1-.282.359Z"
          transform="translate(-110.135 -254.535)"
          fill="#ccc"
        />
        <path
          id="Path_10824"
          data-name="Path 10824"
          d="M156.986,315.05a.328.328,0,0,1-.287-.174l-10.6-20.612a.323.323,0,0,1,.138-.435.333.333,0,0,1,.435.138l10.6,20.612a.323.323,0,0,1-.138.435.369.369,0,0,1-.143.036Z"
          transform="translate(-98.83 -266.259)"
          fill="#ccc"
        />
        <path
          id="Path_10825"
          data-name="Path 10825"
          d="M170.106,324.4a.318.318,0,0,1-.312-.256.323.323,0,0,1,.251-.379l22.205-4.523a.323.323,0,1,1,.128.63l-22.205,4.523Z"
          transform="translate(-110.404 -278.662)"
          fill="#ccc"
        />
        <path
          id="Path_10826"
          data-name="Path 10826"
          d="M146.5,343.344a.328.328,0,0,1-.241-.108l-13.057-14.88a.319.319,0,0,1,.482-.42l13.057,14.855a.323.323,0,0,1-.241.512Z"
          transform="translate(-92.522 -282.857)"
          fill="#ccc"
        />
        <path
          id="Path_10827"
          data-name="Path 10827"
          d="M162.5,347.8a.318.318,0,0,1-.323-.318.323.323,0,0,1,.318-.323l16.765-.143h0a.318.318,0,0,1,.323.318.323.323,0,0,1-.318.323Z"
          transform="translate(-106.694 -292.22)"
          fill="#ccc"
        />
        <path
          id="Path_10828"
          data-name="Path 10828"
          d="M169.1,372.213h0l-12.857-.512a.323.323,0,0,1,.026-.64l12.857.512a.328.328,0,0,1,.307.333.323.323,0,0,1-.333.307Z"
          transform="translate(-103.665 -303.945)"
          fill="#ccc"
        />
        <path
          id="Path_10829"
          data-name="Path 10829"
          d="M144.486,378.128a.323.323,0,0,1-.256-.128l-6.884-9.179a.318.318,0,0,1,.061-.451.323.323,0,0,1,.451.067l6.884,9.174a.323.323,0,0,1-.067.451.3.3,0,0,1-.19.067Z"
          transform="translate(-94.548 -302.602)"
          fill="#ccc"
        />
        <path
          id="Path_10830"
          data-name="Path 10830"
          d="M150.062,392.817a.323.323,0,0,1-.323-.307.318.318,0,0,1,.3-.333l15.039-.748a.312.312,0,0,1,.338.3.323.323,0,0,1-.307.338l-15.034.748Z"
          transform="translate(-100.625 -313.88)"
          fill="#ccc"
        />
        <path
          id="Path_10831"
          data-name="Path 10831"
          d="M74.6,322.967c2.592.978,1.8-1.209-6.229-8.4-3.76-3.365-11.576-10.106-13.7-4.467-2.049,5.419,13.958,10.578,13.958,10.578Zm-4.1,6.659s-16.806.318-16.643-5.471c.179-6.024,9.732-2.187,14.409-.225,9.922,4.19,11.382,6,8.616,5.916Zm6.341,9.195S64.037,341.5,63.33,337.048c-.738-4.61,7.141-3.073,10.982-2.259,8.2,1.767,9.574,2.94,7.443,3.278Zm6.014,9.487s-12.806,2.674-13.513-1.772c-.743-4.61,7.141-3.073,10.982-2.264,8.2,1.767,9.574,2.94,7.443,3.278Zm-3.4-28.475s4.421-16.217,9.927-14.43c5.732,1.859-.64,9.978-3.832,13.887-6.813,8.349-8.959,9.22-8.1,6.6Zm4.851,7.361s7.555-15.019,12.6-12.171c5.25,2.966-2.612,9.651-6.516,12.852-8.334,6.828-10.619,7.274-9.251,4.861Zm4.328,9.036s6.736-12.524,10.936-10c4.369,2.628-2.433,8.1-5.809,10.716-7.207,5.578-9.143,5.906-7.935,3.9Zm4.185,6.8s5.368-13.17,9.814-11.105c4.61,2.151-1.562,8.314-4.641,11.269-6.572,6.311-8.467,6.843-7.473,4.718ZM70.286,310.048s-9.732-17.288-2.561-20.023c7.5-2.853,8.124,9.02,8.3,14.855.379,12.406-1.024,14.855-2.474,11.915Z"
          transform="translate(-53.858 -264.207)"
          fill="#7e59d1"
        />
        <path
          id="Path_10832"
          data-name="Path 10832"
          d="M74.6,322.967c2.592.978,1.8-1.209-6.229-8.4-3.76-3.365-11.576-10.106-13.7-4.467-2.049,5.419,13.958,10.578,13.958,10.578Zm-4.1,6.659s-16.806.318-16.643-5.471c.179-6.024,9.732-2.187,14.409-.225,9.922,4.19,11.382,6,8.616,5.916Zm6.341,9.195S64.037,341.5,63.33,337.048c-.738-4.61,7.141-3.073,10.982-2.259,8.2,1.767,9.574,2.94,7.443,3.278Zm6.014,9.487s-12.806,2.674-13.513-1.772c-.743-4.61,7.141-3.073,10.982-2.264,8.2,1.767,9.574,2.94,7.443,3.278Zm-3.4-28.475s4.421-16.217,9.927-14.43c5.732,1.859-.64,9.978-3.832,13.887-6.813,8.349-8.959,9.22-8.1,6.6Zm4.851,7.361s7.555-15.019,12.6-12.171c5.25,2.966-2.612,9.651-6.516,12.852-8.334,6.828-10.619,7.274-9.251,4.861Zm4.328,9.036s6.736-12.524,10.936-10c4.369,2.628-2.433,8.1-5.809,10.716-7.207,5.578-9.143,5.906-7.935,3.9Zm4.185,6.8s5.368-13.17,9.814-11.105c4.61,2.151-1.562,8.314-4.641,11.269-6.572,6.311-8.467,6.843-7.473,4.718ZM70.286,310.048s-9.732-17.288-2.561-20.023c7.5-2.853,8.124,9.02,8.3,14.855.379,12.406-1.024,14.855-2.474,11.915Z"
          transform="translate(-53.858 -264.207)"
          fill="#e5e5e5"
          opacity="0.65"
        />
        <path
          id="Path_10833"
          data-name="Path 10833"
          d="M113.68,364.9a.312.312,0,0,1-.231-.1,118.435,118.435,0,0,1-19.608-28.649c-4.339-8.954-7.684-32.716-7.714-32.957a.323.323,0,0,1,.271-.364.328.328,0,0,1,.364.277c.031.236,3.36,23.906,7.658,32.783a117.561,117.561,0,0,0,19.491,28.485.323.323,0,0,1,0,.456A.3.3,0,0,1,113.68,364.9Z"
          transform="translate(-69.596 -270.663)"
          fill="#ccc"
        />
        <path
          id="Path_10834"
          data-name="Path 10834"
          d="M82.919,345.187a.323.323,0,0,1-.19-.061l-14.486-10.5a.323.323,0,1,1,.379-.512l14.486,10.491a.319.319,0,0,1-.19.579Z"
          transform="translate(-60.79 -285.879)"
          fill="#ccc"
        />
        <path
          id="Path_10835"
          data-name="Path 10835"
          d="M86.045,363.346a.241.241,0,0,1-.087,0l-16.181-4.5a.319.319,0,1,1,.169-.615l16.181,4.487a.323.323,0,0,1-.082.63Z"
          transform="translate(-61.508 -297.681)"
          fill="#ccc"
        />
        <path
          id="Path_10836"
          data-name="Path 10836"
          d="M99.763,349.683a.3.3,0,0,1-.169-.046.323.323,0,0,1-.108-.441L108,335.162a.323.323,0,1,1,.548.333l-8.513,14.035A.307.307,0,0,1,99.763,349.683Z"
          transform="translate(-76.091 -286.344)"
          fill="#ccc"
        />
        <path
          id="Path_10837"
          data-name="Path 10837"
          d="M95.945,382.829h0L81.6,382.46a.323.323,0,0,1-.312-.328.338.338,0,0,1,.333-.312l14.343.369a.318.318,0,0,1,.312.328.323.323,0,0,1-.333.312Z"
          transform="translate(-67.238 -309.194)"
          fill="#ccc"
        />
        <path
          id="Path_10838"
          data-name="Path 10838"
          d="M106.272,368.02a.318.318,0,0,1-.236-.512l8.145-9.01a.323.323,0,0,1,.456-.026.328.328,0,0,1,0,.456l-8.139,8.995A.323.323,0,0,1,106.272,368.02Z"
          transform="translate(-79.276 -297.765)"
          fill="#ccc"
        />
        <path
          id="Path_10839"
          data-name="Path 10839"
          d="M115.8,385.345a.323.323,0,0,1-.225-.548l6.587-6.587a.323.323,0,0,1,.456.456l-6.592,6.567A.318.318,0,0,1,115.8,385.345Z"
          transform="translate(-83.915 -307.392)"
          fill="#ccc"
        />
        <path
          id="Path_10840"
          data-name="Path 10840"
          d="M113.475,401h-.031l-8.267-.85a.323.323,0,0,1,.067-.64l8.267.85a.323.323,0,0,1-.036.64Z"
          transform="translate(-78.759 -317.822)"
          fill="#ccc"
        />
        <path
          id="Path_10841"
          data-name="Path 10841"
          d="M124.386,397.642a.318.318,0,0,1-.2-.077.323.323,0,0,1-.041-.451l7-8.365a.318.318,0,0,1,.451-.041.323.323,0,0,1,.041.451l-7,8.37a.318.318,0,0,1-.246.113Z"
          transform="translate(-88.103 -312.517)"
          fill="#ccc"
        />
      </g>
      <g
        id="freepik--Character--inject-211"
        transform="translate(114.598 89.651)"
      >
        <rect
          id="Rectangle_27529"
          data-name="Rectangle 27529"
          width="54.153"
          height="11.085"
          transform="translate(7.792 97.509)"
          fill="#fff"
        />
        <path
          id="Path_10842"
          data-name="Path 10842"
          d="M241.56,322.8H187.406a.261.261,0,0,1-.256-.256V311.456a.256.256,0,0,1,.256-.256H241.56a.256.256,0,0,1,.256.256v11.085A.256.256,0,0,1,241.56,322.8Zm-53.9-.512H241.3V311.712H187.662Z"
          transform="translate(-179.614 -213.947)"
          fill="#263238"
        />
        <path
          id="Path_10843"
          data-name="Path 10843"
          d="M198.008,317.322h-9.5a.256.256,0,0,1,0-.512h9.5a.256.256,0,1,1,0,.512Z"
          transform="translate(-180.15 -216.684)"
          fill="#263238"
        />
        <path
          id="Path_10844"
          data-name="Path 10844"
          d="M214.522,323.222h-1.3a.256.256,0,0,1,0-.512h1.3a.256.256,0,1,1,0,.512Z"
          transform="translate(-192.208 -219.561)"
          fill="#263238"
        />
        <path
          id="Path_10845"
          data-name="Path 10845"
          d="M198.876,323.222h-10.66a.256.256,0,0,1-.256-.256.261.261,0,0,1,.256-.256h10.66a.256.256,0,1,1,0,.512Z"
          transform="translate(-180.009 -219.561)"
          fill="#263238"
        />
        <path
          id="Path_10846"
          data-name="Path 10846"
          d="M193.041,329.342h-4.385a.256.256,0,0,1,0-.512h4.385a.256.256,0,0,1,0,.512Z"
          transform="translate(-180.224 -222.546)"
          fill="#263238"
        />
        <path
          id="Path_10847"
          data-name="Path 10847"
          d="M271.014,312.44a20.147,20.147,0,0,0,0,10.45h21.821s-2.295-6.628,0-10.45Z"
          transform="translate(-220.183 -214.552)"
          fill="#fff"
        />
        <path
          id="Path_10848"
          data-name="Path 10848"
          d="M292.6,322.9H270.774a.256.256,0,0,1-.251-.19,20.679,20.679,0,0,1,0-10.593.256.256,0,0,1,.246-.179H292.59a.261.261,0,0,1,.22.389c-2.208,3.678,0,10.168,0,10.245a.256.256,0,0,1-.241.338Zm-21.627-.512h21.273c-.425-1.373-1.813-6.5-.082-9.937H270.943a20.361,20.361,0,0,0,.026,9.937Z"
          transform="translate(-219.944 -214.308)"
          fill="#263238"
        />
        <g
          id="Group_10857"
          data-name="Group 10857"
          transform="translate(50.141 97.888)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Group_10856"
            data-name="Group 10856"
            transform="translate(-0.252 -0.256)"
            opacity="0.24"
          >
            <path
              id="Path_10849"
              data-name="Path 10849"
              d="M271.014,312.44a20.147,20.147,0,0,0,0,10.45h21.821s-2.295-6.628,0-10.45Z"
              transform="translate(-270.073 -312.184)"
            />
            <path
              id="Path_10850"
              data-name="Path 10850"
              d="M292.6,322.9H270.774a.256.256,0,0,1-.251-.19,20.679,20.679,0,0,1,0-10.593.256.256,0,0,1,.246-.179H292.59a.261.261,0,0,1,.22.389c-2.208,3.678,0,10.168,0,10.245a.256.256,0,0,1-.241.338Zm-21.627-.512h21.273c-.425-1.373-1.813-6.5-.082-9.937H270.943a20.361,20.361,0,0,0,.026,9.937Z"
              transform="translate(-269.833 -311.94)"
              fill="#263238"
            />
          </g>
        </g>
        <path
          id="Path_10853"
          data-name="Path 10853"
          d="M187.242,320.482v-7.566a.7.7,0,0,1,.7-.7h64.4a.512.512,0,0,0,.512-.512h0a.512.512,0,0,0-.512-.512H186.2a.7.7,0,0,0-.7.7v9.65a1.132,1.132,0,0,0,1.132,1.137h65.715a.512.512,0,0,0,.512-.512h0a.512.512,0,0,0-.512-.512H188.374a1.137,1.137,0,0,1-1.132-1.173Z"
          transform="translate(-178.809 -213.942)"
          fill="#263238"
        />
        <path
          id="Path_10854"
          data-name="Path 10854"
          d="M252.1,322.655H186.388A1.393,1.393,0,0,1,185,321.262v-9.686a.958.958,0,0,1,.958-.958H252.1a.789.789,0,1,1,0,1.573H187.7a.446.446,0,0,0-.446.446V320.2a.881.881,0,0,0,.876.881H252.1a.789.789,0,1,1,0,1.573ZM185.978,311.13a.446.446,0,0,0-.446.446v9.686a.881.881,0,0,0,.876.881H252.1a.277.277,0,1,0,0-.548H188.13a1.388,1.388,0,0,1-1.388-1.393v-7.566a.958.958,0,0,1,.958-.958h64.4a.277.277,0,1,0,0-.548Z"
          transform="translate(-178.565 -213.662)"
          fill="#263238"
        />
        <rect
          id="Rectangle_27530"
          data-name="Rectangle 27530"
          width="54.153"
          height="11.085"
          transform="translate(7.792 120.385)"
          fill="#fff"
        />
        <path
          id="Path_10855"
          data-name="Path 10855"
          d="M241.56,367.457H187.406a.256.256,0,0,1-.256-.256V356.116a.261.261,0,0,1,.256-.256H241.56a.256.256,0,0,1,.256.256V367.2A.256.256,0,0,1,241.56,367.457Zm-53.9-.512H241.3V356.372H187.662Z"
          transform="translate(-179.614 -235.731)"
          fill="#263238"
        />
        <path
          id="Path_10856"
          data-name="Path 10856"
          d="M198.693,360.512h-9.517a.256.256,0,1,1,0-.512h9.517a.256.256,0,1,1,0,.512Z"
          transform="translate(-180.477 -237.75)"
          fill="#263238"
        />
        <path
          id="Path_10857"
          data-name="Path 10857"
          d="M215.212,366.452h-1.3a.261.261,0,0,1-.256-.256.256.256,0,0,1,.256-.256h1.3a.256.256,0,1,1,0,.512Z"
          transform="translate(-192.544 -240.647)"
          fill="#263238"
        />
        <path
          id="Path_10858"
          data-name="Path 10858"
          d="M199.551,366.452H188.9a.256.256,0,1,1,0-.512h10.654a.256.256,0,0,1,0,.512Z"
          transform="translate(-180.341 -240.647)"
          fill="#263238"
        />
        <path
          id="Path_10859"
          data-name="Path 10859"
          d="M193.711,372.562h-4.385a.256.256,0,0,1,0-.512h4.385a.256.256,0,1,1,0,.512Z"
          transform="translate(-180.55 -243.628)"
          fill="#263238"
        />
        <path
          id="Path_10860"
          data-name="Path 10860"
          d="M271.675,357.11a20.2,20.2,0,0,0,0,10.45h21.483s-2.295-6.628,0-10.45Z"
          transform="translate(-220.507 -236.34)"
          fill="#fff"
        />
        <path
          id="Path_10861"
          data-name="Path 10861"
          d="M292.92,367.562H271.437a.261.261,0,0,1-.246-.195,20.628,20.628,0,0,1,0-10.593.256.256,0,0,1,.241-.174h21.483a.256.256,0,0,1,.22.128.266.266,0,0,1,0,.261c-2.208,3.673,0,10.163,0,10.245a.261.261,0,0,1-.036.236A.251.251,0,0,1,292.92,367.562Zm-21.283-.512h20.93c-.425-1.378-1.813-6.5-.082-9.937H271.632a20.356,20.356,0,0,0,.005,9.937Z"
          transform="translate(-220.269 -236.092)"
          fill="#263238"
        />
        <g
          id="Group_10859"
          data-name="Group 10859"
          transform="translate(50.481 120.77)"
          clipPath="url(#clip-path-2)"
        >
          <g
            id="Group_10858"
            data-name="Group 10858"
            transform="translate(-0.25 -0.261)"
            opacity="0.24"
          >
            <path
              id="Path_10862"
              data-name="Path 10862"
              d="M271.675,357.11a20.2,20.2,0,0,0,0,10.45h21.483s-2.295-6.628,0-10.45Z"
              transform="translate(-270.738 -356.849)"
            />
            <path
              id="Path_10863"
              data-name="Path 10863"
              d="M292.92,367.562H271.437a.261.261,0,0,1-.246-.195,20.628,20.628,0,0,1,0-10.593.256.256,0,0,1,.241-.174h21.483a.256.256,0,0,1,.22.128.266.266,0,0,1,0,.261c-2.208,3.673,0,10.163,0,10.245a.261.261,0,0,1-.036.236A.251.251,0,0,1,292.92,367.562Zm-21.283-.512h20.93c-.425-1.378-1.813-6.5-.082-9.937H271.632a20.356,20.356,0,0,0,.005,9.937Z"
              transform="translate(-270.5 -356.6)"
              fill="#263238"
            />
          </g>
        </g>
        <path
          id="Path_10866"
          data-name="Path 10866"
          d="M187.242,365.147v-7.571a.7.7,0,0,1,.7-.7h64.4a.512.512,0,0,0,.512-.512h0a.512.512,0,0,0-.512-.512H186.2a.7.7,0,0,0-.7.7v9.691a1.132,1.132,0,0,0,1.132,1.132h65.715a.512.512,0,0,0,.512-.512h0a.512.512,0,0,0-.512-.512H188.374a1.132,1.132,0,0,1-1.132-1.2Z"
          transform="translate(-178.809 -235.726)"
          fill="#263238"
        />
        <path
          id="Path_10867"
          data-name="Path 10867"
          d="M252.1,367.315H186.388A1.388,1.388,0,0,1,185,365.927v-9.691a.958.958,0,0,1,.958-.958H252.1a.789.789,0,1,1,0,1.573H187.7a.446.446,0,0,0-.446.446v7.571a.881.881,0,0,0,.876.876H252.1a.789.789,0,1,1,0,1.573ZM185.978,355.79a.446.446,0,0,0-.446.446v9.691a.881.881,0,0,0,.876.876H252.1a.277.277,0,1,0,0-.548H188.13a1.388,1.388,0,0,1-1.388-1.388V357.3a.958.958,0,0,1,.958-.958h64.4a.277.277,0,1,0,0-.548Z"
          transform="translate(-178.565 -235.445)"
          fill="#263238"
        />
        <path
          id="Path_10868"
          data-name="Path 10868"
          d="M189,389.187c.379-1.275,1.772-6.536.051-9.907H251.37v9.907Z"
          transform="translate(-180.516 -247.154)"
          fill="#fff"
        />
        <path
          id="Path_10869"
          data-name="Path 10869"
          d="M251.12,389.179H188.756a.258.258,0,0,1-.246-.333c.307-1.024,1.767-6.388.067-9.732a.248.248,0,0,1,.22-.374H251.12a.256.256,0,0,1,.256.256V388.9a.256.256,0,0,1-.256.277Zm-62.026-.512h61.77v-9.394H189.211C190.584,382.459,189.585,386.91,189.094,388.667Z"
          transform="translate(-180.272 -246.891)"
          fill="#263238"
        />
        <path
          id="Path_10870"
          data-name="Path 10870"
          d="M252.338,383.572h-9.5a.256.256,0,1,1,0-.512h9.5a.256.256,0,0,1,0,.512Z"
          transform="translate(-206.651 -248.998)"
          fill="#263238"
        />
        <path
          id="Path_10871"
          data-name="Path 10871"
          d="M235.412,389.512h-1.3a.256.256,0,1,1,0-.512h1.3a.256.256,0,1,1,0,.512Z"
          transform="translate(-202.397 -251.895)"
          fill="#263238"
        />
        <path
          id="Path_10872"
          data-name="Path 10872"
          d="M251.526,389.512h-10.66a.256.256,0,1,1,0-.512h10.66a.261.261,0,0,1,.256.256A.256.256,0,0,1,251.526,389.512Z"
          transform="translate(-205.69 -251.895)"
          fill="#263238"
        />
        <path
          id="Path_10873"
          data-name="Path 10873"
          d="M257.061,395.592h-4.385a.256.256,0,0,1,0-.512h4.385a.256.256,0,0,1,0,.512Z"
          transform="translate(-211.45 -254.861)"
          fill="#263238"
        />
        <path
          id="Path_10874"
          data-name="Path 10874"
          d="M224.869,387.817v-7.571a.7.7,0,0,0-.7-.7H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h66.124a.7.7,0,0,1,.7.7v9.691a1.132,1.132,0,0,1-1.132,1.132H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h37.685a1.132,1.132,0,0,0,1.132-1.2Z"
          transform="translate(-178.829 -246.784)"
          fill="#263238"
        />
        <path
          id="Path_10875"
          data-name="Path 10875"
          d="M251.422,389.975H185.728a.789.789,0,1,1,0-1.573h37.685a.881.881,0,0,0,.876-.876v-7.571a.446.446,0,0,0-.42-.456H185.728a.789.789,0,1,1,0-1.573h66.124a.958.958,0,0,1,.958.958v9.691A1.393,1.393,0,0,1,251.422,389.975Zm-65.694-1.06a.277.277,0,1,0,0,.548h65.694a.881.881,0,0,0,.876-.876V378.9a.446.446,0,0,0-.446-.446H185.728a.277.277,0,1,0,0,.548h38.141a.958.958,0,0,1,.958.958v7.571a1.393,1.393,0,0,1-1.388,1.388Z"
          transform="translate(-178.505 -246.493)"
          fill="#263238"
        />
        <path
          id="Path_10876"
          data-name="Path 10876"
          d="M189,411.852c.379-1.27,1.772-6.531.051-9.9H251.37v9.9Z"
          transform="translate(-180.516 -258.212)"
          fill="#fff"
        />
        <path
          id="Path_10877"
          data-name="Path 10877"
          d="M251.12,411.844H188.756a.246.246,0,0,1-.2-.1.251.251,0,0,1-.041-.225c.307-1.024,1.767-6.393.067-9.732a.249.249,0,0,1,.22-.374H251.12a.256.256,0,0,1,.256.256v9.9a.256.256,0,0,1-.256.277Zm-62.026-.512h61.77v-9.389H189.211C190.584,405.119,189.585,409.575,189.094,411.332Z"
          transform="translate(-180.272 -257.948)"
          fill="#263238"
        />
        <path
          id="Path_10878"
          data-name="Path 10878"
          d="M252.273,406.372h-9.517a.256.256,0,0,1,0-.512h9.507a.261.261,0,0,1,.256.256A.256.256,0,0,1,252.273,406.372Z"
          transform="translate(-206.612 -260.119)"
          fill="#263238"
        />
        <path
          id="Path_10879"
          data-name="Path 10879"
          d="M235.352,412.282h-1.3a.256.256,0,1,1,0-.512h1.3a.256.256,0,1,1,0,.512Z"
          transform="translate(-202.368 -263.002)"
          fill="#263238"
        />
        <path
          id="Path_10880"
          data-name="Path 10880"
          d="M251.466,412.282h-10.66a.256.256,0,1,1,0-.512h10.66a.256.256,0,0,1,0,.512Z"
          transform="translate(-205.66 -263.002)"
          fill="#263238"
        />
        <path
          id="Path_10881"
          data-name="Path 10881"
          d="M257,418.4h-4.385a.256.256,0,1,1,0-.512H257a.256.256,0,0,1,0,.512Z"
          transform="translate(-211.421 -265.987)"
          fill="#263238"
        />
        <path
          id="Path_10882"
          data-name="Path 10882"
          d="M224.869,410.482v-7.566a.7.7,0,0,0-.7-.7H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h66.124a.7.7,0,0,1,.7.7v9.65a1.132,1.132,0,0,1-1.132,1.137H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h37.685a1.137,1.137,0,0,0,1.132-1.173Z"
          transform="translate(-178.829 -257.841)"
          fill="#f2b636"
        />
        <path
          id="Path_10883"
          data-name="Path 10883"
          d="M251.422,412.655H185.728a.789.789,0,1,1,0-1.573h37.685a.881.881,0,0,0,.876-.881v-7.566a.446.446,0,0,0-.446-.446H185.728a.789.789,0,1,1,0-1.573h66.124a.958.958,0,0,1,.958.958v9.686A1.393,1.393,0,0,1,251.422,412.655Zm-65.694-1.06a.277.277,0,1,0,0,.548h65.694a.881.881,0,0,0,.876-.881v-9.686a.446.446,0,0,0-.446-.446H185.728a.277.277,0,1,0,0,.548h38.141a.958.958,0,0,1,.958.958V410.2a1.393,1.393,0,0,1-1.388,1.393Z"
          transform="translate(-178.505 -257.561)"
          fill="#263238"
        />
        <path
          id="Path_10884"
          data-name="Path 10884"
          d="M189,343.852c.379-1.27,1.772-6.531.051-9.9H251.37v9.9Z"
          transform="translate(-180.516 -225.044)"
          fill="#fff"
        />
        <path
          id="Path_10885"
          data-name="Path 10885"
          d="M251.12,343.844H188.756a.246.246,0,0,1-.2-.1.251.251,0,0,1-.041-.225c.307-1.024,1.767-6.393.067-9.732a.249.249,0,0,1,.22-.374H251.12a.256.256,0,0,1,.256.256v9.9a.256.256,0,0,1-.256.277Zm-62.026-.512h61.77v-9.389H189.212C190.584,337.119,189.585,341.575,189.094,343.332Z"
          transform="translate(-180.272 -224.78)"
          fill="#263238"
        />
        <path
          id="Path_10886"
          data-name="Path 10886"
          d="M252.6,338.292H243.1a.256.256,0,0,1,0-.512H252.6a.256.256,0,0,1,0,.512Z"
          transform="translate(-206.777 -226.912)"
          fill="#263238"
        />
        <path
          id="Path_10887"
          data-name="Path 10887"
          d="M235.672,344.192h-1.3a.256.256,0,0,1-.256-.256.261.261,0,0,1,.256-.256h1.3a.256.256,0,1,1,0,.512Z"
          transform="translate(-202.524 -229.79)"
          fill="#263238"
        />
        <path
          id="Path_10888"
          data-name="Path 10888"
          d="M251.786,344.192h-10.66a.256.256,0,0,1-.256-.256.261.261,0,0,1,.256-.256h10.66a.256.256,0,1,1,0,.512Z"
          transform="translate(-205.817 -229.79)"
          fill="#263238"
        />
        <path
          id="Path_10889"
          data-name="Path 10889"
          d="M257.326,350.312h-4.39a.256.256,0,0,1-.256-.256.261.261,0,0,1,.256-.256h4.39a.261.261,0,0,1,.256.256A.256.256,0,0,1,257.326,350.312Z"
          transform="translate(-211.577 -232.775)"
          fill="#263238"
        />
        <path
          id="Path_10890"
          data-name="Path 10890"
          d="M224.869,342.482v-7.566a.7.7,0,0,0-.7-.7H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h66.124a.7.7,0,0,1,.7.7v9.65a1.132,1.132,0,0,1-1.132,1.137H186.052a.512.512,0,0,1-.512-.512h0a.512.512,0,0,1,.512-.512h37.685a1.137,1.137,0,0,0,1.132-1.173Z"
          transform="translate(-178.829 -224.673)"
          fill="#7e59d1"
        />
        <path
          id="Path_10891"
          data-name="Path 10891"
          d="M251.422,344.655H185.728a.789.789,0,1,1,0-1.573h37.685a.881.881,0,0,0,.876-.881v-7.566a.446.446,0,0,0-.446-.446H185.728a.789.789,0,1,1,0-1.573h66.124a.958.958,0,0,1,.958.958v9.686a1.393,1.393,0,0,1-1.388,1.393Zm-65.694-1.06a.277.277,0,1,0,0,.548h65.694a.881.881,0,0,0,.876-.881v-9.686a.446.446,0,0,0-.446-.446H185.728a.277.277,0,1,0,0,.548h38.141a.958.958,0,0,1,.958.958V342.2a1.393,1.393,0,0,1-1.388,1.393Z"
          transform="translate(-178.505 -224.393)"
          fill="#263238"
        />
        <path
          id="Path_10892"
          data-name="Path 10892"
          d="M206.793,260.932a53.023,53.023,0,0,1-12.98-1.706c-7.684-1.88-14-.512-15.193,6.828s18.44,14.855,32.1,17.59,27.323,3.924,33.982,1.024,6.485-13.318,3.924-18.1-11.1-5.46-15.536-4.754S206.793,260.932,206.793,260.932Z"
          transform="translate(-175.428 -188.226)"
          fill="#263238"
        />
        <path
          id="Path_10893"
          data-name="Path 10893"
          d="M233.388,286.371c-7.853,0-16.965-1.511-22.963-2.71-13.753-2.751-33.531-10.337-32.307-17.882a8.752,8.752,0,0,1,3.662-6.219c2.679-1.777,6.777-2.049,11.848-.82a53.189,53.189,0,0,0,12.908,1.7h0c.22,0,21.878,1.537,26.237.861,3.97-.615,13.057-.215,15.8,4.912a17.457,17.457,0,0,1,.85,12.806,9.145,9.145,0,0,1-4.9,5.635C241.712,285.9,237.742,286.371,233.388,286.371Zm-45.466-27.866a10.63,10.63,0,0,0-5.86,1.491,8.269,8.269,0,0,0-3.437,5.875c-1.122,6.9,17.805,14.476,31.9,17.3,9.271,1.854,26,4.451,33.807,1.024a8.6,8.6,0,0,0,4.61-5.332,17.191,17.191,0,0,0-.814-12.411c-2.618-4.887-11.423-5.24-15.27-4.651-4.4.681-25.284-.773-26.339-.85a54.641,54.641,0,0,1-13.036-1.711,23.564,23.564,0,0,0-5.558-.733Z"
          transform="translate(-175.182 -187.991)"
          fill="#263238"
        />
        <path
          id="Path_10894"
          data-name="Path 10894"
          d="M271.3,162.191q-1.1-7.386-2.2-14.763c-.681-4.569-.968-9.461-2.156-13.923-1.537-6.659-3.842-10.245-10.757-11.525s-14.6,6.147-14.6,9.476,2.377,10.844-1.281,22.666c-1.25,4.042,3.586,4.61,9.43,2.822Z"
          transform="translate(-205.447 -121.581)"
          fill="#263238"
        />
        <path
          id="Path_10895"
          data-name="Path 10895"
          d="M271.01,162.206h-.061l-21.514-5.225c-4.354,1.327-7.791,1.275-9.22-.133a2.832,2.832,0,0,1-.487-3.032,43.735,43.735,0,0,0,1.537-19.742,24.679,24.679,0,0,1-.261-2.848c0-1.614,1.665-4.1,4.144-6.147,1.649-1.368,5.993-4.462,10.757-3.586,7.212,1.332,9.481,5.307,10.962,11.715a69.579,69.579,0,0,1,1.6,9.6c.174,1.434.348,2.92.563,4.344q.594,3.985,1.188,7.975l1.024,6.782a.256.256,0,0,1-.077.23A.282.282,0,0,1,271.01,162.206Zm-21.56-5.758h.061l21.191,5.184-.953-6.408q-.6-3.985-1.188-7.981c-.215-1.429-.389-2.915-.563-4.354a69,69,0,0,0-1.588-9.538c-1.47-6.362-3.586-10.05-10.557-11.341-3.155-.584-7.018.712-10.337,3.473-2.331,1.941-3.96,4.3-3.96,5.752a25.1,25.1,0,0,0,.256,2.787,43.9,43.9,0,0,1-1.537,19.952,2.363,2.363,0,0,0,.359,2.52c1.255,1.26,4.61,1.255,8.749,0a.2.2,0,0,1,.067-.046Z"
          transform="translate(-205.156 -121.34)"
          fill="#263238"
        />
        <path
          id="Path_10896"
          data-name="Path 10896"
          d="M234.925,180.595s-8.452,1.153-12.422,2.177-5.378,2.479-6.808,5.189-3.555,12.775-5.6,17-5.773,5.343-6.413,9.825,2.945,6.019,5.635,6.531a48.151,48.151,0,0,0,6.019.64s4.354,10.245,12.806,11.013,14.727-5.122,18.568-7.3,4.1-7.171,4.226-12.934,3.534-18.722,2.638-22.308-2.238-4.175-8.057-7.171C243.023,181.993,238.51,180.339,234.925,180.595Z"
          transform="translate(-187.639 -150.229)"
          fill="#f2b636"
        />
        <path
          id="Path_10897"
          data-name="Path 10897"
          d="M229.418,233.039a11.866,11.866,0,0,1-1.537-.067c-7.945-.722-12.37-9.732-12.954-11.023a47.4,47.4,0,0,1-5.9-.635c-2.479-.471-6.531-1.987-5.845-6.818.384-2.664,1.818-4.18,3.335-5.788a16.023,16.023,0,0,0,3.1-4.134,72.489,72.489,0,0,0,3.222-9.732,53.652,53.652,0,0,1,2.382-7.284c1.409-2.684,2.874-4.241,6.977-5.3,3.934-1.024,12.365-2.172,12.447-2.182h0c3.519-.256,7.986,1.3,10.726,2.7l.512.261c5.343,2.735,6.782,3.473,7.684,7.064.512,2.1-.338,7.038-1.245,12.294a81.884,81.884,0,0,0-1.383,10.1c-.118,5.317-.241,10.818-4.354,13.149-.773.435-1.629,1.024-2.623,1.685C240.359,229.765,235.514,233.039,229.418,233.039Zm5.291-52.448c-.307.046-8.529,1.178-12.381,2.172-3.939,1.024-5.286,2.438-6.659,5.04a53.112,53.112,0,0,0-2.346,7.171,75.111,75.111,0,0,1-3.253,9.809,16.6,16.6,0,0,1-3.191,4.262c-1.537,1.614-2.843,3-3.2,5.507-.6,4.19,2.618,5.706,5.425,6.244a48.071,48.071,0,0,0,5.983.635.256.256,0,0,1,.225.154c.041.1,4.395,10.112,12.591,10.859,6.659.6,11.92-2.961,15.767-5.558,1.024-.681,1.865-1.265,2.653-1.711,3.586-2.049,3.96-6.551,4.1-12.714a82.471,82.471,0,0,1,1.388-10.178c.861-4.953,1.752-10.076,1.255-12.063-.84-3.365-2.105-4.011-7.412-6.731l-.512-.261C242.5,181.871,238.131,180.35,234.709,180.591Z"
          transform="translate(-187.398 -149.975)"
          fill="#263238"
        />
        <path
          id="Path_10898"
          data-name="Path 10898"
          d="M208.347,233.31c-1.952,2.336-4.2,3.816-4.677,7.2a6.346,6.346,0,0,0,0,2.049,4.449,4.449,0,0,0,5.256,1.767c3.913-1.122,3.493-6.572,1.117-9.927A2.4,2.4,0,0,0,208.347,233.31Z"
          transform="translate(-187.631 -175.955)"
          fill="#fff"
        />
        <path
          id="Path_10899"
          data-name="Path 10899"
          d="M207.115,244.558a4.61,4.61,0,0,1-3.867-2.116.236.236,0,0,1-.041-.1,6.6,6.6,0,0,1-.026-2.11c.384-2.664,1.818-4.185,3.34-5.788.461-.512.937-.994,1.393-1.537a.251.251,0,0,1,.23-.092,2.669,2.669,0,0,1,1.9,1.178c1.665,2.351,2.295,5.507,1.537,7.684a4.031,4.031,0,0,1-2.787,2.643,6.07,6.07,0,0,1-1.675.236ZM203.7,242.2a4.177,4.177,0,0,0,4.938,1.619,3.488,3.488,0,0,0,2.443-2.32c.712-2.049.113-4.989-1.46-7.212a2.218,2.218,0,0,0-1.409-.948c-.435.512-.886.994-1.327,1.455-1.537,1.614-2.843,3.007-3.2,5.512a5.866,5.866,0,0,0,.01,1.9Z"
          transform="translate(-187.398 -175.714)"
          fill="#263238"
        />
        <path
          id="Path_10900"
          data-name="Path 10900"
          d="M256.681,197.891c3.586-1.639,5.522-4.472,7.171-9.845a51.028,51.028,0,0,0,1.46-5.906,19.96,19.96,0,0,0-8.022-1.537s-3.647.512-7.279,1.127c-.3,1.885-1.024,7.253-.179,11.269C250.867,197.891,253.1,199.53,256.681,197.891Z"
          transform="translate(-209.995 -150.233)"
          fill="#fff"
        />
        <path
          id="Path_10901"
          data-name="Path 10901"
          d="M253.976,198.515a3.52,3.52,0,0,1-1.608-.369c-1.45-.727-2.454-2.484-3.073-5.368-.845-3.99-.159-9.256.179-11.341a.251.251,0,0,1,.2-.21c3.586-.625,7.253-1.127,7.289-1.132a21.33,21.33,0,0,1,8.15,1.537.261.261,0,0,1,.159.287,51.681,51.681,0,0,1-1.475,5.957c-1.706,5.573-3.75,8.375-7.3,10h0A6.213,6.213,0,0,1,253.976,198.515ZM249.97,181.7c-.338,2.167-.958,7.171-.154,10.972.569,2.684,1.506,4.374,2.792,5.02a4.159,4.159,0,0,0,3.714-.277c3.4-1.537,5.363-4.277,7.018-9.686a51.658,51.658,0,0,0,1.4-5.645,19.863,19.863,0,0,0-7.684-1.45C257.014,180.607,253.484,181.089,249.97,181.7Zm6.454,15.946Z"
          transform="translate(-209.728 -149.986)"
          fill="#263238"
        />
        <path
          id="Path_10902"
          data-name="Path 10902"
          d="M230.256,219.186a.236.236,0,0,1-.1,0,.251.251,0,0,1-.138-.333c.026-.061,2.561-6.377,2.561-8.1a9.918,9.918,0,0,0-1.194-3.852c-.282-.62-.569-1.255-.84-1.946-.886-2.285,1.127-5.855,1.4-6.326.123-.927,1.5-11.213,2.833-13.134a.251.251,0,0,1,.353-.061.256.256,0,0,1,.067.353c-1.291,1.865-2.74,12.852-2.751,12.96a.319.319,0,0,1-.036.1c0,.041-2.228,3.765-1.388,5.927.261.676.548,1.306.825,1.921a10.23,10.23,0,0,1,1.24,4.062c0,1.818-2.474,8.027-2.561,8.288A.246.246,0,0,1,230.256,219.186Z"
          transform="translate(-200.515 -152.58)"
          fill="#263238"
        />
        <path
          id="Path_10903"
          data-name="Path 10903"
          d="M206.75,224.419l1.921-2.049a21,21,0,0,0,3.97,1.665c5.189,1.726,7.555,2.817,7.812,5.891,0,0,3.458-2.177,6.915-1.793s6.531,1.793,7.555,1.921l1.024.128.256,1.793-1.921,10.117L219.3,240.554l-10.245-8.964Z"
          transform="translate(-189.174 -170.619)"
          fill="#fff"
        />
        <path
          id="Path_10904"
          data-name="Path 10904"
          d="M234.038,242.107h-.026L219.03,240.57a.256.256,0,0,1-.143-.061l-10.245-8.964a.246.246,0,0,1-.077-.113l-2.305-7.171a.266.266,0,0,1,.061-.256l1.921-2.049a.256.256,0,0,1,.318-.041,20.818,20.818,0,0,0,3.919,1.639c5.122,1.706,7.509,2.8,7.94,5.717a12.2,12.2,0,0,1,6.736-1.629,28.767,28.767,0,0,1,5.88,1.439,12.246,12.246,0,0,0,1.675.482l1.024.128a.261.261,0,0,1,.225.22l.256,1.793a.442.442,0,0,1,0,.082l-1.921,10.132A.251.251,0,0,1,234.038,242.107Zm-14.855-2.049,14.665,1.5,1.87-9.845-.22-1.537-.83-.108a11.521,11.521,0,0,1-1.772-.512,28.307,28.307,0,0,0-5.783-1.419c-3.33-.374-6.715,1.736-6.746,1.757a.256.256,0,0,1-.251,0,.251.251,0,0,1-.143-.2c-.241-2.91-2.515-3.965-7.637-5.67a24.292,24.292,0,0,1-3.842-1.583l-1.675,1.788,2.233,6.961Z"
          transform="translate(-188.93 -170.378)"
          fill="#263238"
        />
        <path
          id="Path_10905"
          data-name="Path 10905"
          d="M205.5,225.62l12.166,10.373,1.281-2.049,17.416-.384-1.024,11.269-16.52-.768-1.153.768L208.7,238.17Z"
          transform="translate(-188.564 -172.204)"
          fill="#263238"
        />
        <path
          id="Path_10906"
          data-name="Path 10906"
          d="M235.085,244.839h0l-16.438-.768-1.081.722a.261.261,0,0,1-.3,0l-8.964-6.659a.241.241,0,0,1-.092-.143l-3.222-12.555a.251.251,0,0,1,.113-.282.246.246,0,0,1,.3.026l11.94,10.178,1.127-1.8a.251.251,0,0,1,.21-.118l17.416-.384a.261.261,0,0,1,.195.082.266.266,0,0,1,.067.195L235.331,244.6A.261.261,0,0,1,235.085,244.839Zm-16.52-1.281h0l16.274.758.978-10.757-16.991.374-1.209,1.931a.246.246,0,0,1-.174.118.261.261,0,0,1-.2-.056l-11.546-9.845,2.981,11.679,8.749,6.5,1-.671a.277.277,0,0,1,.143-.031Z"
          transform="translate(-188.312 -171.958)"
          fill="#263238"
        />
        <path
          id="Path_10907"
          data-name="Path 10907"
          d="M256.14,205.091l-.256,14.6s-7.555,4.226-11.781,5.378a27.812,27.812,0,0,1-7.171,1.153c-.384,0-2.049-2.689-2.945-3.586s-1.619-.912-3.258-1.731c-2.285-1.142-3.273-1.342-3.145-.83s.256,1.024,1.409,1.665,1.921,1.153,1.921,1.153l.256.512s-2.817-.384-3.97-.64a20.925,20.925,0,0,1-4.1-2.305s-1.255-.077-.359,1.46,1.767,1.614,1.767,1.614a4.651,4.651,0,0,0,1.281,2.433,19.3,19.3,0,0,0,1.793,1.409s-2.433-.64-2.177,0,1.793.9,3.714,1.793,6.275,1.024,8.708,1.409a61.168,61.168,0,0,0,13.318.256c5.122-.512,12.806-3.97,14.215-5.122s.512-14.086.128-17.032-1.153-8.452-1.665-11.013-2.561-6.147-3.842-6.915S256.4,192.413,256.14,205.091Z"
          transform="translate(-196.817 -155.132)"
          fill="#fff"
        />
        <path
          id="Path_10908"
          data-name="Path 10908"
          d="M245.509,231.1a56.774,56.774,0,0,1-7.975-.512c-.738-.118-1.665-.21-2.648-.307a23.2,23.2,0,0,1-6.147-1.122c-.7-.323-1.332-.558-1.9-.768-1.05-.394-1.742-.651-1.946-1.163a.4.4,0,0,1,.061-.441c.19-.22.692-.225,1.26-.143-.256-.2-.548-.446-.871-.727a4.764,4.764,0,0,1-1.332-2.413,3.456,3.456,0,0,1-1.767-1.7c-.374-.645-.456-1.122-.246-1.465a.948.948,0,0,1,.84-.379.22.22,0,0,1,.133.046,21.837,21.837,0,0,0,4.006,2.264c.84.19,2.612.446,3.468.569h0c-.195-.128-.891-.574-1.844-1.106a2.515,2.515,0,0,1-1.537-1.824.43.43,0,0,1,.133-.476c.169-.133.512-.415,3.37,1.024.558.282,1.024.466,1.4.625a5.035,5.035,0,0,1,1.921,1.153,18.718,18.718,0,0,1,1.485,1.88,10.436,10.436,0,0,0,1.332,1.639,28.414,28.414,0,0,0,7.053-1.153c3.847-1.024,10.583-4.723,11.592-5.281l.256-14.45h0c.169-8.267,1.286-13.579,3.073-14.563a1.117,1.117,0,0,1,1.163,0c1.378.825,3.452,4.533,3.96,7.084s1.291,8.155,1.67,11.028c.169,1.306,1.475,15.879-.22,17.262-1.47,1.2-9.266,4.672-14.343,5.179A53.987,53.987,0,0,1,245.509,231.1Zm-20.074-3.98a4.176,4.176,0,0,0,1.608.794c.574.21,1.219.451,1.931.784a22.648,22.648,0,0,0,5.962,1.076c.989.1,1.921.195,2.679.312a60.548,60.548,0,0,0,13.251.256c5-.512,12.642-3.893,14.076-5.066,1.2-.984.512-13.021.036-16.8-.374-2.869-1.142-8.411-1.66-11s-2.561-6.049-3.724-6.741a.61.61,0,0,0-.651,0c-.594.333-2.561,2.326-2.8,14.127l-.256,14.6a.246.246,0,0,1-.133.215c-.072.046-7.627,4.257-11.838,5.4a28.464,28.464,0,0,1-7.238,1.163c-.3,0-.615-.374-1.69-1.844a19.374,19.374,0,0,0-1.434-1.818,4.61,4.61,0,0,0-1.757-1.024c-.4-.169-.861-.353-1.439-.64a8.326,8.326,0,0,0-2.766-1.076c.123.482.266.9,1.27,1.455,1.147.64,1.931,1.158,1.941,1.163a.313.313,0,0,1,.087.1l.256.512a.271.271,0,0,1,0,.266.251.251,0,0,1-.246.1c-.113,0-2.838-.389-3.99-.64a18.024,18.024,0,0,1-4.1-2.3.41.41,0,0,0-.312.138c-.046.072-.108.312.251.932.8,1.378,1.567,1.491,1.573,1.491a.261.261,0,0,1,.225.21,4.446,4.446,0,0,0,1.2,2.285,18.361,18.361,0,0,0,1.767,1.388.256.256,0,0,1-.21.461,6.26,6.26,0,0,0-1.875-.282Zm30.2-7.684Z"
          transform="translate(-196.558 -154.904)"
          fill="#263238"
        />
        <path
          id="Path_10909"
          data-name="Path 10909"
          d="M226.5,254.87a23.2,23.2,0,0,0,5.332,2.315"
          transform="translate(-198.807 -186.471)"
          fill="#fff"
        />
        <path
          id="Path_10910"
          data-name="Path 10910"
          d="M231.59,257.2h-.056a23.365,23.365,0,0,1-5.4-2.341.261.261,0,0,1-.092-.353.251.251,0,0,1,.348-.087,24.06,24.06,0,0,0,5.256,2.285.257.257,0,0,1-.051.512Z"
          transform="translate(-198.565 -186.231)"
          fill="#263238"
        />
        <path
          id="Path_10911"
          data-name="Path 10911"
          d="M232.5,262.37a10.031,10.031,0,0,0,3.017.625"
          transform="translate(-201.734 -190.13)"
          fill="#fff"
        />
        <path
          id="Path_10912"
          data-name="Path 10912"
          d="M235.273,263.005a10.147,10.147,0,0,1-3.109-.645.255.255,0,1,1,.184-.476,9.85,9.85,0,0,0,2.925.61.256.256,0,1,1,0,.512Z"
          transform="translate(-201.49 -189.884)"
          fill="#263238"
        />
        <path
          id="Path_10913"
          data-name="Path 10913"
          d="M227.26,257.41a15.619,15.619,0,0,0,5.322,2.408"
          transform="translate(-199.178 -187.71)"
          fill="#fff"
        />
        <path
          id="Path_10914"
          data-name="Path 10914"
          d="M232.361,259.828H232.3a15.685,15.685,0,0,1-5.394-2.464.256.256,0,1,1,.312-.4,15.475,15.475,0,0,0,5.225,2.361.251.251,0,0,1,.19.307A.256.256,0,0,1,232.361,259.828Z"
          transform="translate(-198.957 -187.465)"
          fill="#263238"
        />
        <path
          id="Path_10915"
          data-name="Path 10915"
          d="M278.661,230.605c5-.927,11.413-3.857,12.688-4.9,1.409-1.153.512-14.086.128-17.032s-1.153-8.452-1.665-11.013-2.561-6.147-3.842-6.915-3.586,1.665-3.842,14.343l-.256,14.6s-2.935,1.639-6.172,3.155C276.95,224.2,279.055,227.071,278.661,230.605Z"
          transform="translate(-222.805 -155.132)"
          fill="#f2b636"
        />
        <path
          id="Path_10916"
          data-name="Path 10916"
          d="M278.418,230.618a.282.282,0,0,1-.174-.067.261.261,0,0,1-.077-.215c.379-3.427-1.654-6.208-2.9-7.561a.266.266,0,0,1-.061-.22.251.251,0,0,1,.143-.184c2.812-1.316,5.425-2.746,6.024-3.073l.256-14.45c.169-8.267,1.286-13.579,3.073-14.563a1.117,1.117,0,0,1,1.163,0c1.378.825,3.452,4.533,3.96,7.084s1.291,8.155,1.67,11.028c.169,1.306,1.475,15.879-.22,17.262-1.363,1.117-7.853,4.036-12.806,4.953Zm-2.561-7.929a10.323,10.323,0,0,1,2.828,7.361c5.122-1.024,11.115-3.867,12.237-4.784,1.2-.984.512-13.021.036-16.8-.374-2.869-1.142-8.411-1.66-11s-2.561-6.049-3.724-6.741a.61.61,0,0,0-.651,0c-.594.333-2.561,2.326-2.8,14.127l-.256,14.6a.246.246,0,0,1-.133.215c-.005.015-2.771,1.562-5.855,3.022Z"
          transform="translate(-222.563 -154.889)"
          fill="#263238"
        />
        <path
          id="Path_10917"
          data-name="Path 10917"
          d="M254.677,177.426a98.681,98.681,0,0,0-1.26,10.757c-.113,4.1,2.976,8.242,7.325,2.976S264.4,177.19,264.4,177.19a12.089,12.089,0,0,1-9.727.236Z"
          transform="translate(-211.935 -148.582)"
          fill="#fff"
        />
        <path
          id="Path_10918"
          data-name="Path 10918"
          d="M256.61,193.682a2.679,2.679,0,0,1-.973-.179c-1.649-.615-2.792-2.95-2.72-5.548a99.025,99.025,0,0,1,1.26-10.8.282.282,0,0,1,.128-.179.266.266,0,0,1,.225,0,11.915,11.915,0,0,0,9.512-.22.256.256,0,0,1,.374.2c.031.359.645,8.867-3.719,14.153C259.038,193.108,257.65,193.682,256.61,193.682Zm-1.977-16.13a94.59,94.59,0,0,0-1.2,10.414c-.067,2.387.937,4.513,2.387,5.061,1.311.512,2.863-.287,4.487-2.254,3.724-4.513,3.688-11.689,3.621-13.405a12.34,12.34,0,0,1-9.292.184Z"
          transform="translate(-211.691 -148.354)"
          fill="#263238"
        />
        <path
          id="Path_10919"
          data-name="Path 10919"
          d="M256.077,188.753h-.041a.256.256,0,0,1-.21-.3,31.312,31.312,0,0,0,.113-5.435.251.251,0,0,1,.236-.271.261.261,0,0,1,.271.241,31.249,31.249,0,0,1-.118,5.553A.251.251,0,0,1,256.077,188.753Z"
          transform="translate(-213.11 -151.293)"
          fill="#263238"
        />
        <path
          id="Path_10920"
          data-name="Path 10920"
          d="M206.076,244.465a2.218,2.218,0,0,0,.195,1.757c.512.845,3.253,2.469,3.253,1.818s-1.368-1.537-1.434-2.013a3.837,3.837,0,0,0-.451-1.106S206.66,243.686,206.076,244.465Z"
          transform="translate(-188.797 -181.27)"
          fill="#fff"
        />
        <path
          id="Path_10921"
          data-name="Path 10921"
          d="M209.105,248.2a6.347,6.347,0,0,1-3.294-2.09,2.443,2.443,0,0,1-.22-1.972.312.312,0,0,1,.036-.072.82.82,0,0,1,.666-.359h0c.63,0,1.224.727,1.291.809a4.1,4.1,0,0,1,.512,1.229,2.613,2.613,0,0,0,.512.651c.441.456.9.927.9,1.4a.389.389,0,0,1-.292.389A.573.573,0,0,1,209.105,248.2Zm-3.043-3.832a1.952,1.952,0,0,0,.184,1.5A6.71,6.71,0,0,0,209,247.716a3.406,3.406,0,0,0-.727-.932,2.136,2.136,0,0,1-.676-.937,3.586,3.586,0,0,0-.42-1.024,1.608,1.608,0,0,0-.876-.589A.3.3,0,0,0,206.063,244.371Z"
          transform="translate(-188.553 -181.028)"
          fill="#263238"
        />
        <path
          id="Path_10922"
          data-name="Path 10922"
          d="M203.588,237.3a9.522,9.522,0,0,0,.2,2.787c.323.886,3.75,3.253,4.61,2.8s-1.829-2.8-2.469-3.54a21.923,21.923,0,0,0-2.341-2.049Z"
          transform="translate(-187.621 -177.901)"
          fill="#fff"
        />
        <path
          id="Path_10923"
          data-name="Path 10923"
          d="M207.895,242.957a2.6,2.6,0,0,1-.9-.21c-1.3-.512-3.4-2.023-3.688-2.817a9.515,9.515,0,0,1-.21-2.889.256.256,0,0,1,.149-.22.266.266,0,0,1,.261.031,22.68,22.68,0,0,1,2.4,2.1c.133.154.359.379.62.64,1.24,1.24,2.177,2.238,2.075,2.879a.512.512,0,0,1-.307.415.846.846,0,0,1-.394.072Zm-4.308-5.394a7.254,7.254,0,0,0,.2,2.192,8.475,8.475,0,0,0,3.4,2.515c.61.241.825.169.876.143.1-.471-1.25-1.824-1.9-2.474-.277-.271-.512-.512-.651-.666a18.272,18.272,0,0,0-1.926-1.711Z"
          transform="translate(-187.379 -177.657)"
          fill="#263238"
        />
        <path
          id="Path_10924"
          data-name="Path 10924"
          d="M203.522,232.79a9.6,9.6,0,0,0,0,2.792c.256.907,3.509,3.509,4.421,3.12s-1.624-2.925-2.208-3.7a22.247,22.247,0,0,0-2.213-2.208Z"
          transform="translate(-187.544 -175.701)"
          fill="#fff"
        />
        <path
          id="Path_10925"
          data-name="Path 10925"
          d="M207.481,238.75a2.366,2.366,0,0,1-.963-.277c-1.26-.6-3.253-2.259-3.483-3.073a9.859,9.859,0,0,1,0-2.9.251.251,0,0,1,.164-.2.261.261,0,0,1,.256.046,22.845,22.845,0,0,1,2.249,2.249c.118.164.328.4.574.686,1.147,1.322,2.008,2.382,1.865,3.017a.543.543,0,0,1-.333.389.8.8,0,0,1-.328.067Zm-4.011-5.686a7.366,7.366,0,0,0,.046,2.2,8.516,8.516,0,0,0,3.212,2.746c.594.282.809.231.861.2.138-.466-1.112-1.906-1.716-2.6-.251-.292-.471-.543-.594-.712a17.97,17.97,0,0,0-1.808-1.839Z"
          transform="translate(-187.303 -175.453)"
          fill="#263238"
        />
        <path
          id="Path_10926"
          data-name="Path 10926"
          d="M204.472,228.232s-1.952-1.024-2.274.261,1.168,2.664,2.536,3.77,1.947.907,1.947-.133S204.472,228.232,204.472,228.232Z"
          transform="translate(-186.932 -173.289)"
          fill="#fff"
        />
        <path
          id="Path_10927"
          data-name="Path 10927"
          d="M205.894,233.012a2.858,2.858,0,0,1-1.573-.8c-1.567-1.27-2.971-2.638-2.623-4.031a1.024,1.024,0,0,1,.589-.743,2.818,2.818,0,0,1,2.049.318.184.184,0,0,1,.082.072c.236.3,2.264,2.961,2.264,4.052,0,.558-.159.912-.466,1.06A.712.712,0,0,1,205.894,233.012Zm-3.073-5.168a.891.891,0,0,0-.343.061.543.543,0,0,0-.3.4c-.277,1.122,1.081,2.4,2.448,3.509.948.763,1.275.7,1.357.666s.174-.271.174-.6c0-.763-1.5-2.884-2.121-3.693a3.074,3.074,0,0,0-1.183-.343Z"
          transform="translate(-186.684 -173.04)"
          fill="#263238"
        />
        <path
          id="Path_10928"
          data-name="Path 10928"
          d="M192.555,302.544a18.158,18.158,0,0,1-7.34,1.537c-4.779.169-8.708-2.392-6.49-4.441a10.793,10.793,0,0,1,8.708-2.73c3.073.512,7.684,1.706,7.684,1.706Z"
          transform="translate(-175.194 -206.931)"
          fill="#fff"
        />
        <path
          id="Path_10929"
          data-name="Path 10929"
          d="M184.536,304.053c-3.2,0-6.147-1.193-6.8-2.807a1.8,1.8,0,0,1,.584-2.085,11.008,11.008,0,0,1,8.928-2.8c3.073.512,7.684,1.7,7.684,1.711a.261.261,0,0,1,.174.154.256.256,0,0,1-.026.236l-2.561,3.924a.251.251,0,0,1-.092.087,18.273,18.273,0,0,1-7.453,1.567Zm1.2-7.3a10.706,10.706,0,0,0-7.069,2.781,1.291,1.291,0,0,0-.456,1.537c.512,1.291,3.268,2.607,6.761,2.479a18.6,18.6,0,0,0,7.171-1.48l2.326-3.586c-1.127-.282-4.769-1.188-7.315-1.614A8.753,8.753,0,0,0,185.734,296.753Zm6.587,5.522Z"
          transform="translate(-174.961 -206.662)"
          fill="#263238"
        />
        <path
          id="Path_10930"
          data-name="Path 10930"
          d="M193.244,298.227c.231.026.476.041.722.051-1.567-.389-4.41-1.065-6.531-1.419a10.793,10.793,0,0,0-8.708,2.73c-1.127,1.024-.661,2.223.7,3.1a3.283,3.283,0,0,1,2.049-3.442C184.362,297.91,190.339,297.91,193.244,298.227Z"
          transform="translate(-175.196 -206.906)"
          fill="#fff"
        />
        <path
          id="Path_10931"
          data-name="Path 10931"
          d="M179.181,302.706a.271.271,0,0,1-.138-.041,2.76,2.76,0,0,1-1.445-1.936,1.916,1.916,0,0,1,.712-1.567,11.008,11.008,0,0,1,8.928-2.8c2.121.353,5.015,1.024,6.546,1.419a.261.261,0,0,1,.195.287.282.282,0,0,1-.266.22,5.749,5.749,0,0,1-.743-.056h0c-3.073-.359-8.892-.292-11.643,1.024a3.022,3.022,0,0,0-1.89,3.186.261.261,0,0,1-.123.246.3.3,0,0,1-.133.015Zm6.546-5.952a10.706,10.706,0,0,0-7.069,2.781,1.409,1.409,0,0,0-.548,1.153,1.952,1.952,0,0,0,.8,1.25,3.5,3.5,0,0,1,2.192-3.16c2.377-1.122,6.516-1.316,9.492-1.219-1.168-.261-2.4-.512-3.447-.692A8.755,8.755,0,0,0,185.728,296.753Z"
          transform="translate(-174.954 -206.662)"
          fill="#263238"
        />
        <path
          id="Path_10932"
          data-name="Path 10932"
          d="M259.777,270.931s-5.122,7.684-10.245,10.757-28.516,3.442-33.3,3.442H206.33v-3.586s15.71-2.244,24.418-7.171,13.318-10.414,20.146-12.637,12.806,1.537,12.637,10.757"
          transform="translate(-188.969 -189.517)"
          fill="#263238"
        />
        <path
          id="Path_10933"
          data-name="Path 10933"
          d="M215.993,285.094h-9.907a.256.256,0,0,1-.256-.256v-3.586a.256.256,0,0,1,.22-.251c.159,0,15.761-2.274,24.326-7.141a89.407,89.407,0,0,0,9.256-6.224c3.724-2.756,6.941-5.122,10.941-6.434,3.432-1.117,6.633-.758,9.02,1.024,2.628,1.947,4.031,5.5,3.949,9.994a.246.246,0,0,1-.261.251.256.256,0,0,1-.251-.261c.082-4.323-1.25-7.724-3.744-9.574-2.249-1.665-5.286-2-8.554-.932-3.919,1.275-7.1,3.632-10.8,6.362a89.482,89.482,0,0,1-9.3,6.249c-8.093,4.61-22.344,6.879-24.29,7.171V284.6h9.65c.271,0,27.594-.036,33.162-3.381,5.015-3.007,10.111-10.6,10.163-10.675a.256.256,0,0,1,.353-.072.251.251,0,0,1,.072.353c-.051.077-5.209,7.765-10.327,10.834C243.73,285.053,216.269,285.094,215.993,285.094Z"
          transform="translate(-188.725 -189.25)"
          fill="#263238"
        />
        <path
          id="Path_10934"
          data-name="Path 10934"
          d="M274.523,299.15a.257.257,0,1,1-.046-.512,28.972,28.972,0,0,0,14.8-7.32.256.256,0,0,1,.364,0,.251.251,0,0,1,0,.359,29.454,29.454,0,0,1-15.065,7.453Z"
          transform="translate(-222.095 -204.212)"
          fill="#fff"
        />
        <path
          id="Path_10935"
          data-name="Path 10935"
          d="M307.447,285.752a.261.261,0,0,1-.159-.056.256.256,0,0,1-.041-.359,13.074,13.074,0,0,0,1.409-2.049.261.261,0,0,1,.343-.113.256.256,0,0,1,.118.343,13.2,13.2,0,0,1-1.47,2.151A.246.246,0,0,1,307.447,285.752Z"
          transform="translate(-238.166 -200.265)"
          fill="#fff"
        />
        <path
          id="Path_10936"
          data-name="Path 10936"
          d="M243.756,288.2a.256.256,0,0,1,0-.512,14.911,14.911,0,0,0,8.047-3.012.251.251,0,0,1,.359.026.261.261,0,0,1-.031.364A15.137,15.137,0,0,1,243.756,288.2Z"
          transform="translate(-207.099 -200.98)"
          fill="#fff"
        />
        <path
          id="Path_10937"
          data-name="Path 10937"
          d="M199.893,266.006l-.563-.056"
          transform="translate(-185.555 -191.876)"
          fill="#263238"
        />
        <path
          id="Path_10938"
          data-name="Path 10938"
          d="M199.649,266.023h-.026l-.558-.051a.261.261,0,0,1-.236-.277.266.266,0,0,1,.277-.236l.574.056a.257.257,0,1,1-.031.512Z"
          transform="translate(-185.31 -191.636)"
          fill="#263238"
        />
        <path
          id="Path_10939"
          data-name="Path 10939"
          d="M294.008,307.155a47.9,47.9,0,0,1,7.858-2.049c3.586-.512,6.485,0,7.509,2.223s.343,4.948-3.242,5.635-10.076-.686-10.757-.686a5.03,5.03,0,0,1-1.706-.512Z"
          transform="translate(-231.571 -210.886)"
          fill="#fff"
        />
        <path
          id="Path_10940"
          data-name="Path 10940"
          d="M303.465,313.2a49.614,49.614,0,0,1-7.12-.707c-.6-.092-1.081-.169-1.214-.169a5.307,5.307,0,0,1-1.824-.512.251.251,0,0,1-.138-.246l.338-4.61a.272.272,0,0,1,.169-.225,48.438,48.438,0,0,1,7.909-2.049c4.1-.589,6.792.231,7.781,2.367a4.247,4.247,0,0,1,.046,3.724,4.556,4.556,0,0,1-3.478,2.269A13.562,13.562,0,0,1,303.465,313.2Zm-9.773-1.788a4.332,4.332,0,0,0,1.439.41,12.922,12.922,0,0,1,1.291.174c2.172.338,6.69,1.024,9.42.512a4.126,4.126,0,0,0,3.114-2,3.734,3.734,0,0,0-.056-3.273c-1.112-2.413-4.554-2.459-7.243-2.075a46.438,46.438,0,0,0-7.648,1.982Z"
          transform="translate(-231.326 -210.678)"
          fill="#263238"
        />
        <path
          id="Path_10941"
          data-name="Path 10941"
          d="M310.978,305a3.866,3.866,0,0,1,2.771,4.231c-.512,3.586-4.784,3.586-7.684,3.586a2.382,2.382,0,0,0-.635.092,23.719,23.719,0,0,0,6.439.082c3.586-.686,4.267-3.417,3.242-5.635C314.43,305.9,312.944,305.184,310.978,305Z"
          transform="translate(-237.307 -210.923)"
          fill="#fff"
        />
        <path
          id="Path_10942"
          data-name="Path 10942"
          d="M309.2,313.212a35.025,35.025,0,0,1-4.042-.277.256.256,0,0,1-.225-.236.266.266,0,0,1,.189-.266,2.824,2.824,0,0,1,.7-.1c2.93,0,6.946,0,7.427-3.365a3.611,3.611,0,0,0-2.607-3.96.261.261,0,0,1-.159-.3.256.256,0,0,1,.271-.195c2.208.195,3.673,1.024,4.349,2.5a4.246,4.246,0,0,1,.046,3.724,4.556,4.556,0,0,1-3.478,2.269A13.236,13.236,0,0,1,309.2,313.212Zm-.051-.512a14.232,14.232,0,0,0,2.433-.184,4.126,4.126,0,0,0,3.114-2,3.734,3.734,0,0,0-.056-3.273,3.627,3.627,0,0,0-2.408-1.931,3.775,3.775,0,0,1,1.537,3.734C313.4,311.552,311.348,312.407,309.145,312.7Z"
          transform="translate(-237.063 -210.688)"
          fill="#263238"
        />
        <path
          id="Path_10943"
          data-name="Path 10943"
          d="M185.1,258.453s-5.809-1.024-9.563,1.875-3.242,5.978.343,10.419,12.806,12.294,32.783,15.879,26.124,2.387,26.124,2.387l.338-4.61a40.584,40.584,0,0,1-23.9-7.684c-11.182-8.2-16.166-13.579-24.2-14.455"
          transform="translate(-172.684 -188.133)"
          fill="#263238"
        />
        <path
          id="Path_10944"
          data-name="Path 10944"
          d="M231.069,289.217c-3.7,0-10.7-.435-22.7-2.592-21.15-3.8-30.078-12.432-32.937-15.971-2.269-2.807-3.181-4.892-2.961-6.772a6,6,0,0,1,2.659-4.006c3.806-2.94,9.522-1.972,9.763-1.926a.246.246,0,0,1,.21.292.256.256,0,0,1-.3.21c-.056,0-5.737-.968-9.359,1.829a5.591,5.591,0,0,0-2.469,3.662c-.2,1.706.707,3.734,2.853,6.388,2.817,3.493,11.643,12.022,32.624,15.792,17.8,3.191,24.654,2.561,25.842,2.418l.3-4.123a41.44,41.44,0,0,1-23.778-7.74c-2.53-1.854-4.774-3.586-6.751-5.122-6.731-5.194-11.177-8.631-17.319-9.3a.258.258,0,0,1,.056-.512c6.285.686,10.757,4.154,17.575,9.4,1.977,1.537,4.216,3.253,6.741,5.122a40.7,40.7,0,0,0,23.742,7.632.256.256,0,0,1,.195.077.266.266,0,0,1,.072.2l-.338,4.61a.261.261,0,0,1-.21.23,20.407,20.407,0,0,1-3.514.2Z"
          transform="translate(-172.438 -187.882)"
          fill="#263238"
        />
        <path
          id="Path_10945"
          data-name="Path 10945"
          d="M192.256,266.422a.255.255,0,0,1-.046-.507,14.352,14.352,0,0,1,1.865-.225.261.261,0,0,1,.271.246.256.256,0,0,1-.246.266,14.069,14.069,0,0,0-1.8.215Z"
          transform="translate(-181.98 -191.749)"
          fill="#fff"
        />
        <path
          id="Path_10946"
          data-name="Path 10946"
          d="M246.848,288.412h0c-.118,0-12.017-.379-25.248-10.557-9.22-7.079-14.8-10.322-19.332-11.2a.261.261,0,1,1,.1-.512c4.61.891,10.275,4.164,19.542,11.295,13.1,10.076,24.833,10.444,24.951,10.45a.251.251,0,0,1,.251.261A.261.261,0,0,1,246.848,288.412Z"
          transform="translate(-186.884 -191.969)"
          fill="#fff"
        />
        <path
          id="Path_10947"
          data-name="Path 10947"
          d="M269.446,150.268c-3.145,1.66-3.586-1.67-3.56-3.678a.174.174,0,0,0,.067-.087c.225-.922,0-5.522-2.648-7.366a15.594,15.594,0,0,0-8.319-3.027,6.126,6.126,0,0,0-4.933,2.945c-1.3,1.962-1.188,4.236-1.291,6.5a29.927,29.927,0,0,0,.128,7.033c.6,3.022,3.427,9.369,6.854,9.369,3.109,0,8.631-4.651,10.526-7.392a3.335,3.335,0,0,0,1.163-.671A4.292,4.292,0,0,0,269.446,150.268Z"
          transform="translate(-209.595 -128.544)"
          fill="#fff"
        />
        <path
          id="Path_10948"
          data-name="Path 10948"
          d="M255.5,161.955c-3.693,0-6.541-6.756-7.1-9.579a25.214,25.214,0,0,1-.179-6.188l.046-.9.031-.9c.072-1.952.138-3.97,1.306-5.732a6.342,6.342,0,0,1,5.122-3.073h0a15.708,15.708,0,0,1,8.462,3.073c2.8,1.947,3,6.659,2.751,7.637a.439.439,0,0,1-.056.123c0,1.132.159,3.012,1.117,3.586a2.053,2.053,0,0,0,2.049-.236.246.246,0,0,1,.246,0,.251.251,0,0,1,.128.21,4.321,4.321,0,0,1-1.936,3.693l-.169.138a3.5,3.5,0,0,1-1.168.692C264.349,157.119,258.853,161.955,255.5,161.955Zm-.784-25.847A5.841,5.841,0,0,0,250,138.935c-1.086,1.644-1.147,3.5-1.219,5.471l-.031.907-.046.9a24.683,24.683,0,0,0,.169,6.065c.64,3.2,3.473,9.164,6.6,9.164,2.915,0,8.329-4.405,10.316-7.284a.236.236,0,0,1,.133-.1,2.966,2.966,0,0,0,1.071-.62l.174-.143a3.992,3.992,0,0,0,1.752-2.879,2.331,2.331,0,0,1-2.2.041c-1.137-.692-1.368-2.628-1.363-4.129a.266.266,0,0,1,.087-.19c.21-.855-.067-5.317-2.561-7.053a15.17,15.17,0,0,0-8.17-2.981Z"
          transform="translate(-209.349 -128.286)"
          fill="#263238"
        />
        <path
          id="Path_10949"
          data-name="Path 10949"
          d="M260.247,164.892a1.076,1.076,0,0,1-.558-.118.466.466,0,0,1-.225-.312,30.482,30.482,0,0,1,1.316-4.969.261.261,0,0,1,.323-.164.251.251,0,0,1,.164.323,32.317,32.317,0,0,0-1.3,4.713,3.55,3.55,0,0,0,1.491-.2.251.251,0,0,1,.318.174.256.256,0,0,1-.174.318A5.466,5.466,0,0,1,260.247,164.892Z"
          transform="translate(-214.883 -139.864)"
          fill="#263238"
        />
        <path
          id="Path_10950"
          data-name="Path 10950"
          d="M261.345,172.477a3.073,3.073,0,0,1-1.772-.512.256.256,0,0,1,.307-.41h0c.072.051,1.762,1.25,4.533-.8a.256.256,0,0,1,.359.051.261.261,0,0,1-.056.359A5.665,5.665,0,0,1,261.345,172.477Z"
          transform="translate(-214.889 -145.416)"
          fill="#263238"
        />
        <path
          id="Path_10951"
          data-name="Path 10951"
          d="M270.989,164.057a2.9,2.9,0,0,1-2.643-1.424.255.255,0,0,1,.461-.22c.041.082.968,1.885,3.913.779a.257.257,0,0,1,.179.481A5.455,5.455,0,0,1,270.989,164.057Z"
          transform="translate(-219.206 -141.304)"
          fill="#263238"
        />
        <path
          id="Path_10952"
          data-name="Path 10952"
          d="M253.162,159.354a2.665,2.665,0,0,1-2.73-1.675.261.261,0,0,1,.179-.318.256.256,0,0,1,.312.174c0,.082.594,1.865,3.524,1.132a.263.263,0,0,1,.123.512A5.815,5.815,0,0,1,253.162,159.354Z"
          transform="translate(-210.476 -138.906)"
          fill="#263238"
        />
        <path
          id="Path_10953"
          data-name="Path 10953"
          d="M257.156,152.233a.261.261,0,0,1-.246-.19,3.9,3.9,0,0,0-1.8-2.254,3.1,3.1,0,0,0-2.73.394.261.261,0,0,1-.348-.1.256.256,0,0,1,.1-.348,3.585,3.585,0,0,1,3.181-.415,4.272,4.272,0,0,1,2.1,2.592.256.256,0,0,1-.179.312Z"
          transform="translate(-211.243 -134.883)"
          fill="#263238"
        />
        <path
          id="Path_10954"
          data-name="Path 10954"
          d="M268.087,163.565a4.287,4.287,0,1,1,4.287-4.287A4.287,4.287,0,0,1,268.087,163.565Zm0-8.063a3.773,3.773,0,1,1,0,.005Z"
          transform="translate(-217.001 -137.753)"
          fill="#263238"
        />
        <path
          id="Path_10955"
          data-name="Path 10955"
          d="M249.987,160.025a4.287,4.287,0,1,1,4.287-4.287A4.287,4.287,0,0,1,249.987,160.025Zm0-8.063a3.775,3.775,0,1,0,3.775,3.775A3.775,3.775,0,0,0,249.987,151.962Z"
          transform="translate(-208.173 -136.027)"
          fill="#263238"
        />
        <path
          id="Path_10956"
          data-name="Path 10956"
          d="M279.4,164.368a.261.261,0,0,1-.251-.21.256.256,0,0,1,.2-.3l3.33-.6a.255.255,0,1,1,.092.5l-3.329.6Z"
          transform="translate(-224.484 -141.783)"
          fill="#263238"
        />
        <path
          id="Path_10957"
          data-name="Path 10957"
          d="M246.156,157a.261.261,0,0,1-.2-.1.256.256,0,0,1,.051-.359l1.209-.907a.256.256,0,0,1,.359.051.261.261,0,0,1-.051.359l-1.209.907a.251.251,0,0,1-.154.051Z"
          transform="translate(-208.27 -138.041)"
          fill="#263238"
        />
        <path
          id="Path_10958"
          data-name="Path 10958"
          d="M254.15,134.147s3.586-2.182,4.835-.113,6.992,15.818,13.159,12.739-.046-13.2-6.029-16.422S255.1,129.89,254.15,134.147Z"
          transform="translate(-212.294 -125.05)"
          fill="#263238"
        />
        <path
          id="Path_10959"
          data-name="Path 10959"
          d="M270.072,147.232c-4.733,0-8.867-8.114-10.757-11.833-.338-.666-.61-1.194-.784-1.48a1.506,1.506,0,0,0-.978-.707c-1.429-.348-3.483.9-3.5.907a.256.256,0,0,1-.384-.271,6.726,6.726,0,0,1,3.811-4.707c2.489-1.147,5.517-.886,8.529.738,4.17,2.249,8.785,8.1,8.708,12.586a4.61,4.61,0,0,1-2.689,4.293,4.344,4.344,0,0,1-1.952.476ZM257,132.638a2.884,2.884,0,0,1,.666.072,2.018,2.018,0,0,1,1.291.943c.184.3.461.835.8,1.537,2.085,4.1,6.961,13.656,12.027,11.126a4.1,4.1,0,0,0,2.408-3.837c.067-4.3-4.4-9.953-8.442-12.13-2.869-1.537-5.737-1.8-8.073-.722a6.254,6.254,0,0,0-3.376,3.785A6.593,6.593,0,0,1,257,132.638Z"
          transform="translate(-212.055 -124.801)"
          fill="#263238"
        />
        <path
          id="Path_10960"
          data-name="Path 10960"
          d="M252.3,135.9h0a.256.256,0,0,1-.256-.256,2.561,2.561,0,0,1,1.291-1.972c1.316-.881,3.4.861,3.488.932a.256.256,0,1,1-.328.394c-.512-.441-2.049-1.455-2.879-.9a2.074,2.074,0,0,0-1.06,1.537.251.251,0,0,1-.256.266Z"
          transform="translate(-211.265 -127.235)"
          fill="#fff"
        />
        <path
          id="Path_10961"
          data-name="Path 10961"
          d="M290.96,157.376h-.067a5.635,5.635,0,0,1-1.537-.691.256.256,0,1,1,.277-.43,5.122,5.122,0,0,0,1.414.625.258.258,0,0,1-.067.512Z"
          transform="translate(-229.404 -138.346)"
          fill="#fff"
        />
        <path
          id="Path_10962"
          data-name="Path 10962"
          d="M272.567,139.336a.251.251,0,0,1-.2-.1,36.583,36.583,0,0,1-2.843-4.948,63.572,63.572,0,0,0-3.13-5.635c-1.322-2.049-2.725-3.15-4.17-3.263a3.806,3.806,0,0,0-2.853,1.142.246.246,0,0,1-.359,0,.251.251,0,0,1,0-.359,4.226,4.226,0,0,1,3.273-1.316c1.614.128,3.145,1.306,4.559,3.5A63.417,63.417,0,0,1,270,133.988a35.9,35.9,0,0,0,2.8,4.876.261.261,0,0,1-.051.359.251.251,0,0,1-.174.113Z"
          transform="translate(-214.627 -123.052)"
          fill="#fff"
        />
        <path
          id="Path_10963"
          data-name="Path 10963"
          d="M275.647,153.976a.251.251,0,0,1-.174-.072,4.313,4.313,0,0,0-4.943-.763.261.261,0,0,1-.348-.108.256.256,0,0,1,.108-.348,4.912,4.912,0,0,1,5.537.85.256.256,0,0,1-.179.441Z"
          transform="translate(-220.099 -136.386)"
          fill="#fff"
        />
        <path
          id="Path_10964"
          data-name="Path 10964"
          d="M270.4,234.937a14.5,14.5,0,0,1-10.439-4.923.261.261,0,0,1,.4-.323,13.926,13.926,0,0,0,10.035,4.733.256.256,0,0,1,0,.512Z"
          transform="translate(-215.113 -174.155)"
          fill="#263238"
        />
      </g>
      <g id="freepik--Floor--inject-211" transform="translate(67.29 244.233)">
        <path
          id="Path_10965"
          data-name="Path 10965"
          d="M257.113,423.632H80.336a.256.256,0,1,1,0-.512H257.113a.256.256,0,1,1,0,.512Z"
          transform="translate(-80.08 -423.12)"
          fill="#263238"
        />
      </g>
    </g>
  </svg>
);
