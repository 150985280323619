import React from "react";
import { Modal } from "react-bootstrap";
function MainModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={props.modalclass}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {props.modaltitle ? <h4>{props.modaltitle}</h4> : ""}
        <>{props.children}</>
      </Modal.Body>
    </Modal>
  );
}
export default MainModal;
