import React, { useState } from "react";
import MainModal from "../modals/MainModal";
import { Form, Input } from "antd";
import "./shareGroup.scss";
import { colorfulShareIcon } from "../../pages/courses/SVGs";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import {
  facebookColofulIcon,
  linkedColorfulIcon,
  telegramColofulIcon,
  twitterColorfulIcon,
  WhatsappColorfulIcon,
} from "../../SVGs";
import MainButton from "../Buttons/MainButton";

function ShareGroup({ shareLabel }) {
  const copyShareLink = async () => {
    await navigator.clipboard
      .writeText(linkToCopy)
      .then(setCopyLinkText("link copied"));
  };
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const [linkToCopy, setLinkToCopy] = useState(window.location.href);
  const [copyLinkText, setCopyLinkText] = useState("copy link");
  return (
    <div className="shareGroup">
      <div className="shareGroup-toggle" onClick={() => handleOpen()}>
        {colorfulShareIcon} {shareLabel}
      </div>
      <MainModal
        show={show}
        onHide={() => {
          handleClose(setShow);
        }}
        modalclass="ShareGroupModal"
      >
        <h5> Share This Course</h5>
        <Form.Item name="copyLink" label="">
          <Input disabled value={linkToCopy} />
          <MainButton
            text={copyLinkText}
            btnClass="copyLink"
            onclick={copyShareLink}
          />
        </Form.Item>
        <div className="shareToSocialMedia">
          <FacebookShareButton url={linkToCopy} quote="try to see article 1">
            {facebookColofulIcon}
          </FacebookShareButton>
          <TwitterShareButton
            url={linkToCopy}
            quote="try to see article 1"
            hashtag="react-"
          >
            {twitterColorfulIcon}
          </TwitterShareButton>
          <WhatsappShareButton url={linkToCopy} quote="try to see article 1">
            {WhatsappColorfulIcon}
          </WhatsappShareButton>

          <LinkedinShareButton url={linkToCopy} quote="try to see article 1">
            {linkedColorfulIcon}
          </LinkedinShareButton>
          <TelegramShareButton url={linkToCopy} quote="try to see article 1">
            {telegramColofulIcon}
          </TelegramShareButton>
        </div>
      </MainModal>
    </div>
  );
}

export default ShareGroup;
