export const ArticleView = ({ url }) => {
  return (
    <>
      <iframe
        title="xsaxsa"
        src={url}
        width="100%"
        height="600px"
        style={{ border: 'none' }}
      />
    </>
  );
};
