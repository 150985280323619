import React from "react";
import { Button } from "react-bootstrap";
import "./MainButton.scss";
function SmlButton({ text, btnClass, onclick, type, loading }) {
  return (
    <>
      <Button
        variant="outline-success"
        className={"smallButton " + btnClass ? btnClass : ""}
        onClick={onclick ? onclick : () => ""}
        type={type ? type : null}
        loading={loading ? loading : ""}
      >
        {text}
      </Button>
    </>
  );
}
export default SmlButton;
