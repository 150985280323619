import React from "react";
import { Radio, Form } from "antd";
export default function DatesAvailability({ headingTitle, isModal }) {
  const dates = [
    {
      month: "May",
      dayNo: "22",
      firstDay: "Sunday",
      secDay: "Tuesday",
      starting_from: "05:00 PM",
      ending_at: "06:00 PM",
      isDisabled: false,
    },
    {
      month: "May",
      dayNo: "22",
      firstDay: "Sunday",
      secDay: "Tuesday",
      starting_from: "05:00 PM",
      ending_at: "06:00 PM",
      isDisabled: true,
    },
    {
      month: "Jun",
      dayNo: "22",
      firstDay: "Sunday",
      secDay: "Tuesday",
      starting_from: "05:00 PM",
      ending_at: "06:00 PM",
      isDisabled: false,
    },
    {
      month: "Jul",
      dayNo: "22",
      firstDay: "Sunday",
      secDay: "Tuesday",
      starting_from: "05:00 PM",
      ending_at: "06:00 PM",
      isDisabled: false,
    },
  ];

  const fullAvailBundles = dates.map((item, i) => {
    if (isModal) {
      return (
        <div
          className={
            item.isDisabled ? "disabled availability-item" : "availability-item"
          }
        >
          <Radio
            value={"date" + i}
            style={{ width: "100%" }}
            disabled={item.isDisabled ? true : false}
          >
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    {item.month} {item.dayNo}
                  </span>
                  <span className="separator">|</span>
                  <span>
                    {item.firstDay} - {item.secDay}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-end">
                Time - {item.starting_from} to {item.ending_at}
              </div>
            </div>
          </Radio>
        </div>
      );
    } else {
      return (
        <div
          className={
            item.isDisabled ? "disabled availability-item" : "availability-item"
          }
        >
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  {item.month} {item.dayNo}
                </span>
                <span className="separator">|</span>
                <span>
                  {item.firstDay} - {item.secDay}
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-12 text-end">
              Time - {item.starting_from} to {item.ending_at}
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <div className="datesAvailability">
      <h4>{headingTitle}</h4>
      <div className="datesAvailability-container">
        {isModal ? (
          <Form>
            <Form.Item
              name="radio-group"
              rules={[{ required: true, message: "Please pick an item!" }]}
            >
              <Radio.Group>{fullAvailBundles}</Radio.Group>
            </Form.Item>
          </Form>
        ) : (
          fullAvailBundles
        )}
      </div>
    </div>
  );
}
