import React from 'react';
import MainButton from '../../Buttons/MainButton';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function DetailsCard({ details }) {

  const now = new Date();
  return (
    <div className="cartsidebar-body">
      {details?.pricePlan === 'paid' ? (
        details?.salePrice ? (
          <div className="cartsidebar-fees">
            <span className="sale_price">${details?.salePrice}</span>{' '}
            <span className="preSale_price">
              <small>
                <s>{details?.price}</s>
              </small>
            </span>
          </div>
        ) : (
          <div className="cartsidebar-fees">{details?.price}EGP</div>
        )
      ) : (
        ''
      )}
      <p className="cartsidebar-course-includes">Details</p>
      <ul className="cartsidebar-ul">
        <li>
          <div className="cartsidebar-li">
            <div>
              <span className="cartsidebar-li-items ">Hosted By</span>
            </div>
            <div>
              <span className="cartsidebar-li-items">
                {details?.created_by}
              </span>
            </div>
          </div>
          <div className="cartsidebar-li-divider"></div>
          <div className="cartsidebar-li">
            <div>
              <span className="cartsidebar-li-items ">Start</span>
            </div>
            <div>
              <span className="cartsidebar-li-items">
                {details?.start_time
                  ? dayjs(details?.start_time).format('MMM D, YYYY H:mm a')
                  : 'coming soon'}
              </span>
            </div>
          </div>
          <div className="cartsidebar-li-divider"></div>
        </li>
        <li>
          <div className="cartsidebar-li">
            <div>
              <span className="cartsidebar-li-items ">Duration</span>
            </div>
            <div>
              <span className="cartsidebar-li-items">{details?.duration}</span>
            </div>
          </div>
          <div className="cartsidebar-li-divider"></div>
        </li>
        <li>
          <div className="cartsidebar-li">
            <div>
              <span className="cartsidebar-li-items ">Category </span>
            </div>
            <div>
              <span className="cartsidebar-li-items">{details?.category} </span>
            </div>
          </div>
          <div className="cartsidebar-li-divider"></div>
        </li>
        <li>
          <div className="cartsidebar-li">
            <div>
              <span className="cartsidebar-li-items ">Time Zone </span>
            </div>
            <div>
              <span className="cartsidebar-li-items">(GMT+02:00) Cairo</span>
            </div>
          </div>
          {Date.parse(now) > Date.parse(details?.end_time) ? (
            <p className="expired-webinar-p">
              This meeting is no longer valid and cannot be joined !
            </p>
          ) : (
            ''
          )}

          {
            (Date.parse(details?.start_time) <= Date.parse(now) &&
              Date.parse(now) <= Date.parse(details?.end_time)) ||
            Date.parse(now) < Date.parse(details?.end_time) ? (
              details?.pricePlan === 'paid' ? (
                <MainButton
                  cssStyle={{ width: '100%', marginTop: '1rem' }}
                  text="Add To Cart"
                  // onclick={() => addToCard(course?.slug)}
                />
              ) : (
                <MainButton
                  cssStyle={{ width: '100%', marginTop: '1rem' }}
                  text="Join Meeting"
                />
              )
            ) : (
              ''
            )
            // (
            //   <Link to={"/webinars/" + details?.slug}>
            //     {" "}
            //     <MainButton
            //       cssStyle={{ width: "100%", marginTop: "1rem" }}
            //       text="Learn More"
            //     />
            //   </Link>
            // )
          }

          {/* <MainButton
            text="join meeting"
            cssStyle={{
              textTransform: "capitalize",
              width: "100%",
              marginTop: "15px",
            }}
          /> */}
        </li>
      </ul>
    </div>
  );
}

export default DetailsCard;
