import React, { useState } from "react";
import { colorfulGiftIcon } from "../../pages/courses/SVGs";
import MainModal from "../modals/MainModal";
import "./gift.scss";
import GiftForm from "./GiftForm";
function SendGift({ giftLabel }) {
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="sendGift">
      <div className="sendGift-toggle" onClick={() => handleOpen()}>
        {colorfulGiftIcon} {giftLabel ? giftLabel : ""}
      </div>

      <MainModal
        show={show}
        onHide={() => {
          handleClose(setShow);
        }}
        modalclass="giftForm"
      >
        <GiftForm />
      </MainModal>
    </div>
  );
}

export default SendGift;
