import React from "react";
import { Button } from "react-bootstrap";
import "./MainButton.scss";
function MainButton({ text, btnClass, onclick, type, cssStyle, disabled }) {
  return (
    <>
      <Button
        variant="outline-success"
        className={"mainButton " + btnClass ? btnClass : ""}
        onClick={onclick ? onclick : () => ""}
        type={type ? type : null}
        style={cssStyle ? cssStyle : {}}
        disabled={disabled ? disabled : 0}
      >
        {text}
      </Button>
    </>
  );
}
export default MainButton;
