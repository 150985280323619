export const helpSingleFaqData = [
  {
    href: "refundstatus",
    title: "Where can I see the status of my refund?",
    setOfP: [
      "Thank you for joining Scoola. We at Scoola (“scoola”, “we”, “us”) respect your privacy and want you to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection practices and describes your rights regarding your personal data.",
      ,
      {
        img: "/assets/images/pages/refund_status.jpg",
        imgAlt: "refund status",
      },
      "Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use the Scoola and CorpU websites, mobile applications, APIs, or related services, including CorpU Open (the “Services”). It also applies to prospective customers of our business and enterprise products.",
      " By using the Services, you agree to the terms of this Privacy Policy. You shouldn’t use the Services if you don’t agree with this Privacy Policy or any other agreement that governs your use of the Services.",
    ],
  },
  {
    href: "receiverefund",
    title: "When will I receive my refund?",
    setOfP: [
      "You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account. If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a user, the account of that user will be closed.",
    ],
  },
  {
    href: "refundpurchasedifferences",
    title: "What is the difference between a refund and a purchase reversal?",
    setOfP: [
      "You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account. If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a user, the account of that user will be closed.",
    ],
  },
  {
    href: "refundrequestdenied",
    title: "Why was my refund request denied?",
    setOfP: [
      "You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account. If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a user, the account of that user will be closed.",
    ],
  },
];

export const helpSingleTopicData = [
  {
    href: "gettingstart",
    title: "Getting Started",
    links: [
      {
        linkTitle: "How to Sign up With Udemy and Log in (on a Browser)",
        linkUrl: "sign-up",
      },
      {
        linkTitle: "Udemy Platforms and Features",
        linkUrl: "fetaures",
      },
      {
        linkTitle: "System Requirements",
        linkUrl: "requirements",
      },
    ],
  },
  {
    href: "learnmore",
    title: "Learn More About Scoola",
    links: [
      {
        linkTitle: "How Does Dscoola Work? FAQ",
        linkUrl: "how-does-dscoola-work",
      },
      {
        linkTitle: "Lifetime Access",
        linkUrl: "lifetime-access",
      },
    ],
  },
  {
    href: "scoolainstructors",
    title: "Scoola's Instructors",
    links: [
      {
        linkTitle:
          "Scoola Instructor Partner Badges: What do Learners Need to Know?",
        linkUrl: "scoola-instructor-partner-badges",
      },
      {
        linkTitle: "Lifetime Access",
        linkUrl: "lifetime-access",
      },
    ],
  },
];
