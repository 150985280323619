import { css } from '@emotion/css';
import { Button } from 'antd';
import { GiftIcon } from 'assets/svg';

function BtnGiftSend() {
  const BtnGiftSendStyles = css`
    min-width: 3.5rem;
    height: 3.5rem;
    /* box-shadow: 0px 0px 6px #d6d1e5d6; */
    svg {
      margin-bottom: 0.2rem;
    }
  `;

  return (
    <Button
      type="default"
      icon={<GiftIcon width={16} />}
      shape="circle"
      className={BtnGiftSendStyles}
    />
  );
}

export default BtnGiftSend;
