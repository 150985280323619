import { Switch } from 'antd';
import { useState } from 'react';
import ModalGetDecision from '../modals/ModalGetDecision';

function CellActivate({ status, record }) {
  const [isModalGetDecisionActive, setIsModalGetDecisionActive] =
    useState(false);

  return (
    <>
      <Switch
        checked={status}
        onChange={(status) => {
          console.log(status);
          setIsModalGetDecisionActive(true);
        }}
      />
      <ModalGetDecision
        open={isModalGetDecisionActive}
        onOk={() => setIsModalGetDecisionActive(false)}
        onCancel={() => setIsModalGetDecisionActive(false)}
        title="Are You Sure ?"
        subTitle="You Want To Deactivate This Coupon"
        okText="Deactivate"
      />
    </>
  );
}

export default CellActivate;
