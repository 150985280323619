import React, { useEffect } from 'react';
import { Skeleton, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import CourseCard from '../../../helpers/cards/courseCard';
import MultipleSlider from '../../../helpers/carousels/multipleSlider/MultipleSlider';
import { levelsList } from '../../../apis/levelsList';
import LiveSessionCard from '../../../helpers/cards/liveSessionCard';
const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
};

function TopViewedTabs() {
  let mostViewed;

  const allMostViewed = useSelector(
    (state) => state.categories.startingCourses.mostViewed,
  );
  // const loadingTopViewed = useSelector((state) => state.categories.loading);
  console.log(allMostViewed, 'loadingTopViewed');
  const allLevels = levelsList;
  if (allMostViewed?.data?.length > 0) {
    mostViewed = allMostViewed?.map((item, i) => {
      if (item?.type == 'mixed' || item?.type == 'recorded') {
        return (
          <SwiperSlide key={i} className={item?.level}>
            <li className="topCourses__item">
              <div>
                <CourseCard
                  course={item}
                  sliderToggle={true}
                  levels={allLevels}
                  isWishlist={item?.isWishlist}
                />
              </div>
            </li>
          </SwiperSlide>
        );
      } else if (item?.type == 'liveClass') {
        return (
          <SwiperSlide key={i} className={item?.level}>
            <li className="topCourses__item">
              <div>
                <LiveSessionCard
                  course={item}
                  sliderToggle={true}
                  levels={allLevels}
                  isWishlist={item?.isWishlist}
                />
              </div>
            </li>
          </SwiperSlide>
        );
      }
    });
  } else {
    mostViewed = 'no courses';
  }
  const mostViewedLevels = (levelNo) => {
    console.log(levelNo, 'levelNo');
    if (allMostViewed?.data?.length > 0) {
      let allCourses = allMostViewed?.filter((item) => item.level == levelNo);
      // console.log(allCourses, allMostViewed, "allCourses");
      return allCourses?.map((item, i) => {
        if (item.type == 'recorded' || item.type == 'mixed') {
          return (
            <SwiperSlide key={i} className={item.level}>
              <li className="topCourses__item">
                <div>
                  <CourseCard
                    course={item}
                    sliderToggle={true}
                    levels={allLevels}
                    isWishlist={item.isWishlist}
                  />
                </div>
              </li>
            </SwiperSlide>
          );
        } else if (item.type == 'liveClass') {
          return (
            <SwiperSlide key={i} className={item.level}>
              <li className="topCourses__item">
                <div>
                  <LiveSessionCard
                    course={item}
                    sliderToggle={true}
                    levels={allLevels}
                    isWishlist={item.isWishlist}
                  />
                </div>
              </li>
            </SwiperSlide>
          );
        }
      });
    }
  };
  // console.log(mostViewedLevels(3), "mostViewedLevels");
  console.log(mostViewed, 'mostViewed');
  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <TabPane tab="Most Popular" key="1">
          <MultipleSlider
            cardsXSmall={1}
            cardsSmall={2}
            cardsMedium={3}
            cardsLarge={4}
          >
            {mostViewed}
          </MultipleSlider>
        </TabPane>
        <TabPane tab="Beginner" key="2">
          <MultipleSlider
            cardsXSmall={1}
            cardsSmall={2}
            cardsMedium={3}
            cardsLarge={4}
          >
            {mostViewedLevels(1)?.length > 0
              ? mostViewedLevels(1)
              : 'no courses here..'}{' '}
          </MultipleSlider>
        </TabPane>
        <TabPane tab="Intermediate" key="3">
          <MultipleSlider
            cardsXSmall={1}
            cardsSmall={2}
            cardsMedium={3}
            cardsLarge={4}
          >
            {mostViewedLevels(2)?.length > 0
              ? mostViewedLevels(2)
              : 'no courses here..'}{' '}
          </MultipleSlider>
        </TabPane>
        <TabPane tab="Advanced" key="4">
          <MultipleSlider
            cardsXSmall={1}
            cardsSmall={2}
            cardsMedium={3}
            cardsLarge={4}
          >
            {mostViewedLevels(3)?.length > 0
              ? mostViewedLevels(3)
              : 'no courses here..'}
          </MultipleSlider>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default TopViewedTabs;
