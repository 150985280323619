import { css } from '@emotion/css';
import CoursesSliderWrapper from 'components/CoursesSliderWrapper/CoursesSliderWrapper';
import SectionHeader from '../SectionHeader';

function TopCourses({ data, loading }) {
  // console.log('TopCourses  data', data);
  const TopCoursesStyles = css`
    margin: 6rem 0;
  `;

  return (
    <div className={TopCoursesStyles}>
      <SectionHeader title="Browse our top courses" viewAllLink="topcourses" />
      <CoursesSliderWrapper courseList={data} loading={loading} />
    </div>
  );
}

export default TopCourses;
