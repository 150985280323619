import React, { useState } from 'react';
import MainButton from '../../../helpers/Buttons/MainButton';
import { dollarSign, oneOnOne } from './SVGs';
import MainModal from '../../../helpers/modals/MainModal';
import RequestAppointment from '../../../components/containers/views/layouts/coursePreview/CoursePreviewTabs/appointments/RequestAppointment';
function TryOneOnOne({ instructorId }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="tryOneOnOne container">
      <div className="oneToOneCont">
        <img className="group3" src="/assets/images/events/group3.jpg" />
        {oneOnOne}

        <div className="oneOnOneLearning">
          <div className="oneOnOne-header">
            <h3>Try One-on-One Learning</h3>
            <div>
              {/* {dollarSign} <span>$25.00</span> */}
              pay Per Hour
            </div>
          </div>
          <p>
            It is a long established fact that a reader will be distracted by
            the
          </p>
          <MainButton
            text={'Request appointment'}
            cssStyle={{ textTransform: 'capitalize' }}
            btnClass="whiteBtn px-4 py-2"
            onclick={handleShow}
          />

          <MainModal
            show={show}
            onHide={handleClose}
            modaltitle={'New Appointment'}
            // modalbutton={"Request Appointment"}
            modalclass="RequestAppointment"
          >
            <RequestAppointment instructorId={instructorId} />
          </MainModal>
        </div>
      </div>
    </div>
  );
}

export default TryOneOnOne;
