import React, { useState, useEffect } from 'react';
import {
  cartLevel,
  cartDuration,
  cartAccess,
  cartArticles,
  cartLectures,
  cartQuizzes,
  cartCertificate,
  playVideo,
} from '../SVGs';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { message, Button, Skeleton } from 'antd';
// import { CourseVideoPlayer } from '../CourseVideo';
import { useDispatch, useSelector } from 'react-redux';
import BookingDetails from './liveCourses/BookingDetails';
import MainModal from '../../../helpers/modals/MainModal';
// import DatesAvailability from './liveCourses.js/DatesAvailability';
import { useNavigate } from 'react-router-dom';
// import RequestBundle from './liveCourses.js/RequestBundle';
// import SendGift from '../../../helpers/gift/SendGift';
import ShareGroup from '../../../helpers/shareGroup/ShareGroup';
import VideoJsPlayer from '../../../helpers/Video';
import { levelsList } from '../../../apis/levelsList';
// import { protectAxios } from '../../../apis/coursesAPI';

import { fetchStart } from '../../../features/courses/cartList';
import { useEnroll } from '../../../helpers/dropdownCart/hooks/useCartList';
import { useQueryClient } from '@tanstack/react-query';
import { loadedLecInfo } from '../../../features/coursePreview/coursePreview';
import { AboutIcon, ActiveHIeartIcon, HeartIcon } from '../../../assets/svg';
import {
  useAddToMyWishlist,
  useRemoveFromMyWishlist,
} from '../../dashboard/myWishlist/hooks/useWishList';
import useApi from 'Hooks/network/useApi';
// import fetch from '../../../auth/AuthInterceptor';
// import { singleCourse } from '../../../features/singleCourse/singleCourse';
// import { fetchSingleLiveCourse } from '../../../features/singleLiveCourse/singleLiveCourseSlice';

dayjs.extend(duration);

const Cartsidebar = ({
  myCourse,
  liveCourse,
  isLoading,
  isAuth,
  isPreview,
}) => {
  const api = useApi();
  const [isWishList, setIsWishlist] = useState(myCourse?.course?.isWishlist);
  const [openVideo, setOpenVideo] = useState(false);
  const [liveIsClosed, setLiveIsClosed] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleClose = (handleModal) => handleModal(false);
  const handleShow = (handleModal) => handleModal(true);
  const levels = levelsList;
  const dispatch = useDispatch();
  const duration = myCourse?.course?.total_duration;
  const hours = duration?.slice(0, duration?.indexOf(':'));
  const oneDigitHour = duration?.slice(1, duration?.indexOf(':'));

  const { currentUser } = useSelector((state) => state?.user);
  const courseContent = useSelector(
    (state) => state.courseContentState?.courseContentState,
  );
  const [cartData, setCartData] = useState();

  const handleFetchCart = (navigateCart) => {
    api.get('cart').then((res) => {
      if (navigateCart) navigate('/cart');
      const isExist = res?.data?.items?.find(
        (i) => i.id === myCourse?.course?.id,
      );
      setCartData(isExist ? true : false);
    });
  };

  useEffect(() => {
    handleFetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCourse?.course?.id]);

  const onenrollSuccsses = (data) => {
    message.success(data.data.message, 5);
    queryClient.invalidateQueries([`cart-info`]);
    // navigate('/student-dashboard/my-courses');
    dispatch(fetchStart());
  };

  const { mutate: enroll } = useEnroll(onenrollSuccsses);

  const enrollNow = (id) => {
    if (currentUser?.isVerified) {
      enroll({ course_id: id });
    } else {
      message.error('Your email address has not been verified', 5);
    }
  };

  const cartsidebarList = [
    {
      icon: cartLevel,
      key: 'Level',
      value: levels.find((i) => i.id === myCourse?.course?.level)?.title,
    },
    {
      icon: cartDuration,
      key: 'Duration',
      value: duration
        ? hours > 10
          ? `${hours} hours`
          : `${oneDigitHour} hours`
        : '0',
    },
    {
      icon: cartAccess,
      key: 'Access',
      value: 'Lifetime ',
    },
    {
      icon: cartArticles,
      key: 'Articles',
      value: myCourse?.course?.totalArtical
        ? `${myCourse?.course?.totalArtical} Articles`
        : 'No Articles',
    },
    {
      icon: cartLectures,
      key: 'Lectures',
      value: myCourse?.course?.total_lectures
        ? `${myCourse?.course?.total_lectures} Lectures`
        : 'No Lectures',
    },
    {
      icon: cartLectures,
      key: 'Sessions',
      value: myCourse?.course?.total_lectures
        ? myCourse?.course?.total_lectures + ' Sessions'
        : 'No Sessions',
    },
    {
      icon: cartQuizzes,
      key: 'Quizes',
      value: myCourse?.course?.total_quiz
        ? myCourse?.course?.total_quiz + ' Quizzes'
        : 'No Quizzes',
    },
    {
      icon: cartCertificate,
      key: 'Certificate',
      value: 'Yes',
    },
  ];

  const videoObj = {
    videoUrl: myCourse?.course?.videoPreviewUrl?.video_src,
    videoType: 'video/' + myCourse?.course?.videoPreviewUrl?.type,
  };

  const addToCard = (id, navigateToCard) => {
    const form = new FormData();

    form.append('item_id', id);
    form.append('item_type', 'course');

    api
      .post('cart', form)
      .then((res) => {
        message.success(res?.data?.message, 5);
        dispatch(fetchStart());
        queryClient.invalidateQueries([`cart-info`]);

        handleFetchCart(navigateToCard);
      })
      .catch((res) => {
        message.error(res?.response?.data?.message, 5);
      });
  };

  const addToMyWishList = (id) => handleAddWishList(id);

  const handleRemoveFromMyWishList = (id) => removeFromWishlist(id);

  const onAddToMyWishlistSuccsses = (data) => setIsWishlist(true);

  const onRemoveSuccsses = (data) => {
    setIsWishlist(false);
  };

  const onRemoveFail = (data) => queryClient.invalidateQueries('single-course');

  const { mutate: handleAddWishList } = useAddToMyWishlist(
    onAddToMyWishlistSuccsses,
  );

  const { mutate: removeFromWishlist } = useRemoveFromMyWishlist(
    onRemoveSuccsses,
    onRemoveFail,
  );

  return (
    <div className={`cartsidebar ${isLoading ? 'card-in-loading' : ''}`}>
      <MainModal
        modalclass="courseBrief"
        show={openVideo}
        onHide={() => {
          handleClose(setOpenVideo);
        }}
      >
        <VideoJsPlayer videoObj={videoObj} />
      </MainModal>

      <div
        className="videoLoading"
        onClick={() => {
          handleShow(setOpenVideo);
        }}
      >
        {isLoading ? (
          <Skeleton.Image className="cart-image-loadin" active />
        ) : (
          <>
            {playVideo}
            <img
              src={myCourse?.course?.thumbnailurl}
              alt=""
              className="cartsidebar-video"
            />
          </>
        )}
      </div>

      <div className="cartsidebar-body course-card-bar">
        {isLoading
          ? null
          : liveCourse && (
              <BookingDetails
                onLiveCourseClose={setLiveIsClosed}
                myCourse={myCourse}
              />
            )}
        <>
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : myCourse?.course?.isEnrolled.is_enrolled ? (
            <div className="cartsidebar-price">
              <AboutIcon />
              <span className="cartsidebar-enrolled-on">
                You have been enrolled{' '}
                {myCourse?.course?.isEnrolled?.enrolled_at ? (
                  <span className="cartsidebar-enrolled-on-date">
                    <span>
                      on{'   '}
                      {dayjs(myCourse?.course?.isEnrolled?.enrolled_at).format(
                        'YYYY-MM-DD',
                      )}
                    </span>
                  </span>
                ) : (
                  ' successfully'
                )}
              </span>
            </div>
          ) : (
            <div className="cartsidebar-price with-icon">
              <div>
                {myCourse?.course?.sale_price !== 0 && (
                  <span className="cartsidebar-body-disconted">
                    $ {myCourse?.course?.sale_price}
                  </span>
                )}
                <span
                  className={
                    myCourse?.course?.sale_price === 0
                      ? 'cartsidebar-body-disconted'
                      : 'cartsidebar-body-prise'
                  }
                >
                  $ {myCourse?.course?.price || 0}
                </span>
              </div>

              <div className="cartsidebar-heart">
                <span className="card-favourite-button">
                  {!isWishList ? (
                    <HeartIcon
                      onClick={() =>
                        !isPreview
                          ? addToMyWishList(myCourse?.course?.id)
                          : null
                      }
                    />
                  ) : (
                    <ActiveHIeartIcon
                      onClick={() =>
                        !isPreview
                          ? handleRemoveFromMyWishList(myCourse?.course?.id)
                          : null
                      }
                    />
                  )}
                </span>
              </div>
            </div>
          )}

          {isLoading ? (
            <Skeleton active paragraph={{ rows: 5 }} />
          ) : (
            <>
              <p className="cartsidebar-course-includes">
                This course includes:
              </p>
              <ul className="cartsidebar-ul">
                {cartsidebarList?.map((item, index) => {
                  if (
                    (item.key === 'Access' && liveCourse) ||
                    (item.key === 'Sessions' && !liveCourse)
                  )
                    return null;
                  else {
                    return (
                      <li key={index}>
                        {item.key === 'Lectures' ? null : (
                          <div className="cartsidebar-li">
                            <div>
                              {item.icon}
                              <span className="cartsidebar-li-items word">
                                {item.key === 'Lectures'
                                  ? 'Sessions'
                                  : item.key}
                              </span>
                            </div>
                            <div>
                              <span className="cartsidebar-li-items">
                                {item.value}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="cartsidebar-li-divider"></div>
                      </li>
                    );
                  }
                })}
              </ul>
            </>
          )}
          {isLoading ? (
            <div className="cart-btn-loadng">
              <Skeleton.Button active shape="primary" block />
              <Skeleton.Button active shape="default" block />
            </div>
          ) : myCourse?.course?.isEnrolled.is_enrolled ? (
            <Button
              type="default"
              disabled={isPreview}
              onClick={() => {
                dispatch(
                  loadedLecInfo({
                    myCourse: myCourse,
                  }),
                );
                navigate(
                  `/course/${myCourse?.course?.slug}/${myCourse?.course?.id}/section/${courseContent?.sections[0]?.id}/preview/${courseContent?.sections?.[0]?.lectures?.[0]?.id}`,
                );
              }}
            >
              Go to Course
            </Button>
          ) : myCourse?.course?.price_plan !== 'free' ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  if (!isAuth) {
                    navigate('/sign-in');
                    return;
                  }
                  addToCard(myCourse?.course?.id);
                }}
                disabled={cartData || (liveCourse && liveIsClosed) || isPreview}
              >
                Add to cart
              </Button>
              <Button
                disabled={(liveCourse && liveIsClosed) || isPreview}
                onClick={() => {
                  if (!isAuth) {
                    navigate('/sign-in');
                    return;
                  }
                  if (cartData) navigate('/cart');
                  else addToCard(myCourse?.course?.id, true);
                }}
              >
                Buy Now
              </Button>
            </>
          ) : (
            <Button
              disabled={(liveCourse && liveIsClosed) || isPreview}
              type="primary"
              onClick={() => {
                console.log({ isAuth });
                if (!isAuth) {
                  navigate('/sign-in');
                  return;
                }
                enrollNow(myCourse?.course?.id);
              }}
            >
              Enroll Now
            </Button>
          )}

          {/* <MainButton
              text="Add To Cart"
              cssStyle={{ marginBottom: ".75rem", width: "100%" }}
              onclick={() => {
                if (liveCourse) {
                  handleShow(setIsAVailableBundles);
                  setButtonType("add To Cart");
                }
              }}
            /> <<<<<<<<<< NOTE: DON"T REMOVEE >>>>>>>>> */}

          {/* <MainModal
              show={isAVailableBundles}
              onHide={() => {
                handleClose(setIsAVailableBundles);
                setButtonType("");
              }}
              modalclass="selectBundle"
            >
              <DatesAvailability
                headingTitle={"Different bundles to Start From"}
                isModal={true}
              />
              <div className="availableBundles">
                <p>Can't find a bundle you were looking for?</p>
                <Link
                  to="#"
                  onClick={() => {
                    if (liveCourse) {
                      handleClose(setIsAVailableBundles);
                      handleShow(setRequestBundle);
                      setButtonType("");
                    }
                  }}
                >
                  Request a bundle
                </Link>
              </div>
              <div className="btn-cont">
                <MainButton type={"Submit"} text={buttonType} />
              </div>
            </MainModal>

            <MainModal
              show={requestBundle}
              onHide={() => {
                handleClose(setRequestBundle);
                setButtonType("");
              }}
              modalclass="requestBundle"
            >
              <RequestBundle />
            </MainModal> <<<<<<<<<<<<<<< NOTE: WHEN BUNDLES WORK >>>>>>>>>>>>>> */}

          <div className="cartsidebar-divider"></div>

          <div className="d-flex justify-content-between cartsidebar-footer">
            <ShareGroup shareLabel={'Share'} />
            {/* <div className="">{colorfulGiftIcon} Gift This Course</div> */}
            {/* <div>
              <SendGift giftLabel="Gift This Course" />
            </div> */}
          </div>
        </>
      </div>
    </div>
  );
};

export default Cartsidebar;
