import React from "react";
import MainButton from "../../../helpers/Buttons/MainButton";
import { startYallaOnlineTop, startYallaOnlineBottom } from "./SVGs";

function BecomeInstructorNow() {
  return (
    <div className="becomeInstructorNow takeActionSection">
      <div className="actionTopSvg">{startYallaOnlineTop}</div>
      <div className="headSection">
        <h2>Start helping your colleagues</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting{" "}
          <br />
          industry.
        </p>
        <MainButton
          text={"Let's Start Now"}
          cssStyle={{ textTransform: "capitalize", padding: "9px 25px" }}
        />
      </div>
      <div className="actionBottomSvg">{startYallaOnlineBottom}</div>
    </div>
  );
}

export default BecomeInstructorNow;
