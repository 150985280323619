import React from "react";
import { Col, Collapse, Row } from "antd";

import { Link } from "react-router-dom";
function HelpSupportFaq() {
  const { Panel } = Collapse;

  return (
    <div className="helpSupport-faq">
      <h3>Frequently Asked Questions</h3>
      <Row gutter={40} justify="center">
        <Col span={12}>
          <Collapse>
            <Panel header="Refund Status: Common Questions" key="1">
              <p>
                <Link to="faq/:id">
                  Where can I see the status of my refund?
                </Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
            </Panel>
            <Panel header="Payment Methods on Scoola" key="2">
              <p>2</p>
            </Panel>
            <Panel header="Lifetime Access" key="3">
              <p>2</p>
            </Panel>
            <Panel header="How to Find Your Missing Course" key="4">
              <p>2</p>
            </Panel>
            <Panel header="Troubleshooting Failed Payments" key="5">
              <p>2</p>
            </Panel>
          </Collapse>
        </Col>
        <Col span={12}>
          <Collapse>
            <Panel
              header="How to Download Your Certificate of Completion"
              key="1"
            >
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
              <p>
                <Link to="/">Where can I see the status of my refund?</Link>
              </p>
            </Panel>
            <Panel header="How to Refund a Course" key="2">
              <p>2</p>
            </Panel>
            <Panel header="Downloading Course Resources" key="3">
              <p>2</p>
            </Panel>
            <Panel header="How Does Scoola Work? FAQ" key="4">
              <p>2</p>
            </Panel>
            <Panel header="How to Refund a Course" key="5">
              <p>2</p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

export default HelpSupportFaq;
