import React from 'react';
import '../cards.scss';
import { Card } from 'react-bootstrap';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import SendGift from '../../gift/SendGift';
import CountdownTimer from '../../CountdownTimer/CountdownTimer';
import MainCard from '../mainCard';
import ReadOnlyRatings from '../../ratings/ReadOnlyRatings';
import { calendarIcon } from '../SVGs';
import {
  useAddToCartList,
  useEnroll,
} from '../../dropdownCart/hooks/useCartList';
import { fetchStart } from '../../../features/courses/cartList';
import { Statistic } from 'antd';
import { Highlight } from 'react-instantsearch-dom';
const { Countdown } = Statistic;

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

function LiveSessionCard({
  course,
  levels,
  sliderToggle,
  isWishlist,
  algolia,
  hits,
}) {
  const accesstoken = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { is_enrolled } = course.isEnrolled;
  const { currentUser } = useSelector((state) => state?.user);

  const trimText = (text, txtNo) => {
    // let text = course?.objective;
    if (text.length > txtNo) {
      return text.slice(0, txtNo) + '...';
    } else {
      return text;
    }
  };

  const onSuccsses = (data) => {
    message.success(data.data.message, 5);

    dispatch(fetchStart());
    queryClient.invalidateQueries([`cart-info`]);
  };

  const onenrollSuccsses = (data) => {
    message.success(data.data.message, 5);
    queryClient.invalidateQueries([`cart-info`]);
    navigate('/student-dashboard/my-courses');
    dispatch(fetchStart());
  };
  const onFail = (data) => {
    message.error(data.response.data.message, 5);
  };
  const { mutate: addToCart } = useAddToCartList(onSuccsses, onFail);
  const { mutate: enroll } = useEnroll(onenrollSuccsses);

  // const addToCard = (slug) => {
  //   addToCart({ course_slug: slug });
  // };
  const addToCard = (id) => {
    addToCart({
      item_id: id,
      item_type: 'course',
    });
  };

  const enrollNow = (id) => {
    if (currentUser?.isVerified) {
      enroll({ course_id: id });
    } else {
      message.error('Your email address has not been verified', 5);
    }
  };

  const goToCourse = () => {
    // console.log({ course_id: course.id });
    navigate('/course-view/' + course?.slug || course.id);
  };

  const courseType = () => {
    if (is_enrolled) {
      return 'View Course';
    } else if (course.price_plan !== 'free' && !is_enrolled) {
      return 'Add to cart';
    } else {
      return ' Enroll Now';
    }
  };
  const courseTypePtn = () => {
    if (is_enrolled && !!accesstoken) {
      return () => goToCourse();
    } else if (course.price_plan !== 'free' && !is_enrolled && !!accesstoken) {
      return () => addToCard(course.id);
    } else if (course.price_plan === 'free' && !is_enrolled && !!accesstoken) {
      return () => enrollNow(course.id);
    } else {
      return () => navigate('/sign-in');
    }
  };

  let courseImg = course?.thumbnailurl;
  let short_detail = course.short_description;
  if (short_detail) {
    if (short_detail.length > 60) {
      short_detail = short_detail.slice(0, 55).concat('...');
    }
  }
  const bookingPercentage = (course.totalEnrolled * 100) / course.max_attends;
  const currentLevel = (id) => {
    let levelArr;
    let level;
    levelArr = levels.filter((i) => i.id === id);
    level = levelArr[0]?.title;
    if (level === 'Pro') {
      level = 'Advanced';
    }
    return level;
  };

  if (!course) return;

  return (
    <>
      <MainCard
        liveIcon={
          <div className="d-flex justify-content-between align-items-center live-icon">
            <img
              src="/assets/images/icons/Group 10569/Group 10569.png"
              alt="live-icon"
            />
            {bookingPercentage ? (
              bookingPercentage === 100 ? (
                <div className="bookingPercentage fullBooking">
                  {Math.floor(bookingPercentage)}% booked
                </div>
              ) : (
                <div className="bookingPercentage onBooking">
                  {' '}
                  {Math.floor(bookingPercentage)}% booked
                </div>
              )
            ) : (
              <div className="bookingPercentage onBooking">0% booked</div>
            )}
          </div>
        }
        counter={
          <div className="live-session-counter">
            <Card.Text className="card-course-text" as="div">
              {
                <CountdownTimer
                  countdownTimestampMs={'01 Jan 2023 00:00:00 GMT'}
                  endedTimeStamp={course?.start_time}
                  course={course}
                />
              }
            </Card.Text>
          </div>
        }
        slider={sliderToggle}
        favorite={
          //         <span className="card-favourite-button">
          //           <svg
          //             version="1.1"
          //             id="svg2"
          //             xmlns="http://www.w3.org/2000/svg"
          //             x="0px"
          //             y="0px"
          //             width="20px"
          //             height="20px"
          //             viewBox="0 0 1200 1200"
          //             enableBackground="new 0 0 1200 1200"
          //           >
          //             <sodipodi
          //               showgrid="false"
          //               id="namedview30"
          //               guidetolerance="10"
          //               gridtolerance="10"
          //               objecttolerance="10"
          //               borderopacity="1"
          //               bordercolor="#666666"
          //               pagecolor="#ffffff"
          //             ></sodipodi>
          //             <path
          //               id="path11796"
          //               d="M832.486,46.17c-48.316,0.497-97.873,9.932-146.357,29.285l-2.201,0.934
          // l-2.201,1.134c-30.735,15.652-58.748,35.232-83.651,58.436c-134.023-115.917-347.394-124.33-493.37,7.271l-1.468,1.334l-1.334,1.401
          // C-26.152,279.721-23.153,485.712,53.607,648.474l0.667,1.268l0.667,1.334c90.404,167.407,259.216,309.652,413.054,426.596l0.4,0.333
          // l0.333,0.268c9.897,7.256,26.915,22.257,46.562,37.022c19.646,14.766,41.839,30.948,74.312,37.156l7.271,1.4l7.338-0.601
          // c34.082-2.939,47.452-15.523,69.843-29.617c22.391-14.095,47.177-31.986,72.645-51.565
          // c50.202-38.595,102.147-83.079,136.351-114.804c0.44-0.409,1.166-0.997,1.601-1.401c0.071-0.06,0.13-0.14,0.2-0.2
          // c72.052-61.482,143.423-132.361,203.926-215.732c0.019-0.025,0.048-0.041,0.065-0.066c0.114-0.153,0.22-0.313,0.334-0.467
          // c95.955-129.193,142.502-303.295,86.987-474.825l-1.001-3.069l-1.334-2.869C1111.497,123.881,977.435,44.681,832.486,46.17z
          // M834.221,151.702c104.434-2.402,195.973,53.518,241.415,149.158c41.99,133.897,5.693,270.32-72.512,375.364l-0.2,0.267
          // l-0.199,0.268c-54.48,75.18-120.459,140.988-188.85,199.189l-0.867,0.801l-0.867,0.8c-30.797,28.667-82.925,73.323-130.547,109.935
          // c-23.812,18.306-46.64,34.665-64.373,45.828c-6.668,4.197-13.008,7.059-18.212,9.272c-5.469-2.988-12.41-7.196-19.611-12.607
          // c-14.5-10.897-29.94-24.809-46.763-37.224c-0.262-0.199-0.538-0.4-0.8-0.601c-149.294-113.553-306.55-251.435-382.169-390.44
          // C88.275,470.559,90.954,313.58,177.683,221.344c117.926-104.852,293.123-82.057,380.5,25.549l42.56,52.366l41.159-53.433
          // c23.682-30.723,51.943-54.21,86.253-72.044C764.118,159.733,799.849,152.492,834.221,151.702L834.221,151.702z"
          //             />
          //           </svg>
          //         </span>
          course.id
        }
        isWishlist={isWishlist}
        title={
          <Link to={'/course-view/' + course?.slug || course.id}>
            <Card.Title className="card-course-title">
              {algolia ? (
                <Highlight attribute="title" hit={hits} />
              ) : (
                trimText(course?.title, 40)
              )}
            </Card.Title>
          </Link>
        }
        coursePath={'/course-view/' + course?.slug || course.id}
        courseImg={
          courseImg
            ? courseImg
            : 'https://img-c.udemycdn.com/course/240x135/4427730_5388.jpg'
        }
        imgAlt={course.title}
        instructorName={
          course.author?.name ? (
            algolia ? (
              <Highlight attribute="author.name" hit={hits} />
            ) : (
              course.author?.name
            )
          ) : (
            'Unknown'
          )
        }
        instructorPage={'/instructors/' + course?.user_id}
        coursePrice={
          course.sale_price ? (
            <div>
              <span className="afterSale">{course?.sale_price}EGP </span>
              <span className="beforeSale">
                <small className="text-muted">{course?.price}EGP</small>
              </span>
            </div>
          ) : (
            <span className="afterSale">
              {course.price ? <>{course.price} EGP</> : 'free'}
            </span>
          )
        }
        addSmlButton={true}
        btnTxt={courseType()}
        addToCard={courseTypePtn()}
        gift={
          <div className="gift-btn">
            <SendGift />
          </div>
        }
      >
        <p>{short_detail}</p>
        <div className="course-level-container justify-content-between">
          <div className="d-flex align-items-center">
            {course?.level === 1 && (
              <img
                src="/assets/images/icons/Mask Group 583.svg"
                alt="Course Level"
              />
            )}
            {course?.level === undefined && (
              <img
                src="/assets/images/icons/Mask Group 583.svg"
                alt="Course Level"
              />
            )}
            {course?.level === 2 && (
              <img
                src="/assets/images/icons/Mask Group 582.svg"
                alt="Course Level"
              />
            )}
            {course?.level === 3 && (
              <img
                src="/assets/images/icons/Icon awesome-signal.svg"
                alt="Course Level"
              />
            )}
            {course?.level === 4 && (
              <img
                src="/assets/images/icons/Icon awesome-signal.svg"
                alt="Course Level"
              />
            )}

            <span className={'course-level ' + course.level}>
              {course.level ? currentLevel(course.level) : ''}
            </span>
          </div>

          <div className="d-flex align-items-center">
            {calendarIcon}
            <span className={'course-level ' + course.level}>
              {dayjs(course?.end_time).diff(course?.start_time, 'w')} Weeks
            </span>
          </div>
        </div>
        {/* {
            <CountdownTimer
              countdownTimestampMs={dayjs(course.reopen_date).valueOf()}
              endedTimeStamp={dayjs(course.closed_date).valueOf()}
              course={course}
            />
          } */}
        <span>
          {course.rating_value ? (
            <ReadOnlyRatings
              rating={course.rating_value}
              totalRating={course.rating_count}
            />
          ) : (
            // <div className="mt-2">
            //   <small className="text-muted ">not rated yet</small>
            // </div>
            <ReadOnlyRatings rating={0} />
          )}
        </span>
      </MainCard>
    </>
  );
}

export default LiveSessionCard;
