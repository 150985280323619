import * as React from 'react';
import './rating.scss';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { Rate } from 'antd';
export default function ReadOnlyRatings({
  rating,
  totalRating,
  justifyContentCenter,
}) {
  //   const [value, setValue] = React.useState(3);

  return (
    <Stack spacing={1} className="readOnlyRating">
      {/* <Rating name="half-rating" defaultValue={2.5} precision={0.5} /> */}
      <div
        className={`d-flex align-items-center ${
          justifyContentCenter ? justifyContentCenter : ''
        }`}
      >
        {/* <span className="ratingAverage">{rating}</span> */}
        <Rate allowHalf disabled defaultValue={rating} />
        {/* <Rate disabled allowHalf defaultValue={rating} /> */}
        <span className="ratingTotal">
          {totalRating ? `(${totalRating})` : ''}
        </span>
      </div>
    </Stack>
  );
}
