import React from "react";
// import TopViewedTabs from '../../../components/common/tabs/TopViewedTabs'
// import TopViewedTabs from "./CoursesToStartWithTabs";
import TopViewedTabs from "./TopViewedTabs";
function CoursesToStartWith({ paramsCat, paramsSubCat }) {
  return (
    <div className="TopViewed">
      <div
        className="container"
        // style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
      >
        <h3 className="page-title">Courses to get you started</h3>
        <div className="row">
          <TopViewedTabs
            paramsCat={paramsCat}
            paramsSubCat={paramsSubCat ? paramsSubCat : null}
          />
        </div>
      </div>
    </div>
  );
}

export default CoursesToStartWith;
